import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import Listaccordion from '../../components/Listaccordion';
import Accordion from '../../components/Dslll/Accordion';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import { getarchivePosts } from '../../actions/journal/dslll/posts';
import { LinearProgress } from '@material-ui/core';
import Archivegroupaccordion from '../../components/Dslll/Archivegroup/Accordion';
import Dlllarticletemplate from '../../downloads/DS-LLL-Article_Template.doc';
import Dlllcopyrightform from '../../downloads/DS-LLL-Copyright_Form.docx';
import moment from 'moment';




const Archives = () => {
  const [postyear, setPostyear] = useState({ _id: "", noofvolume: "" });
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);


  const cpost = useSelector((state) => state.dslllarchivesgroup.posts);


  cpost && cpost.forEach(yearData => {
    yearData.noofvolume.sort((a, b) => {
      // Sort by volume
      if (a.volume !== b.volume) {
        return a.volume - b.volume;
      }
  
      // If volumes are the same, sort by issue
      if (a.Issue !== b.Issue) {
        return b.Issue - a.Issue;
      }
    });
  });

  useEffect(() => {
    if (!refresh) setRefresh(true)
  }, [refresh])
  


  const Archivegroups = () => {
    return (
      <ul className="accordion">
    {!cpost.length ? <LinearProgress/> : cpost.map((key) => (
      <Archivegroupaccordion title={moment(key._id).format("YYYY")} content={key.noofvolume.map((voliss) => 
      <div>
        <Link
        to={{
          pathname: `/lll/archives/volume${voliss.volume}/issue${voliss.Issue}`,
          state: {
            volume: voliss.volume,
            issue: voliss.Issue,
            month_of_issue: voliss.month_of_issue
          }
        }}
      >
            volume {voliss.volume} Issue{voliss.Issue}, {voliss.month_of_issue}
      </Link>
      </div>)} />
    ))}
    </ul>
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getarchivePosts());
  }, [dispatch])

  useEffect(() => {
    if (cpost) setPostyear(cpost);
  }, [cpost])


  const accordionData = [{
    content:
      <div>
        <HashLink smooth to="/lll" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Aim and Scope</HashLink>
        <NavLink to="/lll/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
        <NavLink to="/lll/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
        <NavLink to="/lll/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
        <NavLink to="/lll/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
        <NavLink to="/lll/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
        <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
        <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
        <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
        <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
        <NavLink to="/lll/article-processing-charge" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
        {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
        <a href={Dlllarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
        <a href={Dlllcopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
      </div>
  },
  ];
  // const listaccordionData = [{
  //   heading: "2023", content: <div>
  //                             <Link to="/lll/archives/volume1/issue1"> Volume1 Issue1 , April – June</Link><br/>
  //                             <Link to="/lll/archives/volume1/issue2"> Volume1 Issue2 , July – September</Link><br/>
  //                             <Link to="/lll/archives/volume1/issue3"> Volume1 Issue3 , October – December</Link>
  //                             </div>

  // },];
  return (
    <>
      <Helmet>
      <title>Archives - DS Journal of Language, Linguistics and Literature</title>
      <link rel="canonical" href="https://dsjournals.com/lll/archives"/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="title" content="Archives - DS Journal of Language, Linguistics and Literature"/>
      <meta name="description" content="Browse the archives of LLL journal and access past issues of the journal. Stay updated with the latest research and advancements in Language, Linguistics and Literature."/>
      <meta name="keywords" content="language, linguistics, literature, peer-reviewed journal, international journal, research, analysis, trends, archives, Psychology and Literature, language journal, literature journal, linguistics journal, culture."/>
      <meta property="og:type" content="Website"/>
      <meta property="og:site_name" content="Dream Science"/>
      <meta property="og:url" content="https://dsjournals.com/lll/archives"/>
      <meta property="og:title" content="Archives - DS Journal of Language, Linguistics and Literature"/>
      <meta property="og:description" content="Browse the archives of LLL journal and access past issues of the journal. Stay updated with the latest research and advancements in Language, Linguistics and Literature."/>
      <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Language-Linguistics-and-Literature.webp"/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content="Archives - DS Journal of Language, Linguistics and Literature"/>
      <meta name="twitter:description" content="Browse the archives of LLL journal and access past issues of the journal. Stay updated with the latest research and advancements in Language, Linguistics and Literature."/>
      <meta name="twitter:site" content="@DreamScience4"/>
      <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
      <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Language-Linguistics-and-Literature.webp"/>
      <meta name="robots" content="index, follow"/>

      </Helmet>


      <section id="hero-no-slide-dlll" className="d-flex justify-center align-items-center" style={{ height: "300px" }}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="row justify-content-center">
              <div className="col-lg-12">
                                    <h2 style={{ fontSize: "25px" }}>DS Journal of Language, Linguistics and Literature ( DS-LLL )</h2>
                                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            {/* <h2>Archives</h2> */}
            <ol>
              <li><Link to="/home">Home</Link></li>
              <li><Link to="/journals">Journals</Link></li>
              <li><Link to="/lll">DS-LLL</Link></li>
              <li>Archives</li>
            </ol>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="blog" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 mb-3">
                <div className="list-group">
                  <div className="accordion">
                    {accordionData.map(({ content }) => (
                      <Accordion content={content} />
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h5 style={{ alignItems: 'center' }}>Archives</h5>
                    <ul className="accordion">
                      {/* {listaccordionData.map(({ heading, content }) => (
                        <Listaccordion heading={heading} content={content} />
                      ))} */}
                         <Archivegroups />
                    </ul>
                  </div>
                </div>
                
              </div>



            </div>

          </div>

        </section>


      </main>
    </>
  )
}

export default Archives;
