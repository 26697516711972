import React,{useState} from 'react';

const Rlistaccordion = ({ heading, content }) => {
    const [isActive, setIsActive] = useState(false);
  return (
    <li className="accordion-item">
    <div className="accordion-toggle" onClick={() => setIsActive(!isActive)}>
        <h6>{heading}</h6><span className="accordion-icon">{isActive ? '-' : '+'}</span>
        </div>

{isActive && <div className="accordion-content">{content}</div>}
</li>
  );
};

export default Rlistaccordion