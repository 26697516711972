import React from 'react';
import { Link } from 'react-router-dom';

const Submission = () => {
  return (
    <div>
    <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center">
   <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
     <div className="row justify-content-center">
       <div className="col-xl-8">
         <div className="row justify-content-center">
           <div className="col-xl-8">
             <h2>Article Submission</h2>
             <Link to="/" className="btn-get-started ">Read More</Link>
           </div>
       </div>
     </div>
   </div>
   </div>
 </section>
 
 <section className="breadcrumbs">
   <div className="container">
     <div className="d-flex justify-content-between align-items-center">
       <h2>Archives</h2>
       <ol>
         <li><Link to="/home">Home</Link></li>
         <li><Link to="/journals">Journals</Link></li>
         <li><Link to="/journalsview">Journals View</Link></li>
         <li><Link to="/archives">Archives</Link></li>
         <li>Special Issues List</li>
       </ol>
     </div>
   </div>
 </section>

      <main id="main">
<section class="blog" id="blog">
 <div class="container">
     <div class="row">
         <div class="col-lg-3">
         <div class="abtlistgrp">
          <div class="card">
           <ul class="list-group list-group-flush">
             <Link to="#"  class="list-group-item btn-submit"><i class="bi bi-display"></i>Our Journals</Link>
             <Link to="/journalprofile"  class="list-group-item"><i class="bi bi-book"></i>DS-IJCSE</Link>
           </ul>
         </div>
       </div> 
         </div>
         <div class="col-lg-9">
             <h5>List of Special Issues</h5>
             <div className="row mt-3">
                 <div className="col-lg-12 text-center">
                 <h5 style={{alignItems:'center'}}>List of Speical Issues </h5>
                 </div>
             
             </div>
             <div className="row">
                 <div className="table-responsive-lg">
                 <table style={{width:"100%"}}>
                 <tr>
                     <th style={{padding:"10px",textAlign:"center",border:"1px solid #000"}}></th>
                     <th style={{padding:"10px",textAlign:"center",border:"1px solid #000"}}>Journal Name</th>
                     <th style={{padding:"10px",textAlign:"center",border:"1px solid #000"}}>Submission Link</th>
                 </tr>
                 <tr>
                     <td style={{padding:"10px", border:"1px solid #000"}}><i class="bi bi-arrow-right-circle"></i></td>
                     <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>
                         International Journal of Computer Science and Engineering - IJCSE
                     </td>
                     <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}> <Link to="/articlesubmit" style={{fontWeight:"bold"}}>Click</Link></td>
                 </tr>
                 
             </table>
                 </div>
            
             
             </div>
            

         </div>
       
     </div>
 </div>

</section>


</main>     

    </div>
  )
}

export default Submission