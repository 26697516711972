import React from 'react';
import { Link } from 'react-router-dom';

const Specialissuelist = () => {
  return (
    <>
    <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center">
   <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
     <div className="row justify-content-center">
       <div className="col-xl-8">
         <div className="row justify-content-center">
           <div className="col-xl-8">
             <h2>Special Issues</h2>
             <Link to="/" className="btn-get-started ">Read More</Link>
           </div>
       </div>
     </div>
   </div>
   </div>
 </section>
 
 <section className="breadcrumbs">
   <div className="container">
     <div className="d-flex justify-content-between align-items-center">
       <h2>Archives</h2>
       <ol>
         <li><Link to="/home">Home</Link></li>
         <li><Link to="/journals">Journals</Link></li>
         <li><Link to="/journalsview">Journals View</Link></li>
         <li><Link to="/archives">Archives</Link></li>
         <li>Special Issues List</li>
       </ol>
     </div>
   </div>
 </section>

      <main id="main">
<section class="blog" id="blog">
 <div class="container">
     <div class="row">
         <div class="col-lg-3">
         <div class="abtlistgrp">
          <div class="card">
           <ul class="list-group list-group-flush">
             <Link to="#"  class="list-group-item btn-submit"><i class="bi bi-caret-right-square"></i>IJCSE</Link>
             <Link to="/archives"  class="list-group-item"><i class="bi bi-book"></i>Journal Profile</Link>
               <Link to="#"  class="list-group-item"><i class="bi bi-person"></i>Editor Spotlight</Link>
                 <Link to="/specialissuelist" class="list-group-item"><i class="bi bi-award"></i>Special Issues</Link>
                 <Link to="#" class="list-group-item"><i class="bi bi-calendar-check"></i>Current Issues</Link>
                 
           </ul>
         </div>
       </div> 
         </div>
         <div class="col-lg-9">
             <h5>List of Special Issues</h5>
             <div className="row mt-3">
                 <div className="col-lg-12 text-center">
                 <h5 style={{alignItems:'center'}}>List of Speical Issues </h5>
                 </div>
             
             </div>
             <div className="row">
                 <div className="table-responsive-lg">
                 <table style={{width:"100%"}}>
                 <tr>
                     <th style={{padding:"10px",textAlign:"center",border:"1px solid #000"}}>S.No</th>
                     <th style={{padding:"10px",textAlign:"center",border:"1px solid #000"}}>Article Description</th>
                     <th style={{padding:"10px",textAlign:"center",border:"1px solid #000"}}>Article Id</th>
                 </tr>
                 <tr>
                     <td style={{padding:"0 0 20px 0",textAlign:"center",border:"1px solid #000"}}>1</td>
                     <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>
                         <Link to="/archivesview" style={{fontWeight:"bold"}}>Common Fixed Point Theorems for a Pair of Self-Mappings in Fuzzy Cone Metric Spaces</Link><br/>
                     <p style={{fontSize:"12px"}}>
                         <span style={{fontWeight:"bold"}}><i class="bi bi-pencil-square"></i> Saif Ur Rehman <sup>1</sup>, Yongjin Li <sup>2</sup>, Shamoona Jabeen<sup>3</sup>, and Tayyab Mahmood<sup>4</sup></span>
                     </p>
                     </td>
                     <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>SPCSE-20213001</td>
                 </tr>
                 <tr>
                     <td style={{padding:"0 0 20px 0",textAlign:"center",border:"1px solid #000"}}>2</td>
                     <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>
                         <Link to="/archivesview" style={{fontWeight:"bold"}}>Common Fixed Point Theorems for a Pair of Self-Mappings in Fuzzy Cone Metric Spaces</Link><br/>
                     <p style={{fontSize:"12px"}}>
                         <span style={{fontWeight:"bold"}}><i class="bi bi-pencil-square"></i> Saif Ur Rehman <sup>1</sup>, Yongjin Li <sup>2</sup>, Shamoona Jabeen<sup>3</sup>, and Tayyab Mahmood<sup>4</sup></span>
                     </p>
                     </td>
                     <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>SPCSE-20213001</td>
                 </tr>
             </table>
                 </div>
            
             
             </div>
            

         </div>
       
     </div>
 </div>

</section>


</main>     
 </>
  )
}

export default Specialissuelist;