import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dscse/Accordion';

import { HashLink } from 'react-router-hash-link';
import Dsjstarticletemplate from '../../downloads/DS-DST-Article_Template.doc';
import Dsjstcopyrightform from '../../downloads/DS-DST-Copyright_Form.docx';

const Dsttopics = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const accordionData = [{
        content: <div>
            <HashLink smooth to="/dst" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Aim and Scope</HashLink>
            <NavLink to="/dst/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
            <NavLink to="/dst/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/dst/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/dst/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/dst/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/dst/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
            {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
            <a href={Dsjstarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Dsjstcopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
        </div>
    },
    ];

    return (
        <>
            <Helmet>
            <title>Topics - Digital Science and Technology - DS Journals</title>
                <link rel="canonical" href="https://dsjournals.com/dst/topics"/>
                <meta name="title" content="Topics - Digital Science and Technology - DS Journals"/>
                <meta name="description" content="DST Topics: Browse and explore diverse areas of research in Digital Science and Technology covered by DS Journals. Find the latest trends and innovations."/>
                <meta name="keywords" content="digital science, technology, peer-reviewed journal, international journal, research, analysis, trends, archives, algorithms, digital science engineering, digital science, data mining, latest science technology, digital science, science technology vision."/>
                <meta property="og:type" content="Website"/>
                <meta property="og:site_name" content="Dream Science"/>
                <meta property="og:url" content="https://dsjournals.com/dst/topics"/>
                <meta property="og:title" content="Topics - Digital Science and Technology - DS Journals"/>
                <meta property="og:description" content="DST Topics: Browse and explore diverse areas of research in Digital Science and Technology covered by DS Journals. Find the latest trends and innovations."/>
                <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Digital-Science-and-Technology.webp"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="Topics - Digital Science and Technology - DS Journals"/>
                <meta name="twitter:description" content="DST Topics: Browse and explore diverse areas of research in Digital Science and Technology covered by DS Journals. Find the latest trends and innovations."/>
                <meta name="twitter:site" content="@DreamScience4"/>
                <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
                <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Digital-Science-and-Technology.webp"/>
                <meta name="robots" content="index, follow"/>
            </Helmet>
            <section id="hero-no-slide-dst" className="d-flex justify-cntent-center align-items-center" style={{ height: "300px" }}>
                <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="row justify-content-center">
                            <div className="col-lg-12">
                            <h1 style={{fontSize:"25px"}}>DS Journal of Digital Science and Technology ( DS-DST )</h1>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">

                        <ol>
                            <li><NavLink to="/home">Home</NavLink></li>
                            <li><NavLink to="/journals">Journals</NavLink></li>
                            <li> <NavLink to="/dst">DS-DST</NavLink></li>
                            <li>Topics</li>
                        </ol>
                    </div>
                </div>
            </section>

            <main id="main">
                <section className="blog" id="blog">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 mb-3">
                                <div className="list-group">
                                    <div className="accordion">
                                        {accordionData.map(({ content }) => (
                                            <Accordion content={content} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">

                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>Topics</p>
                                <p style={{ textAlign: 'justify', textIndent: "50px" }}>
                                    DST is intended for researchers, academicians and industrialists in the fields of Digital Science and
                                    technology. The journal publishes peer-reviewed research articles, and also reviews & analyses, short
                                    communications, case studies, and more. It is primarily interested in the convergence of new technologies,
                                    such as cloud, analytics, blockchain, robotics, automation, internet of things and digital technologies.
                                    Subjects in scope include:
                                </p>
                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>Papers are solicited from, but not limited to the following topics:</p>
                                <p style={{ textAlign: 'justify' }}>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-sm-6'>
                                            <ul>
                                                <li>3D imaging</li>
                                                <li>3D sensing</li>
                                                <li>Activity recognition, event detection, anomaly detection</li>
                                                <li>Adaptive filtering and systems</li>
                                                <li>Advanced communications network infrastructures</li>
                                                <li>Adversarial Machine Learning</li>
                                                <li>Agent-based modelling and simulation</li>
                                                <li>Analog techniques</li>
                                                <li>Anonymization and de-identification technologies</li>
                                                <li>Applied cryptography and cryptographic protocols</li>
                                                <li>Applied mathematics</li>
                                                <li>Artificial and augmented intelligence</li>
                                                <li>Artificial neural networks</li>
                                                <li>Augmented reality</li>
                                                <li>Authentication and identity management technologies</li>
                                                <li>B2B, B2C, B2G, C2C Advancements</li>
                                                <li>Big Data Technologies</li>
                                                <li>Bitcoin, cryptocurrency and digital cash</li>
                                                <li>Blockchain and emerging technologies</li>
                                                <li>Botnets</li>
                                                <li>Business informatics</li>
                                                <li>Centralized and distributed data centers</li>
                                                <li>Circuits systems integrated circuits</li>
                                                <li>Cloud computing</li>
                                                <li>Cloud-let and fog-computing</li>
                                                <li>Coding theory</li>
                                                <li>Color perception</li>
                                                <li>Color, multispectral and hyperspectral imaging</li>
                                                <li>Communication systems and networks</li>
                                                <li>Communications and control</li>
                                                <li>Computability theory</li>
                                                <li>Computational imaging</li>
                                                <li>Computational intelligence, machine learning methods</li>
                                                <li>Computational mechanics of solids and fluids</li>
                                                <li>Computational photography</li>
                                                <li>Computational simulation methodologies</li>
                                                <li>Computer graphics and animation</li>
                                                <li>Computer multitasking multiprocessing</li>
                                                <li>Computer networking and Internet</li>
                                                <li>Computer programming languages</li>
                                                <li>Computer storage (memory)</li>
                                                <li>Computers and computation</li>
                                                <li>CPU design</li>
                                                <li>Cryptography and coding for signals</li>
                                                <li>Cyber security compliance</li>
                                                <li>Cybernetics</li>
                                                <li>Cyber-physical systems</li>
                                                <li>Cybersecurity and data protection</li>
                                                <li>Darknet and Darkweb</li>
                                                <li>Data Analytics</li>
                                                <li>Data Curation and Internet Archiving</li>
                                                <li>Data fusion and data assimilation</li>
                                                <li>Data mining</li>
                                                <li>Data Science</li>
                                                <li>Data Visualization</li>
                                                <li>Data-driven marketing </li>
                                                <li>Data-driven modelling and simulation</li>
                                                <li>Deep learning</li>
                                                <li>Denial of service (dos)</li>
                                                <li>Design and evaluation</li>
                                                <li>Design and test of computers</li>
                                                <li>Digital and sensory marketing</li>
                                                <li>Digital Communications and Networks</li>
                                                <li>Digital Economy</li>
                                                <li>Digital Ecosystems</li>
                                                <li>Digital Health</li>
                                                <li>Digital Humanities and Society       </li>
                                                <li>Digital Ledger Technology</li>
                                                <li>Digital Marketing</li>
                                                <li>Digital Multimedia Broadcasting</li>
                                                <li>Digital Multimedia Processing</li>
                                                <li>Digital signal processing</li>
                                                <li>Digital Society Policies and Regulations (GDPR)</li>
                                                <li>Digital techniques</li>
                                                <li>Digital Technologies</li>
                                                <li>Digital Tele-vision Systems</li>
                                                <li>Digitization of Franchising</li>
                                                <li>Digitized Data and Digital Conversion</li>
                                                <li>Distributed ledger technology (DLT)</li>
                                                <li>Edge computing</li>
                                                <li>Electronic and Mobile</li>
                                                <li>Electronic Business Technologies</li>
                                                <li>Electronic Commerce </li>
                                                <li>Embedded computers</li>
                                                <li>Embedded computing</li>
                                                <li>Empirical software engineering</li>
                                                <li>Enabling, disruptive technologies</li>
                                                <li>End-user constructive pro-activity enabling</li>
                                                <li>End-user-centered design</li>
                                                <li>ERP and CRM Advancements</li>
                                                <li>Ethical aspects of software engineering</li>
                                                <li>Evolution of internet basic services</li>
                                                <li>Evolutionary computing</li>
                                                <li>Exoskeletons, prosthetics, and artificial organs</li>
                                                <li>Expert systems</li>
                                                <li>Financial Technologies (Fintech)</li>
                                                <li>Fog/edge/cloud computing in software engineering</li>
                                                <li>Forensics, including computer and network forensics</li>
                                                <li>Fuzzy logic</li>
                                                <li>Genetic algorithms</li>
                                                <li>Genomics and Personal Genetic Information</li>
                                                <li>Graphics</li>
                                                <li>Graph-theoretic approach</li>
                                                <li>Green computing</li>
                                                <li>Green networking</li>
                                                <li>Hard disk drives, floppy disk drives and optical disc drives</li>
                                                <li>Hardware & architecture</li>
                                                <li>Health Informatics</li>
                                                <li>Health Information Systems</li>
                                                <li>High dynamic range imaging</li>
                                                <li>High performance simulation</li>
                                                <li> Human-Computer Interaction</li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-6'>
                                            <ul>

                                                <li>Human-machine interface</li>
                                                <li>Image and video search, analysis and understanding</li>
                                                <li>Image enhancement, smoothing, filtering, fusion and compression</li>
                                                <li>Image processing and pattern recognition</li>
                                                <li>Image segmentation and analysis, feature detection and extraction</li>
                                                <li>Industrial internet</li>
                                                <li>Information and communications technology</li>
                                                <li>Information applications</li>
                                                <li>Information extraction</li>
                                                <li>Information in society and social development</li>
                                                <li>Information intelligence</li>
                                                <li>Information processes</li>
                                                <li>Information systems</li>
                                                <li>Information theory and methodology</li>
                                                <li>Information visualization</li>
                                                <li>Information-theoretic security</li>
                                                <li>Intelligent machine/agent</li>
                                                <li>Interaction with computers</li>
                                                <li>Internet access and network communication</li>
                                                <li>Internet Health Care and Telemedicine</li>
                                                <li>Internet of Things</li>
                                                <li>Key management, cryptographic credential management</li>
                                                <li>Knowledge management</li>
                                                <li>Knowledge reasoning</li>
                                                <li>Knowledge representation</li>
                                                <li>Lidar and laser scanning</li>
                                                <li>Machine Learning</li>
                                                <li>Malware technology</li>
                                                <li>Medical Data Systems</li>
                                                <li>Medical Device Interoperability</li>
                                                <li>Medical informatics</li>
                                                <li>MEMS/NEMS</li>
                                                <li>Model verification and validation</li>
                                                <li>Modelling and simulation languages</li>
                                                <li>Multidimensional and multivariate signal processing</li>
                                                <li>Multimodal approach and multimedia</li>
                                                <li>Multimodal interaction, interfaces, and communication</li>
                                                <li>Multimodal perception</li>
                                                <li>Multimodal science, technology, and interfaces</li>
                                                <li>Multi-sensor data fusion and SLAM</li>
                                                <li>Multisensory experiences</li>
                                                <li>Multi-spectral and hyperspectral remote sensing</li>
                                                <li>Natural language processing</li>
                                                <li>Net-living lifestyling personalization and optimization</li>
                                                <li>Network and distributed operating systems</li>
                                                <li>Network information theory and coding</li>
                                                <li>Network intelligence</li>
                                                <li>Network management</li>
                                                <li>Network planning</li>
                                                <li>Network protocols and architectures</li>
                                                <li>Network security</li>
                                                <li>Network signal processing</li>
                                                <li>Neural networks and machine learning</li>
                                                <li>Neuroimaging and neuroinformatics</li>
                                                <li>Novel and biologically inspired robotics</li>
                                                <li>Patient Accessibility</li>
                                                <li>Pattern recognition, classification, and mining</li>
                                                <li>Personal computers</li>
                                                <li>Pervasive computing</li>
                                                <li>Philosophy/ethics of information</li>
                                                <li>Physical layer security</li>
                                                <li>Physical layer techniques</li>
                                                <li>Physical modeling and signatures</li>
                                                <li>Physical sensors</li>
                                                <li>Post quantum computing</li>
                                                <li>Privacy compliance</li>
                                                <li>Probabilistic computing</li>
                                                <li>Programmable analog computer</li>
                                                <li>Programming languages, code assessment, and tools</li>
                                                <li>Propagation models</li>
                                                <li>Protocols and services</li>
                                                <li>Psychology and neuroscience</li>
                                                <li>Quantum image processing</li>
                                                <li>Quantum imaging</li>
                                                <li>Quantum information</li>
                                                <li>Quantum security</li>
                                                <li>Reliability compliance</li>
                                                <li>Remote sensors</li>
                                                <li>Responsible and Transparent Data Science</li>
                                                <li>Robotic process automation (RPA)</li>
                                                <li>Robotics</li>
                                                <li>Safety and Security</li>
                                                <li>Satellite and drone communications</li>
                                                <li>Security metrics and the science of security</li>
                                                <li>Semantic structures and related software tools</li>
                                                <li>Sensing and imaging</li>
                                                <li>Sensor technology and application</li>
                                                <li>Signal separation, extraction, and factorization </li>
                                                <li>Simulation and Gamification</li>
                                                <li>Simulation visualization</li>
                                                <li>Smart living.</li>
                                                <li>Social Media and Online Social Networks</li>
                                                <li>Softwarization and programability</li>
                                                <li>Spectrum usage and allocation</li>
                                                <li>Sport Analytics</li>
                                                <li>Storage</li>
                                                <li>Supercomputers</li>
                                                <li>Telecommunication Systems</li>
                                                <li>Tensor networks</li>
                                                <li>Text Mining and Analysis</li>
                                                <li>Thermography, infrared imaging</li>
                                                <li>Traffic analysis</li>
                                                <li>Trust compliance</li>
                                                <li>Ubiquitous computing</li>
                                                <li>Usable Security</li>
                                                <li>Video and multimedia analysis</li>
                                                <li>Virtualization</li>
                                                <li>Visualization of big data financial systems</li>
                                                <li>Wearable Computing</li>
                                                <li>Web services</li>
                                                <li>Wireless sensor network</li>
                                            </ul>

                                        </div>
                                    </div>

                                </p>

                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Dsttopics;