import React,{useState,useEffect} from 'react';
import {NavLink,useHistory} from 'react-router-dom';
import { getPostsBySearch } from '../actions/journal/posts';
import './Navbar.css';
import { useDispatch } from 'react-redux';




const Navbar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  
  // const searchPost = (e) =>  {
  //     setSearch(e.target.value)
  //     if (e.keyCode === 13 || search.trim().length !== 0) {
  //       e.preventDefault();
  //       dispatch(getPostsBySearch({search}));
  //       history.push(`/search?searchQuery=${search}`);
  //     }else {
  //       history.push('/home');
  //     }
  // }
 


  const searchPost = () =>  {
    if (search.trim().length !== 0) {
      dispatch(getPostsBySearch({search}));
      history.push(`/search?searchQuery=${search}`);
    } else {
      history.push('/home');
    }
  }

  const clear = () =>{
    setSearch('');
  }
  // useEffect(() => {
  //   clear();
  // }, [])
  return (
    
    <>
    <header id="header" className="fixed-top d-flex align-items-center header-transparent">
        {/* <header id="header" className="fixed-top d-flex align-items-center header-transparent"> */}
        <div className="container-fluid d-flex justify-content-between align-items-center">
          {/* <div className="container d-flex justify-content-between align-items-center"> */}
            <div className="logo flex-grow-1 ">
            <img  src="assets/img/DS_Tree_3.png" alt="logo" className="img-fluid"/>
              {/* <h1 className="text-light"><Link to="/"><span>Dream Science</span></Link></h1> */}
            </div>
            <nav id="navbar" className="navbar">
              <ul>
                <li className ='nav-item'> <NavLink className="nav-link" to="/home">Home</NavLink></li>
                <li className ='nav-item'> <NavLink className="nav-link" to="/journals">Journals</NavLink></li>
                <li className ='nav-item'> <NavLink className="nav-link" to="/for-authors">Authors</NavLink></li>
                <li className ='nav-item'> <NavLink className="nav-link" to="/for-reviewers">Reviewers</NavLink></li>
                {/* <li><Link to="/pubethics">Publication Ethics</Link></li> */}
                <li className ='nav-item'><NavLink to="/for-editors">Editors</NavLink></li>
              </ul>
            </nav>
            <div className="searchbar">
              <i className="bi bi-search mobile-search-toggle ml-auto" aria-hidden="true" ></i>
            <div className="search-box" id="togglesearch-mobile">
              <form>
              <input type="text" onChange={(e) =>  setSearch(e.target.value)} placeholder="Search Your Article" required /> */}
              <input type="button" onClick={searchPost}  value="search" />
              {/* <input type="text" onKeyDown={{searchPost}} placeholder="Search Your Article" required />
                <input type="button" onClick={searchPost}  value="search" /> */}
              </form>
              
              </div>
              </div>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </div>
        </header>

      </>
  )
}

export default Navbar;