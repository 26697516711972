import React from 'react'

const Notfound404 = () => {
    return (
        <>
        <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center" style= {{height:"300px"}}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="row justify-content-center">
                <div className="col-xl-8">
                  <h2>404 Not Found</h2> 
                </div>
            </div>
          </div>
        </div>
        </div>
      </section>
            <main id="main">
                <section className="blog" id="blog">
                    {/* <img src="img/404.jpg" alt="404image"/> */}
                    <h1 style={{textAlign:"center",fontSize:"45px"}}>404!</h1>
                    <h5 style={{textAlign:"center",fontSize:"35px"}}>Not Found Page</h5>
                </section>
            </main>
            
        </>
    )
}

export default Notfound404
