import React,{useEffect} from 'react';
import {Link,NavLink} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dsair/Accordion';
import { HashLink } from 'react-router-hash-link';
import Ddsairarticletemplate from '../../downloads/DS-AIR-Article_Template.doc';
import Ddsaircopyrightform from '../../downloads/DS-AIR-Copyright_Form.docx';


const Dsdsairapc = () => {
    useEffect(()=>{
        window.scrollTo(0,0);
      },[]);

      const accordionData = [{
        content: <div>
           <NavLink smooth to="/air" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</NavLink>
            <NavLink to="/air/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
            <NavLink to="/air/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/air/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/air/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/air/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/air/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
            <a href={Ddsairarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Ddsaircopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
    </div> 
    },
      ];

  return (
    <>
            <Helmet>
            <title>Article Processing Charges | For Authors | Artificial Intelligence and Robotics</title>
                    <link rel="canonical" href="https://dsjournals.com/air/apc"/>
                    <meta name="title" content="Article Processing Charges | For Authors | Artificial Intelligence and Robotics"/>
                    <meta name="description" content="Learn about the article processing charges (APCs) for DS-RCE Journal and get a step closer to publishing your research in a renowned academic journal."/>
                    <meta name="keywords" content="article processing charges, apc journal, free article processing charges journals, processing charges, apc open access, apc article processing charge, apc journal meaning, apc charges, article processing fee, apc of journal, apc in journals."/>
                    <meta property="og:type" content="Website"/>
                    <meta property="og:site_name" content="Dream Science"/>
                    <meta property="og:url" content="https://dsjournals.com/air/apc"/>
                    <meta property="og:title" content="Article Processing Charges | For Authors | Artificial Intelligence and Robotics"/>
                    <meta property="og:description" content="Learn about the article processing charges (APCs) for DS-RCE Journal and get a step closer to publishing your research in a renowned academic journal."/>
                    <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Artificial-Intelligence-and-Robotics.webp"/>
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:title" content="Article Processing Charges | For Authors | Artificial Intelligence and Robotics"/>
                    <meta name="twitter:description" content="Learn about the article processing charges (APCs) for DS-RCE Journal and get a step closer to publishing your research in a renowned academic journal."/>
                    <meta name="twitter:site" content="@DreamScience4"/>
                    <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
                    <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Artificial-Intelligence-and-Robotics.webp"/>
                    <meta name="robots" content="index, follow"/>
            </Helmet>
     <section id="hero-no-slide-dsair" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
                <div className="col-xl-8">
                    <div className="row justify-content-center">
                        {/* <div className="col-lg-12">
                            <h2>APC</h2>
                        </div> */}
                        <div className="col-lg-12">
                                    <h2 style={{ fontSize: "25px" }}>DS Journal of Artificial Intelligence and Robotics ( DS-AIR )</h2>
                                </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="breadcrumbs">
        <div className="container">
            <div className="d-flex justify-content-between align-items-center">
                
                <ol>
                <li><NavLink to="/home">Home</NavLink></li>
                <li><NavLink to="/journals">Journals</NavLink></li>
                <li> <NavLink to="/air">DS-AIR</NavLink></li>
                <li>APC</li>
              </ol>
            </div>
        </div>
    </section>

<main id="main">
<section className="blog" id="blog">
<div className="container">
<div className="row">
    <div className="col-lg-3 mb-3">
      <div className="list-group">
        <div className="accordion">
                        {accordionData.map(({content }) => (
                          <Accordion content={content} />
                      ))}
        </div>
      </div>
   </div>
    <div className="col-lg-9">
      
<p style={{textAlign:'justify',fontWeight:"bold"}}>APC</p>
<p style={{textAlign:'justify',textIndent:"50px"}}>
Dream Science journals does not charge for either submissions or publication. There is no article processing charge. It’s completely free of cost journal.  
</p>



    </div>
</div>
</div>
</section>
</main>
    </>
  )
}

export default Dsdsairapc;