import { FETCH_BY_SEARCH } from "../../constants/actionTypes";

import * as api from '../../api/index.js';


export const getPostsBySearch = (searchQuery) => async (dispatch) =>{
  try{
    const {data:{data}}  = await api.fetchPostBySearch(searchQuery);
    console.log(data);
    dispatch({ type: FETCH_BY_SEARCH, payload: data });
  }catch(error){
    console.log(error);
  }
  };








