
import React,{useState, useEffect} from 'react';
// import { Link } from 'react-router-dom';

import Posts from '../../../components/Dslll/Posts/Posts';
import { useDispatch } from 'react-redux';
import { getjournalPosts } from '../../../actions/journal/dslll/posts';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { Box} from '@material-ui/core';
import useStyles from './styles';
import TextField from "@mui/material/TextField";

const Dlanview = () => {
    const [currentId, setCurrentId] = useState(0);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
  
    useEffect(()=>{
      dispatch(getjournalPosts());
    },[currentId,dispatch]);
  
    const addRecord = ()=>
    {
      console.log("click");
      history.push('/dlll/dcreatejournal');
    }

  return (
    <>
    <section className="home-section">
   <div className="home-content">
   <Box
        component="span"
        m={3}
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end" >

            <Button  className={classes.buttonSubmit} onClick={addRecord} variant="contained">+ Add</Button>&nbsp;
            <TextField label="Search" id="outlined-size-small" size="small"/>&nbsp;
            <Button  className={classes.buttonSubmit} onClick={addRecord} variant="contained">Search</Button>
        </Box>

       <div className="overview-boxes">
         <Posts setCurrentId={setCurrentId}/>
       </div>
   </div>
</section>
</>
  )
}

export default Dlanview