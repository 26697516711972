import React from 'react';
import {Link}  from 'react-router-dom';
import './Dviewjournal.css';


function Dviewjournal() {

    return (
    <>
                     <section className="home-section">
                    <div className="home-content">
                        <div className="overview-boxes">

                            <div className="box">
                                <div className="right-side">
                                    <span className="text"> Create/Update Journals</span>
                                    <Link to="/dcreatejournal">
                                        <div className="indicator">   
                                        <i className='bx bx-right-arrow-alt'></i>
                                        <span className="text">View More</span>
                                    </div></Link>
                                </div>  
                                <i className='bx bxs-message-square-add award'></i>
                            </div>

                           <div class="box">
                                <div class="right-side">
                                <span className="text"> Update Journals</span>
                                <Link to="/dsjstupdatedata">
                                    <div class="indicator">
                                    <i className='bx bx-right-arrow-alt'></i>
                                        <span className="text">View More</span>
                                    </div></Link>
                                </div>
                                <i class='bx bxs-message-square-edit award' ></i>
                            </div>

                            <div class="box">
                                <div class="right-side">
                                <span className="text"> Delete Journals</span>
                                <Link to="/dviewjournal">
                                    <div class="indicator">
                                    <i className='bx bx-right-arrow-alt'></i>
                                        <span className="text">View More</span>
                                    </div></Link>
                                </div>
                                <i class='bx bxs-message-square-x award' ></i>
                            </div>
                            
                            <div class="box">
                                <div class="right-side">
                                <span className="text"> View Journals</span>
                                <Link to ="/dsstviewdata">
                                    <div class="indicator">
                                    <i className='bx bx-right-arrow-alt'></i>
                                        <span className="text">View More</span>
                                    </div></Link>
                                </div>
                                <i class='bx bxs-message-square-detail award' ></i>
                            </div>
                        </div>
                    </div>
            </section>
            </>
    )
}

export default Dviewjournal;