import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    height: theme.spacing(50),
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: theme.spacing(110),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
  },
  
  gridMargin:{
    marginTop:10,
    marginBottom:20,
  },
  contpaper: {
    marginTop: theme.spacing(10),
    height: theme.spacing(110),
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(5)
  },
  fileInput: {
    width: '100%',
    margin: '10px 0',
  },
  buttonSubmit: {
    marginBottom: 10,
    margin:'10px',
  },
  buttonCancel:{
    marginBottom: 10,
    margin: '10px',
  },
}));
