import React from 'react';
import { Button} from '@material-ui/core/';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// import { deletejournalPost } from '../../../../actions/journal/dlan/posts';
import { deletejournalPost } from '../../../../actions/journal/dsair/posts';
import VisibilityIcon from '@mui/icons-material/Visibility';


const Post = ({ post, setCurrentId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const openPost = (e) => {
    history.push(`/dashboard/dsair/dsairupdatedata/${post._id}`); 
  };
  const viewPost = (e) => {
    history.push(`/dashboard/dsair/dsairdata/${post._id}`);
  };
  return (
    <>
    
    <TableRow
              key={post._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {post.title}
              </TableCell>
              <TableCell align="right">{post.article_id}</TableCell>
              <TableCell align="right">{post.authors}</TableCell>
              <TableCell align="right">{post.article_file}</TableCell>
              <TableCell align="right">{ <div>
                <Button size="small" color="secondary" onClick={viewPost}>
              <VisibilityIcon fontSize="small" />
              </Button> |
              <Button size="small" color="secondary" onClick={() => dispatch(deletejournalPost(post._id))}>
              <DeleteIcon fontSize="small" />
              </Button> |
              <Button size="small" color="primary" onClick={openPost}>
               <EditIcon fontSize="small"/></Button> </div>}
          </TableCell>
            </TableRow>

    </>
  )
}

export default Post