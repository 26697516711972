import React from 'react';
import { Button} from '@material-ui/core/';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { deletecissuePost } from '../../../../actions/currentissue/posts';
import VisibilityIcon from '@mui/icons-material/Visibility';



const Post = ({ post, setCurrentId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
 
  return (
    <>
    
    <TableRow
              key={post._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              style={{textAlign:"left"}}
            >
              <TableCell component="th" scope="row" >
                {post.journal_title}
              </TableCell>
              <TableCell component="th" scope="row">{post.volume}</TableCell>
              <TableCell component="th" scope="row">{post.issue}</TableCell>
              <TableCell align="right">{ <div>
              <Button size="small" color="secondary" onClick={() => dispatch(deletecissuePost(post._id))}>
              <DeleteIcon fontSize="small" />
              </Button> |
              <Button size="small" color="primary" onClick={()=>setCurrentId(post._id)}>
               <EditIcon fontSize="small"/></Button> </div>}
          </TableCell>
            </TableRow>

    </>
  )
}

export default Post