import React, { useState, useEffect } from 'react';
import {Link,NavLink,useLocation} from 'react-router-dom';
import Posts from '../../components/Dsm/Articlelist/Posts/Posts';
import { useSelector, useDispatch } from 'react-redux';
import { getjournalPosts } from '../../actions/journal/dsm/posts';
import { HashLink } from 'react-router-hash-link';
import Pagination from '../../components/Pagination';
import useStyles from '../../components/styles';
import Accordion from '../../components/Dsm/Accordion';
import Ddsmarticletemplate from '../../downloads/DSM-Article_Template.docx';
import Ddsmcopyrightform from '../../downloads/DSM-Copyright_Form.docx';
import Journaltitle from '../../pages/dsdsm/data.json';





const Archiveslist = () => {
  const [currentId, setCurrentId] = useState(0);
  const [monthpost, setMonthpost] = useState("");
  const dispatch = useDispatch();

  const posts = useSelector((state) => state.dsmjposts)

  const currentURL = window.location.href;
  const sQuery = currentURL.split("/");
  const fet_volume = sQuery[5] ? sQuery[5].match(/\d+/g) : "";
  const fet_issue = sQuery[6] ? sQuery[6].match(/\d+/g) : "";
  // const fet_volume = sQuery[5].match(/\d+/g);
  // const fet_issue =  sQuery[6].match(/\d+/g);
  
  const {state} = useLocation();  
  
  const act_volume = !state ? fet_volume[0] : state.volume;
  const act_issue = !state ? fet_issue[0] : state.issue
  const act_issue_month = !state ? "": ", "+state.month_of_issue;

  useEffect(() => {
    dispatch(getjournalPosts());    
  }, [currentId, dispatch]);

  useEffect(() => {
    setMonthpost(posts)    
   });
  
 
  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  const accordionData = [{
    content: <div>
          <HashLink smooth to="/dsm" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
            <NavLink to="/dsm/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>   Editorial Board</NavLink>
            <NavLink to="/dsm/paper-submission"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/dsm/current-issue"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/dsm/archives"           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/dsm/topics"                           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target= "_blank"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target= "_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/dsm/apc"               className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
        {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
            <a href={Ddsmarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Ddsmcopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
</div> 
},
  ];




    return (
        <>
   

           <section id="hero-no-slide-dsdsm" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
          <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    {/* <h2>Article List</h2> */}
                    {
                                Journaltitle.map(heading => {
                                  return(
                                    <h2 style={{fontSize:"25px"}}>{heading.title}</h2>     
                                  )
                                      
                                })
                            }
                    {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                  </div>
              </div>
            </div>
          </div>
          </div>
        </section>
        
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              {/* <h2>Archives</h2> */}
              <ol>
                <li><Link to="/home">Home</Link></li>
                <li><Link to="/journals">Journals</Link></li>
                <li><Link to="/dsm/archives">Archives</Link></li>
                <li>Article List</li>
              </ol>
            </div>
          </div>
        </section>
       
             <main id="main">
                <section className="blog" id="blog">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                              <div className="accordion">
                                    {accordionData.map(({content }) => (
                                      <Accordion content={content} />
                                  ))}
                              </div>
                            </div>
                            
                            <div className="col-lg-9">
                                <h5 className='mt-3'>List of Articles</h5>
                                <div className="row mt-3">
                                    <div className="col-lg-12 text-center">
                                    {/* <h5 style={{alignItems:'center'}}> Volume 1 Issue 1 </h5> */}
                                    <h5 style={{alignItems:'center'}}> Volume {act_volume} Issue {act_issue} {act_issue_month}</h5>
                                    </div>
                                
                                </div>

                                  <div className='row'>
                                    <div className='col-lg-12'>
                                    <Posts setCurrentId={setCurrentId} volume={state.volume} issue={state.issue}/>  
                                        
                                    </div>
                                                      
            
                                  </div>
                                
                      

               
                              

                            </div>
                          
                        </div>
                    </div>

                </section>


              </main>     
        </>
    )
}
export default Archiveslist;