import React, {useState,useEffect} from "react";
import { useDispatch } from 'react-redux';
import {useParams,useHistory} from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Box, Button} from '@material-ui/core';
import useStyles from './styles';
import Multistepform from '../../../components/Forms/dsm/Multistepform';
import { getjournalPosts } from "../../../actions/journal/dsm/posts";

const Dcreatejournal = () => {
  const { id } = useParams();
  const history = useHistory();
  const initialValue = 0;
  const [currentId, setCurrentId] = useState(id === undefined ? initialValue : id);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getjournalPosts());
  }, [currentId,dispatch])


  const viewJournal = () => {
    history.push('/dashboard/dsm/dsmviewdata');
  }

  return (
    <>
     <section className="home-section">
           <div className="home-content">
           <h2>DS Journal of Multidisciplinary</h2>
           <Box
              component="span"
              m={3}
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start">
              <Button  className={classes.buttonSubmit} onClick={viewJournal} variant="contained"> <VisibilityIcon fontSize="small" />&nbsp; Show Journal</Button>
              </Box>  
           <Multistepform currentId = {currentId} setCurrentId={setCurrentId}/>
           </div>
    </section>
    </>
  )
}

export default Dcreatejournal