import React,{ useState, useEffect } from 'react';
import Form from '../../components/Forms/Form';
import Posts from '../../components/Homepagepost/Posts/Posts';
import {getPosts} from '../../actions/posts';
import { useDispatch } from 'react-redux';

const Leftcard = () => {

        
    const [currentId, setCurrentId] = useState(0);
    const dispatch = useDispatch();
    //const classes = useStyles();
  
    useEffect(() => {
      dispatch(getPosts());
    }, [currentId, dispatch]);
 
    return (
        <>
             <section className="home-section">
                    <div className="home-content">
                        <div className="overview-boxes">
                            <div className="row">
                                <div className="col-lg-6">
                                <Form currentId={currentId} setCurrentId={setCurrentId} />
                                </div>
                                <div className="col-lg-6">
                                    <Posts setCurrentId={setCurrentId} />
                                </div>
                            </div>
                        
                        </div>
                        
                    </div>
            </section>
        </>
    )
       
}

export default Leftcard
