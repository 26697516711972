import React,{useState,useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Link,NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
// import Posts from '../../components/Dlan/Articlelist/Posts/Posts';
import Posts from '../../components/Dscy/Cissuelist/Posts/Posts';
import {getjournalPosts} from '../../actions/journal/dscys/posts';
import Accordion from '../../components/Dscy/Accordion';
import Ddscyarticletemplate from '../../downloads/DS-CYS-Article_Template.doc';
import Ddscycopyrightform from '../../downloads/DS-CYS-Copyright_Form.docx';

const Currentissue = () => {
  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();
  const [refresh,setReferesh] = useState(false);

  const jouname = "CYS";
  const [postData, setPostData] = useState({ journal_title: '', volume: '', issue: '' });
  const cpost = useSelector((state) => (jouname ? state.cissueposts.posts.find((jname)=>  jname.journal_title === jouname) : null));

  useEffect(()=>{setReferesh(true)},[refresh]);

  useEffect(() => {
    dispatch(getjournalPosts());    
  }, [currentId, dispatch]);

  useEffect(() => {
    if (cpost) setPostData(cpost);
  }, [cpost]);

  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  const accordionData = [{
    content: <div>
       <HashLink smooth to="/cys" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
           <NavLink to="/cys/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
           <NavLink to="/cys/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
           <NavLink to="/cys/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
           <NavLink to="/cys/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
           <NavLink to="/cys/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
           <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
           <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
           <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
           <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
           <NavLink to="/cys/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
           <a href={Ddscyarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
           <a href={Ddscycopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
</div> 
},
  ];
  return (
    <>
    <Helmet>
      <title>Current Issue - DS Journal of Cyber Security (DS-CYS)</title>
      <link rel="canonical" href="https://dsjournals.com/dscys/current-issue"/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="title" content="Current Issue - DS Journal of Cyber Security (DS-CYS)"/>
      <meta name="description" content="Discover the latest research and insights in the field of Cyber Security with the current issue of DS-CYS Journal. Check it out today."/>
      <meta name="keywords" content="Current Issue in dscys journals, recent research paper in cyber security journals, ds journal, journal of Cyber Security , Cyber Security international journal, Cyber Security journal ugc care, Cyber Security journals in india, Cyber Security journals in ugc care list, Cyber Security journals ugc approved, Cyber Security peer reviewed journal, research review international journal of Cyber Security, ugc care journal Cyber Security, international journal for Cyber Security research."/>
      <meta property="og:type" content="Website"/>
      <meta property="og:site_name" content="Dream Science"/>
      <meta property="og:url" content="https://dsjournals.com/dscys/current-issue"/>
      <meta property="og:title" content="Current Issue - DS Journal of Cyber Security (DS-CYS)"/>
      <meta property="og:description" content="Discover the latest research and insights in the field of Cyber Security with the current issue of DS-CYS Journal. Check it out today."/>
      <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Cyber-Security.webp"/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content="Current Issue - DS Journal of Cyber Security (DS-CYS)"/>
      <meta name="twitter:description" content="Discover the latest research and insights in the field of Cyber Security with the current issue of DS-CYS Journal. Check it out today."/>
      <meta name="twitter:site" content="@DreamScience4"/>
      <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
      <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Cyber-Security.webp"/>
      <meta name="robots" content="index, follow"/>
    </Helmet>
     <>
     <section id="hero-no-slide-dscy" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
   <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
     <div className="row justify-content-center">
       <div className="col-xl-8">
         <div className="row justify-content-center">
           {/* <div className="col-xl-8">
             <h2>Current Issue</h2>
           </div> */}
           <div className="col-lg-12">
           <h2 style={{fontSize:"25px"}}>DS Journal of Cyber Security ( DS-CYS )</h2>
                                </div>
       </div>
     </div>
   </div>
   </div>
 </section>
 
 <section className="breadcrumbs">
   <div className="container">
     <div className="d-flex justify-content-between align-items-center">
       {/* <h2>Current Issues</h2> */}
       <ol>
         <li><Link to="/home">Home</Link></li>
         <li><Link to="/journals">Journals</Link></li>
        <li><Link to="/cys">DS-CYS</Link></li>
         <li>Current Issue</li>
       </ol>
     </div>
   </div>
 </section>

      <main id="main">
<section class="blog" id="blog">
 <div class="container">
     <div class="row">
         <div class="col-lg-3">
         <div className="accordion">
                        {accordionData.map(({content }) => (
                          <Accordion content={content} />
                      ))}
        </div> 
         </div>
         <div class="col-lg-9">
               <div className="row mt-3">
                 <div className="col-lg-12 text-center">
                 {/* <h5 style={{alignItems:'center'}}>Volume 1 Issue 1,  July – September 2022 </h5> */}
                 {/* <h5 style={{alignItems:'center'}}>Volume {1} Issue {1}  </h5> */}
                 <h5 style={{alignItems:'center'}}>Volume {postData.volume} Issue {postData.issue}  </h5>
                 </div>
             
             </div>
             <div className="row">
              <div className='col-lg-12 mt-3'>
              <Posts setCurrentId={setCurrentId} />  
              </div>

       
             </div>
         </div>
       
     </div>
 </div>

</section>


</main>     
 </>
</>
  )
}

export default Currentissue