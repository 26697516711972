import React,{ useState, useEffect } from 'react';
import Jform from '../../components/Forms/Jform';
import Posts from '../../components/Journalpost/Posts';
import {getPosts} from '../../actions/posts';
import { useDispatch } from 'react-redux';

const Journalpage = () => {
    const [currentId, setCurrentId] = useState(0);
    const dispatch = useDispatch();
    //const classes = useStyles();
  
    useEffect(() => {
      dispatch(getPosts());
    }, [currentId, dispatch]);
    return (
   
            <>
              
                         
                                <div className="row">
                                    <div className="col-lg-6">
                                        <Jform currentId={currentId} setCurrentId={setCurrentId} />
                                    </div>
                                    <div className="col-lg-6">
                                        <Posts setCurrentId={setCurrentId} />
                                    </div>
                                </div>
                            
                         
                            
                        
               
            </>
        )
    }
    


export default Journalpage;
