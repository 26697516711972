export const CREATE     = 'CREATE';
export const UPDATE     = 'UPDATE';
export const DELETE     = 'DELETE';
export const FETCH_ALL  = 'FETCH_ALL';
export const FETCH_ALL_ARCH     = 'FETCH_ALL_ARCH';
export const FETCH_BY_SEARCH  = 'FETCH_BY_SEARCH';
export const FETCH_BY_AUTHOR  = 'FETCH_BY_AUTHOR';
export const FETCH_ALL_YEAR  = 'FETCH_ALL_YEAR';
export const FETCH_POST = 'FETCH_POST';
export const END_LOADING = 'END_LOADING';
export const START_LOADING = 'START_LOADING';
export const FETVOLISS = 'FETVOLISS';

export const AUTH   = 'AUTH';
export const LOGOUT = 'LOGOUT';