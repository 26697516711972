import React ,{useEffect} from 'react';
import {Link, NavLink} from 'react-router-dom';
import Accordion from '../../components/Dsair/Accordion';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import Ddsairarticletemplate from '../../downloads/DS-AIR-Article_Template.doc';
import Ddsaircopyrightform from '../../downloads/DS-AIR-Copyright_Form.docx';
import './Dsdsaireditorialboard.css';

const Dsdsaireditorialboard = () => {
    useEffect(()=>{
        window.scrollTo(0,0);
      },[]);
    
      const accordionData = [{
        content: <div>
           <HashLink smooth to="/air" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
            <NavLink to="/air/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
            <NavLink to="/air/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/air/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/air/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/air/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/air/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
            <a href={Ddsairarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Ddsaircopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
            
    </div> 
    },
      ];
  return (
    <>
    <Helmet>
        <title>Editorial Board | DS Journal of Artificial Intelligence and Robotics (DS-AIR)</title>
        <link rel="canonical" href="https://dsjournals.com/air/editorial-board"/>
        <meta name="title" content="Editorial Board | DS Journal of Artificial Intelligence and Robotics (DS-AIR)"/>
        <meta name="description" content="Meet the distinguished editorial board of DS-AIR Journal. Our experts ensure the quality and integrity of research published in our journal."/>
        <meta name="keywords" content="editorial board, editorial board members, editorial department, journal editorial board, become editorial board member, editorial member, editorial editor, board editorial, editorial team members, journal editorial board positions, editorial committee, editorial board positions."/>
        <meta property="og:type" content="Website"/>
        <meta property="og:site_name" content="Dream Science"/>
        <meta property="og:url" content="https://dsjournals.com/air/editorial-board"/>
        <meta property="og:title" content="Editorial Board | DS Journal of Artificial Intelligence and Robotics (DS-AIR)"/>
        <meta property="og:description" content="Meet the distinguished editorial board of DS-AIR Journal. Our experts ensure the quality and integrity of research published in our journal."/>
        <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Artificial-Intelligence-and-Robotics.webp"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:description" content="Meet the distinguished editorial board of DS-AIR Journal. Our experts ensure the quality and integrity of research published in our journal."/>
        <meta name="twitter:site" content="@DreamScience4"/>
        <meta name="twitter:title" content="Editorial Board | DS Journal of Artificial Intelligence and Robotics (DS-AIR)"/>
        <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
        <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Artificial-Intelligence-and-Robotics.webp"/>
        <meta name="robots" content="index, follow"/>
    </Helmet>



     <section id="hero-no-slide-dsair" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
          <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <div className="row justify-content-center">
                  {/* <div className="col-xl-8">
                    <h2>Editorial Board</h2>
                  </div> */}
                  <div className="col-lg-12">
                     <h2 style={{ fontSize: "25px" }}>DS Journal of Artificial Intelligence and Robotics ( DS-AIR )</h2>
                  </div>
              </div>
            </div>
          </div>
          </div>
        </section>
        
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              {/* <h2>Editor Spotlight</h2> */}
              <ol>
              <li><Link to="/home">Home</Link></li>
                <li><Link to="/journals">Journals</Link></li>
                <li><Link to="/air">DS-AIR</Link></li>
                <li>Editorial Board</li>
              </ol>
            </div>
          </div>
        </section>
             <main id="main">
    <section className="blog" id="blog">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 mb-3">
                <div className="list-group">
                  <div className="accordion">
                        {accordionData.map(({content }) => (
                          <Accordion content={content} />
                      ))}
                  </div>
                </div>

            <div className="abtlistgrp">
              </div>
                </div>
                {/* <div className='col-lg-9 video-box align-self-baseline position-relative'>
                 <div className="d-flex justify-content-center">
                           <p><h1>Coming Soon...</h1></p>
                           
                           </div>    
                           <div className="copyright d-flex justify-content-center">
                              &copy; Powered By &nbsp; <strong><span>Dream Science</span></strong>. 
                          </div>
                </div> */}
                <div id='head1' className="col-lg-9">
                 <h3>Editorial Board</h3>
                 <div className='row'>
                  <h5 className='text-left'>
                    <span style={{ backgroundColor: "#94c045",
    color:"#fff",
    lineHeight: "43px",
    padding: "5px 10px",
    whiteSpace:"pre-wrap"}}>Editor in Chief</span></h5>

                         



                  <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}>Dr. Dariusz Jakóbczak,</div> 
                  <p style={{textAlign:"left",fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-justify text-muted">
                  Department of Electronics and Computer Science, <br/>
                  Koszalin University of Technology, <br/>Poland.
                      <br/>dariusz.jakobczak@tu.koszalin.pl<br/>
                    {/* <a href='https://weii.tu.koszalin.pl/pracownicy/wizytowki' target="_balnk">Profile Link</a> </p> */}
                    {/* <a href='https://weii.tu.koszalin.pl/pracownicy/wizytowki/Dariusz-Jak%C3%B3bczak/szczegoly' target="_balnk">Profile Link</a> */}
                    <a href='http://kpiz.tu.koszalin.pl/pracownicy-katedry/' target="_balnk">Profile Link</a> </p>
                    
                  </div>
                 
                
                 </div>

                 <div className='row'>
                    <h5 style={{textAlign:"left"}}><span style={{ backgroundColor: "#94c045",
    color:"#fff",
    lineHeight: "43px",
    padding: "5px 10px",
    whiteSpace:"pre-wrap"}}>Associate Editor</span></h5>
                 <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}> Dr. A. Mallikarjuna Reddy, </div>	
                  <p  style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted">Department of Computer Science and Engineering,<br/>
                  Anurag University,<br/> 
                  Hyderabad, Telangana, India.
                  <br/>mallikarjunreddycse@anurag.edu.in<br/>
                  <a href='https://anurag.edu.in/faculties/dr-a-mallikarjuna-reddy/' target="_balnk">Profile Link</a></p>
                  </div>
                 </div>
    

                 <div className='row'>
                 <h5 className='text-left'><span style={{ backgroundColor: "#94c045",
                  color:"#fff",
                  lineHeight: "43px",
                  padding: "5px 10px",
                  whiteSpace:"pre-wrap"}}>Editorial Board Members</span></h5>
           
                  <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}>Dr. Sabyasachi Pramanik, </div>	
                  <p style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted">Department of Computer Science and Engineering,<br/>
                   Haldia Institute of Technology,<br/>
                   Haldia, West Bengal, India.<br/>
                   sabyasachi.pramanik@hithaldia.in<br/>
                  {/* <a href='https://hithaldia.in/faculty_22/CSE.pdf' target="_balnk">Profile Link</a> */}
                  <a href='https://hithaldia.irins.org/profile/332388' target="_balnk">Profile Link</a></p>
                  </div>

                  <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}>Dr. Stanislaw Krenich, </div>	
                  <p style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted">
                  Department of Engineering and Production Automation,<br/> Cracow University of Technology,<br/> Krakow, Poland.
                  <br/>stanislaw.krenich@pk.edu.pl<br/>
                  <a href='https://m6.pk.edu.pl/index.php/struktura-m-06/pracownicy-instytutu/krenich-stanislaw/' target="_balnk">Profile Link</a></p>
                  </div>

                  <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}>Dr. Tibbie Pon Symon V A, </div>	
                  <p style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted">Department of Electrical and Electronics Engineering,<br/>
                   Noorul Islam Centre for Higher Education,<br/> Kumaracoil, Tamil Nadu, India.
                  <br/>tibbieponsymon@niuniv.com<br/>
                  <a href='http://www.niuniv.com/faculty.php?staffid=12M0708181' target="_balnk">Profile Link</a></p>
                  </div>

              
                 </div>



                      
                </div>
              
            </div>
        </div>

    </section>


  </main>
    </>
  )
}

export default Dsdsaireditorialboard;