import React,{useEffect,useRef} from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getjournalPost } from '../../../actions/journal/posts';

import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import {Helmet} from 'react-helmet';

const Dstsingleview = () => {
  const ref = useRef();
  const { id } = useParams(); 
  console.log(id);
  const  post  = useSelector((state) => state.journalposts.posts.find(post => post._id === id));
  const dispatch = useDispatch();
  const styleObj = {
    lineHeight: '1rem'
  };
  
  
  useEffect(() => {
    dispatch(getjournalPost(id));
 }, [id]);

  return (
    <>
     <Helmet>
    <title>DS - {`${post.title}`} </title>
      {/* <meta name="keywords" content={post.key_words} />
      <meta name="author" content={post.authors}/>
      <meta name="citation_publisher" content=" Dream Science, DS "/>
      <meta name="citation_journal_title" content="Dream Science, DS"/> */}
    </Helmet>
         <section className="home-section">
            <div className="home-content">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-9 mt-3">
                          <h6 style={{fontWeight:"bold"}}>Research Article | Open Access | <span style={{fontSize:"16px",fontStyle:"normal"}}><Link to={`/upload/DST/volume-${post.volume}/issue-${post.issue}/${post.article_file}`} target="_blank" download><i class="bi bi-download"></i> Download</Link></span></h6>
                        
                          <p style={{fontSize:"small"}}>Volume {post.volume} | Issue {post.issue} | Year : { moment(post.year).format("yyyy")} | Article Id. {post.article_id} 
                          </p>
                        
                            <h3 style={{fontSize:"25px"}}>{post.title}</h3>
                            {/* <h3 style={{fontSize:"25px"}}>Common Fixed Point Theorems for a Pair of Self-Mappings in 
                            Fuzzy Cone Metric Spaces</h3> */}
                            <hr/>
                            <p style={{fontSize:"12px"}}>
                            <span style={{fontWeight:"bold"}}> {post.authors} </span>
                            {/* <span style={{fontWeight:"bold"}}> Saif Ur Rehman, Yongjin Li ,Shamoona Jabeen, Tayyab Mahmood </span> */}
                            </p>
                            
                                                                
                            <table style={{width:"100%"}}>
                                <tr>
                                    <td className="text-wrap text-center border-top border-end border-bottom m-2">
                                        Received<br/>
                                        {/* 01 Nov 2018 */}
                                        {moment(post.received_date).format("DD MMM YYYY")}
                                    </td>
                                    <td className="text-wrap text-center border-top border-end border-bottom">
                                        Revised<br/>
                                        {moment(post.revised_date).format("DD MMM YYYY")}
                                    </td>
                                    <td className="text-wrap text-center border-top border-end border-bottom">
                                        Accepted<br/>
                                        {moment(post.accepted_date).format("DD MMM YYYY")}
                                    </td>
                                    <td className="text-wrap text-center border-top border-bottom">
                                        Published<br/>
                                        {moment(post.published_date).format("DD MMM YYYY")}
                                        {/* {post.createdAt} */}
                                    </td>
                                </tr>
                            </table>
                            
                            {/* {console.log(`${post.article_file_path}`)}
                            {console.log(`http://localhost:8000/journalmgt/${post.article_file_path}`)} */}
                            {console.log(`public/upload/volume-${post.volume}/issue-${post.issue}/${post.article_file}`)} 
                            {/* <h3><img src={`http://localhost:8000/journalmgt/${post.article_file_path}`} alt="article_file"/></h3> */}
                            {/* <Link to={`/upload/volume-${post.volume}/issue-${post.issue}/${post.article_file}`} target="_blank" download>Download</Link> */}
                            {/* <h3><img src={`upload/volume-${post.volume}/issue-${post.issue}/${post.article_file}`}  alt="article_file"/></h3> */}
                            <h5>Citations:</h5>
                            <p id='keywords' style={{textAlign:"justify"}}>
                              {/* Computer Science, Self-mapping, Fuzzy */}
                              {post.authors}, " {post.title} " <span style={{fontStyle:"italic"}}>Dream Science Journal of Science and Technology,</span> vol. {post.volume}, no. {post.issue}, pp. {post.page_start}-{post.page_end}, { moment(post.year).format("yyyy")}.
                            
                            </p>
                          <h5>Abstract</h5>
                            {/* <p id='abstract' style={{textAlign:"justify"}}>In this paper, we present some common fixed point theorems 
                                for a pair of self-mappings in fuzzy cone metric spaces under the generalized 
                                fuzzy cone contraction conditions. We extend and improve some recent results 
                                given in the literature.</p> */}

                                <p id='abstract' style={{textAlign:"justify"}}> {post.abstract}</p>

                            <h5>Keywords</h5>
                            <p id='keywords' style={{textAlign:"justify"}}>
                              {/* Computer Science, Self-mapping, Fuzzy */}
                              {post.key_words}
                            </p>

                            <h5>References</h5>
                            {/* <span>
                            <p style={{textAlign:"justify"}}>{post.references.toString().split('</p>').map((item)=> <>
                            {item.replace('<p>','')}<br/></>)}</p>
                            </span> */}
                             {/* <p style={{textAlign:"justify",overflowWrap:"break-word"}}>{post.references.toString().split('[EOL]').map((item)=> <>{item}<br/></>)}</p> */}
                             <p style={{ textAlign: "justify" }}><div className="refertag" dangerouslySetInnerHTML={{ __html: post.references }} style={styleObj} /></p>
                      </div>
                  </div>
              </div>

            </div>
        </section>
    </>
  )
}

export default Dstsingleview;