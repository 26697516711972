import React, {useState,useEffect} from "react";
import { useDispatch } from 'react-redux';
import {useParams,useHistory} from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Box, Button} from '@material-ui/core';
import useStyles from './styles';
import Multistepform from "../../../components/Forms/dsair/Multistepform";
import { getjournalPosts } from "../../../actions/journal/dsair/posts";
import Formmui from '../../../components/Forms/Jmuiform';

const Dcreatejournal = () => { 
  const { id } = useParams();
  const history = useHistory();
  const initialValue = 0;
  const [currentId, setCurrentId] = useState(id === undefined ? initialValue : id);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getjournalPosts());
  }, [currentId,dispatch])


  const viewJournal = () => {
    history.push('/dashboard/dsair/dsairviewdata');
  }


  return (
      <>
       
           <section className="home-section">
           <div className="home-content">
              <Box
              component="span"
              m={3}
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start">
              <Button  className={classes.buttonSubmit} onClick={viewJournal} variant="contained"> <VisibilityIcon fontSize="small" />&nbsp; Show Journal</Button>
              </Box>      
              <Multistepform currentId = {currentId} setCurrentId={setCurrentId}/>

                {/* <Formmui/> */}
              </div>
          </section>
      </>
  )

}

export default Dcreatejournal;
