import React,  { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Link,NavLink} from 'react-router-dom';
import Listaccordion from '../../components/Listaccordion';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dsm/Accordion';
import { HashLink } from 'react-router-hash-link';
import Archivegroupaccordion from '../../components/Dsm/Archivegroup/Accordion';
import { getarchivePosts } from '../../actions/journal/dsm/posts';
import { LinearProgress } from '@material-ui/core';
import Ddsmarticletemplate from '../../downloads/DSM-Article_Template.docx';
import Ddsmcopyrightform from '../../downloads/DSM-Copyright_Form.docx';
import Journaltitle from '../../pages/dsdsm/data.json';
import moment from 'moment';


const Archives = () => {

  const [postyear, setPostyear] = useState({ _id: "", noofvolume: "" });
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);

  const cpost = useSelector((state) => state.dsdsmarchivesgroup.posts);

  cpost && cpost.forEach(yearData => {
    yearData.noofvolume.sort((a, b) => {
      // Sort by volume
      if (a.volume !== b.volume) {
        return a.volume - b.volume;
      }
  
      // If volumes are the same, sort by issue
      if (a.Issue !== b.Issue) {
        return b.Issue - a.Issue;
      }
    });
  });

  useEffect(() => {
    if (!refresh) setRefresh(true)
  }, [refresh])
  
  const Archivegroups = () =>{
    return(
      <ul className="accordion">
    {!cpost.length ? <LinearProgress/> : cpost.map((key) => (
      <Archivegroupaccordion title={moment(key._id).format("YYYY")} content={key.noofvolume.map((voliss) => 
      <div>
        <Link
        to={{
          pathname: `/dsm/archives/volume${voliss.volume}/issue${voliss.Issue}`,
          state: {
            volume: voliss.volume,
            issue: voliss.Issue,
            month_of_issue: voliss.month_of_issue
          }
        }}
      >
            volume {voliss.volume} Issue{voliss.Issue}, {voliss.month_of_issue}
      </Link>
      </div>)} />
    ))}
    </ul>
    )}

  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  useEffect(() => {
    dispatch(getarchivePosts());
  }, [dispatch])

  useEffect(() => {
    if (cpost) setPostyear(cpost);
  }, [cpost])

  const accordionData = [{
    content: 
    <div>
            <HashLink smooth to="/dsm" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
            <NavLink to="/dsm/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>   Editorial Board</NavLink>
            <NavLink to="/dsm/paper-submission"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/dsm/current-issue"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/dsm/archives"           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/dsm/topics"                           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target= "_blank"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target= "_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/dsm/apc"               className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
        {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
            <a href={Ddsmarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Ddsmcopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
</div> 
},
  ];
  const listaccordionData = [{
    heading: "2024", content: <Link to="/dsm/archives/volume1/issue1"> Volume1 Issue1 , January-March</Link>,

},
  ];
  return (
    <>
     <Helmet>
      <title>Archives - DS Journal of Multidisciplinary</title>
      <link rel="canonical" href="https://dsjournals.com/dsm/archives"/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="title" content="Archives - DS Journal of Multidisciplinary"/>
      <meta name="description" content="Access the archives of DSM Journal and uncover valuable research in your field. Discover historical developments and gain new perspectives today."/>
      <meta name="keywords" content="recent research paper in Multidisciplinary, archives of Multidisciplinary journal, a journal article, a journal of multidisciplinary research, a multidisciplinary peer reviewed journal, article for journal, journal multidisciplinary, multidisciplinary research, a multidisciplinary journal, journal of multidisciplinary research, research in multidisciplinary."/>
      <meta property="og:type" content="Website"/>
      <meta property="og:site_name" content="Dream Science"/>
      <meta property="og:url" content="https://dsjournals.com/dsm/archives"/>
      <meta property="og:title" content="Archives - DS Journal of Multidisciplinary"/>
      <meta property="og:description" content="Access the archives of DSM Journal and uncover valuable research in your field. Discover historical developments and gain new perspectives today."/>
      <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Multidisciplinary.webp"/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content="Archives - DS Journal of Multidisciplinary"/>
      <meta name="twitter:description" content="Access the archives of DSM Journal and uncover valuable research in your field. Discover historical developments and gain new perspectives today."/>
      <meta name="twitter:site" content="@DreamScience4"/>
      <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
      <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Multidisciplinary.webp"/>
      <meta name="robots" content="index, follow"/>
            </Helmet>
    <section id="hero-no-slide-dsdsm" className="d-flex justify-center align-items-center" style={{height:"300px"}}>
<div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
<div className="row justify-content-center">
  <div className="col-xl-8">
    <div className="row justify-content-center">
      <div className="col-xl-8">
      {
                                Journaltitle.map(heading => {
                                  return(
                                    <h2 style={{fontSize:"25px"}}>{heading.title}</h2>     
                                  )
                                      
                                })
                            }
        {/* <h2>Archive</h2> */}
        {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
      </div>
  </div>
</div>
</div>
</div>
</section>

<section className="breadcrumbs">
<div className="container">
<div className="d-flex justify-content-between align-items-center">
  {/* <h2>Archives</h2> */}
  <ol>
    <li><Link to="/home">Home</Link></li>
    <li><Link to="/journals">Journals</Link></li>
    <li><Link to="/dsm">DSM</Link></li>
    <li>Archive</li>
  </ol>
</div>
</div>
</section>

 <main id="main">
<section className="blog" id="blog">
<div className="container">
<div className="row">
<div className="col-lg-3 mb-3">
      <div className="list-group">
        <div className="accordion">
                        {accordionData.map(({content }) => (
                          <Accordion content={content} />
                      ))}
        </div>
      </div>
   </div>
    <div className="col-lg-9">
        <div className="row">
            <div className="col-lg-12 text-center">
            <h5 style={{alignItems:'center'}}>Archive</h5>
             <ul className="accordion">
             <Archivegroups />
              {/* {listaccordionData.map(({heading,content }) => (
                <Listaccordion heading={heading} content={content} />
        ))} */}
      </ul>
            </div>
        </div>
        <div className="row">
        {/*<h5>2021</h5>
        <hr/>
         <div className="col-md-2">
        <Link to="/archiveslist" className="btn btn-outline-dark float-center">Issue 1</Link>
        </div> */}


        
        </div>
    </div>

  
  
</div>

</div>

</section>


</main>  
</>
  )
}

export default Archives;
