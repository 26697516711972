import React from 'react';
import IssueForm from '../../../../components/Forms/IssueList/IssueForm';

const IssueList = () => {
    return (
        <>
            <section className="home-section">
                <div className="home-content">
                    <h1>Issuelist</h1>
                    <IssueForm/>
                </div>
            </section>

        </>
    )
}

export default IssueList
