import React,{ useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';
import Post from './Post/Post';
import { getjournalPosts } from '../../../../actions/journal/dscys/posts';

// const Posts = ({setCurrentId}) => {
//     const posts = useSelector((state) =>  state.dscysposts.posts)
//   return (
//     !posts.length ? <CircularProgress /> : (
//         <Grid container alignItems="stretch" spacing={3}>
//             {posts.sort((a, b) => a.article_id > b.article_id ? 1 : -1).map((post) => (
//                <Grid key={post._id} item xs={12} sm={12} md={12}>
//                <Post post={post} setCurrentId={setCurrentId} />
//              </Grid>
//             ))}
//         </Grid>
//     )
  
//   )
// }

const Posts = ({ setCurrentId, volume, issue }) => {

  const dispatch = useDispatch();


useEffect(() => {
  dispatch(getjournalPosts());    
}, [volume,issue,dispatch]);


const posts = useSelector((state) => state.dscysposts.posts)


// useEffect(() => {
//   console.log("articleId",posts[0].article_id);
//   console.log("posts nnew",posts.map((item)=>{
//  return item.article_id
//   }))
// });



return (
  // !posts.length ? <CircularProgress /> : (
    posts && posts.length >= 0 ? (
    <Grid container alignItems="stretch" spacing={3}>
      {/* {posts.filter(key => key.volume == volume & key.issue == issue).map((post) => ( */}
      {posts.filter(key => key.volume == volume & key.issue == issue).sort((a, b) => a.article_id > b.article_id ? 1 : -1).map((post) => (
        <Grid key={post._id} item xs={12} sm={12} md={12}>
          <Post post={post} setCurrentId={setCurrentId} />
        </Grid>
      ))}
    </Grid>
  ) : <CircularProgress />

)
}

export default Posts