import React,{ useState, useEffect } from 'react';
// import Posts from '../../components/Testpost/Posts/Posts';


const Rightcard = () => {
   
 
    return (
        <>
             <section className="home-section">
                    <div className="home-content">
                        <div className="overview-boxes">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* <Posts setCurrentId={setCurrentId} /> */}
                                </div>
                            </div>
                        
                        </div>
                        
                    </div>
            </section>
        </>
    )
}

export default Rightcard
