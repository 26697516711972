import React,  { useState, useEffect } from 'react';
import { TextField,Button, Paper, Switch } from '@material-ui/core';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
// import Posts from '../components/Dscse/Articlelist/Posts/Posts';
import Posts from '../components/Search/Posts/Posts';
// import ChipInput from 'material-ui-chip-input';
import { Link } from 'react-router-dom';
import Pagination from '../components/Pagination';
import { useDispatch,useSelector } from 'react-redux';
// import { getPostsBySearch, getPostsBySearchauthor } from '../actions/journal/posts';
import { getPostsBySearch } from '../actions/search/posts';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { ButtonBase } from '@material-ui/core';
import  useStyles from '../components/styles';
import { Grid, CircularProgress } from '@material-ui/core';

import './Aboutus.css';
import './search.css'; 
import { circularProgressClasses } from '@mui/material';



function useQuery() {
  return new URLSearchParams(useLocation().search);
}



const Search = () => {

  const currentURL = window.location.href;
  const sQuery = currentURL.split("=");
  const pathName = sQuery[0];
  const myquery = decodeURIComponent(sQuery[1]);
  
  if (!myquery){
    
  }
  // console.log(pathName);
  // console.log(decodeURIComponent(myquery));

  const classes = useStyles();
  // const { posts, isLoading } = useSelector((state) => state.journalposts);

  

  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();

  // const page = query.get('page') || 1;

  const searchQuery = query.get('searchQuery');
  const searchAuthor = query.get('searchAuthor');
  const [search, setSearch] = useState(!myquery ? " " : myquery);
  
  const {posts,isLoading} = useSelector((state)=> state.searchposts.posts)
  const [currentId, setCurrentId] = useState(0);
  const [postData,setpostData] = useState([]);
  
  const [authors,setAuthors] = useState([]);
  const [typesearch,setTypesearch] = useState("article");
    
  // const onRadiochange = (e) =>{
  //   console.warn(e.target.value);
  //   setTypesearch(e.target.value);
  // }

  const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');
  const [value, setValue] = React.useState('newestfirst');


useEffect(()=>{
  if(search=== "undefined"){
    setSearch("");
  }
},[])


  useEffect(()=>{
    // dispatch(getPostsBySearch({search}));
      if(!posts)setpostData(posts);
  },[posts])


  const searchPost = () => {
    // if (typesearch==="article"){
    //   console.warn("you are in article");
    //   console.log(search);
    //   if(search.trim().length !==0){

    //   }else{
    //     history.push('/search');
    //     alert("Kindly enter article name inside search articles box")
    //   }
    // }
  
    // if(typesearch === "authors"){
    //   console.warn("you are in authors");
    //   console.log(search);
    //   if(search.length !== 0){
    //     console.log(search.length);
    //     dispatch(getPostsBySearchauthor({search}));
    //     history.push(`/search?searchAuthor=${search}`);
    //   }
    // }

  //}
  

  // const searchPost = () =>  {
  //   if(search.trim().length !== 0){
  //     if(typesearch === "article"){
  //       dispatch(getPostsBySearch({search}));
  //       history.push(`/search?searchQuery=${search}`);
  //     }if(typesearch === "authors"){
  //       dispatch(getPostsBySearchauthor({search}));
  //       history.push(`/search?searchAuthor=${search}`);
  //     }
  //   } else {
  //     history.push('/search');
  //     alert("Kindly enter article or author name inside search articles box based on selection")
  //   }
   


    if (search.trim().length !== 0) {
      dispatch(getPostsBySearch({search}));
      history.push(`/search?searchQuery=${search}`);
    } else {
      history.push('/search');
      alert("Kindly enter article title (or) author name inside search articles box")
    }
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyPress = (e) => {
    if(e.keyCode === 13){
    //search post
    searchPost();
    }
  };

  // if(!posts) return (<>No Record Found</>)
    return (
        <>
        {/* <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center" style= {{height:"300px"}}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="row justify-content-center">
                <div className="col-xl-8">
                  <h2>Search</h2> 
                </div>
            </div>
          </div>
        </div>
        </div>
      </section> */}

      <main id="main">
    <section className="skills" data-aos="fade-up">
      <div className="container">
        <div className="section-title">
          <h2>Search Result</h2>
        <div className="row">
          <div className='container'></div>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
              <div className='col-md-12'>
              <div className="well-header">
              <div className="input-group">
                <TextField onKeyDown={handleKeyPress} 
                  name="search" variant="outlined" 
                  label="Search Articles" 
                  onKeyPress={handleKeyPress}
                  fullWidth value={search.replace('%20',' ')} 
                  onChange={(e) => setSearch(e.target.value)} />
                   
               {/* <Radio
                checked={typesearch === "authors"}
                value="authors"
                onChange={onRadiochange}
                name="radio-buttons"
              />By Author
              <Radio
                checked={typesearch === "article"}
                value="article"
                name="radio-buttons"
                onChange={onRadiochange}
              />By Article */}
              {/* <Typography sx={{ fontSize: '14px',textAlign:'left'}} color="text.secondary" >
                    Enter "artilce title" or "author name"
                   </Typography> */}
              </div>
                </div>
              </div>
              <div className='co-md-4'>
                <div className="input-group-btn">
                  <Button onClick={searchPost}  variant="contained" color="primary">Search</Button>
                </div>
              </div>
              </div>
              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'> 
              {/* <h6 className='text-end p-3'>Ordering : 
              <select value={value} onChange={handleChange}>
                  <option value="newestfirst">Newest First</option>
                  <option value="oldestfirst">Oldest First</option>
              </select>
              </h6> */}

              </div>
                {/* <div className="well search-result">
                
                    <div className="input-group">
                    <input type="text" className="form-control" placeholder="Search"/>
                    
                    <div className="input-group-btn">
                        <button className="btn" type="button">
                        Search
                        </button>
                        
                    </div>
                    </div>
                </div> */}

                <div className="well">
                <div className="row">
                   {/* <div className="col-lg-12">
                     <div className="title">
                     <h3><Link to="/">Title</Link></h3>
                     </div>
                     <br/>
                     <div className="content">
                          <Link to="/"><i class='bx bxs-user'></i>AuthorName-<i class='bx bxs-file'></i>Publication Domain,</Link>
                          <Link to="/"><i class='bx bxs-calendar-alt'></i>Year-<i class='bx bx-globe'></i>Publisher name</Link>
                          <Link to="/"><i class='bx bxs-download'></i>Download PDF</Link><br/>
                     <p>Ut quis libero id orci semper porta ac vel ante. In nec laoreet sapien. Nunc hendrerit ligula at massa sodales, ullamcorper rutrum orci semper.
                     Ut quis libero id orci semper porta ac vel ante. In nec laoreet sapien. Nunc hendrerit ligula at massa sodales, ullamcorper rutrum orci semper.
                     Ut quis libero id orci semper porta ac vel ante. In nec laoreet sapien. Nunc hendrerit ligula at massa sodales, ullamcorper rutrum orci semper.
                     </p>
                     </div>
                   </div> */}



                   <div className='col-lg-12'>
                   {/* <Typography sx={{textAlign:'left'}} variant="p" component="div">No.of Records Found : {posts.length}</Typography> */}
                   {/* {!posts.length ? <CircularProgress/> :
                    posts.map((post) => {
                    const artid = post.article_id;
                    const path = artid.split('-');
                    console.log([path]);

                    return(
                      <div> 
                         <Card sx={{ minWidth: 275,marginTop:"5px"}}>
                      <CardContent>
                          <Typography sx={{ fontSize: '14px',textAlign:'left'}} color="text.secondary" gutterBottom>
                          <i className='bi bi-file-earmark-fill'></i> Research Article <i className='bi bi-diamond-half'></i> {post.article_id} 
                          </Typography>
                          <Typography sx={{textAlign:'left'}} variant="h6" component="div">
                  

                              <Link to={`/${path[0].toLowerCase()}/${post._id}`}>{post.title}</Link>
                          
                          </Typography>
                          <Typography sx={{textAlign:'left'}} variant="body2">
                            {post.authors.map((author)=> `${author}`)}<br/>
                          </Typography>
                        </CardContent>
                      </Card>
                        </div>
                    )
                  })} */}
                  
                  
                {!posts ? 'Enter Article Title (or) Author and "Press" the Search Button' :
                    posts.map((post) => {
                    const artid = post.article_id;
                    const path = artid.split('-');
                    const jname = (juname) => {
                      switch(juname){
                        case 'DST':
                          return "Digital Science and Technology"  
                          case 'LLL':
                            return "Language, Lingustics and Literature"
                            case 'AIR':
                              return "Artificial Intelligence and Robotics"
                      }
                  }
                    return(
                      <div> 
                         <Card sx={{ minWidth: 275,marginTop:"5px"}}>
                      <CardContent>
                          <Typography sx={{ fontSize: '14px',textAlign:'left'}} color="text.secondary" gutterBottom>
                            <i className='bi bi-file-earmark-fill'></i> {jname(path[0])} - Research Article <i className='bi bi-diamond-half'></i> {post.article_id} 
                          </Typography>
                          <Typography sx={{textAlign:'left'}} variant="h6" component="div">
                          {/* <ButtonBase
                            component="span"
                            name="test"
                            className={classes.cardAction}
                            onClick={(e)=>{ history.push(`/${path[0].toLowerCase()}/${post._id}`);}}
                              >{post.title}</ButtonBase><br /> */}

                              <Link to={`/${path[0].toLowerCase()}/${post._id}`}>{post.title}</Link>
                          
                          </Typography>
                          <Typography sx={{textAlign:'left'}} variant="body2">
                            {post.authors.map((author)=> `${author}`)}<br/>
                          </Typography>
                        </CardContent>
                      </Card>
                        </div>
                    )
                  })
                  
                  }

                  {/* <Posts setCurrentId={setCurrentId}/> */}
                   </div>
                   {/* <div className='col-lg-12'>
                   <Paper elevation={6}>
                            <Pagination/>
                            </Paper>
                   </div> */}
                </div>
            </div>   
            </div>
      
        </div>
      </div>
    </section>
    </main> 



      </>
    )
}

export default Search
