import React, {useEffect} from 'react';
import { Paper, CircularProgress } from '@material-ui/core/';

import {Link, useParams} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Accordion from '../components/Dscse/Accordion';
// import Accordion from '../components/Accordion';
import { useDispatch, useSelector } from 'react-redux';
import { getjournalPost } from '../actions/journal/posts';
import useStyles from '../components/styles';



const Archivesview = () => {

  const { post, isLoading } = useSelector((state) => state.posts);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
 
  

  // const { id } = useParams();
  // console.log(id);
 
  useEffect(() => {
    dispatch(getjournalPost(id));
    console.log(getjournalPost)
  }, [id]);

  if (!post) return null;

  

  if (isLoading) {
    return (
      <Paper elevation={6} className={classes.loadingPaper}>
        <CircularProgress size="7em" />
      </Paper>
    );
  }

    const accordionData = [{
    content: <div>
        <HashLink smooth to="#abstract" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>Abstract</HashLink>
        <HashLink to="#keywords" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>Keywords</HashLink>
        <HashLink to="#references" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>References</HashLink>
</div> 
},
  ];
    return (
        <>
         
         
  <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center">
    <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
      <div className="row justify-content-center">
        <div className="col-xl-8">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <h2>Archives View</h2>
              <Link to="/archivesview#about" className="btn-get-started ">Read more</Link>
            </div>
        </div>
      </div>
    </div>
    </div>
  </section>  

  <main id="main">
    <section className="blog section-bg" id="blog">
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                  {/* <div className="list-group" id="article-sidebar">
                    <Link to="#" className="list-group-item list-group-item-action btn-submit">Contents</Link>
                    <Link to="#" className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>Abstract</Link>
                    <Link to="#" className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>Keywords</Link>
                    <Link to="#" className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>References</Link>
                  </div> */}
                    <div className="list-group">
                      <div className="accordion">
                        {accordionData.map(({content }) => (
                          <Accordion content={content} />
                      ))}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 mt-3">
                    <h6 style={{fontWeight:"bold"}}>Research Article | Open Access </h6>
                    <p style={{fontSize:"small"}}>Volume 1 | Issue 1 | Year : 2019 | Article ID DSST1P1I101 
                    </p>
                    <div className="collapse" id="collapseExample">
                        <div className="card card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry 
                          richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson 
                          cred nesciunt sapiente ea proident.
                        </div>
                      </div>
                      <h3 style={{fontSize:"25px"}}>{post}</h3>
                      <h3 style={{fontSize:"25px"}}>Common Fixed Point Theorems for a Pair of Self-Mappings in 
                      Fuzzy Cone Metric Spaces</h3>
                      <hr/>
                      <p style={{fontSize:"12px"}}>
                       <span style={{fontWeight:"bold"}}> Saif Ur Rehman, Yongjin Li ,Shamoona Jabeen, Tayyab Mahmood </span>
                      </p>
                      
                      <div className="collapse" id="collapseExample1">
                        <div className="card card-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry 
                          richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson 
                          cred nesciunt sapiente ea proident.
                        </div>
                      </div>
                                          
                      <table style={{width:"100%"}}>
                          <tr>
                              <td className="text-wrap text-center border-top border-end border-bottom m-2">
                                  Received<br/>
                                  01 Nov 2018
                              </td>
                              <td className="text-wrap text-center border-top border-end border-bottom">
                                  Revised<br/>
                                  29 Jan 2019
                              </td>
                              <td className="text-wrap text-center border-top border-end border-bottom">
                                  Accepted<br/>
                                  19 Feb 2019
                              </td>
                              <td className="text-wrap text-center border-top border-bottom">
                                  Published<br/>
                                  01 Apr 2019
                              </td>
                          </tr>
                      </table>

                    <h5>Abstract</h5>
                      <p id='abstract' style={{textAlign:"justify"}}>In this paper, we present some common fixed point theorems 
                          for a pair of self-mappings in fuzzy cone metric spaces under the generalized 
                          fuzzy cone contraction conditions. We extend and improve some recent results 
                          given in the literature.</p>

           

                      <h5>Keywords</h5>
                      <p id='keywords' style={{textAlign:"justify"}}>
                        Computer Science, Self-mapping, Fuzzy
                      </p>

                      <h5>References</h5>
                      
                      <p id='references' style={{textAlign:"justify"}}>
                        <ol>
                        <li>L. A. Zadeh, “Fuzzy sets,” Information and Control, vol. 8, pp. 338–353, 1965.</li>
                        <li>I. Kramosil and J. Michalek, “Fuzzy metrics and statistical metric spaces,” Kybernetika, vol. 11, no. 5, pp. 326–334, 1975.</li>
                        <li>A. George and P. Veeramani, “On some results in fuzzy metric spaces,” Fuzzy Sets and Systems, vol. 64, no. 3, pp. 395–399, 1994.</li>
                        <li>L. A. Zadeh, “Fuzzy sets,” Information and Control, vol. 8, pp. 338–353, 1965.</li>
                            <li>L. A. Zadeh, “Fuzzy sets,” Information and Control, vol. 8, pp. 338–353, 1965.</li>
                                <li>L. A. Zadeh, “Fuzzy sets,” Information and Control, vol. 8, pp. 338–353, 1965.</li>
                        </ol>
                      </p>

                      <h5>Copyright</h5>
                      <p style={{textAlign:"justify"}}>
                        Copyright © 2019 Saif Ur Rehman et al. This is an open access article distributed
                        under the <Link to="#">Creative Commons Attribution License</Link>, which permits unrestricted use, 
                        distribution, and reproduction in any medium, provided the original work is properly 
                        cited.
                      </p>
                </div>

                <div className="col-lg-3">
                 <div className="abtlistgrp">
                 <div className="card">
                  <ul className="list-group list-group-flush">
                    <Link to="#"  className="list-group-item btn-submit"><i className="bi bi-cloud-arrow-down"></i>Download PDF</Link>
                       
                        

                  </ul>
                  <div className="row text-center mt-4">
                    <div className="col p-2">
                     <h4 className="mb-1 line-height-5">154</h4>
                      <small className="mb-0 font-weight-bold">Download</small>
                     </div>
                      <div className="col p-2">
                        <h4 className="mb-1 line-height-5">5</h4>
                       <small className="mb-0 font-weight-bold">Citations</small>
                      </div>
                      <div className="col p-2">
                       <h4 className="mb-1 line-height-5">9.1k</h4>
                       <small className="mb-0 font-weight-bold">Views</small>
                      </div>
                   </div>
                </div>              
              </div>
       
                </div>
            </div>
        </div>
    </section>
  </main>
        </>
    )
}
export default Archivesview;