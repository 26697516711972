import React,{ useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';
import { getjournalPosts } from '../../../../actions/journal/dsair/posts';
import Post from './Post/Post';

const Posts = ({setCurrentId, volume, issue}) => {
  const dispatch = useDispatch();
  const posts = useSelector((state) =>  state.dsairjposts.posts)


  useEffect(() => {
    dispatch(getjournalPosts());    
  }, [volume,issue,dispatch]);
    
  
  return (
    // !posts.length ? <CircularProgress /> : (
      posts && posts.length >= 0 ? (
        <Grid container alignItems="stretch" spacing={3}>
            {/* {posts.map((post) => ( */}
              {/* {posts.filter(key=> key.volume === 1 & key.issue === 1).sort((a, b) => a.article_id > b.article_id ? 1 : -1).map((post) => ( */}
            {posts.filter(key => key.volume == volume & key.issue == issue).sort((a, b) => a.article_id > b.article_id ? 1 : -1).map((post) => (
               <Grid key={post._id} item xs={12} sm={12} md={12}>
               <Post post={post} setCurrentId={setCurrentId} />
             </Grid>
            ))
            }
        </Grid>
    ) : <CircularProgress/>
  
  )
}

export default Posts;