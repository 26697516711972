import React, {useState, useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Link,NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dsms/Accordion';
// import Posts from '../../components/Dlan/Articlelist/Posts/Posts';
import Posts from '../../components/Dsms/Cissuelist/Posts/Posts';
import { getjournalPosts } from '../../actions/journal/dsms/posts';
import Ddsmsarticletemplate from '../../downloads/DS-MS-Article_Template.doc';
import Ddsmscopyrightform from '../../downloads/DS-MS-Copyright_Form.docx';
import Dsmsdata from './dsmsdata';

const Currentissue = () => {
  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();
  const [refresh,setReferesh] = useState(false);

  const jouname = "MS";
  const [postData, setPostData] = useState({ journal_title: '', volume: '', issue: '' });
  const cpost = useSelector((state) => (jouname ? state.cissueposts.posts.find((jname)=>  jname.journal_title === jouname) : null));

  useEffect(()=>{setReferesh(true)},[refresh]);

  useEffect(() => {
    dispatch(getjournalPosts());    
  }, [currentId, dispatch]);

  useEffect(() => {
    if (cpost) setPostData(cpost);
  }, [cpost]);

  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  const accordionData = [{
    content: <div>
       <HashLink smooth to="/ms" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
            <NavLink to="/ms/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>   Editorial Board</NavLink>
            <NavLink to="/ms/paper-submission"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/ms/current-issue"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/ms/archives"           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/ms/topics"                           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target= "_blank"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target= "_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/ms/apc"               className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
            {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
            <a href={Ddsmsarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Ddsmscopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
</div> 
},
  ];
  return (
    <>
    <Helmet>
    <title>Current Issue - DS Journal of Modeling and Simulation</title>
    <link rel="canonical" href="https://dsjournals.com/ms/current-issue"/>
    <meta name="viewport" content="width=device-width, initial-scale=1"/>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="title" content="Current Issue - DS Journal of Modeling and Simulation"/>
    <meta name="description" content="Stay up-to-date with the latest research and trends in your field. Check out the current issue of DS-MS Journal and broaden your knowledge today."/>
    <meta name="keywords" content="Current Issue in DS-MS Journals, recent research paper in Modeling and Simulation journals, ds journal, journal of Modeling and Simulation, Modeling and Simulation international journal, Modeling and Simulation journal ugc care, Modeling and Simulation journals in india, Modeling and Simulation journals in ugc care list, Modeling and Simulation journals ugc approved, Modeling and Simulation peer reviewed journal, research review international journal of Modeling and Simulation, ugc care journal Modeling and Simulation, international journal for Modeling and Simulation research."/>
    <meta property="og:type" content="Website"/>
    <meta property="og:site_name" content="Dream Science"/>
    <meta property="og:url" content="https://dsjournals.com/ms/current-issue"/>
    <meta property="og:title" content="Current Issue - DS Journal of Modeling and Simulation"/>
    <meta property="og:description" content="Stay up-to-date with the latest research and trends in your field. Check out the current issue of DS-MS Journal and broaden your knowledge today."/>
    <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of -Modeling-and-Simulation.webp"/>
    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:title" content="Current Issue - DS Journal of Modeling and Simulation"/>
    <meta name="twitter:description" content="Stay up-to-date with the latest research and trends in your field. Check out the current issue of DS-MS Journal and broaden your knowledge today."/>
    <meta name="twitter:site" content="@DreamScience4"/>
    <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
    <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of -Modeling-and-Simulation.webp"/>
    <meta name="robots" content="index, follow"/>

    </Helmet>
     <>
     <section id="hero-no-slide-dsms" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
   <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
     <div className="row justify-content-center">
       <div className="col-xl-8">
         <div className="row justify-content-center">
           <div className="col-xl-8">
             {/* <h2 style={{color:"#21494f"}}>Current Issue</h2> */}
             <h2 style={{fontSize:"25px",color:"#21494f"}}>{Dsmsdata[0].banner_title}</h2>
           </div>
       </div>
     </div>
   </div>
   </div>
 </section>
 
 <section className="breadcrumbs">
   <div className="container">
     <div className="d-flex justify-content-between align-items-center">
       {/* <h2>Current Issues</h2> */}
       <ol>
         <li><Link to="/home">Home</Link></li>
         <li><Link to="/journals">Journals</Link></li>
        <li><Link to="/ms">DS-MS</Link></li>
         <li>Current Issue</li>
       </ol>
     </div>
   </div>
 </section>

      <main id="main">
<section class="blog" id="blog">
 <div class="container">
     <div class="row">
         <div class="col-lg-3">
         <div className="accordion">
                        {accordionData.map(({content }) => (
                          <Accordion content={content} />
                      ))}
        </div> 
         </div>
         <div class="col-lg-9">
               <div className="row mt-3">
                 <div className="col-lg-12 text-center">
                 {/* <h5 style={{alignItems:'center'}}>Volume 1 Issue 1</h5> */}
                 <h5 style={{alignItems:'center'}}>Volume {postData.volume} Issue {postData.issue}  </h5>
                 </div>
             
             </div>
             <div className="row">
              <div className='col-lg-12 mt-3'>
              <Posts setCurrentId={setCurrentId} />  
              </div>


                 {/* <div className="table-responsive-lg">
                 <table style={{width:"100%"}}>
                 <tr>
                     <th style={{padding:"10px",textAlign:"center",border:"1px solid #000"}}>S.No</th>
                     <th style={{padding:"10px",textAlign:"center",border:"1px solid #000"}}>Article Description</th>
                     <th style={{padding:"10px",textAlign:"center",border:"1px solid #000"}}>Article Id</th>
                 </tr>
                 <tr>
                     <td style={{padding:"0 0 20px 0",textAlign:"center",border:"1px solid #000"}}>1</td>
                     <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>
                         <Link to="/archivesview" style={{fontWeight:"bold"}}>Common Fixed Point Theorems for a Pair of Self-Mappings in Fuzzy Cone Metric Spaces</Link><br/>
                     <p style={{fontSize:"12px"}}>
                         <span style={{fontWeight:"bold"}}><i class="bi bi-pencil-square"></i> Saif Ur Rehman <sup>1</sup>, Yongjin Li <sup>2</sup>, Shamoona Jabeen<sup>3</sup>, and Tayyab Mahmood<sup>4</sup></span>
                     </p>
                     </td>
                     <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>DS-IJCSE-20213001</td>
                 </tr>
                 <tr>
                     <td style={{padding:"0 0 20px 0",textAlign:"center",border:"1px solid #000"}}>2</td>
                     <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>
                         <Link to="/archivesview" style={{fontWeight:"bold"}}>Common Fixed Point Theorems for a Pair of Self-Mappings in Fuzzy Cone Metric Spaces</Link><br/>
                     <p style={{fontSize:"12px"}}>
                         <span style={{fontWeight:"bold"}}><i class="bi bi-pencil-square"></i> Saif Ur Rehman <sup>1</sup>, Yongjin Li <sup>2</sup>, Shamoona Jabeen<sup>3</sup>, and Tayyab Mahmood<sup>4</sup></span>
                     </p>
                     </td>
                     <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>DS-IJCSE-20213001</td>
                 </tr>
             </table>
                 </div> */}
             </div>
         </div>
       
     </div>
 </div>

</section>


</main>     
 </>
</>
  )
}

export default Currentissue