import React,{useState,useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import {useParams,NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
// import Posts from '../../components/Dslll/Articlelist/Posts/Posts';
import Accordion from '../../components/Dsce/Accordion';
import { getjournalPost } from '../../actions/journal/dsce/posts';
import moment from 'moment';
import Ddscearticletemplate from '../../downloads/DS-CE-Article_Template.doc';
import Ddscecopyrightform from '../../downloads/DS-CE-Copyright_Form.docx';
import Journaltitle from '../../pages/dsce/data.json';

export default function Dsdscearticledetails() {
    const { post } = useSelector((state) => state.dscejposts);
    const { id } = useParams();
  
    const styleObj = {
      lineHeight: '1rem'
    };
  
    const dispatch = useDispatch();
  
  
    useEffect(() => {
      dispatch(getjournalPost(id));
    }, [id]);
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const accordionData = [{
        content:
          <div>
            <HashLink smooth to="/ce" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
            <NavLink to="/ce/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>   Editorial Board</NavLink>
            <NavLink to="/ce/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/ce/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/ce/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/ce/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/ce/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
            {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
            <a href={Ddscearticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Ddscecopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
          </div>
      },
      ];
    if (!post) return null;
  
  return (
    <>
    <Helmet>
      <title>{`${post.title}`} </title>
      <meta name="Author" content={post.authors}/>
      <meta name="title" content={post.title}/>
      <meta name="description" content={post.abstract}/>
      <meta name="keywords" content={post.key_words}/>
      <meta name="rights" content="Copyright Dream science"/>
      <meta name="citation_title" content={post.title}/>
      <meta name="citation_journal_title" content="Dream Science Reviews of Commerce and Economics"/>
      <meta name="citation_publisher" content="Dream Science"/>
      <meta name="citation_author" content={post.authors}/>
      <meta name="citation_volume" content={`volume-${post.volume}`}/>
      <meta name="citation_year" content={moment(post.year).format("yyyy")}/>
      <meta name="citation_date" content={moment(post.published_date).format("D MMM yyyy")}/>
      <meta name="citation_online_date" content={moment(post.published_date).format("D MMM yyyy")}/>
      <meta name="citation_doi" content={post.doi}/>
      <meta name="citation_issn" content="xxxx-xxxx"/>
      <meta name="citation_abstract" content={post.abstract}/>
      <meta name="citation_pdf_url" content={`https://dsjournals.com/upload/CE/volume-${post.volume}/issue-${post.issue}/${post.article_file}`}/>
      <meta name="citation_language" content="English" />
      <meta property="og:url" content={`https://dsjournals.com/ce/${post.article_id}`}/>
      <meta property="og:site_name" content="dsjournals"/>
      <meta property="og:title" content={post.title}/>
      <meta property="og:type" content="website"/>
      <meta property="og:description" content={post.abstract}/>
      <meta property="og:image:type" content="image/webp"/>
      <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Commerce-and-Economics.webp"/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:site" content="website"/>
      <meta name="twitter:description" content={post.title}/>
      <meta name="twitter:title" content={post.abstract}/>
      <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Commerce-and-Economics.webp"/>
      <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
      <meta name="robots" content="index, follow"/>
    </Helmet>
  
    <section id="hero-no-slide-dsce" style={{ height: "300px" }} className="d-flex justify-cntent-center align-items-center">
          <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    {/* <h2>Article Details</h2> */}
                    {
                                Journaltitle.map(heading => {
                                  return(
                                    <h2 style={{fontSize:"25px"}}>{heading.title}</h2>     
                                  )
                                      
                                })
                            }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  
        <main id="main">
          <section className="blog" id="blog">
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <div className="list-group">
                    <div className="accordion">
                      {accordionData.map(({ content }) => (
                        <Accordion content={content} />
                      ))}
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-9 mt-3">
                  <h6 style={{ fontWeight: "bold" }}>Research Article | Open Access | <span style={{ fontSize: "16px", fontStyle: "normal" }}><a href={`/upload/CE/volume-${post.volume}/issue-${post.issue}/${post.article_file}`} target="_blank"><i class="bi bi-download"></i> Download Full Text</a></span></h6>
  
                  <p style={{ fontSize: "small" }}>Volume {post.volume} | Issue {post.issue} | Year {moment(post.year).format("yyyy")} | Article Id. {post.article_id} {/*| DOI :  <a href={post.doi} target="_blank">{post.doi}</a>*/}
                  </p>
                  <div className="collapse" id="collapseExample">
  
                  </div>
                  <h1 style={{ fontSize: "25px" }}>{post.title}</h1>
                  
                  <hr />
                  <h2 style={{ fontSize: "12px" }}>
                    <span style={{ fontWeight: "bold" }}> {post.authors} </span>
                  </h2>
  
  
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td className="text-wrap text-center border-top border-end border-bottom m-2">
                        Received<br />
                        {moment(post.received_date).format("D MMM YYYY")}
                      </td>
                      <td className="text-wrap text-center border-top border-end border-bottom">
                        Revised<br />
                        {moment(post.revised_date).format("D MMM yyyy")}
                      </td>
                      <td className="text-wrap text-center border-top border-end border-bottom">
                        Accepted<br />
                        {moment(post.accepted_date).format("D MMM yyyy")}
                      </td>
                      <td className="text-wrap text-center border-top border-bottom">
                        Published<br />
                        {moment(post.published_date).format("D MMM yyyy")}
                        
                      </td>
                    </tr>
                  </table>
  
                  <h5 style={{ marginTop: '10px' }}>Citations:</h5>
                  <p id='keywords' style={{ textAlign: "justify" }}>
                    
                    {post.authors}, " {post.title} " <span style={{ fontStyle: "italic" }}>DS Reviews of Commerce and Economics, </span> vol. {post.volume}, no. {post.issue}, pp. {post.page_start}-{post.page_end}, {moment(post.year).format("yyyy")}.
  
                  </p>
                  <h5>Abstract</h5>
                  <p id='abstract' style={{ textAlign: "justify" }}> {post.abstract}</p>
                  <h5>Keywords</h5>
                  <p id='keywords' style={{ textAlign: "justify" }}>
                    
                    {post.key_words}
                    
                  </p>
                    
                  <h5>References</h5>
                  <p style={{ textAlign: "justify",overflowWrap:"break-word" }}><div className="refertag" dangerouslySetInnerHTML={{ __html: post.references }} style={styleObj} /></p>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
  )
}
