import React ,{useEffect,useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';
import Post from './Post/Post';
import { getcissuePost,getcissuePosts } from '../../../../actions/currentissue/posts';

const Posts = ({setCurrentId}) => {
  const jouname = "DST";
  const dispatch = useDispatch();
  const [postData, setPostData] = useState({ journal_title: '', volume: '', issue: '' });
  const posts = useSelector((state) =>  state.posts)
  const cpost = useSelector((state) => (jouname ? state.cissueposts.posts.find((jname)=>  jname.journal_title === jouname) : null));
  
    useEffect(() => {
      dispatch(getcissuePosts())
   },[dispatch]);

   useEffect(() => {
    if (cpost) setPostData(cpost);
  }, [cpost]);
  
  useEffect(() => {
    dispatch(getcissuePost(jouname))
  }, [jouname])

  return (
    !posts.length ? <CircularProgress /> : (
        <Grid container alignItems="stretch" spacing={3}>
            {/* {posts.map((post) => (
               <Grid key={post._id} item xs={12} sm={12} md={12}>
               <Post post={post} setCurrentId={setCurrentId} />
             </Grid>
            ))} */}

              {posts.filter(key=> key.volume === postData.volume & key.issue === postData.issue).map((post) => (
               <Grid key={post._id} item xs={12} sm={12} md={12}>
               <Post post={post} setCurrentId={setCurrentId} />
             </Grid>
            ))}
        </Grid>
    )
  
  )
}

export default Posts