import React, {useState,useEffect} from "react";
import { useDispatch } from "react-redux";
import {useParams,useHistory} from 'react-router-dom';
import Formmui from '../../components/Forms/Jmuiform';
import {Box, Button} from '@material-ui/core';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useStyles from '../styles';
import { getjournalPosts } from "../../actions/journal/posts";
import Multistepform from "../../components/Forms/dsjst/Multistepform";


const Dcreatejournal = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  console.log(id)
  const initialValue = 0;
  const [currentId, setCurrentId] = useState(id === undefined ? initialValue : id);
  const dispatch = useDispatch();

  const viewJournal = () => {
    history.push('/dsstviewdata');
  }

  useEffect(() => {
    dispatch(getjournalPosts());
  }, [currentId,dispatch])

  return (
      <>
       
           <section className="home-section">
                  <div className="home-content">

                  <Box
                  component="span"
                  m={3}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="flex-start">
                  <Button  className={classes.buttonSubmit} onClick={viewJournal} variant="contained"> <VisibilityIcon fontSize="small" />&nbsp; Show Journal</Button>
                  </Box>      
                  <Multistepform currentId= {currentId} setCurrentId={setCurrentId}/>



                    {/* <Formmui/> */}
                  </div>
          </section>
      </>
  )

}

export default Dcreatejournal
