import React ,{useState,useEffect}from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import decode from 'jwt-decode';


import * as actionType from '../../constants/actionTypes';

const Dsidebar = () => {
  const [user,setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();   

  const [click, setClick] = useState(false);
  const [dropdown , setDropdown]= useState(false);

  const handleClick = () => setClick(!click);
  const dropdownClick = () => setDropdown(!dropdown);
  

  const logout = () =>{
    dispatch({type: actionType.LOGOUT});
    history.push('/auth');
    window.location.reload();
    // history.pushState('/auth');
    setUser(null);
};

const token = user?.token;

const userProfile = () =>{
  if(token){
    const decodedToken = decode(token);
    if(decodedToken.exp * 1000 < new Date().getTime()) 
    logout();
}
setUser(JSON.parse(localStorage.getItem('profile')));
}
useEffect(()=>{
  userProfile();
},[location])

// useEffect(()=>{
//     const token = user?.token;
//     if(token){
//         const decodedToken = decode(token);
//         if(decodedToken.exp * 1000 < new Date().getTime()) logout();
//     }
//     setUser(JSON.parse(localStorage.getItem('profile')));
// },[location]);

  return (
    <>
      <div className={click? "sidebar close" : "sidebar"} id="dsidebar">
              <div className="logo-details">
                <div className="logo_img">
                <img src="../assets/img/logo192.png" alt="logo" className="img-thumbnail" />
                </div>       
              <span className="logo_name">Dream Science</span>
            </div>
              <ul className="nav-links">
                <li>
                  <Link to="/dashboard">
                    <i className='bx bx-grid-alt' ></i>
                    <span className="link_name">Dashboard</span>
                  </Link>
                  <ul className="sub-menu blank">
                    <li><Link className="link_name" to="#">Dashboard</Link></li>
                  </ul>
                </li>
                {/* <li>
                  <div className="iocn-link">
                    <Link to="/fronthome">
                      <i className='bx bx-collection' ></i>
                      <span className="link_name">HomePage</span>
                    </Link>
                    <i className='bx bxs-chevron-down arrow' ></i>
                  </div>
                  <ul className="sub-menu">
                    <li><Link className="link_name" to="#">HomePage</Link></li>
                    <li><Link to="/testpage">Test Page</Link></li>
                    <li><Link to="/leftcard">Left Card</Link></li>
                    <li><Link to="/midcard">Middle Card</Link></li>
                    <li><Link to="/rightcard">Right Card</Link></li>
                  </ul>
                </li> */}
                {/* <li>
                  <div className="iocn-link">
                    <Link to="#">
                      <i className='bx bx-book-alt' ></i>
                      <span className="link_name">JournalCard</span>
                    </Link>
                    <i className='bx bxs-chevron-down arrow' ></i>
                  </div>
                  <ul className="sub-menu">
                    <li><Link className="link_name" to="#">JournalCard</Link></li>
                    <li><Link to="/journalpage">Add/Edit/Delete</Link></li>
                    
                  </ul>
                </li> */}
                <li onClick={dropdownClick} className={dropdown? "showMenu" : ""}>
                  <div className="iocn-link">
                    <Link  to="#">
                      <i className='bx bx-book-alt' ></i>
                      <span className="link_name">Journals</span>
                    </Link>
                    <i className='bx bxs-chevron-down arrow' ></i>
                  </div>
                 
                   
                 
                  <ul className="sub-menu">
                    <li><Link to="/dviewjournal">DS-DST</Link></li>
                    <li><Link to="/dlll/dslllviewjournal">DS-DLLL</Link></li>
                    <li><Link to="/dashboard/dsair/dsairviewjournal">DS-DAIR</Link></li>
                    <li><Link to="/dashboard/dscys/dscysviewjournal">DS-CYS</Link></li>
                    <li><Link to="/dashboard/dsms/dsmsviewjournal">DS-MS</Link></li>
                    <li><Link to="/dashboard/dsm/dsmviewjournal">DSM</Link></li>
                    <li><Link to="/dashboard/dsce/dsceviewjournal">DS-CE</Link></li>
                    <li><Link to="/dashboard/dsrls/dsrlsviewjournal">DS-RLS</Link></li>
                  </ul>
                </li>
                {/* <li>
                  <Link to="#">
                    <i className='bx bx-pie-chart-alt-2' ></i>
                    <span className="link_name">Analytics</span>
                  </Link>
                  <ul className="sub-menu blank">
                    <li><Link className="link_name" to="#">Analytics</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="#">
                    <i className='bx bx-line-chart' ></i>
                    <span className="link_name">Chart</span>
                  </Link>
                  <ul className="sub-menu blank">
                    <li><Link className="link_name" to="#">Chart</Link></li>
                  </ul>
                </li>
                <li>
                  <div className="iocn-link">
                    <Link to="#">
                      <i className='bx bx-plug' ></i>
                      <span className="link_name">Plugins</span>
                    </Link>
                    <i className='bx bxs-chevron-down arrow' ></i>
                  </div>
                  <ul className="sub-menu">
                    <li><Link className="link_name" to="#">Plugins</Link></li>
                    <li><Link to="#">UI Face</Link></li>
                    <li><Link to="#">Pigments</Link></li>
                    <li><Link to="#">Box Icons</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="#">
                    <i className='bx bx-compass' ></i>
                    <span className="link_name">Explore</span>
                  </Link>
                  <ul className="sub-menu blank">
                    <li><Link className="link_name" to="#">Explore</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="#">
                    <i className='bx bx-history'></i>
                    <span className="link_name">History</span>
                  </Link>
                  <ul className="sub-menu blank">
                    <li><Link className="link_name" to="#">History</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="#">
                    <i className='bx bx-cog' ></i>
                    <span className="link_name">Setting</span>
                  </Link>
                  <ul className="sub-menu blank">
                    <li><Link className="link_name" to="#">Setting</Link></li>
                  </ul>
                </li> */}
                <li>
    <div class="profile-details">
      <div class="profile-content">
        <img src="../assets/img/profile.jpg" alt="profileImg"/>
      </div>
      <div class="name-job">
        <div class="job">{!user === "undefined" ? user.result.name : "Administrator"}</div>
      </div>
      <i class='bx bx-log-out' ></i>
    </div>
  </li>
          </ul>
        </div>

        <div className="home-section">
            <nav className="nav-bar">
                   <div className="sidebar-button" id="dsidebar-button">
                       <i onClick={handleClick} className={`bx ${click ? 'bx-menu-alt-right sidebarBtn' : 'bx bx-menu sidebarBtn'}`}></i>
                       <span className="dashboard">Dashboard</span>
                   </div>
                   <div>
                 
                  </div>
                   <div className="profile-details">
                      <Button className="admin_name" onClick={logout}>Logout</Button>
                   {/* <i className='bx bx-log-out'></i> */}
                   </div>
                   </nav>
               </div>   
    </>
  )
}

export default Dsidebar


// class Dsidebar extends Component {
//     render() {
     
//         return (
//             <>
//             <div className="sidebar close" id="dsidebar">
//               <div className="logo-details">
//                 <div className="logo_img">
//                 <img src="../assets/img/logo192.png" alt="logo" className="img-thumbnail" />
//                 </div>       
//               <span className="logo_name">Dream Science</span>
//             </div>
//               <ul className="nav-links">
//                 <li>
//                   <Link to="/dashboard">
//                     <i className='bx bx-grid-alt' ></i>
//                     <span className="link_name">Dashboard</span>
//                   </Link>
//                   <ul className="sub-menu blank">
//                     <li><Link className="link_name" to="#">Dashboard</Link></li>
//                   </ul>
//                 </li>
//                 <li>
//                   <div className="iocn-link">
//                     <Link to="/fronthome">
//                       <i className='bx bx-collection' ></i>
//                       <span className="link_name">HomePage</span>
//                     </Link>
//                     <i className='bx bxs-chevron-down arrow' ></i>
//                   </div>
//                   <ul className="sub-menu">
//                     <li><Link className="link_name" to="#">HomePage</Link></li>
//                     <li><Link to="/leftcard">Left Card</Link></li>
//                     <li><Link to="/midcard">Middle Card</Link></li>
//                     <li><Link to="/rightcard">Right Card</Link></li>
//                   </ul>
//                 </li>
//                 <li>
//                   <div className="iocn-link">
//                     <Link to="#">
//                       <i className='bx bx-book-alt' ></i>
//                       <span className="link_name">JournalCard</span>
//                     </Link>
//                     <i className='bx bxs-chevron-down arrow' ></i>
//                   </div>
//                   <ul className="sub-menu">
//                     <li><Link className="link_name" to="#">JournalCard</Link></li>
//                     <li><Link to="/journalpage">Add/Edit/Delete</Link></li>
                    
//                   </ul>
//                 </li>
//                 <li>
//                   <div className="iocn-link">
//                     <Link to="#">
//                       <i className='bx bx-book-alt' ></i>
//                       <span className="link_name">Create Journal</span>
//                     </Link>
//                     <i className='bx bxs-chevron-down arrow' ></i>
//                   </div>
//                   <ul className="sub-menu">
//                     <li><Link className="link_name" to="#">Create Journal</Link></li>
//                     <li><Link to="/journalmgt">Add/Edit/Delete</Link></li>
                    
//                   </ul>
//                 </li>
//                 <li>
//                   <Link to="#">
//                     <i className='bx bx-pie-chart-alt-2' ></i>
//                     <span className="link_name">Analytics</span>
//                   </Link>
//                   <ul className="sub-menu blank">
//                     <li><Link className="link_name" to="#">Analytics</Link></li>
//                   </ul>
//                 </li>
//                 <li>
//                   <Link to="#">
//                     <i className='bx bx-line-chart' ></i>
//                     <span className="link_name">Chart</span>
//                   </Link>
//                   <ul className="sub-menu blank">
//                     <li><Link className="link_name" to="#">Chart</Link></li>
//                   </ul>
//                 </li>
//                 <li>
//                   <div className="iocn-link">
//                     <Link to="#">
//                       <i className='bx bx-plug' ></i>
//                       <span className="link_name">Plugins</span>
//                     </Link>
//                     <i className='bx bxs-chevron-down arrow' ></i>
//                   </div>
//                   <ul className="sub-menu">
//                     <li><Link className="link_name" to="#">Plugins</Link></li>
//                     <li><Link to="#">UI Face</Link></li>
//                     <li><Link to="#">Pigments</Link></li>
//                     <li><Link to="#">Box Icons</Link></li>
//                   </ul>
//                 </li>
//                 <li>
//                   <Link to="#">
//                     <i className='bx bx-compass' ></i>
//                     <span className="link_name">Explore</span>
//                   </Link>
//                   <ul className="sub-menu blank">
//                     <li><Link className="link_name" to="#">Explore</Link></li>
//                   </ul>
//                 </li>
//                 <li>
//                   <Link to="#">
//                     <i className='bx bx-history'></i>
//                     <span className="link_name">History</span>
//                   </Link>
//                   <ul className="sub-menu blank">
//                     <li><Link className="link_name" to="#">History</Link></li>
//                   </ul>
//                 </li>
//                 <li>
//                   <Link to="#">
//                     <i className='bx bx-cog' ></i>
//                     <span className="link_name">Setting</span>
//                   </Link>
//                   <ul className="sub-menu blank">
//                     <li><Link className="link_name" to="#">Setting</Link></li>
//                   </ul>
//                 </li>
//                 <li>
//     <div class="profile-details">
//       <div class="profile-content">
//         <img src="../assets/img/profile.jpg" alt="profileImg"/>
//       </div>
//       <div class="name-job">
//         <div class="profile_name">Admin</div>
//         <div class="job">Administrator</div>
//       </div>
//       <i class='bx bx-log-out' ></i>
//     </div>
//   </li>
//           </ul>
//         </div>

//             </>
//         )
//     }
// }
// export default Dsidebar;