import React ,{useEffect} from 'react';
import {Link, NavLink} from 'react-router-dom';
import Accordion from '../../components/Dsm/Accordion';
import { HashLink } from 'react-router-hash-link';
import Ddsmarticletemplate from '../../downloads/DSM-Article_Template.docx';
import Ddsmcopyrightform from '../../downloads/DSM-Copyright_Form.docx';
import './Dsdsmeditorialboard.css';
import { Helmet } from 'react-helmet';
import Journaltitle from '../../pages/dsdsm/data.json';

const Dsdsmeditorialboard = () => {
    useEffect(()=>{
        window.scrollTo(0,0);
      },[]);
    
      const accordionData = [{
        content: <div>
            <HashLink smooth to="/dsm" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
            <NavLink to="/dsm/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>   Editorial Board</NavLink>
            <NavLink to="/dsm/paper-submission"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/dsm/current-issue"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/dsm/archives"           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/dsm/topics"                           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target= "_blank"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target= "_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/dsm/apc"               className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
        {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
            <a href={Ddsmarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Ddsmcopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
            
    </div> 
    },
      ];
  return (
    <>
        <Helmet>
          <title>Editorial Board | DS Journal of Multidisciplinary (DSM)</title>
          <link rel="canonical" href="https://dsjournals.com/dsm/editorial-board"/>
          <meta name="title" content="Editorial Board | DS Journal of Multidisciplinary (DSM)"/>
          <meta name="description" content="DSM Journals editorial board, consisting of experts from different fields, ensures the quality and integrity of published research. Learn more here."/>
          <meta name="keywords" content="editorial board, editorial board members, editorial department, journal editorial board, become editorial board member, editorial member, editorial editor, board editorial, editorial team members, journal editorial board positions, editorial committee, editorial board positions."/>
          <meta property="og:type" content="Website"/>
          <meta property="og:site_name" content="Dream Science"/>
          <meta property="og:url" content="https://dsjournals.com/dsm/editorial-board"/>
          <meta property="og:title" content="Editorial Board | DS Journal of Multidisciplinary (DSM)"/>
          <meta property="og:description" content="DSM Journals editorial board, consisting of experts from different fields, ensures the quality and integrity of published research. Learn more here."/>
          <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Multidisciplinary.webp"/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:description" content="DSM Journals editorial board, consisting of experts from different fields, ensures the quality and integrity of published research. Learn more here."/>
          <meta name="twitter:site" content="@DreamScience4"/>
          <meta name="twitter:title" content="Editorial Board | DS Journal of Multidisciplinary (DSM)"/>
          <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
          <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Multidisciplinary.webp"/>
          <meta name="robots" content="index, follow"/>
        </Helmet>


     <section id="hero-no-slide-dsdsm" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
          <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    {/* <h2>Editorial Board</h2> */}
                    {
                                Journaltitle.map(heading => {
                                  return(
                                    <h2 style={{fontSize:"25px"}}>{heading.title}</h2>     
                                  )
                                      
                                })
                            }
                  </div>
              </div>
            </div>
          </div>
          </div>
        </section>
        
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              {/* <h2>Editor Spotlight</h2> */}
              <ol>
              <li><Link to="/home">Home</Link></li>
                <li><Link to="/journals">Journals</Link></li>
                <li><Link to="/dsm">DSM</Link></li>
                <li>Editorial Board</li>
              </ol>
            </div>
          </div>
        </section>
             <main id="main">
    <section className="blog" id="blog">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 mb-3">
                <div className="list-group">
                  <div className="accordion">
                        {accordionData.map(({content }) => (
                          <Accordion content={content} />
                      ))}
                  </div>
                </div>

            <div className="abtlistgrp">
              </div>
                </div>
                {/* <div className='col-lg-9 video-box align-self-baseline position-relative'>
                 <div className="d-flex justify-content-center">
                           <p><h1>Coming Soon...</h1></p>
                           
                           </div>    
                           <div className="copyright d-flex justify-content-center">
                              &copy; Powered By &nbsp; <strong><span>Dream Science</span></strong>. 
                          </div>
                </div> */}
                <div id='head1' className="col-lg-9">
                 {/* <h3>Editorial Board</h3> */}
                 <h3>Editorial Board</h3>
                 <div className='row'>
                  <h5 className='text-left'>
                    <span style={{ backgroundColor: "#94c045",
    color:"#fff",
    lineHeight: "43px",
    padding: "5px 10px",
    whiteSpace:"pre-wrap"}}>Editor in Chief</span></h5>

                         



                  <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}>Prof. Sr Dr. Mohd Saidin Misnan,</div> 
                  <p style={{textAlign:"left",fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-justify text-muted">
                      Associate Professor,<br/>
                      Department of Quantity Surveying, <br />
                      Faculty of Built Environment and Surveying (FBES), <br/>
                      University of Technology Malaysia,<br />
                      Malaysia.
                      <br/>b-saidin@utm.my<br/>
                    <a href='https://people.utm.my/saidin/' target="_balnk">Profile Link</a> </p>
                  </div>
                 
                
                 </div>

                 <div className='row'>
                    {/* <h5 style={{textAlign:"left"}}><span style={{ backgroundColor: "#94c045",
                      color:"#fff",
                      lineHeight: "43px",
                      padding: "5px 10px",
                      whiteSpace:"pre-wrap"}}>Associate Editor</span>
                    </h5> */}
                     <h5 className='text-left'><span style={{ backgroundColor: "#94c045",
                  color:"#fff",
                  lineHeight: "43px",
                  padding: "5px 10px",
                  whiteSpace:"pre-wrap"}}>Editorial Board Members</span></h5>
                 <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}> Dr. M. Manimaran, </div>	
                  <p  style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted"> 
                  Senior Assistant Professor & Programme Chair,<br/>
                   School of Computing Science and Engineering,<br/> VIT Bhopal University,<br/> Madhya Pradesh, India.
                  <br/>manimaran@vitbhopal.ac.in<br/>
                  <a href='https://vitbhopal.ac.in/b-tech-e-commerce-faculty/' target="_balnk">Profile Link</a></p>
                  </div>
                 </div>
    

                 <div className='row'>
                 {/* <h5 className='text-left'><span style={{ backgroundColor: "#94c045",
                  color:"#fff",
                  lineHeight: "43px",
                  padding: "5px 10px",
                  whiteSpace:"pre-wrap"}}>Editorial Board Members</span></h5> */}
                  <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}>Dr. Gayatri Indrakanti, </div>	
                  <p style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted">
                  Assistant Professor,<br/>
                  Department of Mass Communication and Journalism,<br/> S K Somaiya College, Somaiya Vidyavihar University,<br/> Mumbai, India.
                  <br/>gayatri.i@somaiya.edu<br/>
                  <a href='https://sksc.somaiya.edu/en/view-member/310127?type=faculty' target="_balnk">Profile Link</a></p>
                  </div>
                  <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}>Dr. R. Surendiran, </div>	
                  <p style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted">
                    Associate Professor,<br/>
                  School of Information Science,<br/>
                  Annai College of Arts and Science,<br/> 
                  Tamilnadu, India.
                  <br/>surendiran@annaicollege.com<br/>
                  <a href='https://www.annaicollege.com/departments/ug/school-of-information-science/school-of-information-sciencebca-cs-it/ ' target="_balnk">Profile Link</a></p>
                  </div>
                  <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}>Dr. Tarak Nandy, </div>	
                  <p style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted"> 
                  Lecturer,<br/>
                  Institute of Computer Science and Digital Innovation,<br/> 
                  UCSI University,<br/> 
                  Malaysia.
                  <br/>tarak@ucsiuniversity.edu.my<br/>
                  <a href='https://www.ucsiuniversity.edu.my/dr-tarak-nandy' target="_balnk">Profile Link</a></p>
                  </div>
                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}> Dr. Muhammad Sukri bin Saud, </div>
                    <p style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-left text-muted">
                    Professor, <br/>
                      Department of Technical and Engineering Education,<br /> 
                      University of Technology Malaysia (UTM),<br />
                      Malaysia,<br/>
                      p-sukri@utm.my<br />
                      <a href='https://people.utm.my/sukri/' target="_balnk">Profile Link</a>
                      {/* <a href='https://humanities.utm.my/education/staff-expertise/department-of-technical-and-engineering-education/' target="_balnk">Profile Link</a> */}
                    </p>
                  </div>
              
                 </div>



                      
                </div>
              
            </div>
        </div>

    </section>


  </main>
    </>
  )
}

export default Dsdsmeditorialboard;