import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Lcardhome from './Lcardhome';
import Mcardhome from './Mcardhome';


class Fronthome extends Component {
    render() {
        return (
            <>
             <section className="home-section">
                    <div className="home-content">
                        <div className="overview-boxes">
                            
                        <div className="box">
                                <div className="right-side">
                                    <div className="box-topic">Left Card</div>
                                    <span className="text">Edit / Delete</span>
                                    <Link to="/leftcard">
                                        <div className="indicator">   
                                        <i className='bx bx-right-arrow-alt'></i>
                                        <span className="text">Click</span>
                                    </div></Link>
                                </div>
                                <i className='bx bx-award award'></i>
                            </div>

                            <div className="box">
                                <div className="right-side">
                                    <div className="box-topic">Middle Card</div>
                                    <span className="text">Edit / Delete</span>
                                    <Link to="/midcard">
                                        <div className="indicator">   
                                        <i className='bx bx-right-arrow-alt'></i>
                                        <span className="text">Click</span>
                                    </div></Link>
                                </div>
                                <i className='bx bx-award award'></i>
                            </div>
                            <div className="box">
                                <div className="right-side">
                                    <div className="box-topic">Right Card</div>
                                    <span className="text">Edit / Delete</span>
                                    <Link to="/rightcard">
                                        <div className="indicator">   
                                        <i className='bx bx-right-arrow-alt'></i>
                                        <span className="text">Click</span>
                                    </div></Link>
                                </div>
                                <i className='bx bx-award award'></i>
                            </div>
                            {/* <div className="row">
                                
                                <div className="col-lg-4">
                                <Posts setCurrentId={setCurrentId} /> 
                                Right Card<br/>
                                Edit/Delete

                                </div>
                                <div className="col-lg-4">
                                <Posts setCurrentId={setCurrentId} />
                                Middle Card<br/>
                                Edit/Delete
                                </div>
                                <div className="col-lg-4">
                                <Posts setCurrentId={setCurrentId} />
                                Left Card<br/>
                                Edit/Delete
                                </div>
                            </div> */}
                        
                        </div>
                        
                    </div>
            </section>
            <section className="home-section">
                            <h1>Welcome to TestPage</h1>
                            <div className="row">
                                
                                <div className="col-lg-4">
                                    <h6>Left Card</h6>
                                    <Lcardhome/>
                                </div>
                                <div className="col-lg-4">
                                    <h6>Middle Card</h6>
                                    <Mcardhome/>
                                </div>
                                <div className="col-lg-4">
                                    <h6>Right Card</h6>
                                   
                                </div>
                            </div> 
                           
            </section>
        </>
        )
    }
}

export default Fronthome
