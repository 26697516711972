import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { HashLink } from 'react-router-hash-link';
import Accordion from '../../components/Dscy/Accordion';
import Ddscyarticletemplate from '../../downloads/DS-CYS-Article_Template.doc';
import Ddscycopyrightform from '../../downloads/DS-CYS-Copyright_Form.docx';

const Dsdscytopics = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const accordionData = [{
        content: <div>
            <HashLink smooth to="/cys" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
           <NavLink to="/cys/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
           <NavLink to="/cys/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
           <NavLink to="/cys/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
           <NavLink to="/cys/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
           <NavLink to="/cys/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
           <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
           <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
           <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
           <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
           <NavLink to="/cys/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
           <a href={Ddscyarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
           <a href={Ddscycopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>


        </div>
    },
    ];

    return (
        <>
            <Helmet>
                <title>Topics Covered - DS-CYS Journal</title>
                <link rel="canonical" href="https://dsjournals.com/cys/topics"/>
                <meta name="title" content="Topics Covered - DS-CYS Journal"/>
                <meta name="description" content="Dive into a wide range of topics in the field of Cyber Security with DS-CYS Journal. Explore the latest research and thought-provoking insights."/>
                <meta name="keywords" content="Advanced persistent threat, Information-theoretic security, Legal and public policy aspects of security and privacy, Cyber security in Internet of Things, Cyber security data analytics, Encryption and encryption-by-default."/>
                <meta property="og:type" content="Website"/>
                <meta property="og:site_name" content="Dream Science"/>
                <meta property="og:url" content="https://dsjournals.com/cys/topics"/>
                <meta property="og:title" content="Topics Covered - DS-CYS Journal"/>
                <meta property="og:description" content="Dive into a wide range of topics in the field of Cyber Security with DS-CYS Journal. Explore the latest research and thought-provoking insights."/>
                <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Cyber-Security.webp"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="Topics Covered - DS-CYS Journal"/>
                <meta name="twitter:description" content="Dive into a wide range of topics in the field of Cyber Security with DS-CYS Journal. Explore the latest research and thought-provoking insights."/>
                <meta name="twitter:site" content="@DreamScience4"/>
                <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
                <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Cyber-Security.webp"/>
                <meta name="robots" content="index, follow"/>
            </Helmet>
            <section id="hero-no-slide-dscy" className="d-flex justify-cntent-center align-items-center" style={{ height: "300px" }}>
                <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="row justify-content-center">
                                {/* <div className="col-lg-12">
                                    <h2>Topics</h2>
                                </div> */}
                                <div className="col-lg-12">
                                <h2 style={{fontSize:"25px"}}>DS Journal of Cyber Security ( DS-CYS )</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">

                        <ol>
                            <li><NavLink to="/home">Home</NavLink></li>
                            <li><NavLink to="/journals">Journals</NavLink></li>
                            <li> <NavLink to="/cys">DS-CYS</NavLink></li>
                            <li>Topics</li>
                        </ol>
                    </div>
                </div>
            </section>

            <main id="main">
                <section className="blog" id="blog">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 mb-3">
                                <div className="list-group">
                                    <div className="accordion">
                                        {accordionData.map(({ content }) => (
                                            <Accordion content={content} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">

                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>Topics</p>
                                <p style={{ textAlign: 'justify', textIndent: "50px" }}>
                                DS-CYS also aims at becoming an international source of innovation and an essential reading for IT security professionals around the world by providing an in-depth 
                                and holistic view of all security spectrums and solutions ranging from practical to theoretical. The journal’s goal is to attract a broad spectrum of article types in 
                                order to foster collaboration between different fields working to address the emergence of security and privacy threats and to bring together researchers and 
                                practitioners dealing with the diverse fields of Cyber Security and cover topics that are equally valuable for professionals as well as for those new in the field from 
                                all sectors industry, commerce and academia. The articles can include traditional research-focused articles; educational surveys to instruct the broader technical 
                                community; informative articles that discuss practical policy, legal, and economic aspects of Cyber Security; and editorial or position articles that pose new ideas and 
                                paradigm shifts for the field of Cyber Security. Answering the highly practical and commercial importance of network security R&D, submissions of applications-oriented 
                                papers describing case studies and simulations are encouraged, as well as research analysis-type papers. DS-CYS also accepts the substantially extended version of the 
                                conference papers in the field of cyber security.
                                </p>
                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>DS Journal of Cyber Security solicits articles adhering to the following broadly constructed and interpreted aspects of Cyber Security:</p>
                                <p style={{ textAlign: 'justify' }}>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-sm-6'>
                                            <ul>
                                            <li>Advanced Persistent Threat (APT)</li>
                                        <li>Adversarial Machine Learning</li>
                                        <li>Adversarial Reasoning</li>
                                        <li>AI Security</li>
                                        <li>Analysis of Vulnerabilities and Attacks</li>
                                        <li>Anomaly and Intrusion Detection and Countermeasures to Prevent Detection</li>
                                        <li>Anonymization and De-Identification Technologies</li>
                                        <li>Applied Cryptography and Cryptographic Protocols</li>
                                        <li>Authenticated Key Agreement Protocols</li>
                                        <li>Authentication and Identity Management Technologies</li>
                                        <li>Big Data Security and Privacy</li>
                                        <li>Cloud Computing and Security and Privacy Threats in the Cloud</li>
                                        <li>Computer Science and Security</li>
                                        <li>Critical Infrastructure Protection</li>
                                        <li>Cryptography and Associated Topics</li>
                                        <li>Cryptology</li>
                                        <li>Cyber Attacks</li>
                                        <li>Cyber Breaches</li>
                                        <li>Cyber Defence Tools for Improving the Level of Cyber Security</li>
                                        <li>Cyber Security Education</li>
                                        <li>Cyber Security in Internet of Things (IoT)</li>
                                        <li>Cyber Warfare</li>
                                        <li>Cyber-Physical Security</li>
                                        <li>Cyber Security Data Analytics</li>
                                        <li>Darknet and Darkweb</li>
                                        <li>Data Loss Prevention (DLP)</li>
                                        <li>Data-Driven Security and Measurement Studies</li>
                                        <li>Denial of service (DoS) Against Communication and Computing Services and Countermeasures Against DoS</li>
                                        <li>Design and Test Strategies for Secure and Survivable Systems</li>
                                        <li>Digital Forensics & Counter Forensics</li>
                                        <li>Digital Ledger Technology</li>
                                        <li>Economics of Security</li>
                                        <li>Emerging Trends in Cyber Security</li>
                                        <li>Encryption and Encryption-By-Default</li>
                                        <li>Firewalls</li>
                                        <li>Forensics Including Computer and Network Forensics</li>
                                        <li>Hacking</li>
                                        <li>Hardware Security</li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-6'>
                                            <ul>
                                            <li>Information Hiding, Including Steganology</li>
                                        <li>Information-Theoretic Security</li>
                                        <li>Insider Threats</li>
                                        <li>Jamming and Interference</li>
                                        <li>Key Generation and Key Distribution Schemes</li>
                                        <li>Key Management Cryptographic Credential Management</li>
                                        <li>Legal and Public Policy Aspects of Security and Privacy</li>
                                        <li>Machine Learning Mechanisms for Cyber Security</li>
                                        <li>Machine Learning Security</li>
                                        <li>Malware and Malware Analysis</li>
                                        <li>Malware Reverse Engineering and Malware Defense and Mitigation Strategies</li>
                                        <li>Modern Tools for Improving Cyber Security</li>
                                        <li>Network and Critical Infrastructure Security</li>
                                        <li>Physical Layer Security and Cross-Layer Security for Communications</li>
                                        <li>Privacy and Privacy Enhancing Technologies Including Applications to New Domains</li>
                                        <li>Privacy Attacks and Protection</li>
                                        <li>Privacy-Enhancing Technologies and Anonymity</li>
                                        <li>Quantum Computing Based Encryption and Decryption Techniques</li>
                                        <li>Quantum Security</li>
                                        <li>Quantum-Safe Digital Signature Schemes</li>
                                        <li>Quantum-Safe Encryption Algorithms</li>
                                        <li>Quantum-Safe Key Management Mechanisms</li>
                                        <li>Secure Operating Systems and Applications</li>
                                        <li>Security and Crime Science</li>
                                        <li>Security and Mobility Optimization</li>
                                        <li>Security in Mobile Networks</li>
                                        <li>Security Issues in Intelligent Transportation Systems (ITS)</li>
                                        <li>Security Issues in Various Networking Technologies</li>
                                        <li>Security Metrics and The Science Of Security</li>
                                        <li>Smart Grid and Power/Energy Systems Security</li>
                                        <li>Social Networks Security</li>
                                        <li>Software, Hardware and System Security</li>
                                        <li>Cyber Threat Detection/Protection</li>
                                        <li>Traffic Measurements for Cyber Security</li>
                                        <li>Usable Security</li>
                                        <li>Viruses</li>
                                        <li>Vulnerability Analysis</li>
                                        <li>Wireless Physical Layer Security</li>
                                            </ul>
                                        </div>
                                    </div> 
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Dsdscytopics;