import { START_LOADING, END_LOADING, FETCH_ALL, FETCH_POST, CREATE, UPDATE, DELETE } from "../../constants/actionTypes";

import * as api from '../../api/index.js';


export const getcissuePost = (journal_title) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    
    const { data } = await api.fetchcissuePost(journal_title);

    dispatch({ type: FETCH_POST, payload: {post : data} });
    console.log("cissue", data)
   
  } catch (error) {
    console.log(error);
  }
};

export const getcissuePosts = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchcissuePosts();

    dispatch({ type: FETCH_ALL, payload: data });
    dispatch({ type: END_LOADING });
   
  } catch (error) {
    console.log(error);
  }
};

export const createcissuePost = (post) => async (dispatch) => {
  try{
    dispatch({ type: START_LOADING });
    const data = await api.createcissuePost(post);
    dispatch({type: CREATE, payload: data}); 
    alert("Data Added Successfully");
    }catch(error){  
    console.log(error.message);
   }
};

export const updatecissuePost = (id, post) => async (dispatch) =>{
  try {
    const data = await api.updatedcissuePost(id,post);
    dispatch({type: UPDATE, payload: data});
  } catch (error) {
    console.log(error.message);
  }

};



export const deletecissuePost = (id) => async (dispatch) => {
  try {
    await await api.deletecissuePost(id);

    dispatch({ type: DELETE, payload: id });
  } catch (error) {
    console.log(error);
  }
};






