import React, { useState, useEffect } from 'react';
import {Link,NavLink,useLocation} from 'react-router-dom';
import Posts from '../../components/Dscse/Articlelist/Posts/Posts';
import { useSelector,useDispatch } from 'react-redux';

import { getjournalPosts } from '../../actions/journal/posts';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dscse/Accordion';
import Dsjstarticletemplate from '../../downloads/DS-DST-Article_Template.doc';
import Dsjstcopyrightform from '../../downloads/DS-DST-Copyright_Form.docx';





const Archiveslist = () => {
  
  const [currentId, setCurrentId] = useState(0);
  const [monthpost, setMonthpost] = useState("");
  const dispatch = useDispatch();


const posts = useSelector((state) => state.posts)
  

  const currentURL = window.location.href;
  const sQuery = currentURL.split("/");
  const fet_volume = sQuery[5] ? sQuery[5].match(/\d+/g) : "";
  const fet_issue = sQuery[6] ? sQuery[6].match(/\d+/g) : "";
  // const fet_volume = sQuery[5].match(/\d+/g);
  // const fet_issue =  sQuery[6].match(/\d+/g);

  const {state} = useLocation();  
  
  const act_volume = !state ? fet_volume[0] : state.volume;
  const act_issue = !state ? fet_issue[0] : state.issue
  const act_issue_month = !state ? "": ", "+state.month_of_issue;
  
  

  
  // useEffect(() => {
  //   dispatch(getjournalPosts());    
  // }, [currentId, dispatch]);

  useEffect(() => {
    dispatch(getjournalPosts());   
  }, [currentId,dispatch]);

  useEffect(() => {
   setMonthpost(posts)    
  });
 
  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  const accordionData = [{
    content: <div>
        <HashLink smooth to="/dst" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Aim and Scope</HashLink>
        <NavLink to="/dst/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
        <NavLink to="/dst/paper-submission"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
        <NavLink to="/dst/current-issue"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
        <NavLink to="/dst/archives"           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
        <NavLink to="/dst/topics"                           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
        <NavLink to="/for-authors/publication-ethics" target= "_blank"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
        <NavLink to="/for-authors/author-guideline" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
        <NavLink to="/for-editors" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
        <NavLink to="/for-reviewers" target= "_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
        <NavLink to="/dst/apc"               className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
        {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
        <a href={Dsjstarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
        <a href={Dsjstcopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
</div> 
},
  ];




    return (
        <>
    <Helmet>
    <title>Archives - DS Journal of Digital Science and Technology</title>
      <link rel="canonical" href="https://dsjournals.com/dst/archives"/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="title" content="Archives - DS Journal of Digital Science and Technology"/>
      <meta name="description" content="Browse the archives of DST journal and access past issues of the journal. Stay updated with the latest research and advancements in digital science and technology."/>
      <meta name="keywords" content="digital science, technology, peer-reviewed journal, international journal, research, analysis, trends, archives, algorithms, digital science engineering, digital science, data mining, artificial intelligence, machine learning, computer vision."/>
      <meta property="og:type" content="Website"/>
      <meta property="og:site_name" content="Dream Science"/>
      <meta property="og:url" content="https://dsjournals.com/dst/archives"/>
      <meta property="og:title" content="Archives - DS Journal of Digital Science and Technology"/>
      <meta property="og:description" content="Browse the archives of DST journal and access past issues of the journal. Stay updated with the latest research and advancements in digital science and technology."/>
      <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Digital-Science-and-Technology.webp"/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content="Archives - DS Journal of Digital Science and Technology"/>
      <meta name="twitter:description" content="Browse the archives of DST journal and access past issues of the journal. Stay updated with the latest research and advancements in digital science and technology."/>
      <meta name="twitter:site" content="@DreamScience4"/>
      <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
      <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Digital-Science-and-Technology.webp"/>
      <meta name="robots" content="index, follow"/>
    </Helmet>

           <section id="hero-no-slide-dst" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
          <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <div className="row justify-content-center">
                <div className="col-lg-12">
                            <h1 style={{fontSize:"25px"}}>DS Journal of Digital Science and Technology ( DS-DST )</h1>
                        </div>
              </div>
            </div>
          </div>
          </div>
        </section>
        
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              {/* <h2>Archives</h2> */}
              <ol>
                <li><Link to="/home">Home</Link></li>
                <li><Link to="/journals">Journals</Link></li>
                <li><Link to="/dst/archives">Archives</Link></li>
                <li>Article List</li>
              </ol>
            </div>
          </div>
        </section>
       
             <main id="main">
                <section className="blog" id="blog">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                              <div className="accordion">
                                    {accordionData.map(({content }) => (
                                      <Accordion content={content} />
                                  ))}
                              </div>
                            </div>
                            
                            <div className="col-lg-9">
                                <h5 className='mt-3'>List of Articles</h5>
                                <div className="row mt-3">
                                    <div className="col-lg-12 text-center">
                                    
                                    <h5 style={{alignItems:'center'}}> Volume {act_volume} Issue {act_issue} {act_issue_month}</h5>
                                    {/* <h5 style={{alignItems:'center'}}> Volume {act_volume} Issue {act_issue} {act_issue_month ? act_issue_month : monthfilter && monthfilter ? monthfilter : "null" }</h5> */}
                                    
                                    </div>
                                </div>
                                      {
                                      
                                      }
                                  <div className='row'>
                                    <div className='col-lg-12'>
                                    <Posts setCurrentId={setCurrentId} volume={act_volume} issue={act_issue}/>  
                                      
                                    </div>
                                                      
          

                                  </div>
                                
                                    

                              

                            </div>
                          
                        </div>
                    </div>

                </section>


              </main>     
        </>
    )
}
export default Archiveslist;