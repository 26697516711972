import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Journals.css';
import { Helmet } from 'react-helmet';
// import { HashLink } from 'react-router-hash-link';


function Journals() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Journals | DS Journals | Scholarly Publications</title>
        <link rel="canonical" href="https://dsjournals.com/journals" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="title" content="Journals | DS Journals | Scholarly Publications" />
        <meta name="description" content="DS Journals provides a platform for scholars to publish their research in various fields. Browse our journals and submit your work for peer-review." />
        <meta name="keywords" content="scopus indexed journals, scopus journal, ds journal of engineering digital science and technology, language journal, multidisciplinary journal, modeling and simulation journal, computer science journal, environmental journals." />
        <meta property="og:type" content="Website" />
        <meta property="og:site_name" content="Dream Science" />
        <meta property="og:url" content="https://dsjournals.com/journals" />
        <meta property="og:title" content="Journals | DS Journals | Scholarly Publications" />
        <meta property="og:description" content=" DS Journals provides a platform for scholars to publish their research in various fields. Browse our journals and submit your work for peer-review." />
        <meta property="og:image" content="image url" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Journals | DS Journals | Scholarly Publications" />
        <meta name="twitter:description" content="DS Journals provides a platform for scholars to publish their research in various fields. Browse our journals and submit your work for peer-review." />
        <meta name="twitter:site" content="@DreamScience4" />
        <meta name="twitter:url" content="https://twitter.com/DreamScience4" />
        <meta name="twitter:image" content="image url" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <section id="hero-no-slider" className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="row justify-content-center">
                <div className="col-xl-8">
                  <h2>Journals</h2>
                  {/* <HashLink to="#journals" className="btn-get-started ">Read More</HashLink> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            {/* <h2>Journals</h2> */}
            <ol>
              <li><Link to="/home">Home</Link></li>
              <li>Journals</li>
            </ol>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="blog" id="blog">
          <div className="container">
            <div className="row">
              {/* <div className="col-lg-4">
               <div className="sidebar sticky-top">
               <h3 className="sidebar-title">Year of Publication</h3>
               <div className="sidebar-item categories justify-content-center">
               <Dropdown className="ml-3 outline-dark">
                    <Dropdown.Toggle varient="success" id="dropdownlist-basic">Select Year
                    </Dropdown.Toggle>   
                    <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">2021</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">2020</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">2019</Dropdown.Item>
                    </Dropdown.Menu>
               </Dropdown>

               </div>
             </div>
             </div> */}

              <div id="journals" className="col-lg-12 col-md-6 col-sm-3">
                <div className="row">
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="card">
                      <Link to="/dst">
                        <img
                          src="assets/img/DS-Journal-of-Digital-Dcinence-And-Technology-Bookcover.jpg"
                          className="card-img-top"
                          alt="journal1"
                          style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", objectFit: 'cover' }}
                        /></Link>
                      <div className="card-body">
                        {/* <Link to="/journalsview"><h6 className="card-title" style={{textAlign:"center"}}>DS International Journal of Computer Science and Engineering </h6></Link> */}
                        <Link to="/dst"><h6 className="card-title" style={{ textAlign: "center" }}>DS Journal of Digital Science and Technology </h6></Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="card">
                      <Link to="/lll"><img
                        src="assets/img/DS-Journal-of-Language-Lingustics-Literature-Bookcover.jpg"
                        className="card-img-top"
                        alt="journal1"
                        style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                      /></Link>
                      <div className="card-body">
                        {/* <Link to="/journalsview"><h6 className="card-title" style={{textAlign:"center"}}>DS International Journal of Computer Science and Engineering </h6></Link> */}
                        <Link to="/lll"><h6 className="card-title" style={{ textAlign: "center" }}>DS Journal of Language, Linguistics and Literature </h6></Link>
                      </div>
                    </div>
                  </div>


                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="card">
                      <Link to="/air"><img
                        src="assets/img/DS-Journal-of-Artificial-Intelligence-and-Robotics-Bookcover.jpg"
                        className="card-img-top"
                        alt="journal1"
                        style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                      /></Link>
                      <div className="card-body">
                        {/* <Link to="/journalsview"><h6 className="card-title" style={{textAlign:"center"}}>DS International Journal of Computer Science and Engineering </h6></Link> */}
                        <Link to="/air"><h6 className="card-title" style={{ textAlign: "center" }}>DS Journal of Artificial Intelligence and Robotics </h6></Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="card">
                      <Link to="/cys"><img
                        src="assets/img/DS-Journal-of-Cyber-Security-Bookcover.jpg"
                        className="card-img-top"
                        alt="journal1"
                        style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                      /></Link>
                      <div className="card-body">
                        <Link to="/cys"><h6 className="card-title" style={{ textAlign: "center" }}>DS Journal of Cyber Security </h6></Link>
                      </div>
                    </div>
                  </div>




                  {/* <div className="col-lg-3">
               <div className="card">
               <Link to="/dsm"><img
                 src="assets/img/DS-Journal-of-Multidisciplinary-Bookcover.jpg"
                 className="card-img-top"
                 alt="journal1"
                 style={{boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
               /></Link>
               <div className="card-body">
               <Link to="/dsm"><h6 className="card-title" style={{textAlign:"center"}}>DS Journal of Multidisciplinary </h6></Link><br/>
               </div>
             </div>
               </div> */}
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="card">
                      <Link to="/ms"><img
                        src="assets/img/DS-Journal-of-Modelling-and-simulation-Bookcover.jpg"
                        className="card-img-top"
                        alt="journal1"
                        style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                      /></Link>
                      <div className="card-body">
                        {/* <Link to="/journalsview"><h6 className="card-title" style={{textAlign:"center"}}>DS International Journal of Computer Science and Engineering </h6></Link> */}
                        <Link to="/ms"><h6 className="card-title" style={{ textAlign: "center" }}>DS Journal of Modeling and Simulation </h6></Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="card">
                      <Link to="/dsm"><img
                        src="assets/img/DS-Journal-of-Multidisciplinary-Bookcover.jpg"
                        className="card-img-top"
                        alt="journal1"
                        style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                      /></Link>
                      <div className="card-body">
                        <Link to="/dsm"><h6 className="card-title" style={{ textAlign: "center" }}>DS Journal of Multidisciplinary </h6></Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="card">
                      <Link to="/ce"><img
                        src="assets/img/DS-Reviews-of-Commerce-and-Economics.jpg"
                        className="card-img-top"
                        alt="journal1"
                        style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                      /></Link>
                      <div className="card-body">
                        {/* <Link to="/journalsview"><h6 className="card-title" style={{textAlign:"center"}}>DS International Journal of Computer Science and Engineering </h6></Link> */}
                        <Link to="/ce"><h6 className="card-title" style={{ textAlign: "center" }}>DS Reviews of Commerce and Economics </h6></Link>
                      </div>
                    </div>
                  </div>



                  {/* <div className="col-lg-3">
               <div className="card">
               <Link to="/air"><img
                 src="assets/img/DS-Journal-of-Artificial-Intelligence-and-Robotics-Bookcover.jpg"
                 className="card-img-top"
                 alt="journal1"
                 style={{boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
               /></Link>
               <div className="card-body">
               <Link to="/air"><h6 className="card-title" style={{textAlign:"center"}}>DS Journal of Artificial Intelligence and Robotics </h6></Link>
               </div>
                </div>
               </div> */}


                  {/* <div className="col-lg-3">
               <div className="card">
               <Link to="/dscys"><img
                 src="assets/img/DS-Journal-of-Cyber-Security-Bookcover.jpg"
                 className="card-img-top"
                 alt="journal1"
                 style={{boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
               /></Link>
               <div className="card-body">
               <Link to="/dscys"><h6 className="card-title" style={{textAlign:"center"}}>DS Journal of Cyber Security </h6></Link><br/>
               </div>
                </div>
               </div> */}


                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="card">
                      <Link to="/rls"><img
                        src="assets/img/DS-Reviews-of-Research-in-Life-Sciences-Bookcover.jpg"
                        className="card-img-top"
                        alt="journal1"
                        style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                      /></Link>
                      <div className="card-body">
                        <Link to="/rls"><h6 className="card-title" style={{ textAlign: "center" }}>DS Reviews of Research in Life Sciences </h6></Link>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </section>
      </main>
    </>
  )

}
export default Journals;