import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Email from '../assets/images/email_PNG22.png';
import Address from '../assets/images/email-box-icon-10.png';

const Contactus = () => {
    useEffect(()=>{
        window.scrollTo(0,0);
      },[]);
  return (
    <>
    <Helmet>
        <title>Reach Out to DS Journal - Contact Us for Support and Assistance</title>
        <link rel="canonical" href="https://dsjournals.com/contact-us"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="title" content="Reach Out to DS Journal - Contact Us for Support and Assistance"/>
        <meta name="description" content="We are committed to providing excellent customer service and support to our authors, reviewers, and readers. Get in touch with us today to learn more."/>
        <meta name="keywords" content="scopus indexed journals, scopus journal, international journal of commerce, digital science, modeling and simulation journal, economics journal, cyber security journal, environmental journals, artificial intelligent journals."/>
        <meta property="og:type" content="Website"/>
        <meta property="og:site_name" content="Dream Science"/>
        <meta property="og:url" content="https://dsjournals.com/contact-us"/>
        <meta property="og:title" content="Reach Out to DS Journal - Contact Us for Support and Assistance"/>
        <meta property="og:description" content=" We are committed to providing excellent customer service and support to our authors, reviewers, and readers. Get in touch with us today to learn more."/>
        <meta property="og:image" content="image url"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="Reach Out to DS Journal - Contact Us for Support and Assistance"/>
        <meta name="twitter:description" content="We are committed to providing excellent customer service and support to our authors, reviewers, and readers. Get in touch with us today to learn more."/>
        <meta name="twitter:site" content="@DreamScience4"/>
        <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
        <meta name="twitter:image" content="image url"/>
        <meta name="robots" content="index, follow"/>
    </Helmet>

     <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
    <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
      <div className="row justify-content-center">
        <div className="col-xl-8">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <h1 style={{color:"white"}}>Contact us</h1> 
              {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
            </div>
        </div>
      </div>
    </div>
    </div>
  </section>
  <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          {/* <h2>About Us</h2> */}
          <ol>
            <li><Link to="/home">Home</Link></li>
            <li>Contact Us</li>
          </ol>
        </div>
      </div>
  </section>
  
  <main id="main">
    

    {/* <section className="about section-bg" data-aos="fade-up"> */}
    <section className="contact" data-aos="fade-up">
          <div className="container">
          
            <div className="row  pb-5">
              <div className="col-lg-6">
                <div className='row'>
                    <div className='col-lg-3'>
                    <img src={Email} alt='email' style={{height:"100px"}}/>
                    </div>
                    <div className='col-lg-9'>
                    <h3>Emails Us</h3>
                <p>General inquiries<br/>
                <span style={{color:"blue"}}>queries@dsjournals.com</span></p>
                {/* <span style={{color:"blue"}}>queriesfords@gmail.com</span></p> */}
                <p>Mobile<br/>
                <span style={{color:"blue"}}>+91-9578957897 (whatsapp & call)</span></p>
                <p>Landline<br/>
                <span style={{color:"blue"}}>+91(435)-2403869</span></p>
                    </div>
                </div>              
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <div className='row'>
                    <div className='col-lg-3'>
                    <img src={Address} alt='email' style={{height:"100px"}}/>
                    </div>
                    <div className='col-lg-9'>
                    <h3>Office Address</h3>
                {/* <p>
                <span style={{fontWeight:"bold"}}>Dream Science</span> <br/>
                140, General Bazzar Street,<br/>
                Thennur, Trichy - 620017.<br/>
                TamilNadu, <br/>
                India.<br/>
                </p> */}

                <p>
                <span style={{fontWeight:"bold"}}>Dream Science</span> <br/>
                1272, Thirumetraligai East Street,<br/>
                Patteeswaram P.O.,<br/>
                Kumbakonam T.K.,<br/>
                Thanjavur District - 612 703,<br/>
                TamilNadu, <br/>
                India.<br/>
                </p>
                    </div>
                </div>
                
                
              </div>
            </div>
    
          </div>
        </section>
        </main> 
    </>
  )
}

export default Contactus