import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import './Aboutus.css';
import { Helmet } from 'react-helmet';


function Aboutus() {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);
  return (
    <>

  <Helmet>
    <title>About Us | DS Journals | Open Access Publishing</title>
    <link rel="canonical" href="https://dsjournals.com/about-us"/>
    <meta name="viewport" content="width=device-width, initial-scale=1"/>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="title" content="About Us | DS Journals | Open Access Publishing"/>
    <meta name="description" content="Learn about DS Journals, an academic publisher committed to providing rigorous peer-review and open access for scholarly publications."/>
    <meta name="keywords" content="research journal, journal of business research, journal of digital science, academic journals, journal of Multidisciplinary, Artificial Intelligence and Robotics, journal of modeing and simulation, journal of Cyber Security, research journal, journal of medicinal chemistry, journal of hydrology."/>
    <meta property="og:type" content="Website"/>
    <meta property="og:site_name" content="Dream Science"/>
    <meta property="og:url" content="https://dsjournals.com/about-us"/>
    <meta property="og:title" content="About Us | DS Journals | Open Access Publishing"/>
    <meta property="og:description" content="Learn about DS Journals, an academic publisher committed to providing rigorous peer-review and open access for scholarly publications."/>
    <meta property="og:image" content="image url"/>
    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:title" content="About Us | DS Journals | Open Access Publishing"/>
    <meta name="twitter:description" content="Learn about DS Journals, an academic publisher committed to providing rigorous peer-review and open access for scholarly publications."/>
    <meta name="twitter:site" content="@DreamScience4"/>
    <meta name="twitter:url" content="https://twitter.com/sense_groups"/>
    <meta name="twitter:image" content="image url"/>
    <meta name="robots" content="index"/>
  </Helmet>
  <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
    <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
      <div className="row justify-content-center">
        <div className="col-xl-8">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <h2>About us</h2> 
              {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
            </div>
        </div>
      </div>
    </div>
    </div>
  </section>
  <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          {/* <h2>About Us</h2> */}
          <ol>
            <li><Link to="/home">Home</Link></li>
            <li>About Us</li>
          </ol>
        </div>
      </div>
  </section>
  
  <main id="main">
    

    <section className="contact" data-aos="fade-up">
          <div className="container">
          <div className="section-title">
          <h2>Who We Are</h2>
          <p>
            Dream Science is a service based nonprofit research organization founded in India, 
            furnishing supports and services to education professionals and researchers around the
            globe without any cost or financial expectation. Our mission has always focused on 
            helping our researchers succeed, wherever they are in their education and professional 
            careers.
          </p>
        </div>
            <div className="row  pb-5">
              <div className="col-lg-6">
                <img src="assets/img/about.jpg" className="img-fluid" alt="aboutus"/>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <p style={{textAlign:"justify"}}>
                  We are bridging the higher education gap by delivering content solutions in new and 
                  innovative ways to enrich the learning experience. We are a proven leader in strategic 
                  higher education consulting and partnering with educators and institutions globally to 
                  achieve success, and our print and digital solutions enable students and instructors 
                  worldwide.We encourage learning to be a continuous, lifelong experience an integral, 
                  essential part of every stage of building a career.
                </p>
                <p style={{textAlign:"justify"}}>
                    We are closing the talent deficit by supporting employers, helping them find and retain 
                  top talent, assess their employees’ skill sets, and implement training in order to 
                  transform company cultures.For decades we’ve helped people learn; today we’re showing 
                  them new ways to achieve, get certified and advance their careers.
                </p>
                <p style={{textAlign:"justify"}}>We are strengthening the research community by partnering with learned societies and 
                  supporting researchers to communicate discoveries that make a difference. We collaborate 
                  with authors, societies, libraries, and other members of the research community to generate,
                   communicate, and enable access to the scientific and scholarly insights that are helping to 
                   solve some of the world’s biggest challenges.</p>
                  <p style={{textAlign:"justify"}}>Our commitment to partnership helps us advance innovation and connect researchers, 
                  learners, and professionals with the content, platforms, and tools they need to be 
                  successful.</p>
              </div>
            </div>
    
          </div>
        </section>

        


        {/* <section className="why-us section-bg" data-aos="fade-up" date-aos-delay="200">
       <div className="section-title">
          <h2>Annoncements</h2>
       </div>
    <div className="container">
    <div className="row">
    <div className="col-md-6 col-lg-6 mt-3 mb-3 d-md-flex align-items-md-stretch">
    <div className="card">
    <img src="assets/img/card1.png" className="card-img-top" alt="..."/>
    <div className="card-body">
      <h5 className="card-title">Card title</h5>
      <p className="card-text">This is a wider card with supporting text below as a natural lead-in to 
      additional content. This content is a little bit longer.</p><br/>
    </div>
    <div className="card-footer">
      <small className="text-muted">Last updated 3 mins ago</small>
    </div>
  </div>

          
        </div>
          <div className="col-md-6 col-lg-3 mt-3 mb-3 d-md-flex align-items-md-stretch">
              <div className="card h-100">
    <img src="assets/img/card3.jpg" className="card-img-top" alt="..."/>
    <div className="card-body">
      <h5 className="card-title">Card title</h5>
      <p className="card-text">This is a wider card with supporting text below as a natural lead-in 
      to additional content. This content is a little bit longer.</p>
    </div>
    <div className="card-footer">
      <small className="text-muted">Last updated 3 mins ago</small>
    </div>
  </div>
          </div>
          <div className="col-md-6 col-lg-3 mt-3 mb-3 d-md-flex align-items-md-stretch">
        <div className="card h-100">
    <img src="assets/img/card2.jpg" className="card-img-top" alt="..."/>
    <div className="card-body">
      <h5 className="card-title">Card title</h5>
      <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
    </div>
    <div className="card-footer">
      <small className="text-muted">Last updated 3 mins ago</small>
    </div>
  </div>


  
          
        </div>
        </div>
      </div>
    </section> */}
    </main>
                
            </>
  )
}



export default Aboutus;