import React,{useState,useEffect} from 'react';
import './Accordion.css';


const Accordion = ({ title, content }) => {
    const [isActive, setIsActive] = useState(true);
    return (
        
      <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
          <div className="accordion-text" style={{padding:"10px"}}><span className='accordion-subtext'>{title}</span>
          <span className="accordion-icon">{isActive ? '-' : '+'}</span></div>
      </div> 
  {isActive && <div className="accordion-content">{content.sort().reverse()}</div>}
</div>
    );
  };

export default Accordion;
