import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Accordion from '../../components/Dsce/Accordion';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import Ddscearticletemplate from '../../downloads/DS-CE-Article_Template.doc';
import Ddscecopyrightform from '../../downloads/DS-CE-Copyright_Form.docx';
import './Dsdsceeditorialboard.css';
import Journaltitle from '../../pages/dsce/data.json';

const Dsdsceeditorialboard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const accordionData = [{
    content: <div>
      <HashLink smooth to="/ce" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
      <NavLink to="/ce/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>   Editorial Board</NavLink>
      <NavLink to="/ce/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
      <NavLink to="/ce/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
      <NavLink to="/ce/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
      <NavLink to="/ce/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
      <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
      <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
      <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
      <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
      <NavLink to="/ce/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
      {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
      <a href={Ddscearticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
      <a href={Ddscecopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>


    </div>
  },
  ];
  return (
    <>
      <Helmet>
        <title>Editorial Board | DS Journal of Reviews of Commerce and Economics (DS - RCE)</title>
        <link rel="canonical" href="https://dsjournals.com/ce/editorial-board" />
        <meta name="title" content="Editorial Board | DS Journal of Reviews of Commerce and Economics (DS - RCE)" />
        <meta name="description" content="DS - RCE Journals editorial board, consisting of experts from different fields, ensures the quality and integrity of published research. Learn more here." />
        <meta name="keywords" content="editorial board, editorial board members, editorial department, journal editorial board, become editorial board member, editorial member, editorial editor, board editorial, editorial team members, journal editorial board positions, editorial committee, editorial board positions." />
        <meta property="og:type" content="Website" />
        <meta property="og:site_name" content="Dream Science" />
        <meta property="og:url" content="https://dsjournals.com/ce/editorial-board" />
        <meta property="og:title" content="Editorial Board | DS Journal of Reviews of Commerce and Economics (DS - RCE)" />
        <meta property="og:description" content="DS - RCE Journals editorial board, consisting of experts from different fields, ensures the quality and integrity of published research. Learn more here." />
        <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Commerce-and-Economics.webp" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="DS - RCE Journals editorial board, consisting of experts from different fields, ensures the quality and integrity of published research. Learn more here." />
        <meta name="twitter:site" content="@DreamScience4" />
        <meta name="twitter:title" content="Editorial Board | DS Journal of Reviews of Commerce and Economics (DS - RCE)" />
        <meta name="twitter:url" content="https://twitter.com/DreamScience4" />
        <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Commerce-and-Economics.webp" />
        <meta name="robots" content="index, follow" />

      </Helmet>



      <section id="hero-no-slide-dsce" className="d-flex justify-cntent-center align-items-center" style={{ height: "300px" }}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  {/* <h2 style={{color:"black"}}>Editorial Board</h2> */}
                  {
                    Journaltitle.map(heading => {
                      return (
                        <h2 style={{ fontSize: "25px" }}>{heading.title}</h2>
                      )

                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            {/* <h2>Editor Spotlight</h2> */}
            <ol>
              <li><Link to="/home">Home</Link></li>
              <li><Link to="/journals">Journals</Link></li>
              <li><Link to="/ce">DS-CE</Link></li>
              <li>Editorial Board</li>
            </ol>
          </div>
        </div>
      </section>
      <main id="main">
        <section className="blog" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 mb-3">
                <div className="list-group">
                  <div className="accordion">
                    {accordionData.map(({ content }) => (
                      <Accordion content={content} />
                    ))}
                  </div>
                </div>

                <div className="abtlistgrp">
                </div>
              </div>
              {/* <div className='col-lg-9 video-box align-self-baseline position-relative'>
                <div className="d-flex justify-content-center">
                  <p><h1>Coming Soon...</h1></p>

                </div>
                <div className="copyright d-flex justify-content-center">
                  &copy; Powered By &nbsp; <strong><span>Dream Science</span></strong>.
                </div>
              </div> */}



              <div id='head1' className="col-lg-9">
                <h3>Editorial Board</h3>
                <div className='row'>
                  <h5 className='text-left'>
                    <span style={{
                      backgroundColor: "#94c045",
                      color: "#fff",
                      lineHeight: "43px",
                      padding: "5px 10px",
                      whiteSpace: "pre-wrap"
                    }}>Editor in Chief</span></h5>





                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}>Dr. Ravinder Rena,</div> <p style={{ textAlign: "left", fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-justify text-muted">
                      Adjunct Professor of Economics,<br />
                      Monarch Business School, <br />
                      Volderbergstr, Switzerland. <br />
                      r.rena@spbu.run<br />
                      <a href='https://umonarch.ch/faculty/dr-rena/' target="_balnk">Profile Link</a> </p>
                  </div>


                </div>

                <div className='row'>
                  <h5 className='text-left'><span style={{
                    backgroundColor: "#94c045",
                    color: "#fff",
                    lineHeight: "43px",
                    padding: "5px 10px",
                    whiteSpace: "pre-wrap"
                  }}>Editorial Board Members</span></h5>
                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}> Dr. Dhruba Banjade, </div>
                    <p style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-left text-muted">
                      Assistant Professor,<br />
                      School of Business & Communication,<br />
                      Brenau University,<br /> USA.
                      <br />dbanjade@brenau.edu<br />
                      <a href='https://www.brenau.edu/people/banjade-dhruba/' target="_balnk">Profile Link</a></p>
                  </div>
                </div>


                <div className='row'>
                  {/* <h5 className='text-left'><span style={{ backgroundColor: "#94c045",
color:"#fff",
lineHeight: "43px",
padding: "5px 10px",
whiteSpace:"pre-wrap"}}>Editorial Board Members</span></h5> */}
                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}> Dr. Marshall Iwedi,</div>
                    <p style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-left text-muted">Lecturer, <br />
                      Department of Finance,<br />
                      Rivers State University, Nigeria.<br />
                      marshal.iwedi@ust.edu.ng<br />
                      <a href='https://www.rsu.edu.ng/department-of-banking-and-finance/' target="_balnk">Profile Link</a></p>
                  </div>
                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}>Dr. Ketan Vira,</div>
                    <p style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-left text-muted">Director, <br />
                      Pillai HOC Institute of Management Studies and Research, <br />
                      Maharashtra, India.<br />
                      ketanvira@mes.ac.in<br />
                      <a href='https://www.phimsr.ac.in/faculty/faculty-directory/core-faculty/' target="_balnk">Profile Link</a></p>
                  </div>

                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}>Dr. Bharati Sunil Makhijani,</div>
                    <p style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-left text-muted"> Department of Commerce & Business Studies,<br />
                      S K Somaiya College- Somaiya Vidyavihar University,<br />
                      Maharashtra, India.<br />
                      bharati.m@somaiya.edu<br />
                      <a href='https://www.somaiya.edu/en/view-member/310111/' target="_balnk">Profile Link</a></p>
                  </div>

                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}> Dr. Gurparkash Singh,</div>
                    <p style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-left text-muted">Associate Professor,<br />
                      Thapar School of Liberal Arts and Sciences,<br />
                      Thapar Institute of Engineering and Technology,<br />
                      Punjab, India.
                      <br />gurparkash@thapar.edu<br />
                      <a href='https://tslas.thapar.edu/facultymaster/72' target="_balnk">Profile Link</a></p>
                  </div>

                </div>




              </div>

            </div>
          </div>

        </section>


      </main>
    </>
  )
}

export default Dsdsceeditorialboard;