import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from '../../components/Dslll/Accordion';
// import Accordion from '../components/Accordion';
import useStyles from './styles';
import { getjournalPost } from '../../actions/journal/dslll/posts';
import { HashLink } from 'react-router-hash-link';
import Dlllarticletemplate from '../../downloads/DS-LLL-Article_Template.doc';
import Dlllcopyrightform from '../../downloads/DS-LLL-Copyright_Form.docx';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import './Dslllarticledetails.css';
import { lineHeight } from '@mui/system';



const Articledetails = () => {
  const { post } = useSelector((state) => state.dsllljposts);
  const { id } = useParams();

  const styleObj = {
    lineHeight: '1rem'
  };
  // const [postData, setPostData] = useState({ _id:'',title: '', authors:[], article_id: '', volume: '', issue: '', 
  //                                          year:'', doi: '', abstract: '', key_words: [], references:[], 
  //                                          article_file:[]});    
  //  const  post  = useSelector((state) => state.dsllljposts.posts.find(post => post._id === id));
  // const post = useSelector((state) =>(currentId ? state.dslllposts.posts.find((key) => key._id === currentId): null )) ;
  const dispatch = useDispatch();


  // useEffect(() => {
  //   if(post) setPostData(post);
  // }, [post])

  useEffect(() => {
    dispatch(getjournalPost(id));
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const accordionData = [{
    content: <div>
      <HashLink smooth to="/lll" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Aim and Scope</HashLink>
      <NavLink to="/lll/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
      <NavLink to="/lll/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
      <NavLink to="/lll/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
      <NavLink to="/lll/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
      <NavLink to="/lll/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
      <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
      <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
      <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
      <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
      <NavLink to="/lll/article-processing-charge" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
      {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
      <a href={Dlllarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
      <a href={Dlllcopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
    </div>
  },
  ];
  
  if (!post) return null;

  // if(isLoading){
  //     return(
  //         <Paper elevation={6}>
  //             <CircularProgress size="7em"/>
  //         </Paper>
  //     );
  // }



  // const accordionData = [{
  //     content: <div>
  //         <Link smooth to="#abstract" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>Abstract</Link>
  //         <Link to="#keywords" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>Keywords</Link>
  //         <Link to="#references" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>References</Link>
  // </div> 
  // },
  //   ];

  // if(!post) return null;

  // if(isLoading) {
  //   return <Paper elevation={6} className={classes.loadingPaper}>
  //     <CircularProgress/>
  //   </Paper>
  // }



  return (
    <>

      <Helmet>

        {/* <title>{`${post.title}`} </title>
        <link rel="canonical" href="https://dsjournals.com/" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="title" content={post.title} />
        <meta name="description" content={post.abstract} />
        <meta name="keywords" content={post.key_words} /> */}


          <title>{`${post.title}`} </title>
          <meta name="robots" content="index"/>
          <meta name="Author" content={post.authors}/>
          <meta name="title" content={post.title}/>
          <meta name="description" content={post.abstract}/>
          <meta name="keywords" content={post.key_words}/>
          <meta name="rights" content="Copyright Dream science"/>
          <meta name="citation_title" content={post.title}/>
          <meta name="citation_journal_title" content="Dream Science Journal of Language, Linguistics and Literature"/>
          <meta name="citation_publisher" content="Dream Science"/>
          <meta name="citation_author" content={post.authors}/>
          <meta name="citation_volume" content={`volume-${post.volume}`}/>
          <meta name="citation_year" content={moment(post.year).format("yyyy")}/>
          <meta name="citation_date" content={moment(post.published_date).format("D MMM yyyy")}/>
          <meta name="citation_online_date" content={moment(post.published_date).format("D MMM yyyy")}/>
          <meta name="citation_doi" content={post.doi}/>
          <meta name="citation_issn" content="2583-8490"/>
          <meta name="citation_abstract" content={post.abstract}/>
          <meta name="citation_pdf_url" content={`https://dsjournals.com/upload/LLL/volume-${post.volume}/issue-${post.issue}/${post.article_file}`}/>
          <meta name="citation_language" content="English" />
          <meta property="og:url" content={`https://dsjournals.com/lll/${post.article_id}`}/>
          <meta property="og:site_name" content="dsjournals"/>
          <meta property="og:title" content={post.title}/>
          <meta property="og:type" content="website"/>
          <meta property="og:description" content={post.abstract}/>
          <meta property="og:image:type" content="image/webp"/>
          <meta name="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Language-Linguistics-and-Literature.webp"/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:site" content="website"/>
          <meta name="twitter:description" content={post.title}/>
          <meta name="twitter:title" content={post.abstract}/>
          <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Language-Linguistics-and-Literature.webp"/>
          <meta name="twitter:url" content=""/>





        
      </Helmet>

      <section id="hero-no-slide-dlll" style={{ height: "300px" }} className="d-flex justify-cntent-center align-items-center">
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="row justify-content-center">
                {/* <div className="col-xl-8">
                  <h2>Article Details</h2>
                </div> */}
                <div className="col-lg-12">
                                    <h2 style={{ fontSize: "25px" }}>DS Journal of Language, Linguistics and Literature ( DS-LLL )</h2>
                                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




      <main id="main">
        <section className="blog" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="list-group">
                  <div className="accordion">
                    {accordionData.map(({ content }) => (
                      <Accordion content={content} />
                    ))}
                  </div>
                </div>
              </div>
              
              <div className="col-lg-9 mt-3">
                {/* <h6 style={{fontWeight:"bold"}}>Research Article | Open Access | <span style={{fontSize:"16px",fontStyle:"normal"}}><Link to={`/upload/volume-${post.volume}/issue-${post.issue}/${post.article_file}`} target="_blank" download><i class="bi bi-download"></i> Download</Link></span></h6> */}
                <h6 style={{ fontWeight: "bold" }}>Research Article | Open Access | <span style={{ fontSize: "16px", fontStyle: "normal" }}><a href={`/upload/LLL/volume-${post.volume}/issue-${post.issue}/${post.article_file}`} target="_blank"><i class="bi bi-download"></i> Download Full Text</a></span></h6>

                <p style={{ fontSize: "small" }}>Volume {post.volume} | Issue {post.issue} | Year {moment(post.year).format("yyyy")} | Article Id. {post.article_id} | DOI :  <a href={post.doi} target="_blank">{post.doi}</a>
                </p>
                <div className="collapse" id="collapseExample">

                </div>
                <h1 style={{ fontSize: "25px" }}>{post.title}</h1>
                {/* <h3 style={{fontSize:"25px"}}>Common Fixed Point Theorems for a Pair of Self-Mappings in 
                  Fuzzy Cone Metric Spaces</h3> */}
                <hr />
                <h2 style={{ fontSize: "12px" }}>
                  <span style={{ fontWeight: "bold" }}> {post.authors} </span>
                  {/* <span style={{fontWeight:"bold"}}> Saif Ur Rehman, Yongjin Li ,Shamoona Jabeen, Tayyab Mahmood </span> */}
                </h2>


                <table style={{ width: "100%" }}>
                  <tr>
                    <td className="text-wrap text-center border-top border-end border-bottom m-2">
                      Received<br />
                      {moment(post.received_date).format("D MMM YYYY")}
                    </td>
                    <td className="text-wrap text-center border-top border-end border-bottom">
                      Revised<br />
                      {moment(post.revised_date).format("D MMM yyyy")}
                    </td>
                    <td className="text-wrap text-center border-top border-end border-bottom">
                      Accepted<br />
                      {moment(post.accepted_date).format("D MMM yyyy")}
                    </td>
                    <td className="text-wrap text-center border-top border-bottom">
                      Published<br />
                      {moment(post.published_date).format("D MMM yyyy")}
                      {/* {post.createdAt} */}
                    </td>
                  </tr>
                </table>


                {/* {console.log(`public/upload/volume-${post.volume}/issue-${post.issue}/${post.article_file}`)}  */}

                <h5 style={{ marginTop: '10px' }}>Citations:</h5>
                <p id='keywords' style={{ textAlign: "justify" }}>
                  {/* Computer Science, Self-mapping, Fuzzy */}
                  {post.authors}, " {post.title} " <span style={{ fontStyle: "italic" }}>DS Journal of Language, Linguistics and Literature, </span> vol. {post.volume}, no. {post.issue}, pp. {post.page_start}-{post.page_end}, {moment(post.year).format("yyyy")}.

                </p>
                <h5>Abstract</h5>
                <p id='abstract' style={{ textAlign: "justify" }}> {post.abstract}</p>
                <h5>Keywords</h5>
                <p id='keywords' style={{ textAlign: "justify" }}>
                  {/* Computer Science, Self-mapping, Fuzzy */}
                  {post.key_words}
                  {/* {post.key_words.toString().split(',').map((item)=> <>{item}<br/></>)}  */}
                </p>
                  
                <h5>References</h5>
                {/* <p style={{textAlign:"justify",overflowWrap:"break-word"}}>{post.references.toString().split('</p>').map((item)=> <><li style={{listStyle:"none"}} key={item}>{item.replace('<p>','')}</li></>)}</p>                  */}
                <p style={{ textAlign: "justify",overflowWrap:"break-word"}}><div className="refertag" dangerouslySetInnerHTML={{ __html: post.references }} style={styleObj} /></p>

              </div>

            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Articledetails;