import { START_LOADING, END_LOADING, FETCH_ALL,FETCH_BY_SEARCH,FETCH_ALL_ARCH,FETCH_BY_AUTHOR, FETCH_POST, CREATE, UPDATE, DELETE } from "../../../constants/actionTypes";

import * as api from '../../../api/index.js';




export const getarchivePosts = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchdslllarchivePosts();
    console.log("archivedata",JSON.stringify(data));
    dispatch({ type: FETCH_ALL_ARCH, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};


export const createjournalPost = (post) => async (dispatch) => {
  try{
    dispatch({ type: START_LOADING });
    const data = await api.createdsllljournalPost(post);
    console.log(data);
    dispatch({type: CREATE, payload: data}); 
    }catch(error){  
    console.log(error.message);
   }
};


export const getjournalPost = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING }); 
    const { data } = await api.fetchdsllljournalPost(id);
    dispatch({ type: FETCH_POST, payload: {post : data} });
   
  } catch (error) {
    console.log(error);
  }
};

export const getPostsBySearch = (searchQuery) => async (dispatch) =>{
try{
  const {data:{data}}  = await api.fetchdslllPostBySearch(searchQuery);
  console.log(data);
  console.log(data.length)
  dispatch({ type: FETCH_BY_SEARCH, payload: data });
}catch(error){
  console.log(error);
}
};

export const getPostsBySearchauthor = (searchQuery) => async (dispatch) =>{
  try{
    const {data:{data}}  = await api.fetchdslllPostByauthor(searchQuery);
    console.log(data);
    console.log(data.length)
    dispatch({ type: FETCH_BY_AUTHOR, payload: data });
  }catch(error){
    console.log(error);
  }
  };

export const getjournalPosts = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchdsllljournalPosts();

    dispatch({ type: FETCH_ALL, payload: data });
    dispatch({ type: END_LOADING });
   
  } catch (error) {
    console.log(error);
  }
};

export const updatejournalPost = (id, post) => async (dispatch) =>{
  try {
    const data = await api.updatedsllljournalPost(id,post);
    dispatch({type: UPDATE, payload: data});
    alert("Data updated successfully");
  } catch (error) {
    console.log(error.message);
  }
};

export const deletejournalPost = (id) => async (dispatch) => {
  try {
    await await api.deletedsllljournalPost(id);

    dispatch({ type: DELETE, payload: id });
    alert("Are your sure  deleted " + id);
  } catch (error) {
    console.log(error);
  }
};