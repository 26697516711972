import React,{useEffect} from 'react';
import { Link,NavLink } from 'react-router-dom';
import Accordion from '../components/Accordion';
import { Helmet } from 'react-helmet';

const Openaccess_author = () => {
    useEffect(() => {
        window.scrollTo(0,0);
      }, [])
    const accordionData = [{
        content: <div>
            <NavLink  to="/for-authors/publication-ethics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics </NavLink>
            <NavLink  to="/for-authors/author-guideline" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Step by Step Guidelines for Authors</NavLink>
            <NavLink  to="/for-authors/submitting-a-manuscript" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Submitting a Manuscript</NavLink>
            <NavLink  to="/for-authors/open-access-author" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is Open Access ?</NavLink>
            <NavLink  to="/for-authors/review-process" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Review Process</NavLink>
            <NavLink  to="/for-authors/conflicts-of-interest" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Conflicts of Interest</NavLink>
            <NavLink  to="/for-authors/licensing-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Licensing Policy</NavLink>
            <NavLink  to="/for-authors/copyright-infringement" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Copyright Infringement</NavLink>
            <NavLink  to="/for-authors/correction-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Correction Policy</NavLink>
        <NavLink  to="/for-authors/what-is-apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is APC ?</NavLink>
            {/* <HashLink smooth to="#" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>How to subscribe the hard copy of journal ?</HashLink> */}    
    </div> 
    },
      ];
  return (
    <>
    <Helmet>
    <title>Open Access: DS Journals Author Benefits</title>
    <link rel="canonical" href="https://dsjournals.com/for-authors/open-access-author"/>
    <meta name="title" content="Open Access: DS Journals Author Benefits"/>
    <meta name="description" content="As an open access publisher, DS Journals offers authors increased visibility and readership for their work. Learn about the benefits of open access."/>
    <meta name="keywords" content="Open access publishing, author guidelines, research manuscript, scholarly publishing, open access policies, scientific publishing, ds Journals."/>
    <meta property="og:type" content="Website"/>
    <meta property="og:site_name" content="Dream Science"/>
    <meta property="og:url" content="https://dsjournals.com/for-authors/open-access-author"/>
    <meta property="og:title" content="Open Access: DS Journals Author Benefits"/>
    <meta property="og:description" content="As an open access publisher, DS Journals offers authors increased visibility and readership for their work. Learn about the benefits of open access."/>
    <meta property="og:image" content="image url"/>
    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:description" content="As an open access publisher, DS Journals offers authors increased visibility and readership for their work. Learn about the benefits of open access."/>
    <meta name="twitter:site" content="@DreamScience4"/>
    <meta name="twitter:title" content="Open Access: DS Journals Author Benefits"/>
    <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
    <meta name="twitter:image" content="image url"/>
    <meta name="robots" content="index, follow"/>

    </Helmet>
    <section id="hero-no-slider" style={{height:"300px"}} className="d-flex justify-cntent-center align-items-center">
     <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
       <div className="row justify-content-center">
         <div className="col-xl-8">
           <div className="row justify-content-center">
             <div className="col-xl-8">
               <h2>What is OpenAccess</h2>
               {/* <Link to="#" className="btn-get-started ">Read More</Link> */}
             </div>
         </div>
       </div>
     </div>
     </div>
   </section>

<section className="breadcrumbs">
<div className="container">
<div className="d-flex justify-content-between align-items-center">
{/* <h2>Publication Ethics</h2> */}
<ol>
 <li><Link to="/home">Home</Link></li>
 <li><Link to="/for-authors">Authors</Link></li>
 <li>What is OpenAccess</li>
</ol>
</div>
</div>
</section>

<main id="main">
<section className="blog" id="blog">
<div className="container">
   <div className="row">
     <div className="col-lg-3">
     <div className="list-group">
      <div className="accordion">
                      {accordionData.map(({content }) => (
                        <Accordion content={content} />
                    ))}
                  </div>
      </div>
     </div>
       <div className="col-lg-9">
      
       <h1 style={{fontSize:"35px"}}>Open Access</h1>
         <p style={{textAlign:"justify"}}>According to important definitions of open access found in the academic literature (namely the Budapest, Berlin, and Bethesda declarations), the following criteria are used by Dream Science to define open access:</p>
         <p style={{textAlign:"justify"}}>There are no subscription requirements or price restrictions for peer-reviewed publications. Instantaneous publication of literature in open access format (no embargo period). If the original publication is correctly cited, published material may be reused without requesting permission.</p>
         <p style={{textAlign:"justify"}}>"Reproduction for nonprofit purposes is allowed." Dream Science International Journals began employing the most recent CC BY-NC-ND 4.0 licence, which gives writers the broadest rights, as of 2014 when they first began publishing articles under the Creative Commons Attribution License external link.</p>
         <p style={{textAlign:"justify"}}>This entails that all articles published in Dream Science International Journals, including data, graphics, and supplements, may be freely linked from outside sources, scanned by search engines, or reused by text mining programmes or websites, blogs, etc., subject only to the requirement that the source and original publisher be properly acknowledged. According to Dream Science, open access publication encourages the sharing of research findings among scientists from other fields, hence encouraging interdisciplinary research. Additionally, open access publication makes study findings available to all academics, especially those in underdeveloped nations and the general public. Despite the fact that Dream Science publishes all of its journals using the open-access model, we think that open access is a valuable component of the academic communication process that can and should coexist alongside other models.</p>
         <p style={{textAlign:"justify"}}><span style={{fontWeight:"bold"}}>Important Note:</span> Some articles, especially reviews, may use text, figures, or tables that were previously published elsewhere for which Dream Science does not possess the copyright or authority to re-license the work. Please take notice that the original author should be consulted before the new author decides whether to utilise this information.</p>
         <p style={{textAlign:"justify"}}>Benefits of Open Access for Writers : The free and unfettered online access to the magazine ensures the High Availability and Visibility of our open access papers. Readers of open access journals, or other researchers, do not have to pay any subscription fees or pay-per-view charges in order to read articles published by Dream Science International Journals. Everyone has free access to and download of the full text of every article published with Dream Science International Journals. Additionally, search engines and indexing databases are more likely to incorporate open access journals.</p>
         <p style={{textAlign:"justify"}}>The greater visibility and accessibility of open access papers contributes to their higher citation impact. There is evidence that open access articles receive higher citations.</p>
         <p style={{textAlign:"justify"}}>Lower Publishing Charges: Open access publishers charge authors' institutions or research funding organisations to pay their costs for editorial handling and editing a manuscript.</p>
         <p style={{textAlign:"justify"}}>By only being available online, Dream Science's open access journals can publish articles more quickly.</p>
       </div>

   </div>
</div>
</section>
</main>

   </>
  )
}

export default Openaccess_author