import React,{useEffect} from 'react';
import { Link,NavLink } from 'react-router-dom';
import Llistaccordion from '../components/Llistaccordion';
import './Forauthors.css';
import { Helmet } from 'react-helmet';
import {HashLink} from 'react-router-hash-link';
import Accordion from '../components/Accordion';



const Forauthors = () => {

  const accordionData = [{
    content: <div>
        <NavLink  to="/for-authors/publication-ethics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics </NavLink>
        <NavLink  to="/for-authors/author-guideline" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Step by Step Guidelines for Authors</NavLink>
        <NavLink  to="/for-authors/submitting-a-manuscript" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Submitting a Manuscript</NavLink>
        <NavLink  to="/for-authors/open-access-author" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is Open Access ?</NavLink>
        <NavLink  to="/for-authors/review-process" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Review Process</NavLink>
        <NavLink  to="/for-authors/conflicts-of-interest" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Conflicts of Interest</NavLink>
        <NavLink  to="/for-authors/licensing-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Licensing Policy</NavLink>
        <NavLink  to="/for-authors/copyright-infringement" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Copyright Infringement</NavLink>
        <NavLink  to="/for-authors/correction-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Correction Policy</NavLink>
        <NavLink  to="/for-authors/what-is-apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is APC ?</NavLink>

       
        
        {/* <HashLink smooth to="#" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>How to subscribe the hard copy of journal ?</HashLink> */}
        
        
</div> 
},
  ];


  const Llistaccordiondata = [
   
    {
      heading: <p  style={{fontWeight:"bold"}}>Article Evaluation</p>, 
      content: <p style={{textAlign:"justify"}}>Peer review is required for all manuscripts, and they must also 
      adhere to high academic standards. Submissions will be evaluated by peer reviewers, whose identities will 
      not be disclosed to the authors, if the editor approves them. On submissions having significant ethical, 
      security, biosecurity, or social ramifications, for example, our Research Integrity team may on occasion 
      seek assistance beyond the realm of traditional peer review. Before deciding on the best course of action, 
      we may consult experts and the academic editor. This may include, but is not limited to, selecting 
      reviewers with particular expertise, having additional editors evaluate the contribution, and declining to 
      take the submission further.</p>,
    },
    {
      heading: <p  style={{fontWeight:"bold"}}>Plagiarism</p>, 
      content: <p style={{textAlign:"justify"}}>All of the publications that Dream Science publishes are 
      dedicated to solely publishing original content, which is content that hasn't been published or isn't 
      currently being reviewed anywhere else. The software is used by Dream Science to identify instances of 
      duplicate and overlapping text in submitted publications. Sanctions for plagiarism will be applied to any 
      manuscripts that are discovered to have been lifted verbatim from another author's work, whether it was 
      published or not.</p>,
    },
    {
      heading: <p  style={{fontWeight:"bold"}}>Repetition of Submission</p>, 
      content: <p style={{textAlign:"justify"}}>Sanctions for duplicate submissions and publications will be 
      applied to manuscripts that are discovered to have been published or are currently being reviewed 
      elsewhere. Authors who have used their own previously published work or work that is presently being 
      reviewed as the foundation for a submitted manuscript must cite the earlier work and explain how their 
      new work goes above and beyond what was previously done.</p>,
    },
    {
      heading: <p  style={{fontWeight:"bold"}}>Manipulation of citations</p>, 
      content: <p style={{textAlign:"justify"}}>Citation manipulation sanctions will be applied to submitted 
      manuscripts that are discovered to contain citations that are primarily intended to boost the number of 
      citations to a certain author's work or to publications published in a specific journal.</p>,
    },
    {
      heading: <p  style={{fontWeight:"bold"}}>Falsification and fabrication of data</p>, 
      content: <p style={{textAlign:"justify"}}>Sanctions for data fabrication and falsification will be applied 
      to submitted articles that contain either manufactured or falsified experimental results, including 
      the modification of pictures.</p>,
    },
    {
      heading: <p  style={{fontWeight:"bold"}}>Incorrect author attribution or contribution</p>, 
      content: <p style={{textAlign:"justify"}}>All mentioned authors must have contributed significantly to the research in the manuscript and given their consent to all of its assertions. It's crucial to acknowledge everyone who contributed significantly to science, including students, researchers, project assistants, and lab technicians.</p>,
    },
    {
      heading: <p  style={{fontWeight:"bold"}}>Duplicate Publications</p>, 
      content: <p style={{textAlign:"justify"}}>In redundant publications, research findings are improperly split up into many articles.</p>,
    },
    {
      heading: <p  style={{fontWeight:"bold"}}>Competing Interests</p>, 
      content: <div><p style={{textAlign:"justify"}}>Conflicts of interest (COIs, commonly referred to as "competing interests") arise when circumstances unrelated to the research could be logically interpreted as influencing the objectivity or impartiality of the work or its evaluation. Whether or not they really had an impact, any conflicts of interest must be disclosed in order to make informed decisions. This declaration won't typically prohibit work from being published or always bar someone from participating in a review process.</p>
      <p style={{textAlign:"justify"}}>Declare a prospective interest if unsure, or speak with the editorial office. Sanctions may result from undeclared interests. Submissions with conflicts that are not stated but are later discovered may be rejected. Published articles could need to be revised, corrected, or in extreme circumstances, withdrawn.</p></div>,
    },
    {
      heading: <p  style={{fontWeight:"bold"}}>Conflicts comprise</p>, 
      content: <div>
        <p style={{textAlign:"justify"}}>Financial: money, other payments, goods, and services received or anticipated by the writers in connection with the work's subject matter or from a company with a stake in its success.</p>
        <p style={{textAlign:"justify"}}>Being hired by, serving on the advisory board for, or belonging to an entity with a stake in the project's result are all examples of affiliations.</p>
        <p style={{textAlign:"justify"}}>Intellectual property includes patents and trademarks that are owned by an individual or business.</p>
        <p style={{textAlign:"justify"}}>Personal - relationships with friends, family, and other intimate friends and family members</p>
        <p style={{textAlign:"justify"}}>Ideology is a set of views or political or religious activism that is pertinent to the work.</p>
        <p style={{textAlign:"justify"}}>Academic rivals or those whose work is criticised.</p>
      </div>,
    },
    {
      heading: <p  style={{fontWeight:"bold"}}>Authors</p>, 
      content: <div><p style={{textAlign:"justify"}}>All potential interests must be disclosed by authors in a section titled "Conflicts of Interest," along with a justification for why the interest might be a conflict. The authors should declare that there are no conflicts of interest with regard to the publication of this paper if there are none. Co-authors' declarations of interests must be made by the authors who are submitting the work.</p>
      <p style={{textAlign:"justify"}}>Authors are required to disclose any current or recent financing, as well as any other payments, products, or services that may have influenced the work (including those for article processing fees). No matter if there is a conflict of interest, all funding must be disclosed in the "Acknowledgments."</p>
      <p style={{textAlign:"justify"}}>Any involvement in the conception, planning, design, conduct, or analysis of the work, the preparation or editing of the manuscript, or the decision to publish of anyone other than the authors who: 1) has an interest in the outcome of the work; 2) is affiliated to an organization with such an interest; or 3) was employed or paid by a funder, must be disclosed.</p>
      <p style={{textAlign:"justify"}}>Editors and reviewers will take into account declared conflicts of interest and include them in the final paper.</p></div>,
    },
    {
      heading: <p  style={{fontWeight:"bold"}}>Reviewers and editors</p>, 
      content: <div>
        <p style={{textAlign:"justify"}}>Editors and reviewers ought to decline to work on a submission if: possess a current submission or recent publication with any author share your affiliation with any author, new or old. collaborate with any author, or have you just lately done so?</p>
        <p style={{textAlign:"justify"}}>Possess a close relationship with any author</p>
        <p style={{textAlign:"justify"}}>Possess a monetary stake in the work's subject</p>
        <p style={{textAlign:"justify"}}>Unable to be impartial</p>
        <p style={{textAlign:"justify"}}>Reviewers must disclose any potential conflicts of interest in the "Confidential" area of the review form, where the editor will take them into account.</p>
        <p style={{textAlign:"justify"}}>If editors or reviewers have spoken with the authors about the article in the past, they must disclose this.</p>
        </div>,
    },
    {
      heading: <p  style={{fontWeight:"bold"}}>Sanctions</p>, 
      content: <div>
        <p style={{textAlign:"justify"}}>Regardless of whether the infractions took place in a journal published by DREAM SCIENCE, the following sanctions will be implemented if any of the aforementioned policies are violated and are documented in a journal:</p>
        <p style={{textAlign:"justify"}}>Rejection of the infringement-related manuscript right away</p>
        <p style={{textAlign:"justify"}}>Immediate rejection of any other manuscripts that any of the authors of the plagiarised paper have sent to any journal that is published by Dream Science.</p>
        <p style={{textAlign:"justify"}}>Prohibition against all authors, individually or together with other authors of the infringing work, or jointly with any other authors, for any further submissions to any journal published by Dream Science. This ban will be in place for at least 36 months.</p>
        <p style={{textAlign:"justify"}}>Prohibition on the participation of all authors in the Any journal's editorial board that Dream Science publishes</p>
        <p style={{textAlign:"justify"}}>The publisher maintains the right to impose further consequences beyond those mentioned above in situations where the violations of the aforementioned rules are thought to be extremely flagrant.</p>
        </div>,
    },
    {
      heading: <p  style={{fontWeight:"bold"}}>Research Recording</p>, 
      content: <p style={{textAlign:"justify"}}>It is crucial that the authors document the findings of their research in a way that allows for analysis and evaluation both prior to publication and for a reasonable amount of time following publication by other researchers. Fabrication is viewed as a form of scientific misconduct, is very unethical, and in some jurisdictions may even be illegal. Examples include reporting results from studies that were never undertaken, deceiving people, or intentionally misleading them.</p>,
    },
    {
      heading: <p  style={{fontWeight:"bold"}}>Publication Techniques</p>, 
      content: <p style={{textAlign:"justify"}}>For each publication, the writers must submit their research papers in the exact format required by the journal. The writers' information should be succinct, accurate, and provide specifics on the research studies they conducted. To support their research, authors should use comparative analyses and contemporary research publications. To support their original piece of study, authors should, however, rework the data in their own words and offer it in a different way. This information should be referenced in the study publications as it was taken from the work of rivals, collaborators, and other academics. In addition, they ought to list works that helped define the parameters of the reported effort.</p>,
    },
    {
      heading: <p  style={{fontWeight:"bold"}}>Authorship Techniques</p>, 
      content: <div>
        <p style={{textAlign:"justify"}}>Authorship credit should be given based on significant contributions to the idea and design, the collection, analysis, and interpretation of data, the writing of the article or its critical revision for significant intellectual content, and the final approval of the published version. A cited author must satisfy each of these requirements. Other contributors to the research, such as those who secured funding for the study, gathered crucial information and materials, or coordinated with the publication, are significant but do not meet the criteria for authorship. The research articles can include references to these people.</p>
      <p style={{textAlign:"justify"}}>It is important to identify the funding source for any research projects or publications. The submitted work's main ideas should not have been published before, and the author should explicitly state that they are not being considered for publication elsewhere. The author should explicitly state whether or not a primary research report has been published as well as any new analyses or data synthesis that have been included in the secondary research report. A 10% overlap between such journals is thought to be acceptable.</p>
      <p style={{textAlign:"justify"}}>Plagiarism is a wholly inappropriate practise in the world of research and is unethical. Before submitting the study paper, authors must certify that they are the copyright owners or that they have obtained the copyright owners' consent. It is unacceptable to violate copyright in any way. In addition to publications, Dream Science also freely publishes Conference Proceedings. These are meant to be tools for the community to share the most recent work-in-progress in their various fields of study. The conference planners must state that the proceedings won't be distributed to or published in any other journal.</p>
      </div>,
    },
    {
      heading: <p  style={{fontWeight:"bold"}}>Responsibilities of Editorial</p>, 
      content: <p style={{textAlign:"justify"}}>The decision to approve or reject a work submitted to a journal rests entirely with the editor, who is unaffected by management or owners in any way. While making a judgement, the editor may consult with the associate editor, co-editors, and peer reviewers. All submissions should be evaluated by the editors based solely on their scientific quality, with minimal consideration of outside circumstances. Regardless of the author's caste, culture, origin, gender, or citizenship, the decision must be prompt and equitable. Editors, writers, and peer reviewers are all required to disclose any conflicts of interest that would make it difficult for them to present or evaluate data in an unbiased manner. Relevant financial, individual, political, intellectual, or religious interests are some examples. Editors and board members should disclose their ties and interests if these are pertinent to the material being considered or published. The editorial team should only give reviewers access to information about a submitted manuscript that is being considered. Conflicts of interest should never arise in situations.</p>,
    },
  ];
    useEffect(()=>{
        window.scrollTo(0,0);
      },[]);

      
  return (
    <>
  <Helmet>
  <title>Submit Your Work: Guidelines and Requirements | DSJournals</title>
<link rel="canonical" href="https://dsjournals.com/for-authors"/>
<meta name="title" content="Submit Your Work: Guidelines and Requirements | DSJournals"/>
<meta name="description" content="Get published with DSJournals! Follow our guidelines and requirements for authors to submit your work and share your research with the world."/>
<meta name="keywords" content="journal author guidelines, author guidelines, submission of articles, submission articles, science advances author guidelines, journal guidelines"/>
<meta property="og:type" content="Website"/>
<meta property="og:site_name" content="Dream Science"/>
<meta property="og:url" content="https://dsjournals.com/for-authors"/>
<meta property="og:title" content="Submit Your Work: Guidelines and Requirements | DSJournals"/>
<meta property="og:description" content="Get published with DSJournals! Follow our guidelines and requirements for authors to submit your work and share your research with the world."/>
<meta property="og:image" content="image url"/>
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="Submit Your Work: Guidelines and Requirements | DSJournals"/>
<meta name="twitter:description" content="Get published with DSJournals! Follow our guidelines and requirements for authors to submit your work and share your research with the world."/>
<meta name="twitter:site" content="@DreamScience4"/>
<meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
<meta name="twitter:image" content="image url"/>
<meta name="robots" content="index, follow"/>


    </Helmet>


    <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
              <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <h2>Authors</h2>
                        {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                      </div>
                  </div>
                </div>
              </div>
              </div>
            </section>

            <section className="breadcrumbs">
    <div className="container">
      <div className="d-flex justify-content-between align-items-center">
        {/* <h2>For Authors</h2> */}
        <ol>
          <li><Link to="/home">Home</Link></li>
          <li>Authors</li>
        </ol>
      </div>
    </div>
  </section>

  
  <main id="main">
    <section className="blog" id="blog">
        <div className="container">
            <div className="row">
            <div className="col-lg-3">
                <div className="list-group">
                <div className="accordion">
                      {accordionData.map(({content }) => (
                        <Accordion content={content} />
                    ))}
                  </div>
                </div>
              </div>

                <div className="col-lg-9 mt-3">
                <h1 style={{fontSize:"35px"}}>Authors</h1>
                 {/* <p style={{textAlign:"justify"}}> Dream Science publishes original research articles, reviews and short communications. 
                   Short communications must address a question of particular interest and reach a definitive 
                   conclusion.</p> */}
                    
      <h4>Publication Ethics</h4>
       <p style={{textAlign:"justify"}}>In order to ensure high-quality scientific publications, public 
      confidence in scientific findings, and that people have been given credit for their contributions, 
      there are ethical standards for publication. Dream Science aspires to follow the COPE's Code of 
      Conduct and Best Practice Guidelines for Publication Ethics.</p>,
  

                   {Llistaccordiondata.map(({heading,content}) => (
          <Llistaccordion heading={heading} content={content} />
         ))}

                   {/* <h6>Manuscript Submission</h6>
                    <p id='manuscript' style={{textAlign:"justify"}}> Submission of a manuscript implies: that the work described has not been published before; 
                   that it is not under consideration for publication anywhere else; that its publication has been 
                   approved by all co-authors, if any, as well as by the responsible authorities – tacitly or 
                   explicitly – at the institute where the work has been carried out. The publisher will not be held 
                   legally responsible should there be any claims for compensation.</p>
                    
                   <ul>
                        <h6>Permissions</h6>
                        <p className="m-3" style={{textAlign:"justify"}}>Authors wishing to include figures, tables, or text passages that have already been published elsewhere 
                        are required to obtain permission from the copyright owner(s) for both the print and online format and 
                        to include evidence that such permission has been granted when submitting their papers. Any material 
                        received without such evidence will be assumed to originate from the authors.</p>
                    
                        <h6>Online Submission</h6>
                        <p className="m-3" style={{textAlign:"justify"}}>Please follow the hyperlink “Submit manuscript” on the right and upload all of your 
                        manuscript files following the instructions given on the screen.</p>
                        
                        <h6>Important notes:</h6>
                        <p className="m-3" style={{textAlign:"justify"}}>In case authors are encouraged to resubmit a revised version of their manuscript, they are required to 
                        mark all changes (e.g. using the track changes functionality of their word processing program or by 
                        colored text) and the revised version must be accompanied by a cover letter including an itemized response 
                        to the reviewer's comments.</p>

                        <p className="m-3" style={{textAlign:"justify"}}>A submission to Dream Science generally implies that materials described in the manuscript (e.g. seeds, 
                        genetic stocks, vectors, antibodies) should be freely available to any researcher wishing to use them for 
                        non-commercial purposes. Authors are expected to comply with requests for materials within 60 days after 
                        receipt of the request. Where appropriate, requestors should be prepared to cover reasonable costs of the 
                        request. Furthermore, availability of the materials may be subject to an institutional Material Transfer 
                        Agreement that limits their use to non-profit research purposes.</p>
                  </ul>
 
                  <h6>Author Contribution Statement</h6>
                    <p id='author_contribution_statement'style={{textAlign:"justify"}}>Authors must provide a short description of the contributions made by each listed author 
                    (please use initials). This will be published in a separate section in front of the 
                    Acknowledgments.</p>
                    <p style={{textAlign:"justify"}}> For example: AM and DB conceived and designed research. AM and BB conducted experiments. 
                        GR contributed new reagents or analytical tools. AM and GR analyzed data. AM wrote the manuscript. 
                        All authors read and approved the manuscript.</p>

                        <h6>Title Page</h6>
                  <p id="title_page" style={{textAlign:"justify"}}>The title page should include:</p>
                  <ul>
                 
                    <li><p className="m-3" style={{textAlign:"justify"}}>The full first name, middle initial, 
                    and last name of the author(s)</p></li>
                    <li>
                    <p className="m-3" style={{textAlign:"justify"}}>A concise and informative title; avoid use of undefined abbreviations 
                      Please limit the title to 180 characters (including spaces).</p>
                    </li>
                    <li><p className="m-3" style={{textAlign:"justify"}}>The affiliation(s) and address(es) of all author(s)</p></li>
                    <li><p className="m-3" style={{textAlign:"justify"}}>The e-mail address, telephone and fax numbers of the corresponding author</p></li>
                    <li>
                    <p className="m-3" style={{textAlign:"justify"}}>It is desired that you use your institutional e-mail address for correspondence.</p>
                    </li>
                  </ul>

                  <h6>Text</h6>
                    <p id="text" style={{textAlign:"justify"}}>
                      Text Formatting
                      Manuscripts should be submitted in Word.</p>
                      <ul>
                        <li>
                        Use a normal, plain font (e.g., 10-point Times Roman) for text.
                        </li>
                        <li>
                        Use italics for emphasis.
                        </li>
                        <li>
                        Use the automatic page numbering function to number the pages.
                        </li>
                        <li>
                        Do not use field functions.
                        </li>
                        <li>
                        Use tab stops or other commands for indents, not the space bar.
                        </li>
                        <li>
                        Use the table function, not spreadsheets, to make tables.
                        </li>
                        <li>
                        Use the equation editor or MathType for equations.
                        </li>
                      </ul>
                      <p style={{textAlign:"justify"}}>Save your file in docx format (Word 2007 or higher) or doc format (older Word versions).
                      Manuscripts with mathematical content can also be submitted in LaTeX.</p>

                      <ul>
                        <h6>Headings</h6>
                        <p style={{textAlign:"justify"}}>Please use no more than three levels of displayed headings.</p>
                        
                        
                        <h6>Abbreviations</h6>
                        <p style={{textAlign:"justify"}}>Abbreviations should be defined at first mention and used consistently thereafter.</p>
                        
                        
                        <p style={{textAlign:"justify"}}>Footnotes can be used to give additional information, which may include the citation of a reference included in the reference list. They should not consist solely of a reference citation, and they should never include the bibliographic details of a reference. They should also not contain any figures or tables.
                        Footnotes to the text are numbered consecutively; those to tables should be indicated by superscript lower-case letters (or asterisks for significance values and other statistical data). Footnotes to the title or the authors of the article are not given reference symbols.</p>
                        <p style={{textAlign:"justify"}}>Always use footnotes instead of endnotes.</p>
                        
                        
                        <h6>Acknowledgments</h6>
                        <p style={{textAlign:"justify"}}>Acknowledgments of people, grants, funds, etc. should be placed in a separate section on the title page. 
                            The names of funding organizations should be written in full. Dream Science’s list of units, symbols and 
                            abbreviations</p>
                        
                    
                      </ul>

                   
                  
                        <p style={{textAlign:"justify"}}>Statistical analysis of the results:</p>
                        <p style={{textAlign:"justify"}}>Authors must indicate, in a subsection at the end of the Materials and Methods section, the 
                        reproducibility, or statistical significance of the results, especially as it pertains to figures 
                        where error bars are not indicated (e.g. images, blots).</p>
                        
                        <h6>References</h6>
                        <ul>
                        <h6>Citation</h6>
                        <p id="references" style={{textAlign:"justify"}}>Cite references in the text by name and year in parentheses. Some examples:</p>
                        <li>
                          Negotiation research spans many disciplines (Thompson 1990).
                          </li>
                          <li>
                          This result was later contradicted by Becker and Seligman (1996).
                          </li>
                          <li>
                          This effect has been widely studied (Abbott 1991; Barakat et al. 1995a, b; Kelso 
                          and Smith 1998; Medvec et al. 1999, 2000).
                          </li>
                          <h6>Reference list</h6>
                        <p style={{textAlign:"justify"}}>The list of references should only include works that are cited in the text and that have been published or accepted for publication. Personal communications and unpublished works should only be mentioned in the text. Do not use footnotes or endnotes as a substitute for a reference list.
                        Reference list entries should be alphabetized by the last names of the first author of each work. Order multi-author publications of the same first author alphabetically with respect to second, third, etc. author. Publications of exactly the same author(s) must be ordered chronologically.</p>
                        <li>Journal article</li>
                        <p style={{textAlign:"justify",overflow:"break-word"}}>Gamelin FX, Baquet G, Berthoin S, Thevenet D, Nourry C, Nottin S, Bosquet L (2009) Effect of high intensity intermittent training on heart rate variability in prepubescent children. Eur J ApplPhysiol 105:731-738. 
                       https://doi.org/10.1007/s00421-008-0955-8
                        Ideally, the names of all authors should be provided, but the usage of “et al” in long author lists will also be accepted: Smith J, Jones M Jr, Houghton L et al (1999) Future of health insurance. N Engl J Med 965:325–329.</p>
                        <li>Article by DOI</li>
                        <p style={{textAlign:"justify"}}> Slifka MK, Whitton JL (2000) Clinical implications of dysregulated cytokine production. J Mol Med. https://doi.org/10.1007/s001090000086</p>
                        <li>Book</li>
                        <p style={{textAlign:"justify"}}>South J, Blass B (2001) The future of modern genomics. Blackwell, London</p>
                        <li>Book chapter</li>
                        <p style={{textAlign:"justify"}}>Brown B, Aaron M (2001) The politics of nature. In: Smith J (ed) The rise of modern genomics, 3rd edn. Wiley, New York, pp 230-257.</p>
                        <li>Online document</li>
                        <p style={{textAlign:"justify"}}>Cartwright J (2007) Big stars have weather too. IOP Publishing PhysicsWeb. 
                        http://physicsweb.org/articles/11/6/16/1. 
                        Accessed 26 June 2007.</p>
                        
                        </ul>

                          
                    <h6 id="#">Tables</h6>
                    <ul id="tables">
                    <li>
                      All tables are to be numbered using Arabic numerals.</li>

                      <li>Tables should always be cited in text in consecutive numerical order.</li>

                      <li> For each table, please supply a table caption (title) explaining the components of the table.</li>

                      <li>Identify any previously published material by giving the original source in the form of a reference at the end of the table caption.</li>

                      <li>Footnotes to tables should be indicated by superscript lower-case letters (or asterisks for significance values and other statistical data) and included beneath the table body.</li>

                      </ul>
                   

                      
                    <h6>Artwork</h6>
                    <p id="artwork" style={{textAlign:"justify"}}>
                        For the best quality final product, it is highly recommended that you submit all of 
                        your artwork – photographs, line drawings, etc. – in an electronic format. Your art
                         will then be produced to the highest standards with the greatest accuracy to detail. 
                         The published work will directly reflect the quality of the artwork provided.</p>
                       <ul>
                        <li><h6>Electronic Figure Submission</h6></li>
                        Supply all figures electronically.
                        <ul>
                          <li>Indicate what graphics program was used to create the artwork.</li>

                          <li>For vector graphics, the preferred format is EPS; for halftones, please use TIFF 
                          format. MS Office files are also acceptable.</li>

                          <li> Vector graphics containing fonts must have the fonts embedded in the files.</li>

                          <li>Name your figure files with "Fig" and the figure number, e.g., Fig1.eps.</li>
                        </ul>
                        </ul>
                        
                        <ul>
                            <li><h6>Line Art</h6> </li>
                            <ul>
                              <li>Definition: Black and white graphic with no shading.</li>

                              <li>Do not use faint lines and/or lettering and check that all lines and lettering 
                                within the figures are legible at final size.</li>

                              <li>All lines should be at least 0.1 mm (0.3 pt) wide.</li>

                              <li>Scanned line drawings and line drawings in bitmap format should have a minimum 
                                resolution of 1200 dpi.</li>

                              <li> Vector graphics containing fonts must have the fonts embedded in the files.</li>
                        </ul>
                       </ul>
                       
                       <ul>
                        <li><h6>Halftone Art</h6></li>
                          <ul>
                            <li>Definition: Photographs, drawings, or paintings with fine shading, etc.</li>
                            <li>If any magnification is used in the photographs, indicate this by using scale bars within the figures themselves.</li>
                            <li>Halftones should have a minimum resolution of 300 dpi.</li>
                          </ul>
                       </ul>
                       
                        <ul><li><h6>    Combination Art</h6></li>
                        <ul>
                        <li>Definition: a combination of halftone and line art, 
                          e.g., halftones containing line drawing, extensive lettering, color diagrams, etc.</li>
                        <li>Combination artwork should have a minimum resolution of 600 dpi.</li>
                        </ul>
                        </ul>
                        
                        <ul><li><h6>     Color Art</h6></li>
                        <ul>
                        <li>Color art is free of charge for print and online publication.</li>

                        <li>Color illustrations should be submitted as RGB.</li>
                        </ul>
                        </ul>
                        <ul><li><h6>    Figure Lettering</h6></li>
                        <ul>
                        <li>To add lettering, it is best to use Helvetica or Arial (sans serif fonts).</li>

                        <li>Keep lettering consistently sized throughout your final-sized artwork, 
                          usually about 2–3 mm (8–12 pt).</li>

                        <li>Variance of type size within an illustration should be minimal, 
                          e.g., do not use 8-pt type on an axis and 20-pt type for the axis label.</li>

                        <li>Avoid effects such as shading, outline letters, etc.</li>

                        <li>Do not include titles or captions within your illustrations.</li>
                        </ul>
                        </ul>
                        <ul><li><h6>Figure Numbering</h6></li>
                        <ul>
                        <li>All figures are to be numbered using Arabic numerals.</li>

                        <li>Figures should always be cited in text in consecutive numerical order.</li>

                        <li>Figure parts should be denoted by lowercase letters (a, b, c, etc.).</li>

                        <li>If an appendix appears in your article and it contains one or more figures, continue the consecutive 
                        numbering of the main text. Do not number the appendix figures, "A1, A2, A3, etc." Figures in online 
                        appendices (Electronic Supplementary Material) should, however, be numbered separately.</li>
                        </ul></ul>
                        <ul><li><h6>    Figure Captions</h6></li>
                        <ul>
                        <li>Each figure should have a concise caption describing accurately what the figure depicts. 
                          Include the captions in the text file of the manuscript, not in the figure file.</li>

                        <li>Figure captions begin with the term Fig. in bold type, followed by the figure number, also in bold type.</li>

                        <li>No punctuation is to be included after the number, nor is any punctuation to be 
                          placed at the end of the caption.</li>

                        <li>Identify all elements found in the figure in the figure caption; and use boxes,
                           circles, etc., as coordinate points in graphs.</li>

                        <li>Identify previously published material by giving the original source in the form 
                          of a reference citation at the end of the figure caption.</li>
                        </ul>
                        </ul>
                        
                        <ul><li><h6>Figure Placement and Size</h6></li>
                        <ul>
                        <li>When preparing your figures, size figures to fit in the column width.</li>

                       <li> For most journals the figures should be 39 mm, 84 mm, 129 mm, or 174 mm wide and not higher than 234 mm.</li>

                        <li>For books and book-sized journals, the figures should be 80 mm or 122 mm wide and not higher than 198 mm.</li>
                        </ul></ul>
                       
                        <ul><li><h6>Permissions</h6></li>
                        <p style={{textAlign:"justify"}}>If you include figures that have already been published elsewhere, you must obtain 
                          permission from the copyright owner(s) for both the print and online format. Please 
                          be aware that some publishers do not grant electronic rights for free and that Dream Science will not be able to refund any costs that may have occurred to receive these permissions. In such cases, material from other sources should be used. Accessibility
                        In order to give people of all abilities and disabilities access to the content of 
                        your figures, please make sure that</p>
                        <ul>
                        <li>All figures have descriptive captions (blind users could then use a text-to-speech software or a text-to-Braille hardware)</li>

                        <li>Patterns are used instead of or in addition to colors for conveying information (color-blind users would then be able to distinguish the visual elements)</li>

                        <li>Any figure lettering has a contrast ratio of at least 4.5:1</li>
                    
                        </ul></ul>
                        
                        
                        
                        <h6>Electronic Supplementary Material</h6>
                        <p id="electronic_supplementary_material" style={{textAlign:"justify"}}>
                          Dream Science accepts electronic multimedia files (animations, 
                          movies, audio, etc.) and other supplementary files to be published
                          online along with an article or a book chapter. This feature can 
                          add dimension to the author's article, as certain information 
                          cannot be printed or is more convenient in electronic form.
                          Before submitting research datasets as electronic supplementary 
                          material. We encourage research data to be archived in data 
                          repositories wherever possible.</p>
                          <ul>
                          <li><h6>Submission</h6></li>
                          <p style={{textAlign:"justify"}}>Supply all supplementary material in standard file formats.

                          Please include in each file the following information: article title, journal name,
                           author names; affiliation and e-mail address of the corresponding author.

                          To accommodate user downloads, please keep in mind that larger-sized files may 
                          require very long download times and that some users may experience other problems 
                          during downloading.</p>
                          <li><h6>Audio, Video, and Animations</h6></li><ul>
                            <li>
                            Aspect ratio: 16:9 or 4:3
                            </li>
                            <li>
                            Maximum file size: 25 GB
                            </li>
                            <li>
                            Minimum video duration: 1 sec
                            </li>
                            <li>
                            Supported file formats: avi, wmv, mp4, mov, m2p, mp2, mpg, mpeg, flv, mxf, mts, m4v, 3gp  
                            </li>
                          </ul>
                          
                          <li><h6>Text and Presentations</h6></li>
                          <ul>
                            <li>
                            Submit your material in PDF format; .doc or .ppt files are not suitable for long-term viability.
                            </li>
                            <li>
                            A collection of figures may also be combined in a PDF file.
                              </li></ul>
                          
                          <li><h6>Spreadsheets</h6></li>
                          <ul>
                            <li>
                            Spreadsheets should be submitted as .csv or .xlsx files (MS Excel).
                            </li>
                          </ul>
                          
                          <li><h6>Specialized Formats</h6></li>
                          <ul>
                            <li>
                            Specialized format such as .pdb (chemical), .wrl (VRML), .nb (Mathematica notebook), 
                            and .tex can also be supplied.
                            </li>
                          </ul>
                          
                          <li><h6>Collecting Multiple Files</h6></li>
                          <ul><li>
                          It is possible to collect multiple files in a .zip or .gz file.
                            </li></ul>
                          
                          <li><h6>Numbering</h6></li>
                          <ul>
                         <li>If supplying any supplementary material, the text must make specific mention of 
                           the material as a citation, similar to that of figures and tables.</li>
                          <li>Refer to the supplementary files as “Online Resource”, e.g., "... as shown in 
                            the animation (Online Resource 3)", “... additional data are given in Online 
                            Resource 4”.</li>
                          <li>Name the files consecutively, e.g. “ESM_3.mpg”, “ESM_4.pdf”.</li></ul>
                          <li><h6>Captions</h6></li>
                          <ul><li>For each supplementary material, please supply a concise caption describing 
                            the content of the file.</li></ul>
                          <li><h6>Processing of supplementary files</h6></li>
                          <ul><li>Electronic supplementary material will be published as received from the 
                            author without any conversion, editing, or reformatting.</li></ul>
                          <li><h6>Accessibility</h6></li>
                          <ul>
                          <li>In order to give people of all abilities and disabilities access to the content 
                            of your supplementary files, please make sure that
                          The manuscript contains a descriptive caption for each supplementary material</li>

                          <li>Video files do not contain anything that flashes more than three times per second 
                            (so that users prone to seizures caused by such effects are not put at risk)</li>
                            </ul>
                            </ul>

                    
                  

                    
                    <h6>Ethical Reponsibility of Authors</h6>
                    <p id='ethical_responsibilities_of_authors' style={{textAlign:"justify"}}>
                    This journal is committed to upholding the integrity of the scientific record. As a member 
                    of the Committee on Publication Ethics (COPE) the journal will follow the COPE guidelines on how 
                    to deal with potential acts of misconduct.
                    Authors should refrain from misrepresenting research results which could damage the trust in the 
                    journal, the professionalism of scientific authorship, and ultimately the entire scientific endeavour. 
                    Maintaining integrity of the research and its presentation can be achieved by following the rules of 
                    good scientific practice, which include:</p>
                    <p style={{textAlign:"justify"}}>The manuscript has not been submitted to more than one journal for simultaneous consideration.</p>
                    <ul>
                      <li>
                      The manuscript has not been published previously (partly or in full), unless the new work 
                      concerns an expansion of previous work (please provide transparency on the re-use of material 
                      to avoid the hint of text-recycling (“self-plagiarism”)).
                      </li>
                      <li>
                      A single study is not split up into several parts to increase the quantity of submissions and 
                      submitted to various journals or to one journal over time (e.g. “salami-publishing”).
                      </li>
                      <li>
                      No data have been fabricated or manipulated (including images) to support your conclusions
                      </li>
                      <li>No data, text, or theories by others are presented as if they were the author’s own 
                        (“plagiarism”). Proper acknowledgements to other works must be given (this includes 
                        material that is closely copied (near verbatim), summarized and/or paraphrased), 
                        quotation marks are used for verbatim copying of material, and permissions are secured 
                        for material that is copyrighted.</li>
                        <p style={{textAlign:"justify"}}>Important note: the journal may use software to screen for plagiarism.</p>
                        <li>
                        Consent to submit has been received explicitly from all co-authors, as well as from 
                        the responsible authorities - tacitly or explicitly - at the institute/organization 
                        where the work has been carried out, before the work is submitted.
                        </li>
                        <li>
                        Authors whose names appear on the submission have contributed sufficiently to the 
                        scientific work and therefore share collective responsibility and accountability for 
                        the results.
                        </li>
                        <li>
                        Authors are strongly advised to ensure the correct author group, corresponding author,
                         and order of authors at submission. Changes of authorship or in the order of authors 
                         are not accepted after acceptance of a manuscript.
                        </li>
                        <li>
                        Adding and/or deleting authors and/or changing the order of authors at revision stage 
                        may be justifiably warranted. A letter must accompany the revised manuscript to explain 
                        the reason for the change(s) and the contribution role(s) of the added and/or deleted author(s). Further documentation may be required to support your request.
                        </li>
                        <li>
                        Requests for addition or removal of authors as a result of authorship disputes after acceptance
                         are honored after formal notification by the institute or independent body and/or when there 
                         is agreement between all authors.
                        </li>
                        <li>
                        Upon request authors should be prepared to send relevant documentation or data in 
                        order to verify the validity of the results. This could be in the form of raw data, 
                        samples, records, etc. Sensitive information in the form of confidential proprietary 
                        data is excluded.
                        </li>
                        <p style={{textAlign:"justify"}}>If there is a suspicion of misconduct, the journal will carry out an investigation 
                          following the COPE guidelines. If, after investigation, the allegation seems to 
                          raise valid concerns, the accused author will be contacted and given an opportunity 
                          to address the issue. If misconduct has been established beyond reasonable doubt, 
                          this may result in the Editor-in-Chief’s implementation of the following measures, 
                          including, but not limited to:</p>
                          <li>If the article is still under consideration, it may be rejected and returned to the
                             author.</li>
                          <li>
                          If the article has already been published online, depending on the nature and severity 
                          of the infraction, either an erratum will be placed with the article or in severe cases 
                          complete retraction of the article will occur. The reason must be given in the published 
                          erratum or retraction note. Please note that retraction means that the paper is maintained 
                          on the platform, watermarked "retracted" and explanation for the retraction is provided in 
                          a note linked to the watermarked article.
                          </li>
                          <li>
                          The author’s institution may be informed.
                          </li>
                      </ul>
                      
                    <h6>Compliance with ethical standards</h6>
                    <p id='compliance_with_ethical_standards' style={{textAlign:"justify"}}>
                      To ensure objectivity and transparency in research and to ensure that accepted 
                        principles of ethical and professional conduct have been followed, authors should 
                        include information regarding sources of funding, potential conflicts of interest 
                        (financial or non-financial), informed consent if the research involved human 
                        participants, and a statement on welfare of animals if the research involved animals.</p>
                      <p style={{textAlign:"justify"}}>Authors should include the following statements (if applicable) in a separate section 
                        entitled “Compliance with Ethical Standards” when submitting a paper:</p>
                        <ul>
                          <li>Disclosure of potential conflicts of interest</li>
                          <li>Research involving Human Participants and/or Animals</li>
                          <li>Informed consent</li>
                        </ul>
                      
                      
                      
                      <p style={{textAlign:"justify"}}>Please note that standards could vary slightly per journal dependent on their peer review policies (i.e. single or double blind peer review) as well as per journal subject discipline. Before submitting your article check the instructions following this section carefully.
                      The corresponding author should be prepared to collect documentation of compliance with ethical standards and send if requested during peer review or after publication.
                      The Editors reserve the right to reject manuscripts that do not comply with the 
                      above-mentioned guidelines. The author will be held responsible for false statements 
                      or failure to fulfill the above-mentioned guidelines.</p>   

                      
                    <h6> Disclosure of potential conflicts of interest</h6>
                    <p style={{textAlign:"justify"}}>
                    <p id="disclosure_of_potential_conflicts_of_interest" style={{textAlign:"justify"}}>Authors must disclose all relationships or interests that could have direct or 
                      potential influence or impart bias on the work. Although an author may not feel there 
                      is any conflict, disclosure of relationships and interests provides a more complete and 
                      transparent process, leading to an accurate and objective assessment of the work. 
                      Awareness of a real or perceived conflicts of interest is a perspective to which the 
                      readers are entitled. This is not meant to imply that a financial relationship with an 
                      organization that sponsored the research or compensation received for consultancy work 
                      is inappropriate. Examples of potential conflicts of interests that are directly or 
                      indirectly related to the research may include but are not limited to the following:</p>
                      <ul>
                        <li>
                        Research grants from funding agencies (please give the research funder and the grant number)
                        </li>
                        <li>
                        Honoraria for speaking at symposia
                        </li>
                        <li>
                        Financial support for attending symposia
                        </li>
                        <li>
                        Financial support for educational programs
                        </li>       
                          <li>Employment or consultation</li>

                          <li>Support from a project sponsor</li>

                          <li>Position on advisory board or board of directors or other type of management relationships</li>

                          <li>Multiple affiliations</li>

                          <li>Financial relationships, for example equity ownership or investment interest</li>

                          <li>Intellectual property rights (e.g. patents, copyrights and royalties from such rights)</li>

                          <li>Holdings of spouse and/or children that may have financial interest in the work</li>
                    </ul>
                      <p style={{textAlign:"justify"}}>In addition, interests that go beyond financial interests and compensation (non-financial interests) that 
                        may be important to readers should be disclosed. These may include but are not limited to personal 
                        relationships or competing interests directly or indirectly tied to this research, or professional i
                        nterests or personal beliefs that may influence your research.</p>
                      <p style={{textAlign:"justify"}}>The corresponding author collects the conflict of interest disclosure forms from all authors. 
                        In author collaborations where formal agreements for representation allow it, it is sufficient for 
                        the corresponding author to sign the disclosure form on behalf of all authors.</p>
                      <p style={{textAlign:"justify"}}>The corresponding author will include a summary statement in the text of the manuscript in a separate 
                        section before the reference list, that reflects what is recorded in the potential conflict of interest 
                        disclosure form(s).</p>
                      <p style={{textAlign:"justify"}}>See below examples of disclosures:
                      Funding: This study was funded by X (grant number X).
                      Conflict of Interest: Author A has received research grants from Company A. Author B has received a speaker 
                      honorarium from Company X and owns stock in Company Y. Author C is a member of committee Z.</p>
                      <p style={{textAlign:"justify"}}>If no conflict exists, the authors should state:
                      Conflict of Interest: The authors declare that they have no conflict of interest.</p>

                   
                    <h6 id="#">Research data policy</h6>
                    <p id="research_data_policy" style={{textAlign:"justify"}}>
                    A submission to the journal implies that materials described in the manuscript, including 
                    all relevant raw data, will be freely available to any researcher wishing to use them for 
                    non-commercial purposes, without breaching participant confidentiality.</p>
                    <p style={{textAlign:"justify"}}>The journal strongly encourages that all datasets on which the conclusions of the paper 
                    rely should be available to readers. We encourage authors to ensure that their datasets 
                    are either deposited in publicly available repositories (where available and appropriate)
                     or presented in the main manuscript or additional supporting files whenever possible 
                     General repositories - for all types of research data - such as figshare and Dryad may 
                     be used where appropriate.</p>
                    <p style={{textAlign:"justify"}}>Datasets that are assigned digital object identifiers (DOIs) by a data repository may 
                      be cited in the reference list. Data citations should include the minimum information 
                      recommended by DataCite: authors, title, publisher (repository name), identifier.</p>
                      <ul>
                    </ul>
                    </p>


                    
                    <h6>After acceptance</h6>
                    <p id="after_acceptance" style={{textAlign:"justify"}}>
                    Upon acceptance of your article you will receive a link through mail where you can sign 
                    the Copyright Transfer Statement and send it back to editor.</p>
                      <p style={{textAlign:"justify"}}>Once this procedure has been 
                      completed, your article will be processed and you will receive 
                      the proofs.</p>
                      <ul>
                      <h6>Copyright transfer</h6>
                      <p style={{textAlign:"justify"}}>Authors will be asked to transfer
                       copyright of the article to the Publisher (or grant the Publisher 
                       exclusive publication and dissemination rights). This will ensure 
                       the widest possible protection and dissemination of information 
                       under copyright laws.</p>
                      <h6>Offprints</h6>
                      <p style={{textAlign:"justify"}}>Offprints can be ordered by the 
                      corresponding author.
                      <h6>Color illustrations</h6>
                      <p style={{textAlign:"justify"}}>Publication of color illustrations 
                      is free of charge.</p>
                      <h6>Proof reading</h6>
                      <p style={{textAlign:"justify"}}>The purpose of the proof is to check 
                      for typesetting or conversion errors and the completeness and accuracy 
                      of the text, tables and figures. Substantial changes in content, e.g.,
                       new results, corrected values, title and authorship, are not allowed
                        without the approval of the Editor.</p>
                      After online publication, further changes can only be made in the form of
                       an Erratum, which will be hyperlinked to the article.</p>
                      <h6>Online First</h6>
                      <p style={{textAlign:"justify"}}>The article will be published online 
                      after receipt of the corrected proofs. This is the official first 
                      publication citable with the DOI. After release of the printed version, 
                      the paper can also be cited by issue and page numbers.</p>
                      <h6>Special Issues</h6>
                      <p style={{textAlign:"justify"}}>Special Issues allows you to publish
                       open access in Dream Science journals, making your research more visible
                        and accessible immediately on publication.</p>
                     <h6>Copyright and license term CC BY-NC-ND 4.0 </h6>
                     <img src='..\assets\img\88x31.png' value='limg' />
                  <p  className="mt-2" style={{textAlign:'justify'}}>Dream Science site and its metadata are licensed under CC-BY-NC-ND</p>

                  <p>Attribution-NonCommercial CC BY-NC-ND 4.0</p>
                  <p style={{textAlign:'justify'}}>Copyright on any open access article published in Dream Science International is retained by the author(s). Authors grant Dream Science a license to publish the article and identify itself as the original publisher. Authors also grant any third party the right to use the article freely as long as its integrity is maintained and its original authors, citation details and publisher are identified.</p>
                      </ul>
                     */}
                   
                    
                </div>
            </div>
        </div>
    </section>
  </main>
    </>
  )
}

export default Forauthors;