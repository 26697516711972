import React from 'react';
import { Card, CardActions, CardMedia, Button, Typography } from '@material-ui/core/';

import DeleteIcon from '@material-ui/icons/Delete';
//import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useDispatch } from 'react-redux';

import { deletePost } from '../../../actions/posts';
import useStyles from './styles';

const Post = ({ post, setCurrentId }) => {
  const dispatch = useDispatch();
    const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardMedia className={classes.media} image={post.selectedFile || 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'} title={post.title} />
      <Typography className={classes.title} gutterBottom variant="h6" component="h2">{post.title}</Typography>
      <CardActions className={classes.cardActions}>
        <Button style={{ color: 'blue' }} size="small" onClick={() => setCurrentId(post._id)}>Edit</Button>
        <Button size="small" color="primary" onClick={() => dispatch(deletePost(post._id))}><DeleteIcon fontSize="small" /> Delete</Button>
      </CardActions>
    </Card>
    
  );
};

export default Post;
