import React,{useState,useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Link,NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dsair/Accordion';
import Posts from '../../components/Dsair/Cissuelist/Posts/Posts';
import { getjournalPosts } from '../../actions/journal/dsair/posts';
import { getcissuePost } from '../../actions/currentissue/posts';
import Ddsairarticletemplate from '../../downloads/DS-AIR-Article_Template.doc';
import Ddsaircopyrightform from '../../downloads/DS-AIR-Copyright_Form.docx';

const Currentissue = () => {
  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();
  const [refresh,setReferesh] = useState(false);

  
  const jouname = "AIR";
  const [postData, setPostData] = useState({ journal_title: '', volume: '', issue: '' });
  const cpost = useSelector((state) => (jouname ? state.cissueposts.posts.find((jname)=>  jname.journal_title === jouname) : null));

  useEffect(()=>{setReferesh(true)},[refresh]);

  useEffect(() => {
    dispatch(getjournalPosts());    
  }, [currentId, dispatch]);

  useEffect(() => {
    if (cpost) setPostData(cpost);
    console.log("currentissue",postData);
  }, [cpost,postData]);

  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  const accordionData = [{
    content: <div>
     <HashLink smooth to="/air" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Aim and Scope</HashLink>
            <NavLink to="/air/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
            <NavLink to="/air/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/air/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/air/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/air/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/air/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
            <a href={Ddsairarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Ddsaircopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
</div> 
},
  ];
  return (
    <>
    <Helmet>
        <title>Current Issue - DS Journal of Artificial Intelligence and Robotics</title>
        <link rel="canonical" href="https://dsjournals.com/air/current-issue"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="title" content="Current Issue - DS Journal of Artificial Intelligence and Robotics"/>
        <meta name="description" content="Stay informed with the latest research and discoveries across multiple disciplines. Check out the current issue of DS-AIR Journal today."/>
        <meta name="keywords" content="Current Issue in dsair journals, recent research paper in Artificial Intelligence and Robotics journals,artificial intelligence journal, artificial intelligence review, applied artificial intelligence, journal of artificial intelligence research, international journal of artificial intelligence, international journal on artificial intelligence tools, journal of experimental & theoretical artificial intelligence, journal of artificial intelligence and soft computing research, the ai journal, applied artificial intelligence journal, journal of artificial intelligence research impact factor."/>
        <meta property="og:type" content="Website"/>
        <meta property="og:site_name" content="Dream Science"/>
        <meta property="og:url" content="https://dsjournals.com/air/current-issue"/>
        <meta property="og:title" content="Current Issue - DS Journal of Artificial Intelligence and Robotics"/>
        <meta property="og:description" content="Stay informed with the latest research and discoveries across multiple disciplines. Check out the current issue of DS-AIR Journal today."/>
        <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Artificial-Intelligence-and-Robotics.webp"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="Current Issue - DS Journal of Artificial Intelligence and Robotics"/>
        <meta name="twitter:description" content="Stay informed with the latest research and discoveries across multiple disciplines. Check out the current issue of DS-AIR Journal today."/>
        <meta name="twitter:site" content="@DreamScience4"/>
        <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
        <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Artificial-Intelligence-and-Robotics.webp"/>
        <meta name="robots" content="index, follow"/>
    </Helmet>
     <>
     <section id="hero-no-slide-dsair" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
   <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
     <div className="row justify-content-center">
       <div className="col-xl-8">
         <div className="row justify-content-center">
           {/* <div className="col-xl-8">
           <h2>Current Issue</h2>
           
           </div> */}
            <div className="col-lg-12">
                                    <h2 style={{ fontSize: "25px" }}>DS Journal of Artificial Intelligence and Robotics ( DS-AIR )</h2>
                                    {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                                </div>
       </div>
     </div>
   </div>
   </div>
 </section>
 
 <section className="breadcrumbs">
   <div className="container">
     <div className="d-flex justify-content-between align-items-center">
       {/* <h2>Current Issues</h2> */}
       <ol>
         <li><Link to="/home">Home</Link></li>
         <li><Link to="/journals">Journals</Link></li>
        <li><Link to="/air">DS-AIR</Link></li>
         <li>Current Issue</li>
       </ol>
     </div>
   </div>
 </section>

      <main id="main">
<section class="blog" id="blog">
 <div class="container">
     <div class="row">
         <div class="col-lg-3">
         <div className="accordion">
                        {accordionData.map(({content }) => (
                          <Accordion content={content} />
                      ))}
        </div> 
         </div>
         <div class="col-lg-9">
               <div className="row mt-3">
                 <div className="col-lg-12 text-center">
                 {/* <h5 style={{alignItems:'center'}}>Volume {postData.volume} Issue {postData.issue}  </h5> */}
                 {/* <h5 style={{alignItems:'center'}}>Volume {1} Issue {2}  </h5> */}
                 <h5 style={{alignItems:'center'}}>Volume {postData.volume} Issue {postData.issue}  </h5>
                 </div>
             
             </div>
             <div className="row">
              <div className='col-lg-12 mt-3'>
              <Posts setCurrentId={setCurrentId} />  
              </div>

             

                 {/* <div className="table-responsive-lg">
                 <table style={{width:"100%"}}>
                 <tr>
                     <th style={{padding:"10px",textAlign:"center",border:"1px solid #000"}}>S.No</th>
                     <th style={{padding:"10px",textAlign:"center",border:"1px solid #000"}}>Article Description</th>
                     <th style={{padding:"10px",textAlign:"center",border:"1px solid #000"}}>Article Id</th>
                 </tr>
                 <tr>
                     <td style={{padding:"0 0 20px 0",textAlign:"center",border:"1px solid #000"}}>1</td>
                     <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>
                         <Link to="/archivesview" style={{fontWeight:"bold"}}>Common Fixed Point Theorems for a Pair of Self-Mappings in Fuzzy Cone Metric Spaces</Link><br/>
                     <p style={{fontSize:"12px"}}>
                         <span style={{fontWeight:"bold"}}><i class="bi bi-pencil-square"></i> Saif Ur Rehman <sup>1</sup>, Yongjin Li <sup>2</sup>, Shamoona Jabeen<sup>3</sup>, and Tayyab Mahmood<sup>4</sup></span>
                     </p>
                     </td>
                     <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>DS-IJCSE-20213001</td>
                 </tr>
                 <tr>
                     <td style={{padding:"0 0 20px 0",textAlign:"center",border:"1px solid #000"}}>2</td>
                     <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>
                         <Link to="/archivesview" style={{fontWeight:"bold"}}>Common Fixed Point Theorems for a Pair of Self-Mappings in Fuzzy Cone Metric Spaces</Link><br/>
                     <p style={{fontSize:"12px"}}>
                         <span style={{fontWeight:"bold"}}><i class="bi bi-pencil-square"></i> Saif Ur Rehman <sup>1</sup>, Yongjin Li <sup>2</sup>, Shamoona Jabeen<sup>3</sup>, and Tayyab Mahmood<sup>4</sup></span>
                     </p>
                     </td>
                     <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>DS-IJCSE-20213001</td>
                 </tr>
             </table>
                 </div> */}
             </div>
         </div>
       
     </div>
 </div>

</section>


</main>     
 </>
</>
  )
}

export default Currentissue