import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import Listaccordion from '../components/Listaccordion';
import Rlistaccordion from '../components/Rlistaccordion';

const Faq = () => {

  const listaccordionData = [{
    heading: <p  style={{fontWeight:"bold"}}>1. How does Open Access publishing relate to abstracting and indexing?</p>, 
    content: <p style={{textAlign:"justify"}}>Having an article available in the leading content repositories and major search engines greatly increases exposure, readership, and citations. The abstracting and indexing services should be considered when deciding which journal to submit to. See our guide to selecting the right journal for your research.</p>,
},
{
    heading: <p style={{fontWeight:"bold"}}>2. Why is abstracting and indexing important to me as an author?</p>, 
    content: <p>In the case of subscription-based journals, readers who discover an article via a repository search cannot read the paper in full unless they have a subscription.
    However, as Dream Science only publishes Open Access content, we work closely with the various database providers to ensure that the full text of your papers is made available through the leading abstracting and indexing services. This helps to ensure that your work can be read, used, and cited by the widest possible audience.
    While many people read your work on dsjournal.com, many more access and read it in their preferred environment.
    </p>,
},
{
  heading: <p  style={{fontWeight:"bold"}}>3. About Submission of manuscript:</p>, 
    content: <p>
      <ul>
        <li  style={{fontWeight:"bold"}}>Is any paper publication charge applicable for us?</li>
          <p style={{textAlign:"justify"}}>There are NO CHARGES FOR PUBLICATION; hence Dream Science is an independent nonprofit social service publisher looking for the quality article only. </p>
        
        <li  style={{fontWeight:"bold"}}>How do I submit the manuscript? </li>
<p style={{textAlign:"justify"}}>The researcher must email the paper through the submission link provided separately at each journal's site. Editorial support staff shall acknowledge the receipt of the manuscript within 24 hours. Detailed information is available on the Call for Paper page.
Paper submission</p>

        <li  style={{fontWeight:"bold"}}>How much time does to complete a review? </li>
        <p style={{textAlign:"justify"}}>Each submission undergoes detailed review by appointed domain experts and peer-reviews by domain peers. Dream Science requires a minimum of a fortnight to complete the review process. Depending on the reviewer's communication, it may be extendable for a few days.</p>

        <li  style={{fontWeight:"bold"}}>How do I correct experimental data in my published paper? </li>
        <p style={{textAlign:"justify"}}>Contact the editorial team at the respective journal's submission mail id. Mention the changes and provide the updated manuscript with the proper consent of your co-authors. The revised manuscript shall be published following Dream Science article policies.</p>

        <li  style={{fontWeight:"bold"}}>
        I haven't received any notification after a fortnight of submission!  </li>
        <p style={{textAlign:"justify"}}>Depending on your email spam filter settings, our notification email may be directed to your spam folder rather than your inbox. If this is not the case, we request you to escalate the issue to queries@dsjournals.com. You shall be informed of the status within 24 hours.</p>

       
        <li  style={{fontWeight:"bold"}}>My paper exceeds 10 pages! </li>
        <p style={{textAlign:"justify"}}>Dream Science policies allow the publication of a paper consisting of a maximum of 10 numbers of pages. However, depending upon the merit of the research, appropriate relaxations are granted by the Dream Science editorial panels.</p>

        <li  style={{fontWeight:"bold"}}>Do I need to submit the copyright form along with the manuscript?</li>
        <p style={{textAlign:"justify"}}>Not necessary. Dream Science shall accept the copyright forms once the submission is accepted for publication.</p>

        <li  style={{fontWeight:"bold"}}>What happens if my paper gets rejected?</li>
        <p style={{textAlign:"justify"}}>Dream Science is committed to your publishing success: If your research is of good quality, it may be suitable for another journal. Let us suggest an alternative journal within our esteemed publishing portfolio for resubmitting your manuscript (and any reviewer comments).</p>

        <li  style={{fontWeight:"bold"}}>What happens after my article has been accepted?</li>
        <p style={{textAlign:"justify"}}>While the manuscript files are handed over to production for data processing and typesetting, you (being the corresponding author) will stay in touch with us through our mail.</p>

        <li  style={{fontWeight:"bold"}}>Where do I get further information about manuscript submission?</li>
        <p style={{textAlign:"justify"}}>Any correspondence, queries, or additional requests for information on the Manuscript Submission process should be sent to the Dream Science editorial office at queries@dsjournals.com.</p>

      </ul>
    </p>,
},
{
  heading: <p  style={{fontWeight:"bold"}}>4. Other General queries:</p>, 
  content: <p>
    <ul>
    <li  style={{fontWeight:"bold"}}>Will my manuscript remain confidential?</li>
    <p style={{textAlign:"justify"}}>Yes. Dream Science treats all submitted manuscripts as confidential documents. Dream Science also 
      instructs and expects our Board of Reviewing Editors and reviewers to treat manuscripts as confidential 
      material. Our peer review process is confidential, and the reviewers' identities are not released. 
      (Letters and Technical Comments are sent to the authors of papers on which they comment for response or 
      rebuttal but are treated the same way as other contributions concerning confidentiality.)</p>
    <li  style={{fontWeight:"bold"}}>What's the policy on prior publication of results?</li>
    <p style={{textAlign:"justify"}}>Dream Science will only consider research papers reporting primary data and the main conclusions for 
      the first time. Your cover letter should clarify how the Dream Science submission is original. Any 
      related manuscript under consideration or accepted in another journal but not yet published by any 
      author on the manuscript should be included with your submission or revision so it can be uploaded as an 
      auxiliary file. The Discovery of a related paper by any author that is not disclosed may cause immediate 
      rejection without appeal or editorial retraction after publication.</p>
    <li  style={{fontWeight:"bold"}}>What about manuscripts that have been posted online before submission?</li>
    <p style={{textAlign:"justify"}}>We do not consider manuscripts that have been previously published elsewhere. Posting a paper on the Internet may be considered prior publication that could compromise the originality of the Science submission. However, we allow posting on arxiv.org and, in many cases, on other not-for-profit preprint servers. Please get in touch with the editors for advice about specific cases. </p>
    <li  style={{fontWeight:"bold"}}>What's the policy on the publication of data sets?</li>
    <p style={{textAlign:"justify"}}>As a condition of publication, authors must agree to make available all data necessary to understand and assess the manuscript's conclusions to any Dream Science reader. Data must be included in the body of the paper or in the supplementary materials, where they can be viewed free of charge by all visitors to the site. Certain data types must be deposited in an approved online database, including DNA and protein sequences, microarray data, crystal nmr, electron microscopy structures, and climate records. The accession numbers must be included in the published paper, and coordinates must be released at the time of publication.</p>
    </ul>
  </p>,
}
  ];

        return (
            <>
            <Helmet>
                <title>Get Answers to Your Questions - DS Journal FAQs</title>
                <link rel="canonical" href="https://dsjournals.com/faq"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="title" content="Get Answers to Your Questions - DS Journal FAQs"/>
                <meta name="description" content="FAQs page to find answers to common questions about DS Journal's scope, policies, submission process, publication process, and more. If you have any additional questions or concerns, feel free to contact our team for assistance."/>
                <meta name="keywords" content="trends journal, journal international, journal publication, journal submission, science journal, commerce journals, journal of economics, questionable journals, international journal of Language, Linguistics and Literature, ds journal of modeling and simulation."/>
                <meta property="og:type" content="Website"/>
                <meta property="og:site_name" content="Dream Science"/>
                <meta property="og:url" content="https://dsjournals.com/faq"/>
                <meta property="og:title" content="Get Answers to Your Questions - DS Journal FAQs"/>
                <meta property="og:description" content=" FAQs page to find answers to common questions about DS Journal's scope, policies, submission process, publication process, and more. If you have any additional questions or concerns, feel free to contact our team for assistance."/>
                <meta property="og:image" content="image url"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="Get Answers to Your Questions - DS Journal FAQs"/>
                <meta name="twitter:description" content="FAQs page to find answers to common questions about DS Journal's scope, policies, submission process, publication process, and more. If you have any additional questions or concerns, feel free to contact our team for assistance."/>
                <meta name="twitter:site" content="@DreamScience4"/>
                <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
                <meta name="twitter:image" content="image url"/>
                <meta name="robots" content="index, follow"/>
            </Helmet>

            <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
              <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <h1>FAQs</h1>
                      </div>
                  </div>
                </div>
              </div>
              </div>
            </section>
            
            <section className="breadcrumbs">
              <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                  
                  <ol>
                  <li><Link to="/home">Home</Link></li>
                    <li>FAQs</li>
                  </ol>
                </div>
              </div>
            </section>
                 <main id="main">
        <section className="blog" id="blog">
            <div className="container">
                <div className="row">
                    <div id='head1' className="col-lg-12">
                    <div className="list-group">
                        <div className="accordion">
                          {listaccordionData.map(({heading,content }) => (
                            <Rlistaccordion heading={heading} content={content} />
                            ))}
                        </div>
                      </div>
                                               
                    </div>
                  
                </div>
            </div>
    
        </section>
    
    
      </main>
            </>
        )
}

export default Faq