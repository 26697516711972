import React,{useState} from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import './Dashboard.css';
import Dsidebar         from './Dsidebar';
import Dheader          from './Dheader';
import Fhome            from './Fhome';
import Leftcard         from './Leftcard';
import Middlecard       from './Middlecard';
import Rightcard        from './Rightcard';
import Journalpage      from './Journalpage';
import Dcontent         from './Dcontent';
import Currentissue     from './currentissue/Currentissue';
import Dviewjournal     from './Dviewjournal';
import Dcreatejournal   from './Dcreatejournal';
import Testpage         from './test/Fronthome';
import Dsstviewdata     from './dsst/Dsstview';
import Dstsingleview    from './dsst/Dstsingleview';
import Dsjsctupdatedata from './dsst/Dsjscupdate';
import Dissuelist from './issuelist/dst/Issuelist';
//-------import for Journal of Language, Lingustics and Literature --------------------------//
import Dslllviewjournal      from './dslll/Dviewjournal';
import Dslllcreatejournal    from './dslll/Dcreatejournal';
import Dslllviewdata         from './dslll/Dlanview';
import Dslllsingleview       from './dslll/Dlansingleview';
import Dslllupdatedata       from './dslll/Dslllupdate';

//-------import for DS Journal of Artificial Intelligence and Robotics --------------------------//
import Dsairviewjournal from './dsair/Dviewjournal';
import Dsaircreatejournal from './dsair/Dcreatejournal';
// import Dsairviewdata from './dsair/Dairview';
import Dsairviewdata from './dsair/Dairview';
import Dsairsingleview from './dsair/Dairsingleview';

//-------import for DS Journal of Cyber Security --------------------------//
import Dscysviewjournal from './dscys/Dviewjournal';
import Dscyscreatejournal from './dscys/Dcreatejournal';
import Dscysviewdata from './dscys/Dcysview';
import Dscyssingleview from './dscys/Dcyssingleview';

//-------import for DS Journal of Modelling and Simulation --------------------------//
import Dsmsviewjournal from './dsms/Dviewjournal';
import Dsmscreatejournal from './dsms/Dcreatejournal';
import Dsmsviewdata from './dsms/Dsmsview';
import Dsmssingleview from './dsms/Dsmssingleview';

//-------import for DS Journal of Multidisciplinary --------------------------//
import Dsmviewjournal from './dsm/Dviewjournal';
import Dsmcreatejournal from './dsm/Dcreatejournal';
import Dsmviewdata from './dsm/Dsmview';
import Dsmsingleview from './dsm/Dsmsingleview';


//-------import for DS Review of Commerce and Economics --------------------------//
import Dsceviewjournal from './dsce/Dviewjournal';
import Dscecreatejournal from './dsce/Dcreatejournal';
import Dsceviewdata from './dsce/Dsceview';
import Dscesingleview from './dsce/Dscesingleview';


//-------import for DS Research of Life Sciences --------------------------//
import Dsrlsviewjournal from './dsrls/Dviewjournal';
import Dsrlscreatejournal from './dsrls/Dcreatejournal';
import Dsrlsviewdata from './dsrls/Dsrlsview';
import Dsrlssingleview from './dsrls/Dsrlssingleview';

import { useHistory } from "react-router-dom";



const Adminroutes = () => {
    const history = useHistory();
    const authreducer = useSelector((state)=> state.auth);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));



  return (
    <>
  
            
            {!user ? (history.push('/auth')) : (
                <div>
                 <Dsidebar/>
                {/* <Dheader/> */}
                    <Route exact path="/dashboard" component={Dcontent}>
                        <Dcontent/>
                    </Route>
                    <Route exact path="/fronthome" component={Fhome}>
                        <Fhome/>
                    </Route>
                    <Route exact path="/testpage" component={Testpage}>
                        <Testpage/>
                    </Route>
                    <Route exact path="/leftcard" component={Leftcard}>
                        <Leftcard/>
                    </Route>
                    <Route exact path="/midcard" component={Middlecard}> 
                        <Middlecard/>
                    </Route>
                    <Route exact path="/rightcard" component={Rightcard}>
                        <Rightcard/>    
                    </Route>
                    <Route exact path="/journalpage" component={Journalpage}>
                        <Journalpage/>
                    </Route>
                    <Route exact path="/dashboard/currentissue" component={Currentissue}>
                        <Currentissue/>
                    </Route>


                    {/* -------------------------- Journal of Digital Science and Technology -------------------------- */}
                    <Route exact path="/dcreatejournal" component={Dcreatejournal}>
                        <Dcreatejournal/>
                    </Route>
                    <Route exact path="/dviewjournal" component={Dviewjournal}>
                        <Dviewjournal/>
                    </Route>
                    <Route exact path="/dsstviewdata" component={Dsstviewdata}>
                        <Dsstviewdata/>
                    </Route>
                    <Route exact path="/dstdata/:id" component={Dstsingleview}>
                        <Dstsingleview/>
                    </Route>
                    {/* <Route exact path="/dsjstupdatedata/:id" component={Dsjsctupdatedata}>
                        <Dsjsctupdatedata/>
                    </Route> */}

                    <Route exact path="/dsjstupdatedata/:id" component={Dcreatejournal}>
                        <Dcreatejournal/>
                    </Route>
                    <Route exact path="/issuelist" component={Dissuelist}>
                        <Dissuelist/>
                    </Route>

                    {/* -------------------------- Journal of Language, Lingustics and Literature -------------------------- */}
                    <Route exact path="/dlll/dslllcreatejournal" component={Dslllcreatejournal}>
                        <Dslllcreatejournal/>
                    </Route>
                    <Route exact path="/dlll/dslllviewjournal" component={Dslllviewjournal}>
                        <Dslllviewjournal/>
                    </Route>                  
                
                    <Route exact path="/dlll/dslllviewdata" component={Dslllviewdata}>
                        <Dslllviewdata/>
                    </Route>
                    <Route exact path="/dlll/dsllldata/:id" component={Dslllsingleview}>
                        <Dslllsingleview/>
                    </Route>
                    {/* <Route exact path="/dlll/dslllupdatedata/:id" component={Dslllupdatedata}>
                        <Dslllupdatedata/>
                    </Route> */}
                    <Route exact path="/dlll/dslllupdatedata/:id" component={Dslllcreatejournal}>
                    <Dslllcreatejournal/>
                    </Route>

                    
                    {/* -------------------------- DS Journal of Artificial Intelligence and Robotics -------------------------- */}
                    <Route exact path="/dashboard/dsair/dsaircreatejournal" component={Dsaircreatejournal}>
                        <Dsaircreatejournal/>
                    </Route>
                    <Route exact path="/dashboard/dsair/dsairviewjournal" component={Dsairviewjournal}>
                        <Dsairviewjournal/>
                    </Route>                  
                
                    <Route exact path="/dashboard/dsair/dsairviewdata" component={Dsairviewdata}>
                        <Dsairviewdata/>
                    </Route>
                    <Route exact path="/dashboard/dsair/dsairdata/:id" component={Dsairsingleview}>
                        <Dsairsingleview/>
                    </Route>
                    <Route exact path="/dashboard/dsair/dsairupdatedata/:id" component={Dsaircreatejournal}>
                    <Dsaircreatejournal/>
                    </Route>

                    {/* -------------------------- DS Journal of Cyber Security -------------------------- */}
                    <Route exact path="/dashboard/dscys/dscyscreatejournal" component={Dscyscreatejournal}>
                        <Dscyscreatejournal/>
                    </Route>
                    <Route exact path="/dashboard/dscys/dscysviewjournal" component={Dscysviewjournal}>
                        <Dscysviewjournal/>
                    </Route>
                    <Route exact path="/dashboard/dscys/dscysviewdata" component={Dscysviewdata}>
                        <Dscysviewdata/>
                    </Route>
                    <Route exact path="/dashboard/dscys/dscysdata/:id" component={Dscyssingleview}>
                        <Dscyssingleview/>
                    </Route>
                    <Route exact path="/dashboard/dscys/dscysupdatedata/:id" component={Dscyscreatejournal}>
                        <Dscyscreatejournal/>
                    </Route>


                    {/* -------import for DS Journal of Modelling and Simulation -------------------------- */}

                    <Route exact path="/dashboard/dsms/dsmscreatejournal" component={Dsmscreatejournal}>
                        <Dsmscreatejournal/>
                    </Route>
                    <Route exact path="/dashboard/dsms/dsmsviewjournal" component={Dsmsviewjournal}>
                        <Dsmsviewjournal/>
                    </Route>
                    <Route exact path="/dashboard/dsms/dsmsviewdata" component={Dsmsviewdata}>
                        <Dsmsviewdata/>
                    </Route>
                    <Route exact path="/dashboard/dsms/dsmsdata/:id" component={Dsmssingleview}>
                        <Dsmssingleview/>
                    </Route>
                    <Route exact path="/dashboard/dsms/dsmsupdatedata/:id" component={Dsmscreatejournal}>
                        <Dsmscreatejournal/>
                    </Route>

                    {/* -------import for DS Journal of Modelling and Simulation -------------------------- */}

                    <Route exact path="/dashboard/dsm/dsmcreatejournal" component={Dsmcreatejournal}>
                        <Dsmcreatejournal/>
                    </Route>
                    <Route exact path="/dashboard/dsm/dsmviewjournal" component={Dsmviewjournal}>
                        <Dsmviewjournal/>
                    </Route>
                    <Route exact path="/dashboard/dsm/dsmviewdata" component={Dsmviewdata}>
                        <Dsmviewdata/>
                    </Route>
                    <Route exact path="/dashboard/dsm/dsmdata/:id" component={Dsmsingleview}>
                        <Dsmsingleview/>
                    </Route>
                    <Route exact path="/dashboard/dsm/dsmupdatedata/:id" component={Dsmcreatejournal}>
                        <Dsmcreatejournal/>
                    </Route>

                       {/* -------import for DS Review of Commerce and Economics -------------------------- */}

                       <Route exact path="/dashboard/dsce/dscecreatejournal" component={Dscecreatejournal}>
                        <Dscecreatejournal/>
                    </Route>
                    <Route exact path="/dashboard/dsce/dsceviewjournal" component={Dsceviewjournal}>
                        <Dsceviewjournal/>
                    </Route>
                    <Route exact path="/dashboard/dsce/dsceviewdata" component={Dsceviewdata}>
                        <Dsceviewdata/>
                    </Route>
                    <Route exact path="/dashboard/dsce/dscedata/:id" component={Dscesingleview}>
                        <Dscesingleview/>
                    </Route>
                    <Route exact path="/dashboard/dsce/dsceupdatedata/:id" component={Dscecreatejournal}>
                        <Dscecreatejournal/>
                    </Route>
                     {/* -------import for DS Research of Life Sciences -------------------------- */}

                     <Route exact path="/dashboard/dsrls/dsrlscreatejournal" component={Dsrlscreatejournal}>
                        <Dsrlscreatejournal/>
                    </Route>
                    <Route exact path="/dashboard/dsrls/dsrlsviewjournal" component={Dsrlsviewjournal}>
                        <Dsrlsviewjournal/>
                    </Route>
                    <Route exact path="/dashboard/dsrls/dsrlsviewdata" component={Dsrlsviewdata}>
                        <Dsrlsviewdata/>
                    </Route>
                    <Route exact path="/dashboard/dsrls/dsrlsdata/:id" component={Dsrlssingleview}>
                        <Dsrlssingleview/>
                    </Route>
                    <Route exact path="/dashboard/dsrls/dsrlsupdatedata/:id" component={Dsrlscreatejournal}>
                        <Dsrlscreatejournal/>
                    </Route>


                    </div>
                   
                    )}
               
    </>
  )
}

export default Adminroutes;