import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Post from './Post/Post'

const Posts = ({setCurrentId}) => {
 
  const posts = useSelector((state) => state.cissueposts.posts);
 
  return (

          !posts.length ? <CircularProgress /> : (
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">Journal Name</TableCell>
                  <TableCell align="right">Volume</TableCell>
                  <TableCell align="right">Issue</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
            {posts.map((post) =>(
                <Post post={post} setCurrentId={setCurrentId} />
            ))}
            </TableBody>
      </Table>
    </TableContainer>
          )

  );
};

export default Posts