import React, { useState, useEffect } from 'react';
import {Link,NavLink,useLocation} from 'react-router-dom';
// import Posts from '../../components/Dlan/Articlelist/Posts/Posts';
import Posts from '../../components/Dscy/Articlelist/Posts/Posts';
import { useSelector, useDispatch } from 'react-redux';
import { getjournalPosts } from '../../actions/journal/dscys/posts';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import useStyles from '../../components/styles';
import Accordion from '../../components/Dscy/Accordion';
import Ddscyarticletemplate from '../../downloads/DS-CYS-Article_Template.doc';
import Ddscycopyrightform from '../../downloads/DS-CYS-Copyright_Form.docx';




const Archiveslist = () => {
  const [currentId, setCurrentId] = useState(0);
  const [monthpost, setMonthpost] = useState("");
  const dispatch = useDispatch();

  const posts = useSelector((state) => state.dscyposts)

  const currentURL = window.location.href;
  const sQuery = currentURL.split("/");
  const fet_volume = sQuery[5] ? sQuery[5].match(/\d+/g) : "";
  const fet_issue = sQuery[6] ? sQuery[6].match(/\d+/g) : "";
  // const fet_volume = sQuery[5].match(/\d+/g);
  // const fet_issue =  sQuery[6].match(/\d+/g);
  
  const {state} = useLocation();  
  
  const act_volume = !state ? fet_volume[0] : state.volume;
  const act_issue = !state ? fet_issue[0] : state.issue
  const act_issue_month = !state ? "": ", "+state.month_of_issue;

  useEffect(() => {
    dispatch(getjournalPosts());    
  }, [currentId, dispatch]);

  useEffect(() => {
    setMonthpost(posts)    
   });
  
   useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  const accordionData = [{
    content: <div>
     <HashLink smooth to="/cys" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
           <NavLink to="/cys/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
           <NavLink to="/cys/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
           <NavLink to="/cys/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
           <NavLink to="/cys/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
           <NavLink to="/cys/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
           <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
           <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
           <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
           <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
           <NavLink to="/cys/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
           <a href={Ddscyarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
           <a href={Ddscycopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
</div> 
},
  ];




    return (
        <>
        <Helmet>
        <title>Archives - DS Journal of Cyber Security (DS-CYS)</title>
          <link rel="canonical" href="https://dsjournals.com/cys/archives"/>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="title" content="Archives - DS Journal of Cyber Security (DS-CYS)"/>
          <meta name="description" content="Explore past research in the field of cyber security with the archives of DS-CYS Journal. Discover new insights and perspectives from leading experts."/>
          <meta name="keywords" content="recent research paper in Cyber Security, archives of Cyber Security journal, a journal article, a journal of Cyber Security research, a Cyber Security peer reviewed journal, article for journal, journal Cyber Security, Cyber Security research, a Cyber Security journal, journal of Cyber Security research, research in Cyber Security."/>
          <meta property="og:type" content="Website"/>
          <meta property="og:site_name" content="Dream Science"/>
          <meta property="og:url" content="https://dsjournals.com/cys/archives"/>
          <meta property="og:title" content="Archives - DS Journal of Cyber Security (DS-CYS)"/>
          <meta property="og:description" content="Explore past research in the field of cyber security with the archives of DS-CYS Journal. Discover new insights and perspectives from leading experts."/>
          <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Cyber-Security.webp"/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:title" content="Archives - DS Journal of Cyber Security (DS-CYS)"/>
          <meta name="twitter:description" content="Explore past research in the field of cyber security with the archives of DS-CYS Journal. Discover new insights and perspectives from leading experts."/>
          <meta name="twitter:site" content="@DreamScience4"/>
          <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
          <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Cyber-Security.webp"/>
          <meta name="robots" content="index, follow"/>
        </Helmet>
   

           <section id="hero-no-slide-dscy" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
          <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <div className="row justify-content-center">
                  {/* <div className="col-xl-8">
                    <h2>Article List</h2>
                  </div> */}
                  <div className="col-lg-12">
                  <h2 style={{fontSize:"25px"}}>DS Journal of Cyber Security ( DS-CYS )</h2>
                                </div>
              </div>
            </div>
          </div>
          </div>
        </section>
        
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              {/* <h2>Archives</h2> */}
              <ol>
                <li><Link to="/home">Home</Link></li>
                <li><Link to="/journals">Journals</Link></li>
                <li><Link to="/cys/archives">Archives</Link></li>
                <li>Article List</li>
              </ol>
            </div>
          </div>
        </section>
       
             <main id="main">
                <section className="blog" id="blog">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                              <div className="accordion">
                                    {accordionData.map(({content }) => (
                                      <Accordion content={content} />
                                  ))}
                              </div>
                            </div>
                            
                               
                            <div className="col-lg-9">
                                <h5 className='mt-3'>List of Articles</h5>
                                <div className="row mt-3">
                                    <div className="col-lg-12 text-center">
                                    {/* <h5 style={{alignItems:'center'}}> Volume {vol[1]} Issue {[iss[1]]}, {state.month_of_issue}</h5> */}
                                    {/* <h5 style={{alignItems:'center'}}> Volume {vol[1]} Issue {iss[1]} </h5> */}
                                    {/* <h5 style={{alignItems:'center'}}> Volume 1 Issue 1 </h5> */}
                                    {/* <h5 style={{alignItems:'center'}}> Volume {1} Issue {1}, April - June</h5> */}
                                    <h5 style={{alignItems:'center'}}> Volume {act_volume} Issue {act_issue} {act_issue_month}</h5>
                                    </div>
                                
                                </div>

                                  <div className='row'>
                                    <div className='col-lg-12'>
                                    <Posts setCurrentId={setCurrentId} volume={state.volume} issue={state.issue}/>  
                                    {/* <Posts setCurrentId={setCurrentId} volume={vol[1]} issue={iss[1]}/>   */}
                                    {/* <Posts setCurrentId={setCurrentId} /> */}
                                        {/* Page is under construction.... */}
                                        
                                    </div>


                                  </div>
                                

                            </div>
                          
                        </div>
                    </div>

                </section>


              </main>     
        </>
    )
}
export default Archiveslist;