//import React from 'react';
import React, { Component } from 'react'

import Navbar from './components/Navbar';
import Homecont from './components/Homecont';
import Footer from './components/Footer';
import Forauthors from'./pages/Forauthors';
import Forreviewers from './components/Forreviewers';
import Foreditors from './components/Foreditors';
import Aboutus from './pages/Aboutus';
import Rprocess from './components/Rprocess';
import Pubethics from './components/Pubethics';
import Indexing from './components/Indexing';
import OpenAccess from './components/Openaccess';
import Lpolicy from './components/Lpolicy';
import Copyrightinfringement from './pages/Copyrightinfringement';
import Conferences from './components/Conferences';
import Conferencelist from './pages/Conferencelist';
import Conferenceproposal from './pages/Conferenceproposal';
import Journals from './pages/Journals';
import DSSTjournalprofile from './pages/dst/Journalprofile';
// import Journalcont from './pages/Journalcont';
// import Journalsview from './pages/Journalsview';
import Aboutjournal from './pages/Aboutjournal';

import Editorialboard from './pages/dst/Editorboard';
//------------------Admin Panel------------------//

//-----------------------------------------------------------------------//
//------------------Language, Lingustics and Literature------------------//
// import Dsllltopics from './pages/dslll/Dsllltopics';
// import Dslltest from './pages/dslll/Dslltest';
import Dsllltopics from './pages/dslll/Dsllltopics';
import Dsllleditorialboard from './pages/dslll/Dsllleditorialboard';
import Lllcurrentissue from './pages/dslll/Currentissue';
import Lllarchiveslist from './pages/dslll/Archiveslist';
import Lllarchiveslist2 from './pages/dslll/Archiveslist2';
import Lllarchiveslist3 from './pages/dslll/Archiveslist3';
import Dslllaimandscope from './pages/dslll/Dslllaimandscope';
import Lanarchives from './pages/dslll/Archives';

import Dslllapc from './pages/dslll/Dslllapc';
import Dslllarticlesubmit from './pages/dslll/Dslllarticlesubmit';
import Dslllarticledetails from './pages/dslll/Dslllarticledetails';
//-----------------------------------------------------------------------//

// -------------------Multidisciplinary---------------------------------//
import Dsdsmaimandscope from './pages/dsdsm/Dsdsmaimandscope';
import Dsdsmtopic from './pages/dsdsm/Dsdsmtopics';
import Dsdsmeditorialboard from './pages/dsdsm/Dsdsmeditorialboard';
import Dsmcurrentissue from './pages/dsdsm/Currentissue';
import Dsmarchiveslist from './pages/dsdsm/Archiveslist';
import Dsmarchives from './pages/dsdsm/Archives';
import Dsdsmapc from './pages/dsdsm/Dsdsmapc';
import Dsdsmarticlesubmit from './pages/dsdsm/Dsdsmarticlesubmit';
import Dsmarticledetails from './pages/dsdsm/Dsmarticledetails';

//-----------------------------------------------------------------------//

// -------------------Modelling and Simulation---------------------------------//
import Dsdsmsaimandscope from './pages/dsms/Dsdsmsaimandscope';
import Dsdsmstopic from './pages/dsms/Dsdsmstopics';
import Dsdsmseditorialboard from './pages/dsms/Dsdsmseditorialboard';
import Dsmscurrentissue from './pages/dsms/Currentissue';
import Dsmsarchiveslist from './pages/dsms/Archiveslist';
import Dsmsarchives from './pages/dsms/Archives';
import Dsdsmsapc from './pages/dsms/Dsdsmsapc';
import Dsdsmsarticlesubmit from './pages/dsms/Dsdsmsarticlesubmit';
import Dsmsarticledetails from './pages/dsms/Dsmsarticledetails';


//-----------------------------------------------------------------------//

// -------------------Reviews of Commerce and Economics---------------------------------//
import Dsdsceaimandscope from './pages/dsce/Dsdsceaimandscope';
import Dsdscetopic from './pages/dsce/Dsdscetopics';
import Dsdsceeditorialboard from './pages/dsce/Dsdsceeditorialboard';
import Dscecurrentissue from './pages/dsce/Currentissue';
import Dscearchiveslist from './pages/dsce/Archiveslist';
import Dscearchives from './pages/dsce/Archives';
import Dsdsceapc from './pages/dsce/Dsdsceapc';
import Dsdscearticlesubmit from './pages/dsce/Dsdscearticlesubmit';
import Dsdscearticledetails from './pages/dsce/Dsdscearticledetails';

//-----------------------------------------------------------------------//

// -------------------Artificial Intelligence and Robotics---------------------------------//
import Dsdsairaimandscope from './pages/dsair/Dsdsairaimandscope';
import Dsdsairtopic from './pages/dsair/Dsdsairtopics';
import Dsdsaireditorialboard from './pages/dsair/Dsdsaireditorialboard';
import Dsaircurrentissue from './pages/dsair/Currentissue';
import Dsairarchiveslist from './pages/dsair/Archiveslist';
import Dsdsairarticledetails from './pages/dsair/Dsairarticledetails';
import Dsairarchives from './pages/dsair/Archives';
// import Dsdsairarchiveslist2 from './pages/dsair/list/Archiveslist2';
// import Dsdsairarchiveslist3 from './pages/dsair/list/Archiveslist3';
import Dsdsairapc from './pages/dsair/Dsdsairapc';
import Dsdsairarticlesubmit from './pages/dsair/Dsdsairarticlesubmit';


//-----------------------------------------------------------------------//

// -------------------Cyber Security--------------------------------------//
import Dsdscyaimandscope from './pages/dscy/Dsdscyaimandscope';
import Dsdscytopic from './pages/dscy/Dsdscytopics';
import Dsdscyeditorialboard from './pages/dscy/Dsdscyeditorialboard';
import Dscycurrentissue from './pages/dscy/Currentissue';
import Dscyarchiveslist from './pages/dscy/Archiveslist';
import Dscyarchives from './pages/dscy/Archives';
import Dsdscyapc from './pages/dscy/Dsdscyapc';
import Dsdscyarticlesubmit from './pages/dscy/Dsdscyarticlesubmit';
import Dsdscysarticledetails from './pages/dscy/Dsdscysarticledetails';


//-----------------------------------------------------------------------//


// -------------------Reviews of Life Sciences--------------------------------------//
import Dsdsrlsaimandscope from './pages/dsrls/Dsdsrlsaimandscope';
import Dsdsrlstopic from './pages/dsrls/Dsdsrlstopics';
import Dsdsrlseditorialboard from './pages/dsrls/Dsdsrlseditorialboard';
import Dsrlscurrentissue from './pages/dsrls/Currentissue';
import Dsrlsarchiveslist from './pages/dsrls/Archiveslist';
import Dsrlsarchives from './pages/dsrls/Archives';
import Dsdsrlsapc from './pages/dsrls/Dsdsrlsapc';
import Dsdsrlsarticlesubmit from './pages/dsrls/Dsdsrlsarticlesubmit';
import Dsdsrlsarticledetails from './pages/dsrls/Dsdsrlsarticledetails';


//-----------------------------------------------------------------------//



import Specialissues from './pages/Specialissues';
import Currentissue from './pages/dst/Currentissue';
import Specialissuelist from './pages/Specialissuelist';
import Specialissueproposal from './pages/Specialissueproposal';
import Eventproposal from './pages/Eventproposal';
import Archives from './pages/dst/Archives';
import Archiveslist from './pages/dst/Archiveslist';
// import Temparchiveslist from './pages/dst/Archivelist2';
// import Temparchiveslist1 from './pages/dst/Archivelist3';
import Archivesview from './pages/Archivesview';
import Articledetails from './pages/dst/Articledetails';
import Topics from './pages/dst/Dsttopics';

import APC from './pages/dst/Dstapc';

import What_is_APC from './pages/What_is_APC';
// import Articledetails from './components/Articledetails2';
import Excercise from './pages/excercise';
import Submission from './pages/Submission';
import Articlesubmit from './pages/dst/Articlesubmit';

import FAQ from './pages/Faq';
import Contactus from './pages/Contactus';
import Search from './pages/Search';
import Guidelineauthor from './pages/Guidelineauthor';
import Important_items from './pages/Important_items';
import OpenAccessauthor from './pages/Openaccess_author';
import Reviewprocess from './pages/Reviewprocess';
import Conflicts_of_interest from './pages/Conflicts_of_interest';
import Correction_policy from './pages/Correction_policy';
import { BrowserRouter , Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import routes from './routes.js';
import "./Home.css";
import Notfound404 from './pages/Notfound404';
import Test from './pages/Test';
// import Dsdsrlstopics from './pages/dsrls/Dsdsrlstopics';


// function Home() {
//     return (
//         <div>
//         <Helmet>
            
//             <script src="./assets/vendor/aos/aos.js"></script>
//             <script src="./assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
//             <script src="./assets/vendor/glightbox/js/glightbox.min.js"></script>
//             <script src="./assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
//             <script src="./assets/vendor/swiper/swiper-bundle.min.js"></script>
//             <script src="./assets/vendor/waypoints/noframework.waypoints.js"></script>
//             <script src="./assets/vendor/purecounter/purecounter.js"></script>
//             <script src="./assets/js/main.js"></script>
//             <script src="assets/js/searchbar.js"></script>
//       </Helmet>
//     <Router>
//     <Navbar/>
//         <Switch>
//             <Route exact path="/home" component={Homecont}>
//                 <Homecont/>
//             </Route>
//             <Route exact path="/journals" component={Journals}>
//                 <Journals/>
//             </Route>    
//             <Route exact path="/forauthors" component={Forauthors}>
//                 <Forauthors/>
//             </Route>
//             <Route exact path="/forreviewers" component={Forreviewers}>
//                 <Forreviewers/>
//             </Route>
//             <Route exact path="/pubethics" component={Pubethics}>
//                 <Pubethics/>
//             </Route>
//             <Route exact path="/aboutus" component={Aboutus}>
//                 <Aboutus/>
//             </Route>
//             <Route exact path="/journalsview" component={Journalsview}>
//                 <Journalsview/>
//             </Route>
//             <Route exact path="/journalcont" component={Journalcont}>
//                 <Journalcont/>
//             </Route>
//             <Route exact path="/aboutjournal" component={Aboutjournal}>
//                 <Aboutjournal/>
//             </Route>
//             <Route exact path="/editorialboard" component={Editorialboard}>
//                 <Editorialboard/>
//             </Route>
//             <Route exact path="/specialissues" component={Specialissues}>
//                 <Specialissues/>
//             </Route>
//             <Route exact path="/archives" component={Archives}>
//                 <Archives/>
//             </Route>
//             <Route exact path="/archiveslist" component={Archiveslist}>
//                 <Archiveslist/>
//             </Route>
//             <Route exact path="/archivesview" component={Archivesview}>
//                 <Archivesview/>            
//             </Route>
//            <Route exact path="/rprocess" component={Rprocess}>
//                 <Rprocess/>
//            </Route>
//            <Route exact path="/indexing" component={Indexing}>
//                 <Indexing/>
//            </Route>
//            <Route exact path="/openaccess" component={OpenAccess}>
//                 <OpenAccess/>
//            </Route>
//            <Route exact path="/lpolicy" component={Lpolicy}>
//                 <Lpolicy/>
//            </Route>
//            <Route exact path="/conferences" component={Conferences}>
//                 <Conferences/>
//            </Route>
//         </Switch>
//     <Footer/>
//     </Router>
//         </div>
//     )
// }

class Home extends Component {
    
    render() {
        
        return (
            <div>
                <Helmet>
                         {/* <script src="assets/vendor/aos/aos.js"></script>
                         <script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
                         <script src="assets/vendor/glightbox/js/glightbox.min.js"></script>
                         <script src="assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
                         <script src="assets/vendor/swiper/swiper-bundle.min.js"></script>
                         <script src="assets/vendor/waypoints/noframework.waypoints.js"></script>
                         <script src="assets/vendor/purecounter/purecounter.js"></script>
                         <script src="assets/js/main.js"></script>
                         <script src="assets/js/searchbar.js"></script> */}
                   </Helmet>
                 <BrowserRouter>
                 <Navbar/>
                 {/* <Switch>
                    {routes.map((route) => (
                      {...route} 
                    ))}
                </Switch> */}
                     <Switch>
                         {/* <Route exact path="/home" component={Homecont}>
                             <Homecont/>
                         </Route>
                         <Route exact path="/journals" component={Journals}>
                             <Journals/>
                         </Route>    
                         <Route exact path="/forauthors" component={Forauthors}>
                             <Forauthors/>
                         </Route>
                         <Route exact path="/forreviewers" component={Forreviewers}>
                             <Forreviewers/>
                         </Route>
                         <Route exact path="/pubethics" component={Pubethics}>
                             <Pubethics/>
                         </Route>
                         <Route exact path="/aboutus" component={Aboutus}>
                             <Aboutus/>
                         </Route>
                         <Route exact path="/journalsview" component={Journalsview}>
                             <Journalsview/>
                         </Route>
                         <Route exact path="/journalcont" component={Journalcont}>
                             <Journalcont/>
                         </Route>
                         <Route exact path="/aboutjournal" component={Aboutjournal}>
                             <Aboutjournal/>
                         </Route>
                         <Route exact path="/editorialboard" component={Editorialboard}>
                             <Editorialboard/>
                         </Route>
                         <Route exact path="/specialissues" component={Specialissues}>
                             <Specialissues/>
                         </Route>
                         <Route exact path="/archives" component={Archives}>
                             <Archives/>
                         </Route>
                         <Route exact path="/archiveslist" component={Archiveslist}>
                             <Archiveslist/>
                         </Route>
                         <Route exact path="/archivesview" component={Archivesview}>
                             <Archivesview/>            
                         </Route>
                        <Route exact path="/rprocess" component={Rprocess}>
                             <Rprocess/>
                        </Route>
                        <Route exact path="/indexing" component={Indexing}>
                             <Indexing/>
                        </Route>
                        <Route exact path="/openaccess" component={OpenAccess}>
                             <OpenAccess/>
                        </Route>
                        <Route exact path="/lpolicy" component={Lpolicy}>
                             <Lpolicy/>
                        </Route>
                        <Route exact path="/conferences" component={Conferences}>
                             <Conferences/>
                        </Route>
                        <Route exact path="/excercise" component={Excercise}>
                             <Excercise/>
                        </Route>
                     </Switch> */}

                        <Route exact path="/home" component={Homecont}/>
                          
                         <Route exact path="/journals" component={Journals}/>
                              
                         <Route exact path="/for-authors" component={Forauthors}/>
                             
                         <Route exact path="/for-authors/author-guideline" component={Guidelineauthor}/>                             

                         <Route exact path="/for-authors/submitting-a-manuscript" component={Important_items}/>                             

                         <Route exact path="/for-authors/open-access-author" component={OpenAccessauthor}/>   

                         <Route exact path="/for-authors/review-process" component={Reviewprocess}/>                                                      

                        <Route exact path="/for-authors/conflicts-of-interest" component={Conflicts_of_interest}/>
                        {/* <Route path exact="/for-authors/conflict_of_interest" component={Conflicts_of_interest}/> */}
                        <Route exact path="/for-authors/licensing-policy" component={Lpolicy}/>

                        <Route exact path="/for-authors/copyright-infringement" component={Copyrightinfringement}/>

                        <Route exact path="/for-authors/correction-policy" component={Correction_policy}/>

                        <Route exact path="/for-authors/what-is-apc" component={What_is_APC}/>

                         <Route exact path="/for-reviewers" component={Forreviewers}/>

                         <Route exact path="/for-editors" component={Foreditors}/>
                             
                         <Route exact path="/for-authors/publication-ethics" component={Pubethics}/>

                         <Route exact path="/search" component={Search}/>
                          
                         <Route exact path="/about-us" component={Aboutus}/>
                         
                         
                          {/* -------------Digital Science and Technology------------- */}

                         <Route exact path="/dst" component={DSSTjournalprofile}/>
                         <Route exact path="/dst/editorial-board" component={Editorialboard}/>
                         <Route exact path="/dst/paper-submission" component={Articlesubmit}/>
                         {/* <Route exact path="/journals-view" component={Journalsview}/> */}
                         <Route exact path="/dst/topics" component={Topics}/>
                         <Route exact path="/dst/apc" component={APC}/>
                         <Route exact path="/dst/archives" component={Archives}/>
                         <Route exact path="/dst/archives/volume:volume/issue:issue" component={Archiveslist}/>
                         <Route exact path="/dst/current-issue" component={Currentissue}/>
                         <Route exact path="/dst/:id" component={Articledetails}/>

                         {/* <Route exact path="/journals/dst/archive/Volume1-Issue1" component={Archiveslist}/> */}
                         {/* <Route exact path="/dst/archives/volume1/issue1" component={Archiveslist}/> */}
                         {/* <Route exact path="/dst/archives/volume1/issue2" component={Temparchiveslist}/>
                         <Route exact path="/dst/archives/Volume2/Issue1" component={Temparchiveslist1}/> */}                         
                         {/* <Route exact path="/article/:id" component={Articledetails}/> */}
                         {/* <Route exact path="/dst/archives/volume1/issue2/:id" component={Articledetails}/>
                         <Route exact path="/dst/archives/volume2/issue1/:id" component={Articledetails}/> */}
                         {/* <Route exact path="/dst/volume2/issue1" component={Currentissue}/> */}
                         {/* <Route exact path="/journals/dst/archives/Volume1-Issue2" component={Temparchiveslist}/> */}
                         

                         {/* -------------Language, Linguistics and Literature------------- */}

                         {/* <Route exact path="/journals/lll" component={Dslllaimandscope}/>
                         <Route exact path="/journals/lll/editorial-board" component={Dsllleditorialboard}/>
                         <Route exact path="/journals/lll/paper-submission" component={Dslllarticlesubmit}/> 
                         <Route exact path="/journals/lll/volume1/issue1" component={Lllcurrentissue}/>   
                         {/* <Route exact path="/journals/lll/archives/Volume1-Issue1" component={Lllarchiveslist}/>
                         <Route exact path="/journals/lll/archive/volume1/issue1" component={Lllarchiveslist}/>  
                         <Route exact path="/journals/lll/archive" component={Lanarchives}/>                      
                         <Route exact path="/journals/lll/archive/volume1/issue1/:id" component={Dslllarticledetails}/>
                         <Route exact path="/journals/lll/topics" component={Dsllltopics}/>                        
                         <Route exact path="/journals/lll/apc" component={Dslllapc}/> */}
                          {/* <Route exact path="/lll/current-issue"              component={Lllcurrentissue}/>    */}
                         {/* <Route exact path="/lll/current-issue"              component={Lllarchiveslist2}/>    */}
                         {/* <Route exact path="/journals/lll/archives/Volume1-Issue1" component={Lllarchiveslist}/> */}

                         <Route exact path="/lll"                           component={Dslllaimandscope}/>
                         <Route exact path="/lll/editorial-board"           component={Dsllleditorialboard}/>
                         <Route exact path="/lll/paper-submission"          component={Dslllarticlesubmit}/> 
                         <Route exact path="/lll/current-issue"              component={Lllcurrentissue}/>   
                          
                         <Route exact path="/lll/archives/volume:volume/issue:issue"    component={Lllarchiveslist}/>
                        {/* <Route exact path="/lll/archives/volume1/issue1"    component={Lllarchiveslist}/> 
                          <Route exact path="/lll/archives/volume1/issue2"    component={Lllarchiveslist2}/>  
                         <Route exact path="/lll/archives/volume1/issue3"    component={Lllarchiveslist3}/>   */}
                         <Route exact path="/lll/archives"                   component={Lanarchives}/>     
                         <Route exact path="/lll/topics"                    component={Dsllltopics}/>   
                         {/* <Route exact path="/lll/test"                      component={Dsllltopics}/> */}
                         <Route exact path="/lll/article-processing-charge"                       component={Dslllapc}/>                                      
                         <Route exact path="/lll/:id"                       component={Dslllarticledetails}/>
                         
                         

                        {/* -------------------Multidisciplinary--------------------------- */}
                         
                        <Route exact path="/dsm" component={Dsdsmaimandscope}/>
                         <Route exact path="/dsm/editorial-board" component={Dsdsmeditorialboard}/>
                         <Route exact path="/dsm/paper-submission" component={Dsdsmarticlesubmit}/> 
                         <Route exact path="/dsm/current-issue" component={Dsmcurrentissue}/>   
                         {/* <Route exact path="/dsm/archives/volume1/issue1" component={Dsmarchiveslist}/>            */}
                         <Route exact path="/dsm/archives/volume:volume/issue:issue" component={Dsmarchiveslist}/>   
                         <Route exact path="/dsm/archives" component={Dsmarchives}/>                      
                         <Route exact path="/dsm/topics" component={Dsdsmtopic}/>                        
                         <Route exact path="/dsm/apc" component={Dsdsmapc}/> 
                         <Route exact path="/dsm/:id" component={Dsmarticledetails}/> 

                          {/* ------------------- Modeling and Simulation--------------------------- */}
                         
                         <Route exact path="/ms" component={Dsdsmsaimandscope}/>
                         <Route exact path="/ms/editorial-board" component={Dsdsmseditorialboard}/>
                         <Route exact path="/ms/paper-submission" component={Dsdsmsarticlesubmit}/> 
                         <Route exact path="/ms/current-issue" component={Dsmscurrentissue}/>   
                         <Route exact path="/ms/archives/volume:volume/issue:issue" component={Dsmsarchiveslist}/>
                         {/* <Route exact path="/ms/archives/volume1/issue1" component={Dsmsarchiveslist}/>            */}
                         <Route exact path="/ms/archives" component={Dsmsarchives}/>                      
                         <Route exact path="/ms/topics" component={Dsdsmstopic}/>                        
                         <Route exact path="/ms/apc" component={Dsdsmsapc}/> 
                         <Route exact path="/ms/:id" component={Dsmsarticledetails}/> 


                        {/* ------------------- Reviews of Commerce and Economics--------------------------- */}

                            <Route exact path="/ce" component={Dsdsceaimandscope}/>
                         <Route exact path="/ce/editorial-board" component={Dsdsceeditorialboard}/>
                         <Route exact path="/ce/paper-submission" component={Dsdscearticlesubmit}/> 
                         <Route exact path="/ce/current-issue" component={Dscecurrentissue}/>   
                         <Route exact path="/ce/archives/volume1/issue1" component={Dscearchiveslist}/>           
                         <Route exact path="/ce/archives" component={Dscearchives}/>                      
                         <Route exact path="/ce/topics" component={Dsdscetopic}/>                        
                         <Route exact path="/ce/apc" component={Dsdsceapc}/> 
                         <Route exact path="/ce/:id" component={Dsdscearticledetails}/> 


                         {/* ------------------- Artificial Intelligence and Robotics--------------------------- */}

                        <Route exact path="/air" component={Dsdsairaimandscope}/>
                         <Route exact path="/air/editorial-board" component={Dsdsaireditorialboard}/>
                         <Route exact path="/air/paper-submission" component={Dsdsairarticlesubmit}/> 
                         <Route exact path="/air/current-issue" component={Dsaircurrentissue}/>   
                         <Route exact path="/air/archives" component={Dsairarchives}/>                      
                         <Route exact path="/air/topics" component={Dsdsairtopic}/>                        
                         <Route exact path="/air/apc" component={Dsdsairapc}/> 
                         <Route exact path="/air/archives/volume:volume/issue:issue" component={Dsairarchiveslist}/>   
                         {/* <Route exact path="/air/archives/volume1/issue1" component={Dsairarchiveslist}/>   
                         <Route exact path="/air/archives/volume1/issue2" component={Dsdsairarchiveslist2}/>   
                         <Route exact path="/air/archives/volume2/issue1" component={Dsdsairarchiveslist3}/>   */}
                         <Route exact path="/air/:id" component={Dsdsairarticledetails}/>        
                
                         
                         {/* ------------------- Cyber Security--------------------------- */}

                       <Route exact path="/cys" component={Dsdscyaimandscope}/>
                       <Route exact path="/cys/editorial-board" component={Dsdscyeditorialboard}/>
                       <Route exact path="/cys/paper-submission" component={Dsdscyarticlesubmit}/> 
                       <Route exact path="/cys/current-issue" component={Dscycurrentissue}/>   
                       <Route exact path="/cys/archives/volume:volume/issue:issue" component={Dscyarchiveslist}/>           
                       {/* <Route exact path="/cys/archives/volume1/issue1" component={Dscyarchiveslist}/>   */}
                       <Route exact path="/cys/archives" component={Dscyarchives}/>                      
                       <Route exact path="/cys/topics" component={Dsdscytopic}/>                        
                       <Route exact path="/cys/apc" component={Dsdscyapc}/> 
                       <Route exact path="/cys/:id" component={Dsdscysarticledetails}/>

                        {/* ------------------- Review of Lifce Sciences--------------------------- */}

                       <Route exact path="/rls" component={Dsdsrlsaimandscope}/>
                       <Route exact path="/rls/editorial-board" component={Dsdsrlseditorialboard}/>
                       <Route exact path="/rls/paper-submission" component={Dsdsrlsarticlesubmit}/> 
                       <Route exact path="/rls/current-issue" component={ Dsrlscurrentissue}/>   
                       <Route exact path="/rls/archives/volume:volume/issue:issue" component={Dsrlsarchiveslist}/>  
                       {/* <Route exact path="/rls/archives/volume1/issue1" component={Dsrlsarchiveslist}/>            */}
                       <Route exact path="/rls/archives" component={Dsrlsarchives}/>                      
                       <Route exact path="/rls/topics" component={Dsdsrlstopic}/>                        
                       <Route exact path="/rls/apc" component={Dsdsrlsapc}/> 
                       <Route exact path="/rls/:id" component={Dsdsrlsarticledetails}/>



                         {/* <Route exact path="/journal-cont" component={Journalcont}/> */}
                         
                         <Route exact path="/about-journal" component={Aboutjournal}/>
                          
                         <Route exact path="/specialissues" component={Specialissues}/>
                      
                         <Route exact path="/archives-view" component={Archivesview}/>

                         {/* <Route exact path="/article/:id" component={Articledetails}/> */}

                        <Route exact path="/rprocess" component={Rprocess}/>
                      
                        <Route exact path="/abstract-and-indexing" component={Indexing}/>
                       
                        <Route exact path="/open-access" component={OpenAccess}/>
                     
                        

                        
                        
                        <Route exact path="/conferences" component={Conferences}/>

                        <Route exact path="/conferencelist" component={Conferencelist}/>
                        
                        <Route exact path="/conferenceproposal" component={Conferenceproposal}/>

                        <Route exact path="/specialissueproposal" component={Specialissueproposal}/>

                        <Route exact path="/specialissuelist" component={Specialissuelist}/>

                        <Route exact path="/eventproposal" component={Eventproposal}/>

                        <Route exact path="/excercise" component={Excercise}/>

                        <Route exact path="/submission" component={Submission}/>

                        <Route exact path="/contact-us" component={Contactus}/>

                        <Route exact path="/faq" component={FAQ}/>

                        <Route exact path="/test" component={Test}/>

                        <Route component={Notfound404}/>

                      
                             
                     </Switch>
                 <Footer/>
                 </BrowserRouter> 
            </div>
        )
    }
}

export default Home;