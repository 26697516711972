import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Home from './Home';
import Auth from './components/Auth/Auth';
import "./App.css";
import FrontLayout from "./layout/Frontlayout";
import LoginLayout from "./layout/Loginlayout";
import AdminLayout from "./layout/Adminlayout";
import Dashboard from './pages/admin/Dashboard';


const FrontLayoutRoute = ({ component: Component, layout: FrontLayout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={Props => (
        <FrontLayout>
          <Component {...Props} />
        </FrontLayout>
      )}
    />
  );
};

const LoginLayoutRoute = ({ component: Component, layout: LoginLayout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={Props => (
        <LoginLayout>
          <Component {...Props} />
        </LoginLayout>
      )}
    />
  );
};

const AdminLayoutRoute = ({ component: Component, layout: AdminLayout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={Props => (
        <AdminLayout>
          <Component {...Props} />
        </AdminLayout>
      )}
    />
  );
};


class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            {/* Front Layout */}
            <FrontLayoutRoute path="/home" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/journals" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/for-authors" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/for-authors/author-guideline" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/for-authors/submitting-a-manuscript" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/for-authors/openaccess-author" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/for-authors/review-process" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/for-authors/conflicts_of_interest" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/for-authors/what_is_apc" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/for-reviewers" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/for-editors" layout={FrontLayout} component={Home} />

            {/* Digital Science and Technology */}
            <FrontLayoutRoute path="/dst" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/dst/editorial-board" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/dst/paper-submission" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/dst/archives" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/dst/volume2/issue1" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/dst/archives/volume:volume/issue:issue" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/dst/archives/volume1/issue1" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/dst/archives/volume2/issue1" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/dst/:id" layout={FrontLayout} component={Home} />
            {/* <FrontLayoutRoute path="/dst/archives/volume1/issue2/:id"  layout={FrontLayout} component={Home}/>
                    <FrontLayoutRoute path="/dst/archives/volume2/issue1/:id"  layout={FrontLayout} component={Home}/> */}
            <FrontLayoutRoute path="/dst/topics" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/dst/apc" layout={FrontLayout} component={Home} />

            {/* Language, Ligustics and Literature */}
            {/* <FrontLayoutRoute  path="/journals/lll"                                   layout={FrontLayout} component={Home} />
                    <FrontLayoutRoute  path="/journals/lll/editorial-board"                   layout={FrontLayout} component={Home} />
                    <FrontLayoutRoute  path="/journals/lll/article-submit"                    layout={FrontLayout} component={Home} />
                    <FrontLayoutRoute  path="/journals/lll/archive"                           layout={FrontLayout} component={Home} />
                    <FrontLayoutRoute  path="/journals/lll/volume1/issue1"                    layout={FrontLayout} component={Home} />
                    <FrontLayoutRoute  path="/journals/lll/archive/volume1/issue1/:id"        layout={FrontLayout} component={Home}/>
                    <FrontLayoutRoute  path="/journals/lll/archive/volume1/issue1"           layout={FrontLayout} component={Home} />
                    <FrontLayoutRoute  path="/journals/lll/topics"                            layout={FrontLayout} component={Home} />
                    <FrontLayoutRoute  path="/journals/lll/apc"                               layout={FrontLayout} component={Home} /> */}

            <FrontLayoutRoute path="/lll" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/lll/editorial-board" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/lll/article-submit" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/lll/archives" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/lll/current-issue" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/lll/:id" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/lll/archives/volume:volume/issue:issue" layout={FrontLayout} component={Home} />
            {/* <FrontLayoutRoute  path="/lll/archives/volume1/issue1"            layout={FrontLayout} component={Home} />
                    <FrontLayoutRoute  path="/lll/archives/volume1/issue2"            layout={FrontLayout} component={Home} />
                    <FrontLayoutRoute  path="/lll/archives/volume1/issue3"            layout={FrontLayout} component={Home} /> */}
            <FrontLayoutRoute path="/lll/topics" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/lll/article-processing-charge" layout={FrontLayout} component={Home} />




            {/* Multidisciplinary */}
            <FrontLayoutRoute path="/dsm" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/dsm/editorial-board" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/dsm/article-submit" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/dsm/archives" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/dsm/current-issue" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/dsm/archives/volume1/issue1" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/dsm/topics" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/dsm/apc" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/dsm/:id" layout={FrontLayout} component={Home} />

            {/* Modelling and simulation */}
            <FrontLayoutRoute path="/ms" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/ms/editorial-board" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/ms/article-submit" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/ms/archives" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/ms/current-issue" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/ms/archives/volume1/issue1" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/ms/topics" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/ms/apc" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/ms/:id" layout={FrontLayout} component={Home} />

            {/* Reviews of Commerce and Economics */}
            <FrontLayoutRoute path="/ce" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/ce/editorial-board" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/ce/article-submit" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/ce/archives" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/ce/current-issue" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/ce/archives/volume1/issue1" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/ce/topics" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/ce/apc" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/ce/:id" layout={FrontLayout} component={Home} />

            {/* Artificial Intelligence and Robotics */}
            <FrontLayoutRoute path="/air" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/air/editorial-board" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/air/article-submit" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/air/archives" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/air/current-issue" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/air/:id" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/air/archives/volume:volume/issue:issue" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/air/archives/volume1/issue1" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/air/archives/volume1/issue2" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/air/topics" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/air/apc" layout={FrontLayout} component={Home} />


            {/* Cyber Security */}
            <FrontLayoutRoute path="/cys" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/cys/editorial-board" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/cys/article-submit" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/cys/archives" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/cys/current-issue" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/cys/:id" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/cys/archives/volume:volume/issue:issue" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/cys/topics" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/cys/apc" layout={FrontLayout} component={Home} />

            {/* Research in Life Science */}
            <FrontLayoutRoute path="/rls" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/rls/editorial-board" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/rls/article-submit" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/rls/archives" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/rls/current-issue" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/rls/archives/volume1/issue1" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/rls/topics" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/rls/apc" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/rls/:id" layout={FrontLayout} component={Home} />


            <FrontLayoutRoute path="/for-authors/publication-ethics" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/forauthors" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/article/:id" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/search" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/about-us" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/abstract-and-indexing" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/open-access" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/for-authors/licensing-policy" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/for-authors/copyright-infringement" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/faq" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/contact-us" layout={FrontLayout} component={Home} />
            <FrontLayoutRoute path="/test" layout={FrontLayout} component={Home} />

            {/* Login Layout */}
            <LoginLayoutRoute exact path="/auth" layout={LoginLayout} component={Auth} />

            {/* Admin Layout */}
            <AdminLayoutRoute exact path="/dashboard" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/currentissue" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dviewjournal" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dcreatejournal" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dsstviewdata" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dsjstupdatedata" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dcurissue" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dstdata/:id" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/issuelist" layout={AdminLayout} component={Dashboard} />

            {/* Language, Ligustics and Literature  */}
            <AdminLayoutRoute exact path="/dlll/dslllcreatejournal" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dlll/dslllviewjournal" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dlll/dslllviewdata" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dlll/dsllldata/:id" layout={AdminLayout} component={Dashboard} />

            {/* DS Journal of Artificial Intelligence and Robotics  */}
            <AdminLayoutRoute exact path="/dashboard/dsair/dsaircreatejournal" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dsair/dsairviewjournal" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dsair/dsairviewdata" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dsair/dsairdata/:id" layout={AdminLayout} component={Dashboard} />

            {/* DS Journal of Artificial Intelligence and Robotics  */}
            <AdminLayoutRoute exact path="/dashboard/dscys/dscyscreatejournal" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dscys/dscysviewjournal" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dscys/dscysviewdata" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dscys/dscysdata/:id" layout={AdminLayout} component={Dashboard} />

            {/* DS Journal of Modeling and Simulation  */}
            <AdminLayoutRoute exact path="/dashboard/dsms/dsmscreatejournal" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dsms/dsmsviewjournal" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dsms/dsmsviewdata" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dsms/dsmsdata/:id" layout={AdminLayout} component={Dashboard} />

            {/* DS Journal of Multidisciplinary  */}
            <AdminLayoutRoute exact path="/dashboard/dsm/dsmcreatejournal" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dsm/dsmviewjournal" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dsm/dsmviewdata" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dsm/dsmdata/:id" layout={AdminLayout} component={Dashboard} />


            {/* DS Review of Commerce and Economics  */}
            <AdminLayoutRoute exact path="/dashboard/dsce/dscecreatejournal" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dsce/dsceviewjournal" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dsce/dsceviewdata" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dsce/dscedata/:id" layout={AdminLayout} component={Dashboard} />

            {/* DS Research of Life Sciences  */}
            <AdminLayoutRoute exact path="/dashboard/dsrls/dsrlscreatejournal" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dsrls/dsrlsviewjournal" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dsrls/dsrlsviewdata" layout={AdminLayout} component={Dashboard} />
            <AdminLayoutRoute exact path="/dashboard/dsrls/dsrlsdata/:id" layout={AdminLayout} component={Dashboard} />

          </Switch>
        </BrowserRouter>
      </div>
    )
  }
}






export default App;