import React from 'react'
import {Link} from 'react-router-dom';
import '../pages/Forauthors.css';


function Rprocess() {
  return (
    <>  
    <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center">
      <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <h2>Review Process</h2>
                <Link to="/" className="btn-get-started ">Read More</Link>
              </div>
          </div>
        </div>
      </div>
      </div>
    </section>

<section className="breadcrumbs">
<div className="container">
<div className="d-flex justify-content-between align-items-center">
<h2>For Authors</h2>
<ol>
  <li><Link to="/">Home</Link></li>
  <li>For Authors</li>
</ol>
</div>
</div>
</section>

<main id="main">
<section className="blog" id="blog">
<div className="container">
    <div className="row">
      <div className="col-lg-4">
        <div className="list-group">
          <Link to="/rprocess" className="list-group-item list-group-item-action btn-submit active">Index in this page</Link>
          <Link to="#rprocess" className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>Review Process</Link>
          <Link to="#copyinfri" className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i> Copyright Infringement</Link>
          <Link to="#authorins" className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>Author Instruction</Link>
          <Link to="#publictioneth" className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i><span style={{overflowWrap:'break-word'}}> Publishing policy</span></Link>
        </div>
         <div className="abtlistgrp">
         <div className="card">
          <ul className="list-group list-group-flush">
            <Link to="/"  className="list-group-item btn-submit"><i className="bi bi-file-arrow-up"></i>Submit</Link>
              <Link to="/"  className="list-group-item"><i className="bi bi-clipboard-data"></i>Author Guidelines</Link>
                <Link to="/" className="list-group-item"><i className="bi bi-people-fill"></i>Editorial Board</Link>
                <Link to="/" className="list-group-item"><i className="bi bi-server"></i>Databases and Indexing</Link>
                <li className="list-group-item"><i className="bi bi-box-arrow-in-right"></i>Signup for content alerts
                  <button className="btn btn-outline-dark float-center">SignUp</button></li>
          </ul>
        </div>
      </div>
      </div>
        <div className="col-lg-8">
          <h3>For Authors</h3>
         <h6 id="#rprocess">Review Process</h6>
         <p> Dream Science follows quick evaluation method. In order to substantially reduce the time to publication as compared with traditional 
           journals. Submitted article result will announce as soon as possible. Editorial board members 
           will make the final decision. Submit the original article without any plagiarism and copyright 
           material.</p>
         <div className="list-group mt-3 mb-3">
          <Link to="/">
            <i className="bi bi-chevron-double-right"></i>Review Process</Link>
          </div>
         <h6 id="#copyinfri">Copyright Infringement</h6>
         <p> Any claims of copyright infringement should be addressed to the Editor at 
           editor@dsjournal.org bearing the subject line "Copyright Infringement".</p>
           <div className="list-group mt-3 mb-3">
          <Link to="/" className="list-group-item list-group-item-action">
            <i className="bi bi-chevron-double-right"></i>Copyright Infringement</Link>
          </div>
           <h6 id="#authorins">Author Instruction</h6>
         <p>  Papers must be submitted on the understanding that they have not been published elsewhere
            and are not currently under consideration by any other publisher. The submitting author is 
            responsible for ensuring that the article’s publication has been approved by all the other 
            coauthors. It is also the authors’ responsibility to ensure that the articles emanating from 
            a particular institution are submitted with   the approval of the necessary institution. 
            Submit it to <Link to="/">editor@dsjournal.com</Link></p>
            <div className="list-group mt-3 mb-3">
          <Link to="/" className="list-group-item list-group-item-action">
            <i className="bi bi-chevron-double-right"></i>Author Instruction</Link>
          </div>
            <h6 id="#publictioneth">Publiction Ethics</h6>
         <p>Guidance on our ethical standards for publication, information on our policies regarding 
           research data and reporting guidelines, and details on the distribution and dissemination of 
           research we publish can be found in the resources below. </p>
         <div className="list-group  mt-3 mb-3">
          <Link to="/" className="list-group-item list-group-item-action">
            <i className="bi bi-chevron-double-right"></i>Publication ethics</Link>
          </div>
        </div>

    </div>
</div>

</section>


</main>

    </>
  )
}

export default Rprocess;