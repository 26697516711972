import React,{useEffect} from 'react';
import {Link} from 'react-router-dom';
import '../pages/Forauthors.css';
import Accordion from './Accordion';
import {HashLink} from 'react-router-hash-link';
import { Helmet } from 'react-helmet';


function Indexing() {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);
  const accordionData = [{
    content: <div>
      <HashLink smooth to="#head1" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publishing on dsjournals</HashLink>
      <HashLink smooth to="#head2" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Search Engines</HashLink>
      <HashLink smooth to="#head3" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> A &amp; I Databases</HashLink>
                  
</div> 
},
  ];
  return (
    <>  
    <Helmet>
    <title>Abstract and Indexing | DS Journals | Indexing, Peer-Review and Open Access</title>
    <link rel="canonical" href="https://dsjournals.com/abstract-and-indexing"/>
    <meta name="title" content="Abstract and Indexing | DS Journals | Indexing, Peer-Review and Open Access "/>
    <meta name="description" content="DS Journals provides comprehensive abstract and indexing services for scholarly publications with peer-review and open access options."/>
    <meta name="keywords" content="scopus indexed journal, ugc approved journal, ugc listed journal, crossref indexed journals, doi indexed journals, sci journals, clarivate journals, scimago journals, doaj journals, index Copernicus journals"/>
    <meta property="og:type" content="Website"/>
    <meta property="og:site_name" content="Dream Science"/>
    <meta property="og:url" content="https://dsjournals.com/abstract-and-indexing"/>
    <meta property="og:title" content="Abstract and Indexing | DS Journals | Indexing, Peer-Review and Open Access"/>
    <meta property="og:description" content="DS Journals provides comprehensive abstract and indexing services for scholarly publications with peer-review and open access options."/>
    <meta property="og:image" content="image url"/>
    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:title" content="Abstract and Indexing | DS Journals | Indexing, Peer-Review and Open Access"/>
    <meta name="twitter:description" content="DS Journals provides comprehensive abstract and indexing services for scholarly publications with peer-review and open access options."/>
    <meta name="twitter:site" content="@DreamScience4"/>
    <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
    <meta name="twitter:image" content="image url"/>
    <meta name="robots" content="index, follow"/>
    </Helmet>
    <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
              <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <h2>Indexing</h2>
                      </div>
                  </div>
                </div>
              </div>
              </div>
            </section>
  
  <section className="breadcrumbs">
    <div className="container">
      <div className="d-flex justify-content-between align-items-center">
        {/* <h2>Abstracting and Indexing (A &amp; I)</h2> */}
        <ol>
          <li><Link to="/home">Home</Link></li>
          <li>Abstracting and Indexing</li>
        </ol>
      </div>
    </div>
  </section>

  <main id="main">
    <section className="blog" id="blog">
        <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="list-group">
                <div className="accordion">
                      {accordionData.map(({content }) => (
                        <Accordion content={content} />
                    ))}
                  </div>
                  
                  
                </div>
              </div>
                <div className="col-lg-9">
                  <h3>Abstracting and Indexing</h3>
                    <p style={{textAlign:"justify"}}> The importance of discoverability: discovery layers, ‘abstracting and indexing,’ and open 
                     repositories.</p>
                   <p style={{textAlign:"justify"}}> Dream Science’s publications appear in a wide range of abstracting and indexing databases. 
                    Different journals are listed in different databases. For a detailed breakdown of journals 
                    per repository, see our dedicated abstracting and indexing page of respective journal.</p>
                    <p style={{textAlign:"justify"}}>Post-publication distribution of your research is one of the most important things we do at 
                    Dream Science. If your research is not discoverable, it cannot advance science, medicine, or 
                    society. A lack of discoverability has a negative impact on readership and future citations.</p>
                
                 <p style={{textAlign:"justify"}}>There are many ways in which we disseminate your research out to potential readers:</p>
                  <h6> Publishing on dsjournals</h6>
                    <p  id='head1' style={{textAlign:"justify"}}>All articles published in our journals are made available on dsjournal.com. They can be read, downloaded,
                      and shared by any visitor to the site.</p>
                      <p style={{textAlign:"justify"}}>Visitors may discover articles by browsing the contents of a journal directly, from the results of a 
                      search engine, or from a shared link.</p>
                      <h6>Search Engines</h6>
                      <p id='head2' style={{textAlign:"justify"}}>Dream Science’s article pages are optimized to perform well in search results. This makes your 
                        research available to readers from a wider range of backgrounds. Despite the existence of specialized 
                        research tools, readers still rely primarily on search engines like Google to discover relevant 
                        articles.</p>
                      <p style={{textAlign:"justify"}}>As our journals are Open Access publications, any visitor arriving at dsjournal.com can immediately 
                          access and read your work.</p>
                      <h6>Abstracting and Indexing Databases</h6>
                      <p id='head3' style={{textAlign:"justify"}}>Many researchers use abstract databases as curated discovery tools. These could be general 
                          multi-discipline databases such as Google scholar, Scopus or Web of Science, where coverage is 
                          broad but a selection process is employed based on journal quality (the main index of Web of Science 
                          contains only about 9,000 of the approximately 28,000 active scholarly peer-reviewed English-language 
                          journals). There are also subject-specific databases such as Embase (biomedical research), 
                          INSPEC (physics and engineering) or MEDLINE (medicine). These databases select journals based on quality 
                          and compatibility with their respective scopes.</p>
                      <p style={{textAlign:"justify"}}>Dream Science trying to works with many abstracting and indexing databases to ensure that our 
                          journals are included in both the general indices and the relevant subject-specific databases.</p>






                    
                    
                </div>
        
            </div>
        </div>
    </section>
  </main>
            </>
  )
}
export default Indexing;