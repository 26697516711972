import React ,{useEffect} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {Helmet} from 'react-helmet'
import Accordion from '../../components/Dscse/Accordion';
// import Accordion from '../components/Accordion';
import { HashLink } from 'react-router-hash-link';
import './Editorialboard.css';
import Dsjstarticletemplate from '../../downloads/DS-DST-Article_Template.doc';
import Dsjstcopyrightform from '../../downloads/DS-DST-Copyright_Form.docx';

const Aboutjournal = () => {

  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  const accordionData = [{
    content: <div>
        <HashLink smooth to="/dst" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Aim and Scope</HashLink>
        <NavLink to="/dst/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
        <NavLink to="/dst/paper-submission"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
        <NavLink to="/dst/current-issue"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/dst/archives"           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archive</NavLink>
            <NavLink to="/dst/topics"                           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target= "_blank"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target= "_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/dst/apc"               className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
        {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
        <a href={Dsjstarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
        <a href={Dsjstcopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
</div> 
},
  ];
    return (
        <>
        <Helmet>
        <title>Editorial Board | DS Journal of Digital Science and Technology</title>
        <link rel="canonical" href="https://dsjournals.com/dst/editorial-board"/>
        <meta name="title" content="Editorial Board | DS Journal of Digital Science and Technology"/>
        <meta name="description" content="Meet the distinguished editorial board of Digital Science and Technology, composed of experts in digital science and related fields from around the world."/>
        <meta name="keywords" content="Editorial Board, International Journal, computer science, computer trends, and computer technology, Peer-Reviewed Journal, Scientific Research, Academic Publishing, Scholarly Articles, Journal Editors, Expertise, Quality Control."/>
        <meta property="og:type" content="Website"/>
        <meta property="og:site_name" content="Dream Science"/>
        <meta property="og:url" content="https://dsjournals.com/dst/editorial-board"/>
        <meta property="og:title" content="Editorial Board | DS Journal of Digital Science and Technology"/>
        <meta property="og:description" content="Meet the distinguished editorial board of Digital Science and Technology, composed of experts in digital science and related fields from around the world."/>
        <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Digital-Science-and-Technology.webp"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:description" content="Meet the distinguished editorial board of Digital Science and Technology, composed of experts in digital science and related fields from around the world."/>
        <meta name="twitter:site" content="@DreamScience4"/>
        <meta name="twitter:title" content="Editorial Board | DS Journal of Digital Science and Technology"/>
        <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
        <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Digital-Science-and-Technology.webp"/>
        <meta name="robots" content="index, follow"/>
        </Helmet>

        <section id="hero-no-slide-dst" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
          <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <div className="row justify-content-center">
                  {/* <div className="col-xl-8">
                    <h1>Editorial Board</h1>
                  </div> */}

                        <div className="col-lg-12">
                            <h1 style={{fontSize:"25px"}}>DS Journal of Digital Science and Technology ( DS-DST )</h1>
                            {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                        </div>
              </div>
            </div>
          </div>
          </div>
        </section>
        
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              {/* <h2>Editor Spotlight</h2> */}
              <ol>
              <li><Link to="/home">Home</Link></li>
                <li><Link to="/journals">Journals</Link></li>
                <li><Link to="/dst">DS-DST</Link></li>
                <li>Editorial Board</li>
              </ol>
            </div>
          </div>
        </section>
             <main id="main">
    <section className="blog" id="blog">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 mb-3">
                <div className="list-group">
                  <div className="accordion">
                        {accordionData.map(({content }) => (
                          <Accordion content={content} />
                      ))}
                  </div>
                </div>

                  <div className="abtlistgrp">
                 {/* <div className="card">
                  <ul className="list-group list-group-flush">
                    <Link to="/submission"  className="list-group-item btn-submit"><i className="bi bi-file-arrow-up"></i>Submit</Link>
                      <Link to="/journalprofile"  className="list-group-item"><i className="bi bi-book"></i>Journal Profile</Link>
                        <Link to="/specialissues" className="list-group-item"><i className="bi bi-award"></i>Special Issues</Link>

                  </ul>
                </div> */}
              </div>
                </div>
                <div id='head1' className="col-lg-9">
                 <h3>Editorial Board</h3>
                 <div className='row'>
                  <h5 className='text-left'>
                    <span style={{ backgroundColor: "#94c045",
    color:"#fff",
    lineHeight: "43px",
    padding: "5px 10px",
    whiteSpace:"pre-wrap"}}>Editor in Chief</span></h5>
                  <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}>Prof. Dr. S. Nallusamy,</div> <p style={{textAlign:"left",fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-justify text-muted">
                      Department of ACEE, Jadavpur University, <br/>
                      Kolkata - 700 032, India.
                      <br/>snallusamy@jadavpuruniversity.in<br/>
                    <a href='http://www.jaduniv.edu.in/profile.php?uid=1221' target="_balnk">Profile Link</a> </p>
                  </div>
                 
                
                 </div>

                 <div className='row'>
                    <h5 style={{textAlign:"left"}}><span style={{ backgroundColor: "#94c045",
    color:"#fff",
    lineHeight: "43px",
    padding: "5px 10px",
    whiteSpace:"pre-wrap"}}>Associate Editor</span></h5>
                 <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}> Dr. Zaira Zaman Chowdhury, </div>	
                  <p  style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted"> 
                  Department of Nanotechnology,<br/> Catalysis Research Centre (Nanocat),<br/> Universiti Malaya, Malaysia.
                  <br/>dr.zaira.chowdhury@um.edu.my<br/>
                  <a href='https://umexpert.um.edu.my/dr-zaira-chowdhury.html' target="_balnk">Profile Link</a></p>
                  </div>
                 </div>
    

                 <div className='row'>
                 <h5 className='text-left'><span style={{ backgroundColor: "#94c045",
    color:"#fff",
    lineHeight: "43px",
    padding: "5px 10px",
    whiteSpace:"pre-wrap"}}>Editorial Board Members</span></h5>
                  <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}>Prof.Dr. Rasheed Mustafa, </div>	
                  <p style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted">Department of CSE,<br/> University of Chittagong,<br/> Bangladesh.
                  <br/>rashed.m@cu.ac.bd<br/>
                  <a href='https://www.cu.ac.bd/public_profile/index.php?ein=4792' target="_balnk">Profile Link</a></p>
                  </div>
                  <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}> Prof.Dr.Nihal Fayez Fahmy Gomaa Areed, </div>	<p style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted"> Department Electronics Communication Engineering,<br/> Mansoura University, Mansoura,<br/> Egypt.
                  <br/>nahoolaf@mans.edu.eg<br/>
                  <a href='https://mymans.mans.edu.eg/cv/90f2b26a-0c10-4e8b-8507-16585d372137/3' target="_balnk">Profile Link</a></p>
                  </div>
              
                 </div>

                 <div className='row'>
                  <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}>Dr. Shridhar Allagi, </div>	
                  <p style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted">Department of CSE,<br/> KLE Institute of Technology,<br/> Hubballi, India.<br/>shridharallagi@kleit.ac.in
                  <br/><a href='https://kleit.ac.in/computer-science-engineering/' target="_balnk">Profile Link 1 </a>
                  | <a href='https://kleit.ac.in/wp-content/uploads/2022/05/SHRIDHAR_ALLAGI_PROFILE.pdf' target="_balnk">Profile Link 2</a></p>
                  </div>
                 </div>
               

                    {/* <h5 className='text-center'>Editor in Chief</h5>
                    <li className='text-center'>Prof. Dr. S. Nallusamy, Ph.D (Engg.) , <span style={{textAlign:"justify"}} className="text-justify text-muted">
                      Department of ACEE, Jadavpur University, Kolkata - 700 032, India.</span></li><br/>
                    <h6>Associate Editors</h6>
                    <li> Dr.Zaira Zaman Chowdhury, <span style={{textAlign:"justify"}} className="text-justify text-wrap"> Senior Lecturer,Department of Nanotechnology, Catalysis Research Centre (Nanocat), Universiti Malaya, Malaysia.</span></li>	
                   
                    <li>Prof.Dr. Rasheed Mustafa, <span style={{textAlign:"justify"}} className="text-justify">Department of Computer Science and Engineering, University of Chittagong, Bangladesh.</span></li>	
                   
                    <li>Dr.Dipankar Pal, <span style={{textAlign:"justify"}} className="text-justify">Department of Chemical Engineering, Indian Institute of Petroleum &amp; Energy, Ministry of Petroleum and Natural Gas, India.</span> </li>	
                   
                    <li>Dr. R. Surendiran, <span style={{textAlign:"justify"}} className="text-justify">School of Information Science, Annai College of Arts &amp; Science, Kumbakonam, India.</span></li>	<br/>
                    <h6>Editorial Board Members</h6>
                    <li>Prof.Dr.Nihal Fayez Fahmy Gomaa Areed, <span>Department Electronics Communication Engineering, Mansoura University, Mansoura, Egypt. Email : nahoolaf@mans.edu.eg, nahoolaf@yahoo.com, nfayez@zewailcity.edu.eg, 02 22680231, 01067222945, 01067445353</span></li>
                    <li>Dr. K. Raghunath, <span>Assistant Professor, Department of Mathematics, Bheema Institute of Technology &amp; Sciences, Adoni, AP, India. drkraghunath@gmail.com , kraghunath0821@yahoo.com, Mobile: +91-9440737867</span></li>	
                    <li>Dr. Z. Faizal khan,<span>Department of Computer and Network Engineering, College of Engineering, Shaqra University, Saudi Arabia.</span></li>	
                    <li>Dr. Mamta Pandey,<span>Associate Professor, Aditya Engineering College, Surempalem,AP, India. Phone : 07898225030/07067510672. Email: mamta.pandey07@gmail.com, mamta.pandeycse@gmail.com.</span></li>	
                    <li>Dr.S.Gopalakrishnan,<span>Associate professor, Department of Electronics Communication Engineering, Siddhartha Institute of Technology &amp; Sciences, Hyderabad, India. Mobile : 91 + 7010935263,91 + 9864862411, drsgk85@gmail.com</span></li>	
                    <li>Dr. SHRIDHAR ALLAGI,<span>Associate Professor, Department of Computer Science and Engineering, KLE Institute of Technology, Hubballi, India. Mobile : 8951824964.shridharallagi@kleit.ac.in
</span></li>	 */}
                     

                      
                </div>
              
            </div>
        </div>

    </section>


  </main>
        </>
    )
}
export default Aboutjournal;