import React,{useEffect} from 'react';
import './Dsdsmaimandscope.css';
import { NavLink,Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dsm/Accordion';
import Ddsmarticletemplate from '../../downloads/DSM-Article_Template.docx';
import Ddsmcopyrightform from '../../downloads/DSM-Copyright_Form.docx';
import Journaltitle from '../../pages/dsdsm/data.json';

const Dsdsmsaimandscope = () => {
    useEffect(() => { 
        window.scrollTo(0,0);
    }, []);
    const accordionData = [{
        content: <div>
             <NavLink smooth to="/dsm" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</NavLink>
            <NavLink to="/dsm/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>   Editorial Board</NavLink>
            <NavLink to="/dsm/paper-submission"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/dsm/current-issue"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/dsm/archives"           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/dsm/topics"                           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target= "_blank"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target= "_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/dsm/apc"               className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
        {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
            <a href={Ddsmarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Ddsmcopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
    </div> 
    },
      ];
  return (

    <>
     <Helmet>
        <title>DS Journal of Multidisciplinary - DS Journals</title>
        <link rel="canonical" href="https://dsjournals.com/dsm"/>
        <meta name="title" content="DS Journal of Multidisciplinary - DS Journals"/>
        <meta name="description" content="DS Journal of Multidisciplinary (DSM) publishes original research and scholarly articles on a wide range of Edge Computing, Genomics and Internet of Medical Things, etc.,"/>
        <meta name="keywords" content="journal of multidisciplinary applied natural science, journal of multidisciplinary academic, journal of advanced multidisciplinary research, international journal of multidisciplinary approach and studies, international journal of multidisciplinary and current research, journal of multidisciplinary research and development, journal of multidisciplinary care, journal of multidisciplinary cases, journal of multidisciplinary cycle research, journal of multidisciplinary clinical case reports."/>
        <meta property="og:type" content="Website"/>
        <meta property="og:site_name" content="Dream Science"/>
        <meta property="og:url" content="https://dsjournals.com/dsm"/>
        <meta property="og:title" content="DS Journal of Multidisciplinary - DS Journals"/>
        <meta property="og:description" content="DS Journal of Multidisciplinary (DSM) publishes original research and scholarly articles on a wide range of Edge Computing, Genomics and Internet of Medical Things, etc.,"/>
        <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Multidisciplinary.webp"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="DS Journal of Multidisciplinary - DS Journals"/>
        <meta name="twitter:description" content="DS Journal of Multidisciplinary (DSM) publishes original research and scholarly articles on a wide range of Edge Computing, Genomics and Internet of Medical Things, etc.,"/>
        <meta name="twitter:site" content="@DreamScience4"/>
        <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
        <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Multidisciplinary.webp"/>
        <meta name="robots" content="index, follow"/>
        </Helmet>

        <section id="hero-no-slide-dsdsm" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
                <div className="col-xl-8">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                        {
                                Journaltitle.map(heading => {
                                  return(
                                    <h2 style={{fontSize:"25px"}}>{heading.title}</h2>     
                                  )
                                      
                                })
                            }
                            {/* <h2 style={{fontSize:"25px"}}>DS Journal of Multidisciplinary ( DSM )</h2> */}
                            {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="breadcrumbs">
        <div className="container">
            <div className="d-flex justify-content-between align-items-center">
                <ol>
                <li><NavLink to="/home">Home</NavLink></li>
                <li><NavLink to="/journals">Journals</NavLink></li>
                <li>DSM</li>
              </ol>
            </div>
        </div>
    </section>
    <main id="main">
<section className="blog" id="blog">
<div className="container">
<div className="row">
    <div className="col-lg-3 mb-3">
      <div className="list-group">
        <div className="accordion">
                        {accordionData.map(({content }) => (
                          <Accordion content={content} />
                      ))}
        </div>
      </div>
   </div>
    <div className="col-lg-9">
      <div id="jorunalcard">
         <div  className="card" style={{maxWidth:"950px"}}>
  <div className="row">
    <div className="col-lg-3 col-md-4">
      <img id="journalpfimg_p" src="assets/img/DS-Journal-of-Multidisciplinary-Bookcover.jpg" className="img-responsive center-block d-block mx-auto" alt="journalprofileimg"/>
    </div>
    <div className="col-lg-9 col-md-8">
      <div className="card-body">
        {/* <h6 className="card-title center">DS International Journal of Computer Science and Engineering ( DSCSE )</h6> */}
        
        <p class="card-text">
            <table className='table'>
                        <tr className="pt-1">
                            <th scope="row" className="col-md-4 align-top">Editor in Chief</th>
                            <td className="col-md-8 px-2"> Prof. Sr Dr. Mohd Saidin Misnan,<br />
                                                           Associate Professor,<br/>
                                                           Department of Quantity Surveying, <br />
                                                           Faculty of Built Environment and Surveying (FBES),
                                                           University of Technology Malaysia,<br />
                                                           Malaysia.
                            </td>
                        </tr>
                        <tr className="mt-2">
                            <th scope="row" className="col-md-4">ISSN (Online)</th>
                            <td className="col-md-8 px-2">3048-4561</td>
                        </tr>
                        <tr className="mt-2">
                            <th scope="row" className="col-md-4">Subject</th>
                            <td className="col-md-8 px-2">Multidisciplinary</td>
                        </tr>
                        <tr>
                            <th scope="row" className="col-md-4">Year of Starting</th>
                            <td className="col-md-8 px-2">2024</td>
                        </tr>
                        <tr>
                            <th scope="row" className="col-md-4">Publication Frequency</th>
                            <td className="col-md-8 px-2">4 Issue per Year</td>
                        </tr>
                        
                        <tr>
                            <th scope="row" className="col-md-4"> Language</th>
                            <td className="col-md-8 px-2">English</td>
                        </tr>
                        <tr>
                            <th scope="row" className="col-md-4">Paper Submission id</th>
                            {/* <td className="col-md-8 px-2">submit2dsm@gmail.com</td> */}
                            <td className="col-md-8 px-2">multi@dsjournals.com</td>
                        </tr>
                        <tr>
                            <th scope="row" className="col-md-4">Format of Publication</th>
                            <td className="col-md-8 px-2">online</td>
                        </tr>
                        <tr>
                            <th scope="row" className="col-md-4 align-top">Publication Fee</th>
                            <td className="col-md-8 px-2">Free of Cost Journal</td>
                        </tr>
                        <tr>
                            <th scope="row" className="col-md-4"> Publisher</th>
                            <td className="col-md-8 px-2">Dream Science</td>
                        </tr>
            </table>
        </p>
       
      </div>
    </div>
  </div>
</div>
</div>
<p style={{textAlign:'justify',fontWeight:"bold"}}>Aim and Scope</p>
<p style={{textAlign:'justify',textIndent:"50px"}}> DS Journal of Multidisciplinary (DSM) is an international, quarterly, peer-reviewed research and open-access journal that provides a locus for 
academicians and Scholastic people to publish various modes of research articles. The journal is fabricated to publish original research articles, technical notes, and state-of-art reviews from 
all areas of Engineering and Technology, Mathematics, Life Science, Medical Science, Geography, Chemistry, Accountancy, Economics, Computer Application, Marketing Management, Museology, 
Hotel Management, Nursing, Psychology, Arts and Humanities, Visual, performing and Fine Arts, Education, Philosophy, Political Science, Biology, Vector Molecular Biology, Fisheries, 
Immunobiology, Physics, Law/Criminology, Apiology, Anthropology, Architecture & Architectural Engineering, Mechatronics, Advertising, Pharmacy, Physical Education, Finance, Population Studies, 
Bioinformatics, Nanotechnology, History, Human Resources, Veterinary Sciences, Public Health, Microbiology, Earth and Planetary Sciences, Entrepreneurship, Taxonomical studies, Health Care, 
Industrial Relations, Nutraceuticals and so on.</p>
<p style={{textAlign:'justify',textIndent:"50px"}}>
DS Journal of Multidisciplinary is always passionate about practitioners gaining excellent assessment skills to provoke critical thinking, adaptability, self-management, and flexibility and learn 
a variety of distinct logical and methodical approaches and choose the best to use for specific situations by studying across subject boundaries and a more comprehensive range of subjects. 
The journal publishes peer-reviewed research articles, surveys, reviews and analyses, book reviews, short communications, case studies, recent research and more. The journal is an innovative, 
multidisciplinary, and universal publication that underscores empirical, methodological, and theoretical articles in various fields of study. The journal's role entails mixed methods approach, 
spotlighting design and methodology problems, recognizing the logistics of undertaking mixed methodologies research, and classifying various research methods used in various fields.
</p>
<p style={{textAlign:'justify',fontWeight:"bold"}}>The papers are demanded, but not limited to, the following topics:</p>
<p style={{textAlign:'justify'}}>
<div className='row'>
  <div className='col-md-6'>
  <p><i className="bi bi-chevron-double-right"></i>Computational Biomedicine</p>
<p><i className="bi bi-chevron-double-right"></i>Hematology and Medical Oncology</p>
<p><i className="bi bi-chevron-double-right"></i>Biomedical Genetics</p>
<p><i className="bi bi-chevron-double-right"></i>Neurotechnology</p>
<p><i className="bi bi-chevron-double-right"></i>Digital Therapeutics</p>
<p><i className="bi bi-chevron-double-right"></i>Technology in Mental Health</p>
<p><i className="bi bi-chevron-double-right"></i>Internet of Medical Things</p>
<p><i className="bi bi-chevron-double-right"></i>Remote Patient Monitoring</p>
<p><i className="bi bi-chevron-double-right"></i>NanoMedicine</p>
<p><i className="bi bi-chevron-double-right"></i>Tricorders in Diagnoising Patients</p>
<p><i className="bi bi-chevron-double-right"></i>Robotic Surgery</p>
<p><i className="bi bi-chevron-double-right"></i>Quantum Computing</p>
<p><i className="bi bi-chevron-double-right"></i>Edge Computing</p>
<p><i className="bi bi-chevron-double-right"></i>Hyperautomation</p>
<p><i className="bi bi-chevron-double-right"></i>Cybersecurity</p>
<p><i className="bi bi-chevron-double-right"></i>Telemedicine</p>
<p><i className="bi bi-chevron-double-right"></i>Health Care Digital Assistants</p>
<p><i className="bi bi-chevron-double-right"></i>3D Printing  Human Tissues</p>
<p><i className="bi bi-chevron-double-right"></i>Cancer Immunotherapy</p>


</div>
  <div className='col-md-6'>
  
  <p><i className="bi bi-chevron-double-right"></i>Computational Creativity in Music</p>
<p><i className="bi bi-chevron-double-right"></i>Cosmological Simulations in Machine Learning</p>
<p><i className="bi bi-chevron-double-right"></i>Human Rights in the Age of AI</p>
<p><i className="bi bi-chevron-double-right"></i>3D Fabrication Technology</p>
<p><i className="bi bi-chevron-double-right"></i>Intelligent Automation and Robotic Process Automation (RPA)</p>
<p><i className="bi bi-chevron-double-right"></i>Autonomous Robotics</p>
<p><i className="bi bi-chevron-double-right"></i>AI in Healthcare </p>
<p><i className="bi bi-chevron-double-right"></i>Virtual Reality and Augmented Reality </p>
<p><i className="bi bi-chevron-double-right"></i>Bioprinting</p>
<p><i className="bi bi-chevron-double-right"></i>Immersive Technologies</p>
<p><i className="bi bi-chevron-double-right"></i>AI in Medical Imaging</p>
<p><i className="bi bi-chevron-double-right"></i>Reinforcement Learning Between AI System and Environment</p>
<p><i className="bi bi-chevron-double-right"></i>Judicial Ethics</p>
<p><i className="bi bi-chevron-double-right"></i>Mass Torts</p>
<p><i className="bi bi-chevron-double-right"></i>Wage & Hour Litigation  </p>
<p><i className="bi bi-chevron-double-right"></i>Genomics</p>
<p><i className="bi bi-chevron-double-right"></i>Pharmaceutical Innovation</p>
<p><i className="bi bi-chevron-double-right"></i>AI-Powered Personalization, and AI-Content Creation</p>
<p><i className="bi bi-chevron-double-right"></i>Commerece Technology</p>



    </div>
</div>
</p>
    </div>
</div>
</div>
</section>
</main>
    </>
  )
}

export default Dsdsmsaimandscope;
