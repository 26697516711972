import React,{useEffect} from 'react';
import { Link,NavLink } from 'react-router-dom';
import Accordion from '../../components/Dscse/Accordion';
// import Accordion from '../components/Accordion';
import {Helmet} from 'react-helmet';
import {HashLink} from 'react-router-hash-link';
import Dsjstarticletemplate from '../../downloads/DS-DST-Article_Template.doc';
import Dsjstcopyrightform from '../../downloads/DS-DST-Copyright_Form.docx';

const Articlesubmit = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  const accordionData = [{
    content: <div>
        <HashLink smooth to="/dst" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Aim and Scope</HashLink>
        <NavLink to="/dst/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
        <NavLink to="/dst/paper-submission"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
        <NavLink to="/dst/current-issue"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/dst/archives"           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/dst/topics"                           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target= "_blank"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target= "_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/dst/apc"               className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
        {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
        <a href={Dsjstarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
        <a href={Dsjstcopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
</div> 
},
  ];
  return (
    <>
    <Helmet>
                <title>DS Journal of DST - Submit Your Paper - Digital Science and Technology</title>
                <link rel="canonical" href="https://dsjournals.com/dst/paper-submission"/>
                <meta name="title" content="DS Journal of DST - Submit Your Paper - Digital Science and Technology"/>
                <meta name="description" content="Submit your digital science research paper to Digital Science and Technology journal for publication. Follow the guidelines and make your submission today."/>
                <meta name="keywords" content="paper submission, paper submission in ds journal, journal publication free of charge, research paper submission, research paper submission guidelines, research paper submission process, research paper presentation, journal submission process, journal submission, international journal submission, paper submission process, journal submission guidelines, manuscript submission guidelines"/>
                <meta property="og:type" content="Website"/>
                <meta property="og:site_name" content="Dream Science"/>
                <meta property="og:url" content="https://dsjournals.com/dst/paper-submission"/>
                <meta property="og:title" content="DS Journal of DST - Submit Your Paper - Digital Science and Technology"/>
                <meta property="og:description" content="Submit your digital science research paper to Digital Science and Technology journal for publication. Follow the guidelines and make your submission today."/>
                <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Digital-Science-and-Technology.webp"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:description" content="Submit your digital science research paper to Digital Science and Technology journal for publication. Follow the guidelines and make your submission today."/>
                <meta name="twitter:site" content="@DreamScience4"/>
                <meta name="twitter:title" content="DS Journal of DST - Submit Your Paper - Digital Science and Technology"/>
                <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
                <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Digital-Science-and-Technology.webp"/>
                <meta name="robots" content="index, follow"/>
    </Helmet>
     <section id="hero-no-slide-dst" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
                <div className="col-xl-8">
                    <div className="row justify-content-center">
                        {/* <div className="col-xl-8">
                            <h2>Paper Submission</h2>
                        </div> */}
                         <div className="col-lg-12">
                            <h1 style={{fontSize:"25px"}}>DS Journal of Digital Science and Technology ( DS-DST )</h1>
                            {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="breadcrumbs">
        <div className="container">
            <div className="d-flex justify-content-between align-items-center">
                {/* <h2>Submit Article</h2> */}
                
                <ol>
                <li><Link to="/home">Home</Link></li>
                <li><Link to="/journals">Journals</Link></li>
                <li><Link to="/dst">DS-DST</Link></li>
                <li>Paper Submission</li>
              </ol>
                  
               
            </div>
        </div>
    </section>

<main id="main">
<section className="blog" id="blog">
<div className="container">
<div className="row">
    <div className="col-lg-3">
      <div className="list-group">
      <div className="accordion">
                      {accordionData.map(({content }) => (
                        <Accordion content={content} />
                    ))}
                  </div>
      </div>


   
                 {/* <div className="abtlistgrp">
                 <div className="card">
                  <ul className="list-group list-group-flush">
                      <Link to="/"  className="list-group-item btn-submit"><i className="bi bi-cloud-arrow-down"></i>Submit</Link>
                      <Link to="/editorialboard"  className="list-group-item"><i className="bi bi-people-fill"></i>Editor Spotlight</Link>
                       <Link to="/" className="list-group-item"><i className="bi bi-award"></i>Special Issue</Link>
                  </ul>
                </div>              
              </div> */}
       
                


   </div>
    <div className="col-lg-9">
     <h3>Paper Submission</h3>
     <div className="card mb-3" style={{maxWidth:"950px"}}>
  <div className="row g-0">
    <div className="col-md-3">
      <img id="journalpfimg_p" src="assets/img/submission-support-img.png" className="img-fluid rounded-start" alt="..."/>
    </div>
    <div className="col-md-9">
      <div className="card-body">
        <h6 className="card-title text-center">Please send your paper as attached file to mail id:</h6>
        <p class="card-text text-center"><h6>scitech@dsjournals.com</h6>
        </p>
        <p class="card-text text-center" style={{fontSize:"13px"}}>Note: Kindly add our email address <span>scitech@dsjournals.com</span> to your Address Book or Contacts to continue receiving our emails in your inbox !</p>
       
      </div>
    </div>
  </div>
</div>

<h6>Preparation Guidelines for Paper Submission:</h6>
<p id='head1' style={{textAlign:'justify',textIndent:"50px"}}>Submissions by anyone other than one of the authors will not be accepted. The submitting author takes responsibility for the paper during submission and peer review.</p>
    <p style={{textAlign:'justify'}}>
        <ul>
            <li>Manuscripts submitted to this journal will be deemed as they have not been published and are not under consideration for publication elsewhere</li>
            <li>Submit your paper in the form of Microsoft word format ( .doc or .docx ) , if you were used latex software for article preparation then send your paper in PDF format.</li>
            <li>Submitting the paper in multiple journals are offence, Don't waste our valuable time.</li>
            {/* <li>Once the paper is accepted, it can't be withdrawn at any cost.</li> */}
            <li>Please follow publication ethics and regulation.</li>
            <li>Avoid plagiarism and copied material.</li>
            <li>Strictly Follow DS Journals Paper Template.</li>
            
            
        </ul>
    </p>
    <h6>Terms of Submission:</h6>
    <p id='head2' style={{textAlign:'justify',textIndent:"50px"}}>
    Papers must be submitted on the understanding that they have not been published elsewhere and are not currently under consideration by another journal 
    published by DS Journals or any other publisher. The submitting author is responsible for ensuring that the article's publication has been approved by all the other coauthors. 
    It is also the authors' responsibility to ensure that the articles emanating from a particular institution are submitted with the approval of the necessary institution.
    </p>
    <h6>Peer Review:</h6>
    <p id='head3' style={{textAlign:'justify',textIndent:"50px"}}>All manuscripts are subject to peer review and are expected to meet standards of academic excellence. If approved by the editor, submissions will be considered by peer-reviewers, whose identities will remain anonymous to the authors.</p>
    <h6>Units of Measurement:</h6>
    <p id='head4' style={{textAlign:'justify',textIndent:"50px"}}>Units of measurement should be presented simply and concisely using System International (SI) units.</p>
    <h6>Title and Authorship Information:</h6>
    <p>The following information should be included:</p>
        <ul>
        <li>Paper title</li>
        <li>Full author names</li>   
        <li>Full institutional mailing addresses</li> 
        <li>Email addresses</li>
        <li>Abstract</li>
        </ul>
    <p id='head5' style={{textAlign:'justify',textIndent:"50px"}}>The manuscript should contain an abstract. The abstract should be self-contained and citation-free and should not exceed 200 words.</p>
    <h6>Introduction:</h6>
    <p id='head6' style={{textAlign:'justify',textIndent:"50px"}}>This section should be succinct, with no subheadings.</p>
    <h6>Materials and Methods:</h6>
    <p  id='head7' style={{textAlign:'justify',textIndent:"50px"}}>This part should contain sufficient detail so that all procedures can be repeated. It can be divided into subsections if several methods are described.</p>
    <h6>Preparation of Tables:</h6>
    <p id='head8' style={{textAlign:'justify',textIndent:"50px"}}>Tables should be cited consecutively in the text. Every table must have a descriptive title and if numerical measurements are given, the units should be included in the column heading.</p>
    <h6>Results and Discussion:</h6>
    <p id='head9' style={{textAlign:'justify',textIndent:"50px"}}>This section may each be divided by subheadings or may be combined.</p>
    <h6>Conclusions:</h6>
    <p id='head10' style={{textAlign:'justify',textIndent:"50px"}}>This should clearly explain the main conclusions of the work highlighting its importance and relevance.</p>
    <h6>Acknowledgments:</h6>
    <p id='head11' style={{textAlign:'justify',textIndent:"50px"}}>All acknowledgments (if any) should be included at the very end of the paper before the references and may include supporting grants, presentations, and so forth.</p>
    <h6>References:</h6>
    <p id='head12' style={{textAlign:'justify',textIndent:"50px"}}>Authors are responsible for ensuring that the information in each reference is complete and accurate. All references should be cited within the text; otherwise, these references will be automatically removed.</p>

    
    </div>
   
    

</div>



</div>

</section>


</main>
</>
  )
}

export default Articlesubmit;