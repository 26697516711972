import React,{useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link,NavLink } from 'react-router-dom';
import Accordion from '../components/Accordion';


const Copyrightinfringement = () => {
 
    useEffect(()=>{
        window.scrollTo(0,0);
      },[]);
      const accordionData = [{
        content: <div>
            <NavLink  to="/for-authors/publication-ethics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics </NavLink>
            <NavLink  to="/for-authors/author-guideline" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Step by Step Guidelines for Authors</NavLink>
            <NavLink  to="/for-authors/submitting-a-manuscript" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Submitting a Manuscript</NavLink>
            <NavLink  to="/for-authors/open-access-author" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is Open Access ?</NavLink>
            <NavLink  to="/for-authors/review-process" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Review Process</NavLink>
            <NavLink  to="/for-authors/conflicts-of-interest" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Conflicts of Interest</NavLink>
            <NavLink  to="/for-authors/licensing-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Licensing Policy</NavLink>
            <NavLink  to="/for-authors/copyright-infringement" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Copyright Infringement</NavLink>
            <NavLink  to="/for-authors/correction-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Correction Policy</NavLink>
        <NavLink  to="/for-authors/what-is-apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is APC ?</NavLink>
            {/* <HashLink smooth to="#" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>How to subscribe the hard copy of journal ?</HashLink> */}    
    </div> 
    },
      ];
      return (
        <>  
        <Helmet>
          <title>DS Journals Licensing Policy for Authors</title>
          <link rel="canonical" href="https://dsjournals.com/for-authors/licensing-policy"/>
          <meta name="title" content="DS Journals Licensing Policy for Authors"/>
          <meta name="description" content="DS Journals' licensing policy allows authors to retain ownership of their work while granting us the right to publish and distribute it. Learn more."/>
          <meta name="keywords" content="ds Journals, license policy, copyright, Creative Commons, licensing options, research publishing."/>
          <meta property="og:type" content="Website"/>
          <meta property="og:site_name" content="Dream Science"/>
          <meta property="og:url" content="https://dsjournals.com/for-authors/licensing-policy"/>
          <meta property="og:title" content="DS Journals Licensing Policy for Authors"/>
          <meta property="og:description" content="DS Journals' licensing policy allows authors to retain ownership of their work while granting us the right to publish and distribute it. Learn more."/>
          <meta property="og:image" content="image url"/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:description" content="DS Journals' licensing policy allows authors to retain ownership of their work while granting us the right to publish and distribute it. Learn more."/>
          <meta name="twitter:site" content="@DreamScience4"/>
          <meta name="twitter:title" content="DS Journals Licensing Policy for Authors"/>
          <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
          <meta name="twitter:image" content="image url"/>
          <meta name="robots" content="index, follow"/>
        </Helmet>
        <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
                  <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div className="row justify-content-center">
                          <div className="col-xl-8">
                            <h2>Copyright Infringement</h2>
                          </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </section>
      
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
             
            <ol>
              <li><Link to="/home">Home</Link></li>
              <li><Link to="/for-authors">Authors</Link></li>
              <li>Copyright Infringement</li>
            </ol>
          </div>
        </div>
      </section>
    
      <main id="main">
        <section className="blog" id="blog">
            <div className="container">
                <div className="row">
                <div className="col-lg-3">
     <div className="list-group">
      <div className="accordion">
                      {accordionData.map(({content }) => (
                        <Accordion content={content} />
                    ))}
                  </div>
      </div>
     </div>
                    <div className="col-lg-9">
                    <h1 style={{fontSize:"35px"}}>Copyright Infringement</h1>
                      
                      <p className="mt-2" style={{textAlign:'justify'}}>Any claims of copyright infringement 
                      should be addressed to the Editor at <span style={{fontWeight:"bold"}}>queries@dsjournals.com</span> bearing the subject line 
                      "Copyright Infringement". The claim must be sufficed by documented evidence supporting 
                      the same version as being published or copyrighted or patented by the aggrieved party 
                      before the date of publication of the concerned DS article. On receipt of the claim, 
                      the DS Board, if found deemed, shall inform the DS author to provide an explanation; 
                      the discussion of which shall be transparent to both parties.</p>
                    
                      <p style={{textAlign:'justify'}}>The DS Board reserves the sole rights to decide the 
                      validity of any such claims. After deliberation, if the claim is found justified, the 
                      concerned manuscript will be removed from all DS archives and servers. Any subsequent 
                      print copies of the concerned issue will not contain the article. In case, the changes 
                      required are minimal such as inclusion of references, the authors will be intimated to 
                      do the required amendments according to the DS article correction policies. The 
                      alternative version shall undergo peer-review as any other general submission and shall 
                      be published in the same issue (number) of the concerned volume. Any claims on copyright 
                      will be addressed with the highest priority. A revert mail will be dispatched within 3 
                      working days provided the claim is supported with documented evidence.</p>
                    </div>
            
                </div>
            </div>
        </section>
      </main>
                </>
      )
}

export default Copyrightinfringement