import React from 'react';
import {Link} from 'react-router-dom';
import '../pages/Forauthors.css';
import Accordion from './Accordion';

function Conferences() {
  const accordionData = [{
    content: <div>
    <div className="abtlistgrp">
        <div className="card">
            <ul className="list-group list-group-flush">
                
                <Link to="/archives"  className="list-group-item"><i className="bi bi-archive"></i>Archives</Link>
                <Link to="#"  className="list-group-item"><i className="bi bi-clipboard-data"></i>Author Guidelines</Link>
                <Link to="#" className="list-group-item"><i className="bi bi-people-fill"></i>Editorial Board</Link>
                <Link to="#"  className="list-group-item btn-submit"><i className="bi bi-file-arrow-up"></i>Submit</Link>
            </ul>
        </div>
    </div> 
</div> 
},

];
  return (
    <>  
            <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center">
              <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <h2>Conferences</h2>
                      </div>
                  </div>
                </div>
              </div>
              </div>
            </section>
  
  <section className="breadcrumbs">
    <div className="container">
      <div className="d-flex justify-content-between align-items-center">
        <h2>Conferences</h2>
        <ol>
          <li><Link to="/">Home</Link></li>
          <li>Conferences</li>
        </ol>
      </div>
    </div>
  </section>

  <main id="main">
  <section className="blog" id="blog">
        <div className="container">
            <div className="row">
            {/* <div className="col-lg-3"> 
                 <div className="abtlistgrp">
                 <div className="card">
                  <ul className="list-group list-group-flush">
                    <div className="accordion">
                      {accordionData.map(({content }) => (
                        <Accordion content={content} />
                    ))}
                  </div>
                  </ul>
                </div>
              </div> 
                </div> */}
                <div className="col-lg-4">
                    <article className="entry">
                        <h6>
                          <i className="bi bi-calendar-week m-2"></i>Upcoming Conference
                          <hr/>
                        </h6>
                        <div className="entry-content">
                          <p>
                          Dream Science Journal International conferences will be in various 
                          states of India like Tamilnadu, Andhra Pradesh, 
                          Telangana, Karnataka, Kerala, Maharashtra, Goa, 
                          Mumbai, etc.
                          </p>
                          <div className="read-more">
                            <Link to="/conferencelist">Read More</Link>
                          </div>
                        </div>
                      </article>
                </div>
                <div className="col-lg-4">
                    <article className="entry">
                        <h6>
                            <i className="bi bi-pencil-square m-2"></i>Conference Proposal
                          <hr/>
                        </h6>
                        <div className="entry-content">
                          <p>
                          Dream Science Journal sponsors for organizing the International or 
                          National conferences in your college. 
                          This initiative strengthens the research 
                          community and disseminates scientific knowledge.
                          </p>
                          <div className="read-more">
                          <Link to="/conferenceproposal">Read More</Link>
                          </div>
                        </div>
                      </article>
                </div>
                <div className="col-lg-4">
                    <article className="entry">
                        <h6>
                           <i className="bi bi-award m-2"></i>Special Issues
                          <hr/>
                        </h6>
                       
                        <div className="entry-content">
                          <p>
                          The special issue is our add-on issue from various 
                          guest editors significantly involved and recognized in 
                          their research areas in the name of special issues on 
                          common topics of interest.
                          </p>
                          <div className="read-more">
                          <Link to="/specialissues">Read More</Link>
                          </div>
                        </div>
          
                      </article>
                </div>
         
            </div>
        </div>
    </section>
  </main>
            </>
  )
}
export default Conferences;