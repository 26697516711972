import React,{useState,useEffect} from 'react';
import moment from 'moment';
import './Accordion.css';


const Accordion = ({ title, content }) => {
  const currentYear= moment().year();
  const [isActive, setIsActive] = useState(title === JSON.stringify(currentYear) ? true : false);
    return (
      <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
          <div className="accordion-text" style={{padding:"10px"}}><span className='accordion-subtext'>{title}</span>
          <span className="accordion-icon">{isActive ? '-' : '+'}</span></div>
      </div> 
  {isActive && <div className="accordion-content">{content.sort().reverse()}</div>}
</div>
    );
  };

export default Accordion;
