import React,{useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link,NavLink } from 'react-router-dom';
import Accordion from '../components/Accordion';

const What_is_APC = () => {
    useEffect(()=>{
        window.scrollTo(0,0);
      },[]);
      const accordionData = [{
        content: <div>
            <NavLink  to="/for-authors/publication-ethics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics </NavLink>
            <NavLink  to="/for-authors/author-guideline" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Step by Step Guidelines for Authors</NavLink>
            <NavLink  to="/for-authors/submitting-a-manuscript" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Submitting a Manuscript</NavLink>
            <NavLink  to="/for-authors/open-access-author" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is Open Access ?</NavLink>
            <NavLink  to="/for-authors/review-process" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Review Process</NavLink>
            <NavLink  to="/for-authors/conflicts-of-interest" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Conflicts of Interest</NavLink>
            <NavLink  to="/for-authors/licensing-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Licensing Policy</NavLink>
            <NavLink  to="/for-authors/copyright-infringement" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Copyright Infringement</NavLink>
            <NavLink  to="/for-authors/correction-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Correction Policy</NavLink>
        <NavLink  to="/for-authors/what-is-apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is APC ?</NavLink>
            {/* <HashLink smooth to="#" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>How to subscribe the hard copy of journal ?</HashLink> */}    
    </div> 
    },
      ];
      return (
        <>  
        <Helmet>
            <title>What is APC? DS Journals Authors</title>
            <link rel="canonical" href="https://dsjournals.com/for-authors/what-is-apc"/>
            <meta name="title" content="What is APC? DS Journals Authors"/>
            <meta name="description" content="DS Journals charges an article processing charge (APC) for accepted articles. Learn more about what APC is and what it covers for authors."/>
            <meta name="keywords" content="article processing charges, apc journal, free article processing charges journals, processing charges, apc open access, apc article processing charge, apc journal meaning, apc charges, article processing fee, apc of journal, apc in journals."/>
            <meta property="og:type" content="Website"/>
            <meta property="og:site_name" content="Dream Science"/>
            <meta property="og:url" content="https://dsjournals.com/for-authors/what-is-apc"/>
            <meta property="og:title" content="What is APC? DS Journals Authors"/>
            <meta property="og:description" content="DS Journals charges an article processing charge (APC) for accepted articles. Learn more about what APC is and what it covers for authors."/>
            <meta property="og:image" content="image url"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content="What is APC? DS Journals Authors"/>
            <meta name="twitter:description" content="DS Journals charges an article processing charge (APC) for accepted articles. Learn more about what APC is and what it covers for authors."/>
            <meta name="twitter:site" content="@DreamScience4"/>
            <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
            <meta name="twitter:image" content="image url"/>
            <meta name="robots" content="index, follow"/>
        </Helmet>
        <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
                  <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div className="row justify-content-center">
                          <div className="col-xl-8">
                            <h2>What is APC?</h2>
                          </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </section>
      
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
             
            <ol>
              <li><Link to="/home">Home</Link></li>
              <li><Link to="/for-authors">Authors</Link></li>
              <li>What is APC?</li>
            </ol>
          </div>
        </div>
      </section>
    
      <main id="main">
        <section className="blog" id="blog">
            <div className="container">
                <div className="row">
                <div className="col-lg-3">
     <div className="list-group">
      <div className="accordion">
                      {accordionData.map(({content }) => (
                        <Accordion content={content} />
                    ))}
                  </div>
      </div>
     </div>
                    <div className="col-lg-9">
                    <h1 style={{fontSize:"35px"}}>What are Article Processing Charges?</h1>
                      
                      <p className="mt-2" style={{textAlign:'justify'}}>Dream Science welcomes you to submit the high standardized paper for review process. We
enhance the researchers to read/download the articles which is purely an Open-Access
Journal. We support sustainable access and work hard to provide a range of open access
options.</p>
                    
                      <p style={{textAlign:'justify'}}>All articles published in Dream Science open access journals are peer reviewed and upon
acceptance will be immediately and permanently free for everyone to read and download.
Permitted reuse is defined by your choice of a user license.
</p>
                      <p style={{textAlign:'justify'}}>Dream Science does not charge for either submissions or publication. There is no Article
Processing charge. Even archiving of original prominent research contents is a lifetime process which helps and lifts our mankind,  Dream science wishes to reduce the financial  burden of authors.   
</p>
                      <p style={{textAlign:'justify'}}>
                      It’s completely free of cost journal.
                      <ul style={{listStyle:"none"}}>
                        <li>1.	Submission @ free of cost</li>
                        <li>2.	Reviewing @ free of cost</li>
                        <li>3.	Grammar checking @ free of cost</li>
                        <li>4.	Proof reading @ free of cost</li>
                        <li>5.	Readability @ free of cost</li>
                        <li>6.	Archiving @ free of cost</li>
                        <li>7.	E-Certificates @ free of cost</li>
                      </ul>
                      We only wish the authors has to submit their original &amp; unique research articles which can uplift the global scientific community in all domains. 
                      </p>
                    </div>
            
                </div>
            </div>
        </section>
      </main>
                </>
      )
}

export default What_is_APC