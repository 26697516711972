import React, { useEffect } from 'react';
import './Dsdsairaimandscope.css';
import { NavLink, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dsair/Accordion';
import Ddsairarticletemplate from '../../downloads/DS-AIR-Article_Template.doc';
import Ddsaircopyrightform from '../../downloads/DS-AIR-Copyright_Form.docx';

const Dsdsairaimandscope = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const accordionData = [{
        content: <div>
            <NavLink smooth to="/air" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</NavLink>
            <NavLink to="/air/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
            <NavLink to="/air/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/air/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/air/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/air/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/air/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
            <a href={Ddsairarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Ddsaircopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
        </div>
    },
    ];
    return (

        <>
            <Helmet>
                <title>DS-AIR Journal - Artificial Intelligence and Robotics</title>
                <link rel="canonical" href="https://dsjournals.com/air" />
                <meta name="title" content="DS-AIR Journal - Artificial Intelligence and Robotics" />
                <meta name="description" content="Discover the latest advancements in research across multiple disciplines with DS-AIR Journal. Submit your research paper for peer-review today." />
                <meta name="keywords" content="artificial intelligence, research paper on artificial intelligence, artificial intelligence is about, about artificial intelligence, computer intelligence, artificial intelligence problems, journal of artificial intelligence research, artificial intelligence review, artificial intelligence journal, artificial intelligence research, ai research papers, artificial intelligence review journal." />
                <meta property="og:type" content="Website" />
                <meta property="og:site_name" content="Dream Science" />
                <meta property="og:url" content="https://dsjournals.com/air" />
                <meta property="og:title" content="DS-AIR Journal - Artificial Intelligence and Robotics" />
                <meta property="og:description" content="Discover the latest advancements in research across multiple disciplines with DS-AIR Journal. Submit your research paper for peer-review today." />
                <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Artificial-Intelligence-and-Robotics.webp" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="DS-AIR Journal - Artificial Intelligence and Robotics" />
                <meta name="twitter:description" content="Discover the latest advancements in research across multiple disciplines with DS-AIR Journal. Submit your research paper for peer-review today." />
                <meta name="twitter:site" content="@DreamScience4" />
                <meta name="twitter:url" content="https://twitter.com/DreamScience4" />
                <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Artificial-Intelligence-and-Robotics.webp" />
                <meta name="robots" content="index, follow" />
            </Helmet>

            <section id="hero-no-slide-dsair" className="d-flex justify-cntent-center align-items-center" style={{ height: "300px" }}>
                <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <h2 style={{ fontSize: "25px" }}>DS Journal of Artificial Intelligence and Robotics ( DS-AIR )</h2>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <ol>
                            <li><NavLink to="/home">Home</NavLink></li>
                            <li><NavLink to="/journals">Journals</NavLink></li>
                            <li>DS-AIR</li>
                        </ol>
                    </div>
                </div>
            </section>
            <main id="main">
                <section className="blog" id="blog">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 mb-3">
                                <div className="list-group">
                                    <div className="accordion">
                                        {accordionData.map(({ content }) => (
                                            <Accordion content={content} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div id="jorunalcard">
                                    <div className="card" style={{ maxWidth: "950px" }}>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4">
                                                <img id="journalpfimg_p" src="assets/img/DS-Journal-of-Artificial-Intelligence-and-Robotics-Bookcover.jpg" className="img-responsive center-block d-block mx-auto" alt="journalprofileimg" />
                                            </div>
                                            <div className="col-lg-9 col-md-8">
                                                <div className="card-body">
                                                    {/* <h6 className="card-title center">DS International Journal of Computer Science and Engineering ( DSCSE )</h6> */}

                                                    <p class="card-text">
                                                        <table className='table'>
                                                            <tr className="pt-1">
                                                                <th scope="row" className="col-md-4 align-top">Editor in Chief</th>
                                                                <td className="col-md-8 px-2"> Dr. Dariusz Jakóbczak,<br />
                                                                    Department of Electronics and Computer Science, <br />
                                                                    Koszalin University of Technology, <br />
                                                                    Poland.
                                                                </td>
                                                            </tr>
                                                            <tr className="mt-2">
                                                                <th scope="row" className="col-md-4">ISSN (Online)</th>
                                                                <td className="col-md-8 px-2">2583-9926</td>
                                                            </tr>
                                                            <tr className="mt-2">
                                                                <th scope="row" className="col-md-4">Subject</th>
                                                                <td className="col-md-8 px-2">Artificial Intelligence and Robotics</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Year of Starting</th>
                                                                <td className="col-md-8 px-2">2023</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Publication Frequency</th>
                                                                <td className="col-md-8 px-2">4 Issue per Year</td>
                                                            </tr>

                                                            <tr>
                                                                <th scope="row" className="col-md-4"> Language</th>
                                                                <td className="col-md-8 px-2">English</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Paper Submission id</th>
                                                                <td className="col-md-8 px-2">robotics@dsjournals.com</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Format of Publication</th>
                                                                <td className="col-md-8 px-2">online</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4 align-top">Publication Fee</th>
                                                                <td className="col-md-8 px-2">Free of Cost Journal</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4"> Publisher</th>
                                                                <td className="col-md-8 px-2">Dream Science</td>
                                                            </tr>
                                                        </table>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>Aim and Scope</p>
                                <p style={{ textAlign: 'justify', textIndent: "50px" }}>DS Journal of Artificial Intelligence and Robotics (DS-AIR) is an international scholarly peer-reviewed gold
                                    open access journal focusing on state-of-art interdisciplinary research on integrating artificial intelligence, robotics, and fundamental sciences. DS-AIR aims to
                                    provide an international forum with which to report the latest developments on AI and robotic systems in theory, design, and applications by publishing articles on the
                                    theoretical and experimental aspects of artificial intelligence and robotic technology. This journal is a high-quality international platform for the exchange of the
                                    latest academic developments from the global artificial intelligence and robotics community. The journal aims at bridging the gap between theory and practice,
                                    and intends to stimulate interaction between faculty, researchers, engineers and practitioners from academia, industry and government, focusing on theoretical and
                                    applied research in all areas of artificial intelligent systems and robotics.</p>
                                <p style={{ textAlign: 'justify', textIndent: "50px" }}>
                                    DS-AIR lays the foundation for a common framework for publication of ideas, derivation and testing of techniques that combine theory, science, engineering, and mathematics, leading to future
                                    innovations and novel technologies at the forefront of disseminating and communicating scientific knowledge and impactful discoveries defining the Frontiers of a cutting-edge domain such as
                                    Robotics and AI to researchers and the public, worldwide. The journal encourages scientists and engineers to publish their experimental and theoretical research in as much detail as possible.
                                    Therefore, the journal has no restrictions regarding the length of papers. Full experimental details should be provided so that the results can be reproduced.  DS-AIR publishes original
                                    research articles, review articles, short papers, book reviews and communications, that offer substantial new insight into any field of study that involves artificial intelligence (AI) and
                                    robotics. Special emphasis is given to technological innovations and real-world applications. Special issues related to the topics of DS-AIR are also welcome.
                                </p>
                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>Papers are in demand, but not limited to the following topics:</p>
                                <p style={{ textAlign: 'justify' }}>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <p><i className="bi bi-chevron-double-right"></i>Wireless Sensor Networks for Robot Navigation</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Theoretical Interpretation of AI</p>
                                            <p><i className="bi bi-chevron-double-right"></i>The Modeling, Identification, and Control of Robotic Systems</p>
                                            <p><i className="bi bi-chevron-double-right"></i>The History of Robotics</p>
                                            <p><i className="bi bi-chevron-double-right"></i>System Modelling</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Swarm Robotics, Mobile, and Flying Robotic Systems</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Swarm Intelligence, Artificial Life and Multi-Agent Systems</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Space, Planetary and Undersea Exploration</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Soft Robotics</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Social and Lifestyle Robots</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Social and Ethical Issues Related to AI-R</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Smart Actuators</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Service Robotics, Industrial Robotizations, Space Robotics</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Sensorization, Robot Navigation, Communication Robotics</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Science of Robotics For Human</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Science of Intelligent Physical Systems</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Robotics and Health/Medicine</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Robotics and Automation</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Robot and Swarm Intelligence</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Remote Operation and Exploration</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Psychology-based Social Robotics</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Probabilistic Computing</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Novel and Biologically Inspired Robotics</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Natural Language Processing, Recognition and Applications</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Nano and Micro-Robotics</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Multi-Sensor Data Fusion and SLAM</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Multi-Robot Control</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Multimodal Human–Machine Interaction</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Motion Planning and Control</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Morphological Computation in Modular Robots</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Machine Perception and Pattern Recognition</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Machine and Deep Learning</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Knowledge Representation</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Kinematics Knowledge Reasoning and Discovery</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Intelligent Robotics, Mechatronics, and Biomimetics</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Intelligent Machine/Agent</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Intelligent and Information Systems</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Intelligence System and Application</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Information Theory of Cognitive Systems</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Industrial Robotics</p>
                                        </div>
                                        <div className='col-md-6'>
                                        <p><i className="bi bi-chevron-double-right"></i>Humanoids</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Human–Machine Interaction</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Human Augmentation</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Guided Self-Organization</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Genetic Algorithms</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Fuzzy Logic</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Field Robotics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Expert Systems</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Exoskeletons, Prosthetics, and Artificial Organs</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Evolutionary Game Theory in Robotics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Embodied Intelligence</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Data Mining for Robotics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Conversational AI</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Control Theory</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Control Software</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Control Applications</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Computer Vision</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Computational Robotics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Computational Intelligence</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Complex Networks and Distributed Computation</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Complex Adaptive Systems</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Brain Science-Inspired AI</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Brain and Cognitive Science</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Biomedical, Rehabilitation, and Surgical Robotics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Bio-Inspired Robotics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Bio-Inspired Design</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Bio-Inspired Cyber-Physical Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Big Data and Knowledge Engineering</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Autonomous Vehicles</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Autonomous Behaviors</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Automated Planning and Scheduling</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Artificial Neural Networks</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Artificial General Intelligence</p>
                                        <p><i className="bi bi-chevron-double-right"></i>AI-Generated Art</p>
                                        <p><i className="bi bi-chevron-double-right"></i>AI-Based Molecular and Material Design</p>
                                        <p><i className="bi bi-chevron-double-right"></i>AI-Based Medical Image Analysis</p>
                                        <p><i className="bi bi-chevron-double-right"></i>AI-Assisted Protein Structure Predictions</p>
                                        <p><i className="bi bi-chevron-double-right"></i>AI-Assisted Mathematics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>AI, Neural Networks, and Fuzzy Logic in Robotics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>AI Theory</p>
                                        <p><i className="bi bi-chevron-double-right"></i>AI Technique</p>
                                        <p><i className="bi bi-chevron-double-right"></i>AI Games</p>
                                        <p><i className="bi bi-chevron-double-right"></i>AI Application</p>
                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Dsdsairaimandscope;
