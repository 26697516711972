import { combineReducers } from 'redux';
import posts from './posts';
import auth from './auth';
import midcard from './midcard';
import journalposts from './journalposts';
import dsllljposts from './dslll/dsllljposts';
import dsairjposts from './dsair/dsairjposts';
import dscysposts from './dscys/dscysposts';
import dsmsjposts from './dsms/dsmsjposts';
import dsmjposts from './dsm/dsmjposts';
import dscejposts from './dsce/dscejposts';
import dsrlsjposts from './dsrls/dsrlsjposts'
import cissueposts from './cissue/cissueposts';
import searchposts from './search/searchposts';
import archivesgroup from './archive_group/archivesgroup';
import dsairarchivesgroup from './archive_group/dsairarchivesgroup';
import dslllarchivesgroup from './archive_group/dslllarchivesgroup';
import dsdsmarchivesgroup from './archive_group/dsdsmarchivesgroup';
import dscysarchivesgroup from './archive_group/dscysarchivesgroup';
import dsmsarchivesgroup from './archive_group/dsmsarchivesgroup';
import dscearchivesgroup from './archive_group/dscearchivesgroup';
import dsrlsarchivesgroup from './archive_group/dsrlsarchivesgroup';

import dsjournal from './global/dsreducer'




// export const reducers = combineReducers({ posts:posts, 
//                                           auth:auth, 
//                                           midcard:midcard,
//                                           journalposts:journalposts,
//                                           dsllljposts:dsllljposts,
//                                           dsairjposts:dsairjposts,
//                                           cissueposts:cissueposts,
//                                           searchposts:searchposts,
//                                           archivesgroup:archivesgroup,
//                                           dsairarchivesgroup:dsairarchivesgroup,
//                                          });

export const reducers = combineReducers({ posts, 
                                          auth, 
                                          midcard,
                                          journalposts,
                                          dsllljposts,
                                          dsairjposts,
                                          dscysposts,
                                          dsmsjposts,
                                          dsmjposts,
                                          dscejposts,
                                          dsrlsjposts,
                                          cissueposts,
                                          searchposts,
                                          archivesgroup,
                                          dsairarchivesgroup,
                                          dslllarchivesgroup,
                                          dsdsmarchivesgroup,
                                          dscysarchivesgroup,
                                          dsjournal,
                                          dsmsarchivesgroup,
                                          dscearchivesgroup,
                                          dsrlsarchivesgroup
                                          });
                                          
// export const reducers = combineReducers({ posts, auth, midcard,journalposts,dsllljposts,cissueposts,searchposts,archivesgroup });
