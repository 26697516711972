import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ButtonBase } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import useStyles from '../../../../styles';
import { useHistory } from 'react-router-dom';

const Post = ({post}) => {
  const classes = useStyles();
  const history = useHistory();
  const openPost = (e) => {
    // dispatch(getPost(post._id, history));

    history.push(`/dst/${post._id}`);
    
  };

  return (
    
    <>
                  <Card sx={{ minWidth: 275 }}>
                  
                        <CardContent>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          <i className='bi bi-file-earmark-fill'></i> Research Article <i className='bi bi-diamond-half'></i> {post.article_id}
                          </Typography>
                          <Typography variant="h6" component="div">
                          {/* <ButtonBase
        component="span"
        name="test"
        className={classes.cardAction}
        onClick={openPost}
          >{post.title}</ButtonBase><br /> */}
                          <Link to={`/dst/${post._id}`}>{post.title}
                          </Link><br />
                          </Typography>
                          <Typography variant="body2">
                            {/* {post.authors.map((author)=> `${author}`)}<br/> */}
                            {post.authors}<br/>
                          </Typography>
                        </CardContent>
                        <CardActions>
                        {/* <Button onClick={() => setCurrentId(post._id)} style={{ color: 'blue' }} size="small">
                        View
                        </Button> */}
                        
                        </CardActions>
                      </Card>
    </>

  )
}

export default Post