import { FETCH_ALL, CREATE, UPDATE, DELETE} from '../../../constants/actionTypes';
import * as api from '../../../api/index.js';


export const gethmcardPosts = () => async (dispatch) => {
  try {
    const { data } = await api.fetchhmcardPosts();

    dispatch({ type: FETCH_ALL, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const createhmcardPost = (post) => async (dispatch) => {
  try {
    const { data } = await api.createhmcardPost(post);

    dispatch({ type: CREATE, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const updatehmcardPost = (id, post) => async (dispatch) => {
  try {
    const { data } = await api.updatehmcardPost(id, post);

    dispatch({ type: UPDATE, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const deletehmcardPost = (id) => async (dispatch) => {
  try {
    await api.deletehmcardPost(id);

    dispatch({ type: DELETE, payload: id });
  } catch (error) {
    console.log(error.message);
  }
};