import React from 'react';
// import { Link } from 'react-router-dom';
import '../components/Pubethics.css';
import Accordion from '../components/Accordion';
import './Journalsview.css';
import { Link } from 'react-router-dom';


function excercise() {
    const accordionData = [
        {
     
          content: <div>
                        <div className="abtlistgrp">
                            <div className="card">
                                <ul className="list-group list-group-flush">
                                    <Link to="#"  className="list-group-item btn-submit"><i className="bi bi-file-arrow-up"></i>Submit</Link>
                                    <Link to="/archives"  className="list-group-item"><i className="bi bi-archive"></i>Archives</Link>
                                    <Link to="#"  className="list-group-item"><i className="bi bi-clipboard-data"></i>Author Guidelines</Link>
                                    <Link to="#" className="list-group-item"><i className="bi bi-people-fill"></i>Editorial Board</Link>
                                    <Link to="#" className="list-group-item"><i className="bi bi-server"></i>Databases and Indexing</Link>
                                </ul>
                            </div>
                        </div> 
                   </div> 
        },
        {
        
          content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
          reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
          quaerat iure quos dolorum accusantium ducimus in illum vero commodi
          pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
          quidem maiores doloremque est numquam praesentium eos voluptatem amet!
          Repudiandae, mollitia id reprehenderit a ab odit!`
        },
        {
      
          content: `Sapiente expedita hic obcaecati, laboriosam similique omnis architecto ducimus magnam accusantium corrupti
          quam sint dolore pariatur perspiciatis, necessitatibus rem vel dignissimos
          dolor ut sequi minus iste? Quas?`
        }
      ];
    return (
        <div>
                <div className="accordion">
        {accordionData.map(({content }) => (
          <Accordion content={content} />
        ))}
      </div>
        </div>
    )
}

export default excercise
