import React, { Component } from 'react';
//import Navbar from '../components/Navbar';

//import Footer from '../components/Footer';


import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';


class Frontlayout extends Component {
   
    render() {
        return (
            <>
        <Helmet>
        <script src="assets/vendor/purecounter/purecounter.js"></script>
        <script src="assets/js/main.js"></script>
        <script src="assets/js/searchbar.js"></script>
      </Helmet>
    <Router>

            {this.props.children}

    </Router>
            </>
        )
    }
}
export default Frontlayout;