import React ,{useEffect} from 'react';
import {Link,NavLink} from 'react-router-dom';
import '../pages/Forauthors.css';
import Accordion from './Accordion';
import {HashLink} from 'react-router-hash-link';
import { Helmet } from 'react-helmet';



function Lpolicy() {

  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);
  const accordionData = [{
    content: <div>
        <NavLink  to="/for-authors/publication-ethics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics </NavLink>
        <NavLink  to="/for-authors/author-guideline" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Step by Step Guidelines for Authors</NavLink>
        <NavLink  to="/for-authors/submitting-a-manuscript" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Submitting a Manuscript</NavLink>
        <NavLink  to="/for-authors/open-access-author" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is Open Access ?</NavLink>
        <NavLink  to="/for-authors/review-process" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Review Process</NavLink>
        <NavLink  to="/for-authors/conflicts-of-interest" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Conflicts of Interest</NavLink>
        <NavLink  to="/for-authors/licensing-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Licensing Policy</NavLink>
        <NavLink  to="/for-authors/copyright-infringement" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Copyright Infringement</NavLink>
        <NavLink  to="/for-authors/correction-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Correction Policy</NavLink>
        <NavLink  to="/for-authors/what-is-apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is APC ?</NavLink>
        {/* <HashLink smooth to="#" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>How to subscribe the hard copy of journal ?</HashLink> */} 
</div> 
},
  ];
  return (
    <>  
    <Helmet>
      <title>DS Journals Licensing Policy for Authors</title>
      <link rel="canonical" href="https://dsjournals.com/for-authors/licensing-policy"/>
      <meta name="title" content="DS Journals Licensing Policy for Authors"/>
      <meta name="description" content="DS Journals' licensing policy allows authors to retain ownership of their work while granting us the right to publish and distribute it. Learn more."/>
      <meta name="keywords" content="ds Journals, license policy, copyright, Creative Commons, licensing options, research publishing."/>
      <meta property="og:type" content="Website"/>
      <meta property="og:site_name" content="Dream Science"/>
      <meta property="og:url" content="https://dsjournals.com/for-authors/licensing-policy"/>
      <meta property="og:title" content="DS Journals Licensing Policy for Authors"/>
      <meta property="og:description" content="DS Journals' licensing policy allows authors to retain ownership of their work while granting us the right to publish and distribute it. Learn more."/>
      <meta property="og:image" content="image url"/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:description" content="DS Journals' licensing policy allows authors to retain ownership of their work while granting us the right to publish and distribute it. Learn more."/>
      <meta name="twitter:site" content="@DreamScience4"/>
      <meta name="twitter:title" content="DS Journals Licensing Policy for Authors"/>
      <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
      <meta name="twitter:image" content="image url"/>
      <meta name="robots" content="index, follow"/>
    </Helmet>
    <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
              <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <h2>Licensing Policy</h2>
                      </div>
                  </div>
                </div>
              </div>
              </div>
            </section>
  
  <section className="breadcrumbs">
    <div className="container">
      <div className="d-flex justify-content-between align-items-center">
         
        <ol>
          <li><Link to="/home">Home</Link></li>
          <li><Link to="/for-authors">Authors</Link></li>
          <li>Licensing Policy</li>
        </ol>
      </div>
    </div>
  </section>

  <main id="main">
    <section className="blog" id="blog">
        <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="list-group">
                <div className="accordion">
                      {accordionData.map(({content }) => (
                        <Accordion content={content} />
                    ))}
                  </div>
                  
                  
                </div>
              </div>
                <div className="col-lg-9">
                <h1 style={{fontSize:"35px"}}>Copyright and Licensing Policy</h1>
                  <p id='copyright' style={{fontWeight:'bold',marginTop:'10px'}}>Copyright</p>
                  <p className="mt-2" style={{textAlign:'justify'}}>Authors will be asked to transfer copyright of the article to the publisher (or grant the Publisher exclusive publication and dissemination rights). 
                      This will ensure the widest possible protection and dissemination of information under copyright laws.</p>
                  <p id='licensing' style={{fontWeight:'bold'}}>Licensing</p>
                  <img src='..\assets\img\88x31.png' value='' />
                  <p className="mt-2" style={{textAlign:'justify'}}>Dream Science site and its metadata are licensed under CC-BY-NC-ND 4.0</p>

                  
                  <p style={{fontWeight:'bold'}}>Attribution-NonCommercial CC BY-NC-ND 4.0</p>
                  <p style={{textAlign:'justify'}}>Copyright on any open access article published in Dream Science International Journals is retained by the author(s). Authors grant Dream Science a license to publish the article and identify itself as the original publisher. Authors also grant any third party the right to use the article freely as long as its integrity is maintained and its original authors, citation details and publisher are identified.</p>

                  <p style={{fontWeight:'bold'}}>Creative Commons License</p>
                  <p style={{textAlign:'justify'}}>Dream Science International Journals publishes open access articles under a Attribution-NonCommercial-No Derivatives 4.0 International (CC BY-NC-ND 4.0). This license permits user to freely share (copy, distribute and transmit) and adapt the contribution including for commercial purposes, as long as the author is properly attributed.</p>

                  <p style={{fontWeight:'bold'}}>Publishing Rights</p>
                  <p style={{textAlign:'justify'}}>Dream Science allows its author(s) to retain publishing rights without restrictions.</p>
                </div>
        
            </div>
        </div>
    </section>
  </main>
            </>
  )
}
export default Lpolicy;