import React, { useEffect } from 'react';
import './Dsdscyaimandscope.css';
import { NavLink, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dscy/Accordion';
import Ddscyarticletemplate from '../../downloads/DS-CYS-Article_Template.doc';
import Ddscycopyrightform from '../../downloads/DS-CYS-Copyright_Form.docx';

const Dsdscyaimandscope = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const accordionData = [{
        content: <div>
          <NavLink smooth to="/cys" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</NavLink>
           <NavLink to="/cys/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
           <NavLink to="/cys/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
           <NavLink to="/cys/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
           <NavLink to="/cys/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
           <NavLink to="/cys/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
           <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
           <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
           <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
           <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
           <NavLink to="/cys/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
           <a href={Ddscyarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
           <a href={Ddscycopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
        </div>
    },
    ];
    return (

        <>
            <Helmet>
            <title>DS Journal of Cyber Security - DS-CYS</title>
            <link rel="canonical" href="https://dsjournals.com/cys"/>
            <meta name="title" content="DS Journal of Cyber Security - DS-CYS"/>
            <meta name="description" content="Explore cutting-edge research in the field of complex systems with DSCYS Journal. Submit your work for peer-review and join our academic community."/>
            <meta name="keywords" content="cyber security, what is cyber security, cyber security research paper, cyber security challenges, journal of cyber security, information and cyber security, information security services, journal of cyber security, journal of computer security, cyber security journal pdf, cyber security journal articles, journal cyber security, cyber journal, network security journal."/>
            <meta property="og:type" content="Website"/>
            <meta property="og:site_name" content="Dream Science"/>
            <meta property="og:url" content="https://dsjournals.com/cys"/>
            <meta property="og:title" content="DS Journal of Cyber Security - DS-CYS"/>
            <meta property="og:description" content="Explore cutting-edge research in the field of complex systems with DSCYS Journal. Submit your work for peer-review and join our academic community."/>
            <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Cyber-Security.webp"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content="DS Journal of Cyber Security - DS-CYS"/>
            <meta name="twitter:description" content="Explore cutting-edge research in the field of complex systems with DSCYS Journal. Submit your work for peer-review and join our academic community."/>
            <meta name="twitter:site" content="@DreamScience4"/>
            <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
            <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Cyber-Security.webp"/>
            <meta name="robots" content="index, follow"/>

            </Helmet>

            <section id="hero-no-slide-dscy" className="d-flex justify-cntent-center align-items-center" style={{ height: "300px" }}>
                <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <h2 style={{fontSize:"25px"}}>DS Journal of Cyber Security ( DS-CYS )</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <ol>
                            <li><NavLink to="/home">Home</NavLink></li>
                            <li><NavLink to="/journals">Journals</NavLink></li>
                            <li>DS-CYS</li>
                        </ol>
                    </div>
                </div>
            </section>
            <main id="main">
                <section className="blog" id="blog">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 mb-3">
                                <div className="list-group">
                                    <div className="accordion">
                                        {accordionData.map(({ content }) => (
                                            <Accordion content={content} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div id="jorunalcard">
                                    <div className="card" style={{ maxWidth: "950px" }}>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4">
                                                <img id="journalpfimg_p" src="assets/img/DS-Journal-of-Cyber-Security-Bookcover.jpg" className="img-responsive center-block d-block mx-auto" alt="journalprofileimg" />
                                            </div>
                                            <div className="col-lg-9 col-md-8">
                                                <div className="card-body">
                                                    {/* <h6 className="card-title center">DS International Journal of Computer Science and Engineering ( DSCSE )</h6> */}

                                                    <p class="card-text">
                                                        <table className='table'>
                                                            <tr className="pt-1">
                                                                <th scope="row" className="col-md-4 align-top">Editor in Chief</th>
                                                                <td className="col-md-8 px-2">  Dr. Tarak Nandy,<br />
                                                                Institute of Computer Science and Digital Innovation,<br/>
                                                                UCSI University,<br/>
                                                                Malaysia.<br />
                                                                </td>
                                                            </tr>
                                                            <tr className="mt-2">
                                                                <th scope="row" className="col-md-4">ISSN (Online)</th>
                                                                <td className="col-md-8 px-2">2584-0665</td>
                                                            </tr>
                                                            <tr className="mt-2">
                                                                <th scope="row" className="col-md-4">Subject</th>
                                                                <td className="col-md-8 px-2">Cyber Security</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Year of Starting</th>
                                                                <td className="col-md-8 px-2">2023</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Publication Frequency</th>
                                                                <td className="col-md-8 px-2">4 Issue per Year</td>
                                                            </tr>

                                                            <tr>
                                                                <th scope="row" className="col-md-4"> Language</th>
                                                                <td className="col-md-8 px-2">English</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Paper Submission id</th>
                                                                <td className="col-md-8 px-2">security@dsjournals.com</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Format of Publication</th>
                                                                <td className="col-md-8 px-2">online</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4 align-top">Publication Fee</th>
                                                                <td className="col-md-8 px-2">Free of Cost Journal</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4"> Publisher</th>
                                                                <td className="col-md-8 px-2">Dream Science</td>
                                                            </tr>
                                                        </table>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>Aim and Scope</p>
                                <p style={{ textAlign: 'justify', textIndent: "50px" }}>Dream Science Journal of Cyber Security (DS-CYS) is an online, open-access, peer-reviewed journal which provides 
                                a forum for the dissemination of research related to all aspects of sciences, technologies, and applications relating to hardware security, software security and system 
                                security. Cyber Security is the basis of information dissemination in the internet age and a rapidly growing area of computer science and technology, encompassing 
                                software, systems, IT, networks, communications, cryptography, and various other fields across numerous platforms, including traditional computers, smartphones, 
                                tablets, servers and any networkable device, whether Internet-connected or not. DS-CYS is a new journal aimed at publishing World-leading research and is dedicated to 
                                being the primary platform for research in all technical areas of cyber security. This journal is aimed to systematically cover all essential aspects of Cyber Security, 
                                with a focus on reporting on cyberspace security issues, the latest research results, and real-world deployment of security technologies. It will encompass original 
                                research and review papers in computer science, electronics, networking, information technology, systems, telecommunications, and other related areas of security 
                                protocols, approaches and techniques applied to all types of information and communication networks, including wired, wireless and optical transmission platforms. 
                                In addition, the journal also includes interdisciplinary articles that cover the privacy, ethical, legal, and economic aspects of Cyber Security.</p>
                                <p style={{ textAlign: 'justify', textIndent: "50px" }}>
                                The journal also aims at becoming an international source of innovation and an essential reading for IT security professionals around the world by providing an in-depth 
                                and holistic view of all security spectrums and solutions ranging from practical to theoretical. The journal’s goal is to attract a broad spectrum of article types in 
                                order to foster collaboration between different fields working to address the emergence of security and privacy threats and to bring together researchers and 
                                practitioners dealing with the diverse fields of Cyber Security and cover topics that are equally valuable for professionals as well as for those new in the field from 
                                all sectors industry, commerce and academia. The articles can include traditional research-focused articles; educational surveys to instruct the broader technical 
                                community; informative articles that discuss practical policy, legal, and economic aspects of Cyber Security; and editorial or position articles that pose new ideas 
                                and paradigm shifts for the field of Cyber Security. Answering the highly practical and commercial importance of network security R&D, submissions of 
                                applications-oriented papers describing case studies and simulations are encouraged, as well as research analysis-type papers. DS-CYS also accepts the substantially 
                                extended version of the conference papers in the field of cyber security.
                                </p>
                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>DS Journal of Cyber Security solicits articles adhering to the following broadly constructed and interpreted aspects of Cyber Security:</p>
                                <p style={{ textAlign: 'justify' }}>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                        <p><i className="bi bi-chevron-double-right"></i>Advanced Persistent Threat (APT)</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Adversarial Machine Learning</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Adversarial Reasoning</p>
                                        <p><i className="bi bi-chevron-double-right"></i>AI Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Analysis of Vulnerabilities and Attacks</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Anomaly and Intrusion Detection and Countermeasures to Prevent Detection</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Anonymization and De-Identification Technologies</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Applied Cryptography and Cryptographic Protocols</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Authenticated Key Agreement Protocols</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Authentication and Identity Management Technologies</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Big Data Security and Privacy</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Cloud Computing and Security and Privacy Threats in the Cloud</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Computer Science and Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Critical Infrastructure Protection</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Cryptography and Associated Topics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Cryptology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Cyber Attacks</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Cyber Breaches</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Cyber Defence Tools for Improving the Level of Cyber Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Cyber Security Education</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Cyber Security in Internet of Things (IoT)</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Cyber Warfare</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Cyber-Physical Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Cyber Security Data Analytics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Darknet and Darkweb</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Data Loss Prevention (DLP)</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Data-Driven Security and Measurement Studies</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Denial of service (DoS) Against Communication and Computing Services and Countermeasures Against DoS</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Design and Test Strategies for Secure and Survivable Systems</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Digital Forensics & Counter Forensics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Digital Ledger Technology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Economics of Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Emerging Trends in Cyber Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Encryption and Encryption-By-Default</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Firewalls</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Forensics Including Computer and Network Forensics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Hacking</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Hardware Security</p>

                                        </div>
                                        <div className='col-md-6'>
                                        <p><i className="bi bi-chevron-double-right"></i>Information Hiding, Including Steganology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Information-Theoretic Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Insider Threats</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Jamming and Interference</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Key Generation and Key Distribution Schemes</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Key Management Cryptographic Credential Management</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Legal and Public Policy Aspects of Security and Privacy</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Machine Learning Mechanisms for Cyber Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Machine Learning Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Malware and Malware Analysis</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Malware Reverse Engineering and Malware Defense and Mitigation Strategies</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Modern Tools for Improving Cyber Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Network and Critical Infrastructure Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Physical Layer Security and Cross-Layer Security for Communications</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Privacy and Privacy Enhancing Technologies Including Applications to New Domains</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Privacy Attacks and Protection</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Privacy-Enhancing Technologies and Anonymity</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Quantum Computing Based Encryption and Decryption Techniques</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Quantum Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Quantum-Safe Digital Signature Schemes</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Quantum-Safe Encryption Algorithms</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Quantum-Safe Key Management Mechanisms</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Secure Operating Systems and Applications</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Security and Crime Science</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Security and Mobility Optimization</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Security in Mobile Networks</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Security Issues in Intelligent Transportation Systems (ITS)</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Security Issues in Various Networking Technologies</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Security Metrics and The Science Of Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Smart Grid and Power/Energy Systems Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Social Networks Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Software, Hardware and System Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Cyber Threat Detection/Protection</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Traffic Measurements for Cyber Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Usable Security</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Viruses</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Vulnerability Analysis</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Wireless Physical Layer Security</p>
                                        </div>
                                    </div> 
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Dsdscyaimandscope;
