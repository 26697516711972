import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './IssueForm.css';
import { TextField, Button, Typography, Paper } from '@material-ui/core';

import useStyles from './styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';

import Monthissue from './moissuedata.json';
import Yearofissue from './yearofissue.json';

const IssueForm = ({ currentId, setCurrentId }) => {
    const [postData, setPostData] = useState({ journal_title: '', volume: '', issue: '', moissue: '' ,yoissue:''});
    const classes = useStyles();
    const post = useSelector((state) => (currentId ? state.cissueposts.posts.find((jname) => jname._id === currentId) : null));

    const handleSubmit = async (e) => {
        console.log("Form hit");
        console.log(postData.moissue);
        console.log(postData.yoissue);
    }

    const clear = () => {
        // setCurrentId (0);
        setPostData({ journal_title: '', volume: '', issue: '', moissue: '',yoiss:'' });
    };

    return (
        <div className='col-lg-6'>
               <Grid container direction="row" alignItems="center" spacing={2}>
            <Paper className={classes.paper}>
                <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit}>
                    <Typography variant="h6">{currentId ? `Editing "${post.journal_title}"` : 'Issue List Management'}</Typography>
                    <TextField name="journal_title" variant="outlined" label="Journal Name" fullWidth value={postData.journal_title} onChange={(e) => setPostData({ ...postData, journal_title: e.target.value })} />
                    <TextField name="volume" variant="outlined" label="Volume" fullWidth value={postData.volume} onChange={(e) => setPostData({ ...postData, volume: e.target.value })} />
                    <TextField name="issue" variant="outlined" label="Issue" fullWidth value={postData.issue} onChange={(e) => setPostData({ ...postData, issue: e.target.value })} />
                    {/* <TextField name="monthofissue" variant="outlined" label="Month of Issue" fullWidth value={postData.moissue} onChange={(e) => setPostData({ ...postData, moissue: e.target.value })} /> */}
                    <FormControl sx={{ m: 1, width: 300 }}> 
                    <InputLabel id="demo-multiple-name-label">Month of Issue</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        onChange={(e) => setPostData({ ...postData, moissue: e.target.value })}
                    >
                        {Monthissue.map((moiss) => (
                            <MenuItem
                                key={moiss}
                                value={moiss}
                            >
                                {moiss}
                            </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 300 }}> 
                    <InputLabel id="demo-multiple-name-label">Year of Issue</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        onChange={(e) => setPostData({ ...postData, moissue: e.target.value })}
                    >
                        {Yearofissue.map((yoiss) => (
                            <MenuItem
                                key={yoiss}
                                value={yoiss}
                            >
                                {yoiss}
                            </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                   
                    <Button className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="submit">Submit</Button>
                    <Button className={classes.buttonCancel} variant="contained" color="secondary" size="small" onClick={clear} >Clear</Button>
                    {/* <Button className={classes.buttonCancel} variant="contained" color="secondary" size="small" onClick={reset} >Reset</Button> */}
                </form>
            </Paper>
            </Grid>
        </div>
    )
}

export default IssueForm;
