import React,{useEffect}  from 'react';
import './Journalprofile.css';
import { NavLink} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dscse/Accordion';
// import Accordion from '../../components/Accordion';
import Dsjstarticletemplate from '../../downloads/DS-DST-Article_Template.doc';
import Dsjstcopyrightform from '../../downloads/DS-DST-Copyright_Form.docx';



const Journalprofile = () => {

    useEffect(()=>{
        window.scrollTo(0,0);
      },[]);
    
    const accordionData = [{
        content: <div>
            <NavLink smooth to="/dst"            className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Aim and Scope</NavLink>
            <NavLink to="/dst/editorial-board"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
            <NavLink to="/dst/paper-submission"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/dst/current-issue"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/dst/archives"           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/dst/topics"            className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target= "_blank"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target= "_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/dst/apc"               className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
            {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
            <a href={Dsjstarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Dsjstcopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
    </div> 
    },
      ];
      
  return (
    <>
      <Helmet>
        <title>Journal of Digital Science and Technology  - DS Journals</title>
        <link rel="canonical" href="https://dsjournals.com/dst"/>
        <meta name="title" content="Journal of Digital Science and Technology - DS Journals"/>
        <meta name="description" content="Explore the latest trends and advances in data science with DS Journals Data Science Trends. Stay up-to-date with the latest research and insights."/>
        <meta name="keywords" content="digital science, social science digital group, forensic computer science, digital and cyber forensic science, cyber forensic science, science digital, computer science and forensics, wellbeing digital sciences, digital health science, digital humanities and social sciences."/>
        <meta property="og:type" content="Website"/>
        <meta property="og:site_name" content="Seventh Sense Research Group"/>
        <meta property="og:url" content="https://dsjournals.com/dst"/>
        <meta property="og:title" content="Journal of Digital Science and Technology - DS Journals"/>
        <meta property="og:description" content="Explore the latest trends and advances in data science with DS Journals Data Science Trends. Stay up-to-date with the latest research and insights."/>
        <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Digital-Science-and-Technology.webp"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:description" content="Explore the latest trends and advances in data science with DS Journals Data Science Trends. Stay up-to-date with the latest research and insights."/>
        <meta name="twitter:site" content="@sense_groups"/>
        <meta name="twitter:title" content="Journal of Digital Science and Technology - DS Journals"/>
        <meta name="twitter:url" content="https://twitter.com/sense_groups"/>
        <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Digital-Science-and-Technology.webp"/>
        <meta name="robots" content="index, follow"/>
      </Helmet>
     <section id="hero-no-slide-dst" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
                <div className="col-xl-8">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <h1 style={{fontSize:"25px"}}>DS Journal of Digital Science and Technology ( DS-DST )</h1>
                            {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="breadcrumbs">
        <div className="container">
            <div className="d-flex justify-content-between align-items-center">
                <ol>
                <li><NavLink to="/home">Home</NavLink></li>
                <li><NavLink to="/journals">Journals</NavLink></li>
                <li> DS-DST</li>
              </ol>
            </div>
        </div>
    </section>

<main id="main">
<section className="blog" id="blog">
<div className="container">
<div className="row">
    <div className="col-lg-3 mb-3">
      <div className="list-group">
        <div className="accordion">
                        {accordionData.map(({content }) => (
                          <Accordion content={content} />
                      ))}
        </div>
      </div>
   </div>
    <div className="col-lg-9">
      <div id="jorunalcard">
         <div  className="card" style={{maxWidth:"950px"}}>
  <div className="row">
    <div className="col-lg-3 col-md-4">
      <img id="journalpfimg_p" src="assets/img/DS-Journal-of-Digital-Dcinence-And-Technology-Bookcover.jpg" className="img-responsive center-block d-block mx-auto" alt="journalprofileimg"/>
    </div>
    <div className="col-lg-9 col-md-8">
      <div className="card-body">
        {/* <h6 className="card-title center">DS International Journal of Computer Science and Engineering ( DSCSE )</h6> */}
        
        <p class="card-text">
            <table className='table'>
                        <tr className="pt-1">
                            <th scope="row" className="col-md-4 align-top">Editor in Chief</th>
                            <td className="col-md-8 px-2"> Prof. Dr. S. Nallusamy,<br/>
                                                           Department of ACEE, Jadavpur University, <br />
                                                           Kolkata - 700 032, India
                            </td>
                        </tr>
                        <tr className="mt-2">
                            <th scope="row" className="col-md-4">ISSN (Online)</th>
                            <td className="col-md-8 px-2">2583-5416</td>
                        </tr>
                        <tr className="mt-2">
                          <th scope="row" className="col-md-4">Subject</th>
                          <td className="col-md-8 px-2">Digital Science and Technology</td>
                        </tr>
                        <tr>
                            <th scope="row" className="col-md-4">Year of Starting</th>
                            <td className="col-md-8 px-2">2022</td>
                        </tr>
                        <tr>
                            <th scope="row" className="col-md-4">Publication Frequency</th>
                            <td className="col-md-8 px-2">4 Issue per Year</td>
                        </tr>
                        
                        <tr>
                            <th scope="row" className="col-md-4"> Language</th>
                            <td className="col-md-8 px-2">English</td>
                        </tr>
                        <tr>
                            <th scope="row" className="col-md-4">Paper Submission id</th>
                            {/* <td className="col-md-8 px-2">submit2dst@gmail.com</td> */}
                            <td className="col-md-8 px-2">scitech@dsjournals.com</td>
                        </tr>
                        <tr>
                            <th scope="row" className="col-md-4">Format of Publication</th>
                            <td className="col-md-8 px-2">online</td>
                        </tr>
                        <tr>
                            <th scope="row" className="col-md-4 align-top">Publication Fee</th>
                            <td className="col-md-8 px-2">Free of Cost Journal</td>
                        </tr>
                        <tr>
                            <th scope="row" className="col-md-4"> Publisher</th>
                            <td className="col-md-8 px-2">Dream Science</td>
                        </tr>
            </table>
        </p>
       
      </div>
    </div>
  </div>
</div>
</div>
<p style={{textAlign:'justify',fontWeight:"bold"}}>Aim and Scope</p>
<p style={{textAlign:'justify',textIndent:"50px"}}>DS Journal of Digital Science and Technology (DS-DST) is an international, 
multidisciplinary, scholarly peer reviewed research publishing open access Journal. The journal focused on the 
recent research works addressing the integration of digital science in the world of digital applications, 
digital innovations, digital transformations, digital technologies and digitization initiatives in all areas. 
It also covers the theoretical and empirical research and also on the critical analysis of Digital Science and 
technology development, uses, cases, and its impacts. DST emphasizes innovative applications in advanced 
computing, simulation, and analytics, among other cutting-edge techniques.</p>
<p style={{textAlign:'justify',textIndent:"50px"}}>
DST is intended for researchers, academicians and industrialists in the fields of Digital Science and 
technology. The journal publishes peer-reviewed research articles, and also reviews & analyses, short 
communications, case studies, and more. It is primarily interested in the convergence of new technologies, 
such as cloud, analytics, blockchain, robotics, automation, internet of things and digital technologies. 
Subjects in scope include:
</p>
<p style={{textAlign:'justify',fontWeight:"bold"}}>Papers are solicited from, but not limited to the following topics:</p>
<p style={{textAlign:'justify'}}>
<div className='row'>
  <div className='col-md-6'>
  <p><i className="bi bi-chevron-double-right"></i>Artificial and augmented intelligence</p>
  <p><i className="bi bi-chevron-double-right"></i>Artificial neural networks</p>
  <p><i className="bi bi-chevron-double-right"></i>Augmented reality</p>
  <p><i className="bi bi-chevron-double-right"></i>Authentication and identity management technologies</p>
  <p><i className="bi bi-chevron-double-right"></i>Big Data Technologies</p>
  <p><i className="bi bi-chevron-double-right"></i>Bitcoin, cryptocurrency and digital cash</p>
  <p><i className="bi bi-chevron-double-right"></i>Blockchain and emerging technologies</p>
  <p><i className="bi bi-chevron-double-right"></i>Computability theory</p>
  <p><i className="bi bi-chevron-double-right"></i>Computational imaging</p>
  <p><i className="bi bi-chevron-double-right"></i>Computational intelligence, machine learning methods</p>
  <p><i className="bi bi-chevron-double-right"></i>Computational mechanics of solids and fluids</p>
  <p><i className="bi bi-chevron-double-right"></i>Cryptography and coding for signals</p>
  <p><i className="bi bi-chevron-double-right"></i>Cyber security compliance</p>
  <p><i className="bi bi-chevron-double-right"></i>Cybernetics</p>
  <p><i className="bi bi-chevron-double-right"></i>Cyber-physical systems</p>
  <p><i className="bi bi-chevron-double-right"></i>Cybersecurity and data protection</p>
  <p><i className="bi bi-chevron-double-right"></i>Darknet and Darkweb</p>
  <p><i className="bi bi-chevron-double-right"></i>Data mining</p>
  <p><i className="bi bi-chevron-double-right"></i>Deep learning</p>
  <p><i className="bi bi-chevron-double-right"></i>Denial of service (dos)</p>

  </div>
  <div className='col-md-6'>
  <p><i className="bi bi-chevron-double-right"></i>Digital signal processing</p>
  <p><i className="bi bi-chevron-double-right"></i>Digital Communications and Networks</p>
  <p><i className="bi bi-chevron-double-right"></i>Fuzzy logic</p>
  <p><i className="bi bi-chevron-double-right"></i>Genetic algorithms</p>
  <p><i className="bi bi-chevron-double-right"></i>Graphics</p>
  <p><i className="bi bi-chevron-double-right"></i>Green computing</p>
  <p><i className="bi bi-chevron-double-right"></i>Human-Computer Interaction</p>
  <p><i className="bi bi-chevron-double-right"></i>Human-machine interface</p>
  <p><i className="bi bi-chevron-double-right"></i>Information and communications technology</p>
  <p><i className="bi bi-chevron-double-right"></i>Intelligent machine/agent</p>
  <p><i className="bi bi-chevron-double-right"></i>Interaction with computers</p>
  <p><i className="bi bi-chevron-double-right"></i>Internet of Things</p>
  <p><i className="bi bi-chevron-double-right"></i>Machine Learning</p>
  <p><i className="bi bi-chevron-double-right"></i>Neural networks and machine learning</p>
  <p><i className="bi bi-chevron-double-right"></i>Neuroimaging and neuroinformatic</p>
  <p><i className="bi bi-chevron-double-right"></i>Robotics</p>
  <p><i className="bi bi-chevron-double-right"></i>Safety and Security</p>
  <p><i className="bi bi-chevron-double-right"></i>Wireless sensor network</p>
  <p><i className="bi bi-chevron-double-right"></i>Wearable Computing</p>
  <p><i className="bi bi-chevron-double-right"></i>Network security</p>
    </div>
</div>
</p>
{/* <p style={{textAlign:'justify',textIndent:"50px"}}>DS Journal of Digital Science and Technology ( DS - DST ) is a scholarly peer reviewed research publishing Journal.</p>
<h6>Aim and Scope:</h6>
<p id='head1' style={{textAlign:'justify',textIndent:"50px"}}>DS Journal of Digital Science and Technology - DST. The aim of the journal is to share knowledge of algorithms, software, and architectures, 
    and to transmit lessons-learned to a broad scientific audience in the field of Digital Science and Technology. The computational and data-centric problems faced 
    by scientists and engineers transcend disciplines, DST is a cross-disciplinary, international publication that meets this need by presenting contributions of high 
    interest and educational value from a variety of fields, including - but not limited to - application of computer science and engineering in physics, biology, chemistry, 
    and astronomy.</p>
    <p style={{textAlign:'justify',textIndent:"50px"}}>DST emphasizes innovative applications in advanced computing, simulation, and analytics, among other cutting-edge techniques. DST publishes peer-reviewed research 
        articles, and also runs departments spanning news and analyses, topical reviews, tutorials, case studies, and more.</p>
    <h6>Papers are solicited from, but not limited to the following topics:</h6>
    <p> 
        <div className='row'>
        <div className='col-md-6'>
                        <p><i className="bi bi-chevron-double-right"></i>3G/4G Network Evolution</p>
                        <p><i className="bi bi-chevron-double-right"></i>Ad-hoc, Mobile, Wireless Networks</p>
                        <p><i className="bi bi-chevron-double-right"></i>Algorithm Design and Analysis</p>
                        <p><i className="bi bi-chevron-double-right"></i>Artificial Intelligence</p>
                        <p><i className="bi bi-chevron-double-right"></i>Communication Protocol</p>
                        <p><i className="bi bi-chevron-double-right"></i>Communication Technologies</p>
                        <p><i className="bi bi-chevron-double-right"></i>Computer Graphics and Multimedia</p>
                        <p><i className="bi bi-chevron-double-right"></i>Cryptography and Computer Security</p>
                        <p><i className="bi bi-chevron-double-right"></i>Database Management Systems</p>
                        <p><i className="bi bi-chevron-double-right"></i>Data Warehouse &amp; Data Mining</p>
                        <p><i className="bi bi-chevron-double-right"></i>High-Performance Computing</p>   
        </div>
          <div className='col-md-6'>
               
                <p><i className="bi bi-chevron-double-right"></i>IDS/Firewall, Anti-Virus Issues</p> 
                <p><i className="bi bi-chevron-double-right"></i>Internet of Things (IOT)</p> 
                <p><i className="bi bi-chevron-double-right"></i>Mobile Computing</p> 
                <p><i className="bi bi-chevron-double-right"></i>Multi-Processing</p> 
                <p><i className="bi bi-chevron-double-right"></i>Neural Network</p> 
                <p><i className="bi bi-chevron-double-right"></i>Process Control &amp; Instrumentation</p> 
                <p><i className="bi bi-chevron-double-right"></i>Public Key Infrastructures</p> 
                <p><i className="bi bi-chevron-double-right"></i>Quantum and Cloud Computing</p> 
                <p><i className="bi bi-chevron-double-right"></i>Virtual Reality</p> 
                <p><i className="bi bi-chevron-double-right"></i>Wireless Security System</p> 
                <p><i className="bi bi-chevron-double-right"></i>Wireless Sensor Networks</p> 
          </div>
        </div>
    </p>*/}
    </div>
</div>
</div>
</section>
</main>
    </>
  )
}

export default Journalprofile;