import React, { useState, useEffect } from 'react';
import {Link,NavLink,useLocation} from 'react-router-dom';
import Posts from '../../components/Dsms/Articlelist/Posts/Posts';
import { useSelector, useDispatch } from 'react-redux';
import { getjournalPosts } from '../../actions/journal/dsms/posts';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import useStyles from '../../components/styles';
import Accordion from '../../components/Dsm/Accordion';
import Ddsmsarticletemplate from '../../downloads/DS-MS-Article_Template.doc';
import Ddsmscopyrightform from '../../downloads/DS-MS-Copyright_Form.docx';
import Dsmsdata from './dsmsdata';





const Archiveslist = () => {
  const [currentId, setCurrentId] = useState(0);
  const [monthpost, setMonthpost] = useState("");
  const dispatch = useDispatch();

  const posts = useSelector((state) => state.dsmsjposts);

  const currentURL = window.location.href;
  const sQuery = currentURL.split("/");
  const fet_volume = sQuery[5] ? sQuery[5].match(/\d+/g) : "";
  const fet_issue = sQuery[6] ? sQuery[6].match(/\d+/g) : "";
  // const fet_volume = sQuery[5].match(/\d+/g);
  // const fet_issue =  sQuery[6].match(/\d+/g);
  
  const {state} = useLocation();  
  
  const act_volume = !state ? fet_volume[0] : state.volume;
  const act_issue = !state ? fet_issue[0] : state.issue
  const act_issue_month = !state ? "": ", "+state.month_of_issue;

  useEffect(() => {
    dispatch(getjournalPosts());    
  }, [currentId, dispatch]);

  useEffect(() => {
    setMonthpost(posts)    
   });
 
  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  const accordionData = [{
    content: <div>
     <HashLink smooth to="/ms" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
            <NavLink to="/ms/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>   Editorial Board</NavLink>
            <NavLink to="/ms/paper-submission"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/ms/current-issue"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/ms/archives"           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/ms/topics"                           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target= "_blank"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target= "_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/ms/apc"               className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
        {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
            <a href={Ddsmsarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Ddsmscopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
</div> 
},
  ];




    return (
        <>
        <Helmet>
        <title>Archives - DS Journal of Modeling and Simulation</title>
        <link rel="canonical" href="https://dsjournals.com/ms/archives"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="title" content="Archives - DS Journal of Modeling and Simulation"/>
        <meta name="description" content="Explore the archives of DS-MS Journal and gain new insights into historical scientific developments. Access valuable research from your field today."/>
        <meta name="keywords" content="recent research paper in Modeling and Simulation, archives of Modeling and Simulation journal, a journal article, a journal of Modeling and Simulation research, a Modeling and Simulation peer reviewed journal, article for journal, journal Modeling and Simulation, Modeling and Simulation research, a Modeling and Simulation journal, journal of Modeling and Simulation research, research in Modeling and Simulation."/>
        <meta property="og:type" content="Website"/>
        <meta property="og:site_name" content="Dream Science"/>
        <meta property="og:url" content="https://dsjournals.com/ms/archives"/>
        <meta property="og:title" content="Archives - DS Journal of Modeling and Simulation"/>
        <meta property="og:description" content="Explore the archives of DS-MS Journal and gain new insights into historical scientific developments. Access valuable research from your field today."/>
        <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of -Modeling-and-Simulation.webp"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="Archives - DS Journal of Modeling and Simulation"/>
        <meta name="twitter:description" content="Explore the archives of DS-MS Journal and gain new insights into historical scientific developments. Access valuable research from your field today."/>
        <meta name="twitter:site" content="@DreamScience4"/>
        <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
        <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of -Modeling-and-Simulation.webp"/>
        <meta name="robots" content="index, follow"/>
        </Helmet>   

           <section id="hero-no-slide-dsms" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
          <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                  <h2 style={{fontSize:"25px",color:"#21494f"}}>{Dsmsdata[0].banner_title}</h2>
                    {/* <h2 style={{color:"#21494f"}}>Article List</h2> */}
                    {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                  </div>
              </div>
            </div>
          </div>
          </div>
        </section>
        
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              {/* <h2>Archives</h2> */}
              <ol>
                <li><Link to="/home">Home</Link></li>
                <li><Link to="/journals">Journals</Link></li>
                <li><Link to="/ms/archives">Archives</Link></li>
                <li>Article List</li>
              </ol>
            </div>
          </div>
        </section>
       
             <main id="main">
                <section className="blog" id="blog">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                              <div className="accordion">
                                    {accordionData.map(({content }) => (
                                      <Accordion content={content} />
                                  ))}
                              </div>
                            </div>
                            
                            <div className="col-lg-9">
                                <h5 className='mt-3'>List of Articles</h5>
                                <div className="row mt-3">
                                    <div className="col-lg-12 text-center">
                                    {/* <h5 style={{alignItems:'center'}}> Volume 1 Issue 1 </h5> */}
                                    <h5 style={{alignItems:'center'}}> Volume {act_volume} Issue {act_issue} {act_issue_month}</h5>
                                    </div>
                                
                                </div>

                                  <div className='row'>
                                    <div className='col-lg-12'>
                                        {/* <Posts setCurrentId={setCurrentId} />   */}
                                        <Posts setCurrentId={setCurrentId} volume={state.volume} issue={state.issue}/>  
                                        
                                    </div>
                                                      
            {/* 
                                  <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                      <i className='bi bi-file-earmark-fill'></i> Research Article <i className='bi bi-diamond-half'></i> DSST-20213001 
                                      </Typography>
                                      <Typography variant="h6" component="div">
                                      <Link to="/archivesview">Common Fixed Point Theorems for a Pair of Self-Mappings in Fuzzy Cone Metric Spaces</Link>
                                      </Typography>
                                      <Typography variant="body2">
                                      Saif Ur Rehman , Yongjin Li , Shamoona Jabeen , and Tayyab Mahmood
                                      </Typography>
                                    </CardContent>
                                  </Card> */}

                                  </div>
                                
                                    {/* <div className='row'>
                                    <div className='col-lg-12'>
                                        <Paper className={classes.pagination} elevation={6}>
                                          <Pagination/>
                                        </Paper>
                                    </div>
                                    </div> */}

                                {/* <div className="row">
                                    <div className="table-responsive-lg">
                                    <table style={{width:"100%"}}>
                                    <tr>
                                        <th style={{padding:"10px",textAlign:"center",border:"1px solid #000"}}>S.No</th>
                                        <th style={{padding:"10px",textAlign:"center",border:"1px solid #000"}}>Article Description</th>
                                        <th style={{padding:"10px",textAlign:"center",border:"1px solid #000"}}>Article Id</th>
                                    </tr>
                                    <tr>
                                        <td style={{padding:"0 0 20px 0",textAlign:"center",border:"1px solid #000"}}>1</td>
                                        <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>
                                            <Link to="/archivesview" style={{fontWeight:"bold"}}>Common Fixed Point Theorems for a Pair of Self-Mappings in Fuzzy Cone Metric Spaces</Link><br/>
                                        <p style={{fontSize:"12px"}}>
                                            <span style={{fontWeight:"bold"}}><i class="bi bi-pencil-square"></i> Saif Ur Rehman <sup>1</sup>, Yongjin Li <sup>2</sup>, Shamoona Jabeen<sup>3</sup>, and Tayyab Mahmood<sup>4</sup></span>
                                        </p>
                                        </td>
                                        <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>IJCSE-20213001</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding:"0 0 20px 0",textAlign:"center",border:"1px solid #000"}}>2</td>
                                        <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>
                                            <Link to="/archivesview" style={{fontWeight:"bold"}}>Common Fixed Point Theorems for a Pair of Self-Mappings in Fuzzy Cone Metric Spaces</Link><br/>
                                        <p style={{fontSize:"12px"}}>
                                            <span style={{fontWeight:"bold"}}><i class="bi bi-pencil-square"></i> Saif Ur Rehman <sup>1</sup>, Yongjin Li <sup>2</sup>, Shamoona Jabeen<sup>3</sup>, and Tayyab Mahmood<sup>4</sup></span>
                                        </p>
                                        </td>
                                        <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>IJCSE-20213001</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding:"0 0 20px 0",textAlign:"center",border:"1px solid #000"}}>3</td>
                                        <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>
                                            <Link to="/archivesview" style={{fontWeight:"bold"}}>Common Fixed Point Theorems for a Pair of Self-Mappings in Fuzzy Cone Metric Spaces</Link><br/>
                                        <p style={{fontSize:"12px"}}>
                                            <span style={{fontWeight:"bold"}}><i class="bi bi-pencil-square"></i> Saif Ur Rehman <sup>1</sup>, Yongjin Li <sup>2</sup>, Shamoona Jabeen<sup>3</sup>, and Tayyab Mahmood<sup>4</sup></span>
                                        </p>
                                        </td>
                                        <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>IJCSE-20213001</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding:"0 0 20px 0",textAlign:"center",border:"1px solid #000"}}>4</td>
                                        <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>
                                            <Link to="/archivesview" style={{fontWeight:"bold"}}>Common Fixed Point Theorems for a Pair of Self-Mappings in Fuzzy Cone Metric Spaces</Link><br/>
                                        <p style={{fontSize:"12px"}}>
                                            <span style={{fontWeight:"bold"}}><i class="bi bi-pencil-square"></i> Saif Ur Rehman <sup>1</sup>, Yongjin Li <sup>2</sup>, Shamoona Jabeen<sup>3</sup>, and Tayyab Mahmood<sup>4</sup></span>
                                        </p>
                                        </td>
                                        <td style={{padding:"10px",textAlign:"justify",border:"1px solid #000"}}>IJCSE-20213001</td>
                                    </tr>
                                </table>
                                    </div>
                              
                                
                                </div> */}
                              

                            </div>
                          
                        </div>
                    </div>

                </section>


              </main>     
        </>
    )
}
export default Archiveslist;