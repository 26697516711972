import React,{useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link,NavLink } from 'react-router-dom';
import Accordion from '../components/Accordion';

const Correction_policy = () => {
    useEffect(()=>{
        window.scrollTo(0,0);
      },[]);
      const accordionData = [{
        content: <div>
            <NavLink  to="/for-authors/publication-ethics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics </NavLink>
            <NavLink  to="/for-authors/author-guideline" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Step by Step Guidelines for Authors</NavLink>
            <NavLink  to="/for-authors/submitting-a-manuscript" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Submitting a Manuscript</NavLink>
            <NavLink  to="/for-authors/open-access-author" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is Open Access ?</NavLink>
            <NavLink  to="/for-authors/review-process" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Review Process</NavLink>
            <NavLink  to="/for-authors/conflicts-of-interest" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Conflicts of Interest</NavLink>
            <NavLink  to="/for-authors/licensing-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Licensing Policy</NavLink>
            <NavLink  to="/for-authors/copyright-infringement" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Copyright Infringement</NavLink>
            <NavLink  to="/for-authors/correction-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Correction Policy</NavLink>
        <NavLink  to="/for-authors/what-is-apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is APC ?</NavLink>
            {/* <HashLink smooth to="#" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>How to subscribe the hard copy of journal ?</HashLink> */}    
    </div> 
    },
      ];
      return (
        <>  
        <Helmet>
        <title>DS Journals Correction Policy for Authors</title>
        <link rel="canonical" href="https://dsjournals.com/for-authors/correction-policy"/>
        <meta name="title" content="DS Journals Correction Policy for Authors"/>
        <meta name="description" content="DS Journals strives for accuracy and transparency in published work. Learn about our correction policy for authors, including retractions and errata."/>
        <meta name="keywords" content="correction policy, author guidelines, published research, research accuracy, journal publication, research publication, published research papers, research paper publication, research and publication ethics, free publication journals, author manuscript, peer reviewed publications."/>
        <meta property="og:type" content="Website"/>
        <meta property="og:site_name" content="Dream Science"/>
        <meta property="og:url" content="https://dsjournals.com/for-authors/correction-policy"/>
        <meta property="og:title" content="DS Journals Correction Policy for Authors"/>
        <meta property="og:description" content="DS Journals strives for accuracy and transparency in published work. Learn about our correction policy for authors, including retractions and errata."/>
        <meta property="og:image" content="image url"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:description" content="DS Journals strives for accuracy and transparency in published work. Learn about our correction policy for authors, including retractions and errata."/>
        <meta name="twitter:site" content="@DreamScience4"/>
        <meta name="twitter:title" content="DS Journals Correction Policy for Authors"/>
        <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
        <meta name="twitter:image" content="image url"/>
        <meta name="robots" content="index, follow"/>

        </Helmet>
        <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
                  <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div className="row justify-content-center">
                          <div className="col-xl-8">
                            <h2>Correction Policy</h2>
                          </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </section>
      
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
             
            <ol>
              <li><Link to="/home">Home</Link></li>
              <li><Link to="/for-authors">Authors</Link></li>
              <li>Correction Policy</li>
            </ol>
          </div>
        </div>
      </section>
    
      <main id="main">
        <section className="blog" id="blog">
            <div className="container">
                <div className="row">
                <div className="col-lg-3">
     <div className="list-group">
      <div className="accordion">
                      {accordionData.map(({content }) => (
                        <Accordion content={content} />
                    ))}
                  </div>
      </div>
     </div>
                    <div className="col-lg-9">
                    <h1 style={{fontSize:"35px"}}>Correction Policy</h1>
                      <h6>Online Corrections</h6>
                      <p className="mt-2" style={{textAlign:'justify'}}>The final and complete version of an 
                      article is the one that is posted online. Although it is feasible to correct this 
                      version, it is against our policy (and that of other publishers) to do so unless 
                      absolutely necessary. Typographical mistakes may only be fixed in the following areas: 
                      author names, affiliations, paper titles, abstracts, and keywords. In these situations, 
                      it would also be essential to issue an erratum or corrigendum (see below), so that there 
                      would be a record to document the discrepancy between the online and print versions.<br/><br/>
                      Send an email to<br/>
                      queries@dsjournals.com<br/>
                        if you need any changes to be effected.

                      </p>
                    <h6>Errata</h6>
                      <p style={{textAlign:'justify'}}>If a significant error was made during the production of the journal article (one that has an impact on the record of publications, the scientific integrity of the paper, the reputation of the authors, or the reputation of the journal), an erratum will be used. This includes errors of omission like failing to make factual proof corrections requested by authors by the journal within the deadline provided by the journal and in accordance with journal policy.</p>
                      <p style={{textAlign:'justify'}}>Except in cases when a seemingly little error has a severe impact, we do not post errata for typing errors (for example, an incorrect unit). An erratum is a fresh corrected figure or table that is published when a serious mistake in a figure or table has been discovered. Only if the editor deems it essential will the figure or table be reposted.</p>
                      <h6>Corrigenda</h6>
                      <p style={{textAlign:'justify'}}>A corrigendum is a statement that the writers of the article committed a significant error. To be published, corrigenda must be signed by all writers. When co-authors disagree, the editors will implement the necessary adjustment on the recommendation of independent peer-reviewers, mentioning the dissenting author(s) in the text of the published edition.</p>
                      <h6>Addenda</h6>
                      <p style={{textAlign:'justify'}}>An addendum serves as notice that material has been peer-reviewed added to a document, maybe in response to a reader's clarification request. Addenda do not change the content of the original publication, but they may be added if the author unintentionally left out important information that was at the time accessible. Rarely, and only when the editors determine that the addendum is essential to the reader's comprehension of a sizable portion of the original contribution, are addenda published.</p>
                    </div>
            
                </div>
            </div>
        </section>
      </main>
                </>
      )
}

export default Correction_policy