import React,{ useState, useEffect } from 'react';
import Posts from '../../../components/Hmcardpost/Middle/Posts/Posts';
import {gethmcardPosts} from '../../../actions/card/middle/posts';
import { useDispatch } from 'react-redux';

const Mcardhome = () => {
    const [currentId,setCurrentId] = useState(0);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(gethmcardPosts());
    },[currentId,dispatch]);
    return (
        <div>
            <Posts setCurrentId={setCurrentId} />
        </div>
    )
}

export default Mcardhome;
