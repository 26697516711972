import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import '../pages/Forauthors.css';
import Accordion from './Accordion';
import{HashLink} from 'react-router-hash-link';
import { Helmet } from 'react-helmet';


function OpenAccess() {

  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  const accordionData = [{
    content: <div>
       
       <HashLink smooth to="#head1" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Open Access</HashLink>
                  
</div> 
},
  ];
  return (
    <>  
    <Helmet>
      <title>Open Access | DS Journals | Scholarly Publishing</title>
      <link rel="canonical" href="https://dsjournals.com/open-access"/>
      <meta name="title" content="Open Access | DS Journals | Scholarly Publishing"/>
      <meta name="description" content="DS Journals provides open access publication with rigorous peer-review for scholarly articles and research. Publish with us and increase your impact."/>
      <meta name="keywords" content="open access, open access publisher, open access journals, open journal systems, publishing journals, open access in library, open access articles, open access journals list, open publishing, open access journals publishers"/>
      <meta property="og:type" content="Website"/>
      <meta property="og:site_name" content="Dream Science"/>
      <meta property="og:url" content="https://dsjournals.com/open-access"/>
      <meta property="og:title" content="Open Access | DS Journals | Scholarly Publishing"/>
      <meta property="og:description" content="DS Journals provides open access publication with rigorous peer-review for scholarly articles and research. Publish with us and increase your impact."/>
      <meta property="og:image" content="image url"/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:description" content="DS Journals provides open access publication with rigorous peer-review for scholarly articles and research. Publish with us and increase your impact."/>
      <meta name="twitter:site" content="@DreamScience4"/>
      <meta name="twitter:title" content="Open Access | DS Journals | Scholarly Publishing"/>
      <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
      <meta name="twitter:image" content="image url"/>
      <meta name="robots" content="index, follow"/>

    </Helmet>
    <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
              <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <h2>Open Access</h2>
                      </div>
                  </div>
                </div>
              </div>
              </div>
            </section>
  
  <section className="breadcrumbs">
    <div className="container">
      <div className="d-flex justify-content-between align-items-center">
        {/* <h2>Open Access</h2> */}
        <ol>
          <li><Link to="/home">Home</Link></li>
          <li>Open Access</li>
        </ol>
      </div>
    </div>
  </section>

  <main id="main">
    <section className="blog" id="blog">
        <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="list-group">
                <div className="accordion">
                      {accordionData.map(({content }) => (
                        <Accordion content={content} />
                    ))}
                  </div>
                  
                </div>
              </div>
                <div className="col-lg-9">
                  <h3>Open Access</h3>
            <p style={{textAlign:'justify',fontStyle:'italic'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;By 'open access' to this literature, we mean its free availability on the public internet, permitting any users to read, download, copy, distribute, print, 
              search, or link to the full texts of these articles, crawl them for indexing, pass them as data to software, or use them for any other lawful purpose, without 
              financial, legal, or technical barriers other than those inseparable from gaining access to the internet itself.</p>
            <p style={{textAlignLast:'right',fontStyle:'italic'}}>– The Budapest Open Access Initiative</p>
            <p style={{textAlign:'justify'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DREAM SCIENCE publishes all of our journals using a Gold Open Access model. All articles published in DREAM SCIENCE journals are available to readers completely free 
              of charge. Access is made possible by a fee for publication that is paid at the time of article acceptance, usually by an author’s institution or funder. Articles 
              become available immediately upon publication and a visitor to dsjournal.com can immediately access, download, print, and read any published article in its entirety. 
              Author(s) retain copyright of their work, but readers are free to reuse the material (providing proper citations are given), as all DREAM SCIENCE articles are published 
              under the Creative Commons Attribution License (CC-BY).</p>
              <p style={{textAlign:'justify'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Open Access ensures that articles are free at the point of consumption. Rather than charge for access, Open Access publishers apply an initial fee at the time an 
              article is accepted for publication. This fee, known as an Article Processing Charge (APC), covers the costs of the production process. Often this charge will be 
              covered by the authors’ affiliated institution(s), or by the funding body responsible for commissioning the research. See ‘Article Processing Charges’ for more details.</p>
              <p style={{textAlign:'justify'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;By using a liberal copyright license, Open Access removes barriers and allows researchers to make use of articles in new ways. This includes practices like 
                meta-analysis and text mining, which help reveal trends in science that would not be obvious at the level of individual articles.</p>                   
                </div>
            </div>
        </div>
    </section>
  </main>
            </>
  )
}
export default OpenAccess;