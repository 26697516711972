import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root' : {
      margin: theme.spacing(1),
    },
  },

  paper: {
    padding: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  fileInput: {
    width: '100%',
    margin: '10px 0',
  },
  buttonSubmit: {
    // marginBottom: 10,
    marginRight: '10px',
  },
  buttonCancel:{
    marginBottom: 10,
    margin: '10px',
  },
  gridMargin:{
    marginTop:10,
    marginBottom:20,
    height: '10px',
  },
  quill:{
    width:'750px',
  }
}));
