import React, { Component, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './Dashboard.css';
import Dsidebar         from './Dsidebar';
import Dheader          from './Dheader';
import Fhome            from './Fhome';
import Leftcard         from './Leftcard';
import Middlecard       from './Middlecard';
import Rightcard        from './Rightcard';
import Journalpage      from './Journalpage';
import Dcontent         from './Dcontent';
import Currentissue     from './currentissue/Currentissue';
import Dviewjournal     from './Dviewjournal';
import Dcreatejournal   from './Dcreatejournal';
import Testpage         from './test/Fronthome';
import Dsstviewdata     from './dsst/Dsstview';
import Dstsingleview    from './dsst/Dstsingleview';
import Dsjsctupdatedata from './dsst/Dsjscupdate';
//-------import for Journal of Language, Lingustics and Literature --------------------------//
import Dslllviewjournal      from './dslll/Dviewjournal';
import Dslllcreatejournal    from './dslll/Dcreatejournal';
import Dslllviewdata         from './dslll/Dlanview';
import Dslllsingleview       from './dslll/Dlansingleview';
import Dslllupdatedata       from './dslll/Dslllupdate';
import Adminroutes from './Adminroutes';


// class Dashboard extends Component {

//     constructor(props){
//         super(props);
//         this.handleload =  this.handleload.bind(this);
//     }
//     componentDidMount(){
//         window.addEventListener('load',this.handleload);
//     }
//     componentWillUnmount(){
//         window.addEventListener('load',this.handleload);
//     }
//     handleload(){
//         let arrow = document.querySelectorAll(".arrow");
//         for (var i = 0; i < arrow.length; i++) {
//         arrow[i].addEventListener("click", (e)=>{
//         let arrowParent = e.target.parentElement.parentElement;//selecting main parent of arrow
//         arrowParent.classList.toggle("showMenu");
//         });
//         }
//     let sidebar = document.querySelector("#dsidebar");
//     let sidebarBtn = document.querySelector(".sidebarBtn");
//     sidebarBtn.onclick = function() {
//         sidebar.classList.toggle("close");
//             if(sidebar.classList.contains("close")){
//                 sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
//             }else
//             sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
//             }
//     }



//     render() {
        
//         // window.onload = function(){
//         //         let arrow = document.querySelectorAll(".arrow");
//         //         for (var i = 0; i < arrow.length; i++) {
//         //         arrow[i].addEventListener("click", (e)=>{
//         //         let arrowParent = e.target.parentElement.parentElement;//selecting main parent of arrow
//         //         arrowParent.classList.toggle("showMenu");
//         //         });
//         //         }
//         //     let sidebar = document.querySelector("#dsidebar");
//         // let sidebarBtn = document.querySelector(".sidebarBtn");
//         //     sidebarBtn.onclick = function() {
//         //         sidebar.classList.toggle("close");
//         //             if(sidebar.classList.contains("close")){
//         //                 sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
//         //             }else
//         //             sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
//         //             }
                   
//         // }
//         return (
//             <>
//                 <Router>
//                 <Dsidebar/>
//                 <Dheader/>
//                     <Switch>
//                     <Route exact path="/dashboard" component={Dcontent}>
//                         <Dcontent/>
//                     </Route>
//                     <Route exact path="/fronthome" component={Fhome}>
//                         <Fhome/>
//                     </Route>
//                     <Route exact path="/testpage" component={Testpage}>
//                         <Testpage/>
//                     </Route>
//                     <Route exact path="/leftcard" component={Leftcard}>
//                         <Leftcard/>
//                     </Route>
//                     <Route exact path="/midcard" component={Middlecard}> 
//                         <Middlecard/>
//                     </Route>
//                     <Route exact path="/rightcard" component={Rightcard}>
//                         <Rightcard/>    
//                     </Route>
//                     <Route exact path="/journalpage" component={Journalpage}>
//                         <Journalpage/>
//                     </Route>
//                     <Route exact path="/dashboard/currentissue" component={Currentissue}>
//                         <Currentissue/>
//                     </Route>

//                     {/* -------------------------- Journal of Digital Science and Technology -------------------------- */}
//                     <Route exact path="/dcreatejournal" component={Dcreatejournal}>
//                         <Dcreatejournal/>
//                     </Route>
//                     <Route exact path="/dviewjournal" component={Dviewjournal}>
//                         <Dviewjournal/>
//                     </Route>
//                     <Route exact path="/dsstviewdata" component={Dsstviewdata}>
//                         <Dsstviewdata/>
//                     </Route>
//                     <Route exact path="/dstdata/:id" component={Dstsingleview}>
//                         <Dstsingleview/>
//                     </Route>
//                     {/* <Route exact path="/dsjstupdatedata/:id" component={Dsjsctupdatedata}>
//                         <Dsjsctupdatedata/>
//                     </Route> */}

//                     <Route exact path="/dsjstupdatedata/:id" component={Dcreatejournal}>
//                         <Dcreatejournal/>
//                     </Route>

//                     {/* -------------------------- Journal of Language, Lingustics and Literature -------------------------- */}
//                     <Route exact path="/dlll/dslllviewjournal" component={Dslllviewjournal}>
//                         <Dslllviewjournal/>
//                     </Route>                  
//                     <Route exact path="/dlll/dslllcreatejournal" component={Dslllcreatejournal}>
//                         <Dslllcreatejournal/>
//                     </Route>
//                     <Route exact path="/dlll/dslllviewdata" component={Dslllviewdata}>
//                         <Dslllviewdata/>
//                     </Route>
//                     <Route exact path="/dlll/dsllldata/:id" component={Dslllsingleview}>
//                         <Dslllsingleview/>
//                     </Route>
//                     {/* <Route exact path="/dlll/dslllupdatedata/:id" component={Dslllupdatedata}>
//                         <Dslllupdatedata/>
//                     </Route> */}
//                     <Route exact path="/dlll/dslllupdatedata/:id" component={Dslllcreatejournal}>
//                     <Dslllcreatejournal/>
//                     </Route>
//                     </Switch>
//                 </Router>
                
              
//             </>
//         )
//     }
// }
// export default Dashboard;

// function DummyView () {
//     const authreducer = useSelector((state)=> state.auth);
//     return(
//         authreducer ? console.log("authenticated",authreducer) : console.log("not authenticated")
//     );
// }

class Dashboard extends Component {

    constructor(props){
        super(props);
    }

    // constructor(props){
    //     super(props);
    //     this.handleload =  this.handleload.bind(this);
    // }
    
    // componentDidMount(){
    //     window.addEventListener('load',this.handleload);
    // }
    // componentWillUnmount(){
    //     window.addEventListener('load',this.handleload);
    // }
    // handleload(){
    //     let arrow = document.querySelectorAll(".arrow");
    //     for (var i = 0; i < arrow.length; i++) {
    //     arrow[i].addEventListener("click", (e)=>{
    //     let arrowParent = e.target.parentElement.parentElement;//selecting main parent of arrow
    //     arrowParent.classList.toggle("showMenu");
    //     });
    //     }
    // let sidebar = document.querySelector("#dsidebar");
    // let sidebarBtn = document.querySelector(".sidebarBtn");
 
    // sidebarBtn.onclick = function() {
    //     sidebar.classList.toggle("close");
    //         if(sidebar.classList.contains("close")){
    //             sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
    //         }else
    //         sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
    //         }
    // }



    render() {
    
        return (
            <>
            <Router>
                <Switch>
                    <Adminroutes/>
                </Switch>
            </Router>
           
                {/* <Router>
                <Dsidebar/>
                <Dheader/>
                    <Switch>
                    <Route exact path="/dashboard" component={Dcontent}>
                        <Dcontent/>
                    </Route>
                    <Route exact path="/fronthome" component={Fhome}>
                        <Fhome/>
                    </Route>
                    <Route exact path="/testpage" component={Testpage}>
                        <Testpage/>
                    </Route>
                    <Route exact path="/leftcard" component={Leftcard}>
                        <Leftcard/>
                    </Route>
                    <Route exact path="/midcard" component={Middlecard}> 
                        <Middlecard/>
                    </Route>
                    <Route exact path="/rightcard" component={Rightcard}>
                        <Rightcard/>    
                    </Route>
                    <Route exact path="/journalpage" component={Journalpage}>
                        <Journalpage/>
                    </Route>
                    <Route exact path="/dashboard/currentissue" component={Currentissue}>
                        <Currentissue/>
                    </Route>

                    -------------------------- Journal of Digital Science and Technology --------------------------
                    <Route exact path="/dcreatejournal" component={Dcreatejournal}>
                        <Dcreatejournal/>
                    </Route>
                    <Route exact path="/dviewjournal" component={Dviewjournal}>
                        <Dviewjournal/>
                    </Route>
                    <Route exact path="/dsstviewdata" component={Dsstviewdata}>
                        <Dsstviewdata/>
                    </Route>
                    <Route exact path="/dstdata/:id" component={Dstsingleview}>
                        <Dstsingleview/>
                    </Route>
                    

                    <Route exact path="/dsjstupdatedata/:id" component={Dcreatejournal}>
                        <Dcreatejournal/>
                    </Route>

                    -------------------------- Journal of Language, Lingustics and Literature --------------------------
                    <Route exact path="/dlll/dslllviewjournal" component={Dslllviewjournal}>
                        <Dslllviewjournal/>
                    </Route>                  
                    <Route exact path="/dlll/dslllcreatejournal" component={Dslllcreatejournal}>
                        <Dslllcreatejournal/>
                    </Route>
                    <Route exact path="/dlll/dslllviewdata" component={Dslllviewdata}>
                        <Dslllviewdata/>
                    </Route>
                    <Route exact path="/dlll/dsllldata/:id" component={Dslllsingleview}>
                        <Dslllsingleview/>
                    </Route>
                 
                    <Route exact path="/dlll/dslllupdatedata/:id" component={Dslllcreatejournal}>
                    <Dslllcreatejournal/>
                    </Route>
                    </Switch>
                </Router> */}
                
              
            </>
        )
    }
}
export default Dashboard;