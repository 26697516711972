import React,{useEffect} from 'react';
import { Link,NavLink } from 'react-router-dom';
import Accordion from '../components/Accordion';
import { Helmet } from 'react-helmet';



const Important_items = () => {
    useEffect(() => {
        window.scrollTo(0,0);
      }, [])
    const accordionData = [{
        content: <div>
            <NavLink  to="/for-authors/publication-ethics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics </NavLink>
            <NavLink  to="/for-authors/author-guideline" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Step by Step Guidelines for Authors</NavLink>
            <NavLink  to="/for-authors/submitting-a-manuscript" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Submitting a Manuscript</NavLink>
            <NavLink  to="/for-authors/open-access-author" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is Open Access ?</NavLink>
            <NavLink  to="/for-authors/review-process" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Review Process</NavLink>
            <NavLink  to="/for-authors/conflicts-of-interest" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Conflicts of Interest</NavLink>
            <NavLink  to="/for-authors/licensing-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Licensing Policy</NavLink>
            <NavLink  to="/for-authors/copyright-infringement" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Copyright Infringement</NavLink>
            <NavLink  to="/for-authors/correction-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Correction Policy</NavLink>
        <NavLink  to="/for-authors/what-is-apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is APC ?</NavLink>
            {/* <HashLink smooth to="#" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>How to subscribe the hard copy of journal ?</HashLink> */}    
    </div> 
    },
      ];

  return (
    <>
    <Helmet>
    <title>Important Items to Submit a Manuscript in DS Journals Authors</title>
    <link rel="canonical" href="https://dsjournals.com/for-authors/important-items"/>
    <meta name="title" content="Important Items to Submit a Manuscript in DS Journals Authors"/>
    <meta name="description" content="DS Journals authors, don't miss these important items! Find out about formatting, submission requirements, fees, and more before submitting your work."/>
    <meta name="keywords" content="paper submission, paper submission in ds journal, journal publication free of charge, research paper submission, research paper submission guidelines, research paper submission process, research paper presentation, journal submission process, journal submission, international journal submission, paper submission process, journal submission guidelines, manuscript submission guidelines"/>
    <meta property="og:type" content="Website"/>
    <meta property="og:site_name" content="Dream Science"/>
    <meta property="og:url" content="https://dsjournals.com/for-authors/important-items"/>
    <meta property="og:title" content="Important Items to Submit a Manuscript in DS Journals Authors"/>
    <meta property="og:description" content="DS Journals authors, don't miss these important items! Find out about formatting, submission requirements, fees, and more before submitting your work."/>
    <meta property="og:image" content="image url"/>
    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:description" content="DS Journals authors, don't miss these important items! Find out about formatting, submission requirements, fees, and more before submitting your work."/>
    <meta name="twitter:site" content="@DreamScience4"/>
    <meta name="twitter:title" content="Important Items to Submit a Manuscript in DS Journals Authors"/>
    <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
    <meta name="twitter:image" content="image url"/>
    <meta name="robots" content="index, follow"/>
    </Helmet>
    <section id="hero-no-slider" style={{height:"300px"}} className="d-flex justify-cntent-center align-items-center">
     <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
       <div className="row justify-content-center">
         <div className="col-xl-8">
           <div className="row justify-content-center">
             <div className="col-xl-8">
               <h2>Submitting a Manuscript</h2>
               {/* <Link to="#" className="btn-get-started ">Read More</Link> */}
             </div>
         </div>
       </div>
     </div>
     </div>
   </section>

<section className="breadcrumbs">
<div className="container">
<div className="d-flex justify-content-between align-items-center">
{/* <h2>Publication Ethics</h2> */}
<ol>
 <li><Link to="/home">Home</Link></li>
 <li><Link to="/for-authors">Authors</Link></li>
 <li>Submitting a Manuscript</li>
</ol>
</div>
</div>
</section>

<main id="main">
<section className="blog" id="blog">
<div className="container">
   <div className="row">
     <div className="col-lg-3">
     <div className="list-group">
      <div className="accordion">
                      {accordionData.map(({content }) => (
                        <Accordion content={content} />
                    ))}
                  </div>
      </div>
     </div>
       <div className="col-lg-9">
      
       <h1 style={{fontSize:"35px"}}>Summary of Important Items When Submitting a Manuscript</h1>
         <p style={{textAlign:"justify"}}>
                <ul>
                    <li>Make sure your paper has content that is appropriate for the journal. Unreviewed publications that are outside of scope will be sent back to the writers. Please read the journal's Aim & Scope.</li>
                    <li>Please read the publishing ethics webpage.</li>
                    <li>Please include a cover letter outlining the manuscript's significance.</li>
                    <li>The three most crucial screening criteria are believed to be correctness, grammar, and spelling. Your text will be returned if it has any grammatical or spelling mistakes.</li>
                    <li>At the editor's discretion, manuscripts that do not satisfy the novelty, significance, and competence requirements (Aim & Scope of the Journal) may be returned to authors at any time.</li>
                    <li>Make sure that figures are properly labeled (with coordinates, a scale bar, and an orientation) and that the resolution is acceptable for the scale of the publication.</li>
                    <li>Decide whether your article should be a research, application, review, or short note (see Aim & Scope of the journal). Make sure your text doesn't exceed the word count for the article type you select. Note that the maximum word count only considers the text, not the abstract, keywords, references, or captions.</li>
                    <li>Address the reviewers' remarks in detail, point by point, if you are submitting a revised article. This includes any requests for linguistic alterations.</li>
                    <li>The structure of your manuscript must be, in brief:
                        <ul style={{listStyle:"none"}}>
                            <li>(a) Word processing format (i.e. MS Word or LaTeX during the submission). The amended paper should not be sent as a PDF file.</li>
                            <li>(b) Single column.</li>
                            <li>(c) Double-spaced lines.</li>
                            <li>(d) Line numbers.</li>
                            <li>(e) Follow the journal's reference format requirements. </li>
                            <li>(f) separate and correctly labelled figures must be uploaded throughout the submission process.</li>
                            <li>(g) At the end of the text document, include a separate list of the captions for the figures and tables.</li>
                        </ul>
                    </li>
                </ul>
            </p>
       </div>

   </div>
</div>
</section>
</main>

   </>
  )
}

export default Important_items