import React, { useEffect } from 'react';
import './Dsdsmsaimandscope.css';
import { NavLink, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dsms/Accordion';
import Ddsmsarticletemplate from '../../downloads/DS-MS-Article_Template.doc';
import Ddsmscopyrightform from '../../downloads/DS-MS-Copyright_Form.docx';
import Dsmsdata from './dsmsdata';

const Dsdsmsaimandscope = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const accordionData = [{
        content: <div>
             <NavLink smooth to="/ms" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</NavLink>
            <NavLink to="/ms/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>   Editorial Board</NavLink>
            <NavLink to="/ms/paper-submission"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/ms/current-issue"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/ms/archives"           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/ms/topics"                           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target= "_blank"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target= "_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/ms/apc"               className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
        {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
            <a href={Ddsmsarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Ddsmscopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
        </div>
    },
    ];
    return (

        <>
            <Helmet>
            <title>DS Journal of Modeling and Simulation – DS-MS</title>
            <link rel="canonical" href="https://dsjournals.com/ms"/>
            <meta name="title" content="DS Journal of Modeling and Simulation – DS-MS"/>
            <meta name="description" content="DS Journal of Modeling and Simulation (DS-MS) publishes original research and scholarly articles on a wide range of Intelligent Simulation, Complex system modeling and Applications of modeling and simulation, etc.,"/>
            <meta name="keywords" content="modeling paper, simulation model, international journal of health sciences, modeling and simulation, mathematical modeling, international journal of current research, international journal of science and research, journal of scientific research, indian journal physics, list of science journals."/>
            <meta property="og:type" content="Website"/>
            <meta property="og:site_name" content="Dream Science"/>
            <meta property="og:url" content="https://dsjournals.com/ms"/>
            <meta property="og:title" content="DS Journal of Modeling and Simulation – DS-MS"/>
            <meta property="og:description" content="DS Journal of Modeling and Simulation (DS-MS) publishes original research and scholarly articles on a wide range of Intelligent Simulation, Complex system modeling and Applications of modeling and simulation, etc.,"/>
            <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of -Modeling-and-Simulation.webp"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content="DS Journal of Modeling and Simulation – DS-MS"/>
            <meta name="twitter:description" content="DS Journal of Modeling and Simulation (DS-MS) publishes original research and scholarly articles on a wide range of Intelligent Simulation, Complex system modeling and Applications of modeling and simulation, etc.,"/>
            <meta name="twitter:site" content="@DreamScience4"/>
            <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
            <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of -Modeling-and-Simulation.webp"/>
            <meta name="robots" content="index, follow"/>
            </Helmet>

            <section id="hero-no-slide-dsms" className="d-flex justify-cntent-center align-items-center" style={{ height: "300px" }}>
                <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    {/* <h2 style={{fontSize:"25px",color:"#21494f"}}>DS Journal of Modeling and Simulation ( DS-MS )</h2> */}
                                    <h2 style={{fontSize:"25px",color:"#21494f"}}>{Dsmsdata[0].banner_title}</h2>
                                    {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <ol>
                            <li><NavLink to="/home">Home</NavLink></li>
                            <li><NavLink to="/journals">Journals</NavLink></li>
                            <li>DS-MS</li>
                        </ol>
                    </div>
                </div>
            </section>
            <main id="main">
                <section className="blog" id="blog">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 mb-3">
                                <div className="list-group">
                                    <div className="accordion">
                                        {accordionData.map(({ content }) => (
                                            <Accordion content={content} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div id="jorunalcard">
                                    <div className="card" style={{ maxWidth: "950px" }}>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4">
                                                <img id="journalpfimg_p" src="assets/img/DS-Journal-of-Modelling-and-simulation-Bookcover.jpg" className="img-responsive center-block d-block mx-auto" alt="journalprofileimg" />
                                            </div>
                                            <div className="col-lg-9 col-md-8">
                                                <div className="card-body">
                                                    {/* <h6 className="card-title center">DS International Journal of Computer Science and Engineering ( DSCSE )</h6> */}

                                                    <p class="card-text">
                                                        <table className='table'>
                                                            <tr className="pt-1">
                                                                <th scope="row" className="col-md-4 align-top">Editor in Chief</th>
                                                                <td className="col-md-8 px-2">Prof. Dr. G. Vijay Kumar,<br />
                                                                Department of Mechanical Engineering,<br/>
                                                                NS Raju Institute of Technology (NSRIT),<br />
                                                                Visakhapatnam, Andhra Pradesh, India. <br />
                                                                </td>
                                                            </tr>
                                                            <tr className="mt-2">
                                                                <th scope="row" className="col-md-4">ISSN (Online)</th>
                                                                <td className="col-md-8 px-2">2584-1564</td>
                                                            </tr>
                                                            <tr className="mt-2">
                                                                <th scope="row" className="col-md-4">Subject</th>
                                                                <td className="col-md-8 px-2">Modeling and Simulation</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Year of Starting</th>
                                                                <td className="col-md-8 px-2">2023</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Publication Frequency</th>
                                                                <td className="col-md-8 px-2">4 Issue per Year</td>
                                                            </tr>

                                                            <tr>
                                                                <th scope="row" className="col-md-4"> Language</th>
                                                                <td className="col-md-8 px-2">English</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Paper Submission id</th>
                                                                <td className="col-md-8 px-2">modeling@dsjournals.com</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Format of Publication</th>
                                                                <td className="col-md-8 px-2">online</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4 align-top">Publication Fee</th>
                                                                <td className="col-md-8 px-2">Free of Cost Journal</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4"> Publisher</th>
                                                                <td className="col-md-8 px-2">Dream Science</td>
                                                            </tr>
                                                        </table>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>Aim and Scope</p>
                                <p style={{ textAlign: 'justify', textIndent: "50px" }}>DS Journal of Modeling and Simulation (DS-MS) is an international, quarterly, peer-reviewed open-access journal focusing on theory and application
                                    dealing with any aspect of system simulation and modeling. Our journal invites technical notes, state-of-art reviews, original articles, survey papers, short communications, and book reviews on
                                    new developments and their implications in current times of modeling and simulation in all engineering, medical, physics, chemistry, computing, geography, scientific and other research fields.
                                    Creating the finest model and simulation for new discoveries requires an hour. The unexpected problems can be solved through simulation and model that enhances the human capability of existence.</p>
                                <p style={{ textAlign: 'justify', textIndent: "50px" }}>
                                    DS Journal of Modeling and Simulation (DS-MS) offers a sophisticated setting for research on the creation and use of modeling and simulation techniques. The journal's goal is to serve as a resource and
                                    effective tool for anyone working in the simulation field or interested in it. It also intends to motivate experts to share their model advancements and simulation outputs in as much detail as
                                    feasible. Electronic files and software regarding the full details of the algorithmic formulations, implementations, validation examples, and computational applications, if unable to be published
                                    in the usual way, can be deposited as supplementary electronic material.
                                </p>
                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>Papers are in demand, but not limited to the following topics:</p>
                                <p style={{ textAlign: 'justify' }}>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <p><i className="bi bi-chevron-double-right"></i>Modeling and Simulation Theory and Methodology</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Modeling and Simulation Languages</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Optimization in Modeling and Simulation</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Agent-based Modeling and Simulation</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Data-driven Modeling and Simulation</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Applications of Modeling and Simulation </p>
                                            <p><i className="bi bi-chevron-double-right"></i>Modeling and Simulation of Continuous Systems/Discrete Systems/Hybrid Systems/Intelligent Systems</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Modeling and Simulation Technology of Complex Systems/System of Systems/Cyber-Physical Systems </p>
                                            <p><i className="bi bi-chevron-double-right"></i>Model Engineering / Model-based System Engineering </p>
                                            <p><i className="bi bi-chevron-double-right"></i>Mathematical Modeling (Stochastic And Probabilistic Modeling, Continuum and Discrete Modeling, Graph Theory, Algebraic Coding, Behavioural Language) </p>
                                            <p><i className="bi bi-chevron-double-right"></i>Conceptual Modeling </p>
                                            <p><i className="bi bi-chevron-double-right"></i>Complex System Modeling  </p>
                                            <p><i className="bi bi-chevron-double-right"></i>Stochastic and Polymorphic Uncertainty Models </p>
                                            <p><i className="bi bi-chevron-double-right"></i>Model Verification and Validation  </p>
                                            <p><i className="bi bi-chevron-double-right"></i>Neural and Fuzzy Modeling   </p>
                                        </div>

                                        <div className='col-md-6'>
                                            <p><i className="bi bi-chevron-double-right"></i>Numerical Modeling of Materials, Structures, Processes, and Systems</p>  
                                            <p><i className="bi bi-chevron-double-right"></i>Model-Free (Experimental) Modeling</p>  
                                            <p><i className="bi bi-chevron-double-right"></i>Formal Methods of Simulation (Monte Carlo Method, Planning and Scheduling, Ontologies, Real-Time Systems, Stochastic Method, Dynamic Method, Nonlinear Method, Optimization, Distributed and Parallel Computing, Mathematical Method, Decision Support, Risk Analysis, Verification and Validation, Multi-Scale Analysis)</p>
                                            <p><i className="bi bi-chevron-double-right"></i>Business Systems Simulation</p>                                                           
                                            <p><i className="bi bi-chevron-double-right"></i>Computational Simulation Methodologies</p>   
                                            <p><i className="bi bi-chevron-double-right"></i>High-Performance Simulation</p>  
                                            <p><i className="bi bi-chevron-double-right"></i>Intelligent Simulation</p>           
                                            <p><i className="bi bi-chevron-double-right"></i>Knowledge-Based Simulation Environments</p>   
                                            <p><i className="bi bi-chevron-double-right"></i>Methodology for Analysing Simulation Output</p>    
                                            <p><i className="bi bi-chevron-double-right"></i>Simulation Application Techniques</p>   
                                            <p><i className="bi bi-chevron-double-right"></i>Simulation Applications in Different Disciplines</p>   
                                            <p><i className="bi bi-chevron-double-right"></i>Simulation-Based Acquisition / Virtual Prototyping / Digital Twin</p>   
                                            <p><i className="bi bi-chevron-double-right"></i>Simulation Software, Simulators</p>   
                                            <p><i className="bi bi-chevron-double-right"></i>Simulation Supporting Tools and Platforms</p>
                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Dsdsmsaimandscope;
