import React, { useEffect } from 'react';
import './Dslllaimandscope.css';
import { NavLink} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dslll/Accordion';
import Dlllarticletemplate from '../../downloads/DS-LLL-Article_Template.doc';
import Dlllcopyrightform from '../../downloads/DS-LLL-Copyright_Form.docx';

const Dslllaimandscope = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const accordionData = [{
        content: <div>
            <NavLink smooth to="/lll" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Aim and Scope</NavLink>
            <NavLink to="/lll/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
            <NavLink to="/lll/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/lll/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/lll/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/lll/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/lll/article-processing-charge" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
            <a href={Dlllarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Dlllcopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
        </div>
    },
    ];
    return (

        <>
            <Helmet>
            <title>Journal of Language, Linguistics and Literature  - DS Journals</title>
            <link rel="canonical" href="https://dsjournals.com/lll"/>
            <meta name="title" content="Journal of Language, Linguistics and Literature - DS Journals"/>
            <meta name="description" content="DS Journals' Language, Linguistics and Literature Journal (LLL) publishes original research and scholarly articles on a wide range of language and literature topics."/>
            <meta name="keywords" content=" a review of literature, language and literature, research article, journal of management, language in literature, journal of language and linguistic studies, journal of advanced research, international journal of information management, international journal of english language and literature studies."/>
            <meta property="og:type" content="Website"/>
            <meta property="og:site_name" content="Dream Science"/>
            <meta property="og:url" content="https://dsjournals.com/lll"/>
            <meta property="og:title" content="Journal of Language, Linguistics and Literature - DS Journals"/>
            <meta property="og:description" content="DS Journals' Language, Linguistics and Literature Journal (LLL) publishes original research and scholarly articles on a wide range of language and literature topics."/>
            <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Language-Linguistics-and-Literature.webp"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:description" content="DS Journals' Language, Linguistics and Literature Journal (LLL) publishes original research and scholarly articles on a wide range of language and literature topics."/>
            <meta name="twitter:site" content="@Dream Science4"/>
            <meta name="twitter:title" content="Journal of Language, Linguistics and Literature - DS Journals"/>
            <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
            <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Language-Linguistics-and-Literature.webp"/>
            <meta name="robots" content="index, follow"/>
            </Helmet>

            <section id="hero-no-slide-dlll" className="d-flex justify-cntent-center align-items-center" style={{ height: "300px" }}>
                <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <h2 style={{ fontSize: "25px" }}>DS Journal of Language, Linguistics and Literature ( DS-LLL )</h2>
                                    {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <ol>
                            <li><NavLink to="/home">Home</NavLink></li>
                            <li><NavLink to="/journals">Journals</NavLink></li>
                            <li>DS-LLL</li>
                        </ol>
                    </div>
                </div>
            </section>
            <main id="main">
                <section className="blog" id="blog">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 mb-3">
                                <div className="list-group">
                                    <div className="accordion">
                                        {accordionData.map(({ content }) => (
                                            <Accordion content={content} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div id="jorunalcard">
                                    <div className="card" style={{ maxWidth: "950px" }}>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4">
                                                <img id="journalpfimg_p" src="assets/img/DS-Journal-of-Language-Lingustics-Literature-Bookcover.jpg" className="img-responsive center-block d-block mx-auto" alt="journalprofileimg" />
                                            </div>
                                            <div className="col-lg-9 col-md-8">
                                                <div className="card-body">
                                                    {/* <h6 className="card-title center">DS International Journal of Computer Science and Engineering ( DSCSE )</h6> */}

                                                    <p class="card-text">
                                                        <table className='table'>
                                                            <tr className="pt-1">
                                                                <th scope="row" className="col-md-4 align-top">Editor in Chief</th>
                                                                <td className="col-md-8 px-2"> Prof. Dr. Reza Kafipour,<br />
                                                                    Department of English  <br />
                                                                    Shiraz University of Medical Sciences, <br />
                                                                    Shiraz, Iran.
                                                                </td>
                                                            </tr>
                                                            <tr className="mt-2">
                                                                <th scope="row" className="col-md-4">ISSN (Online)</th>
                                                                <td className="col-md-8 px-2">2583-8490</td>
                                                            </tr>
                                                            <tr className="mt-2">
                                                                <th scope="row" className="col-md-4">Subject</th>
                                                                <td className="col-md-8 px-2">Language, Linguistics and Literature</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Year of Starting</th>
                                                                <td className="col-md-8 px-2">2023</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Publication Frequency</th>
                                                                <td className="col-md-8 px-2">4 Issue per Year</td>
                                                            </tr>

                                                            <tr>
                                                                <th scope="row" className="col-md-4"> Language</th>
                                                                <td className="col-md-8 px-2">English</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Paper Submission id</th>
                                                                {/* <td className="col-md-8 px-2">submit2lang@gmail.com</td> */}
                                                                <td className="col-md-8 px-2">literature@dsjournals.com</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Format of Publication</th>
                                                                <td className="col-md-8 px-2">online</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4 align-top">Publication Fee</th>
                                                                <td className="col-md-8 px-2">Free of Cost Journal</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4"> Publisher</th>
                                                                <td className="col-md-8 px-2">Dream Science</td>
                                                            </tr>
                                                        </table>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>Aim and Scope</p>
                                <p style={{ textAlign: 'justify', textIndent: "50px" }}>DS Journal of Language, Linguistics and Literature (DS-LLL) is an international, interdisciplinary scholarly peer-reviewed research publishing open access journal.
                                    The journal is focused on great varieties of literary and thematic areas that motivate; researchers and academicians to ponder English Language Teaching, Translation studies, History of Language,
                                    Theoretical Linguistics, Applied Linguistics, Descriptive Linguistics, Corpus Linguistics, Rhetoric and Stylistics, Research methodology, Comparative Literature, Ancient, Medieval, Renaissance, Diasporic,
                                    Subaltern, Modern, Indigenous, Culinary and World Literatures, Literary Criticisms, Autobiographies, Biographies, Gender studies, Contemporary Literature and all topics relevant to the language literature and
                                    linguistics and genre of studies.</p>
                                <p style={{ textAlign: 'justify', textIndent: "50px" }}>
                                    The journal invites new forms of research topics such as literature and media, Queer criticisms, Literary trends in cyberspace, Evolution of language from signs and words to artificial intelligence,
                                    Traumatic studies, etc. Exploring the literature is suitable for all ages. DS Journal of Language, Linguistics and Literature always opens doors for all types of research topics in language, literature
                                    and linguistics, even emerging literature genres and topics like Climatic fiction, Graphic fiction, Science fiction, mythopoeic literature, cyberpunk, goes on. The journal publishes peer-reviewed research
                                    articles, reviews & analyses, short communications, case studies, comparative studies, and more. The scope of subjects includes:
                                </p>
                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>Papers are solicited from, but not limited to the following topics:</p>
                                <p style={{ textAlign: 'justify' }}>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                        <p><i className="bi bi-chevron-double-right"></i>Psychology and Literature</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Feminism, EcoFeminism, EcoCriticism</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Trauma Studies</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Translation Studies</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Green Literature</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Evolution of Modern Linguistics</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Flash Fiction</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Metafiction</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Phonetics</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Phonology </p>
                                               <p><i className="bi bi-chevron-double-right"></i>Gothic Literature/ Fiction</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Women’s Writing</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Children’s Literature</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Literature, Language and Media</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Modern European Fiction Literature</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Applied Linguistics- Computational Linguistics, Internet Linguistics, Evolutionary Linguistics, Forensic Linguistics, Psycholinguistics, Neurolinguistics, Language Acquisition and Assessment, Language documentation, Second Language Acquisition, Sociolinguistics.</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Relation between Translation and Popular Culture</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Language Learning in the Age of AI</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Evolution of Language Translation and Linguistics in the Age of AI</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Artificial Intelligence and Linguistics</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Language Translation in AI</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Linguistics for the Age of AI</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Future Language Learning with AI Translators</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Machine Learning and Applied Linguistics</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Machine Learning on Literature Great Works</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Big Data Analytics in Literature and Language</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Cryptography in Linguistics</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Literature in the Age of Artificial Intelligence</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Linguistics and Robots</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Cyberpunks</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Issues and Conflicts in English Language Teaching in the Age of AI</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Language Lab and Digital Teaching</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Language and Culture</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Artificial Intelligence in English Language Learning</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Slip Stream</p>
                                               <p><i className="bi bi-chevron-double-right"></i>Climate Fiction</p>
                                        </div>

                                        <div className='col-md-6'>
                                               
                                        <p><i className="bi bi-chevron-double-right"></i>Cyber Literature</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Modern Literature and Technology</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Web Fiction and it's Importance in the Contemporary Era</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Indian English Literature</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Literature, Language and Media</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Dialect, Idiolect, Sociolect, Pidgin, Creole</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Morphology </p>
                                                <p><i className="bi bi-chevron-double-right"></i>Knowledge of Languages: Translation, Bilingualism, Multilingualism</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Pieces of Literature: British, American, Canadian, African-American, Indigenous, Diaspora, Comparative, Wisdom, Subaltern literature, Indian writings, Translated Indian Literature, Contemporary, Canadian, Asian, Modern, World, Post-Colonial Literature, Modernism and Post-Modernism, Culinary, Apocalyptic Literature and so on</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Literary Criticism</p>
                                                <p><i className="bi bi-chevron-double-right"></i>World Classics in Translation</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Theatre Arts</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Cultural Studies</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Gender Studies</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Literary Form and Terms</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Indian Literature Translated into English</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Translation: Theory and Practice</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Translation and Interpretation</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Literary Theories</p>
                                                <p><i className="bi bi-chevron-double-right"></i>English Language Teaching</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Second Language Teaching</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Media Studies</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Academic Writing and Proofreading</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Post-War Theories</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Modern Poetry</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Myth in Literature</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Indian Literature in Translation</p>
                                                <p><i className="bi bi-chevron-double-right"></i>History of Literature</p>
                                                <p><i className="bi bi-chevron-double-right"></i>English in Communication</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Research Methodology</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Genre: Poetry, Drama, Prose, Novel, Fiction, Non-fiction, Short Story, Science Fiction, Creative Non-fiction, Travelogues</p>
                                                <p><i className="bi bi-chevron-double-right"></i>English as a Language of Science and Technology</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Witness Literature	</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Religious and Ethnic Studies</p>
                                                <p><i className="bi bi-chevron-double-right"></i>Philology and Research Studies</p>


                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Dslllaimandscope;
