import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';

import './Dsdscyeditorialboard.css';
import Accordion from '../../components/Dscy/Accordion';
import Ddscyarticletemplate from '../../downloads/DS-CYS-Article_Template.doc';
import Ddscycopyrightform from '../../downloads/DS-CYS-Copyright_Form.docx';

const Dsdscyeditorialboard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const accordionData = [{
    content: <div>
    <HashLink smooth to="/cys" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
           <NavLink to="/cys/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
           <NavLink to="/cys/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
           <NavLink to="/cys/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
           <NavLink to="/cys/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
           <NavLink to="/cys/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
           <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
           <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
           <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
           <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
           <NavLink to="/cys/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
           <a href={Ddscyarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
           <a href={Ddscycopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>


    </div>
  },
  ];
  return (
    <>
      <Helmet>
        <title>Editorial Board | DS Journal of Cyber Security (DS-CYS)</title>
        <link rel="canonical" href="https://dsjournals.com/cys/editorial-board"/>
        <meta name="title" content="Editorial Board | DS Journal of Cyber Security (DS-CYS)"/>
        <meta name="description" content="Meet the distinguished editorial board of DS-CYS Journal, who ensure the quality and integrity of research published in our peer-reviewed journal."/>
        <meta name="keywords" content="editorial board, editorial board members, cyber security journal editorial department, journal editorial board, become editorial board member, editorial member, editorial editor, board editorial, editorial team members, journal editorial board positions, editorial committee, editorial board positions."/>
        <meta property="og:type" content="Website"/>
        <meta property="og:site_name" content="Dream Science"/>
        <meta property="og:url" content="https://dsjournals.com/cys/editorial-board"/>
        <meta property="og:title" content="Editorial Board | DS Journal of Cyber Security (DS-CYS)"/>
        <meta property="og:description" content="Meet the distinguished editorial board of DS-CYS Journal, who ensure the quality and integrity of research published in our peer-reviewed journal."/>
        <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Cyber-Security.webp"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:description" content="Meet the distinguished editorial board of DS-CYS Journal, who ensure the quality and integrity of research published in our peer-reviewed journal."/>
        <meta name="twitter:site" content="@DreamScience4"/>
        <meta name="twitter:title" content="Editorial Board | DS Journal of Cyber Security (DS-CYS)"/>
        <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
        <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Cyber-Security.webp"/>
        <meta name="robots" content="index, follow"/>
      </Helmet>



      <section id="hero-no-slide-dscy" className="d-flex justify-cntent-center align-items-center" style={{ height: "300px" }}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="row justify-content-center">
                {/* <div className="col-xl-8">
                  <h2>Editorial Board</h2>
                </div> */}
                <div className="col-lg-12">
                <h2 style={{fontSize:"25px"}}>DS Journal of Cyber Security ( DS-CYS )</h2>
                                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            {/* <h2>Editor Spotlight</h2> */}
            <ol>
              <li><Link to="/home">Home</Link></li>
              <li><Link to="/journals">Journals</Link></li>
              <li><Link to="/cys">DS-CYS</Link></li>
              <li>Editorial Board</li>
            </ol>
          </div>
        </div>
      </section>
      <main id="main">
        <section className="blog" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 mb-3">
                <div className="list-group">
                  <div className="accordion">
                    {accordionData.map(({ content }) => (
                      <Accordion content={content} />
                    ))}
                  </div>
                </div>

                <div className="abtlistgrp">
                </div>
              </div>
              {/* <div className='col-lg-9 video-box align-self-baseline position-relative'>
                <div className="d-flex justify-content-center">
                  <p><h1>Coming Soon...</h1></p>

                </div>
                <div className="copyright d-flex justify-content-center">
                  &copy; Powered By &nbsp; <strong><span>Dream Science</span></strong>.
                </div>
              </div> */}
              <div id='head1' className="col-lg-9">
                 <h3>Editorial Board</h3>
                 <div className='row'>
                  <h5 className='text-left'>
                    <span style={{ backgroundColor: "#94c045",
    color:"#fff",
    lineHeight: "43px",
    padding: "5px 10px",
    whiteSpace:"pre-wrap"}}>Editor in Chief</span></h5>

                  <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}>Dr. Tarak Nandy,</div> <p style={{textAlign:"left",fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-justify text-muted">
                  Institute of Computer Science and Digital Innovation,<br/>
                  UCSI University,<br/>
                  Malaysia.<br />
                  tarak@ucsiuniversity.edu.my<br/>
                  <a href='https://www.ucsiuniversity.edu.my/dr-tarak-nandy' target="_balnk">Profile Link</a> 
                    </p>
                  </div>
                 
                
                 </div>

                 <div className='row'>
                    <h5 style={{textAlign:"left"}}><span style={{ backgroundColor: "#94c045",
    color:"#fff",
    lineHeight: "43px",
    padding: "5px 10px",
    whiteSpace:"pre-wrap"}}>Associate Editor</span></h5>
                 <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}> Dr. Ripal D. Ranpara, </div>	
                  <p  style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted"> 
                  Department of Computer Science,<br/> Atmiya University,<br/> Rajkot, Gujarat.
                  <br/>ripal.ranpara@atmiyauni.ac.in<br/>
                  {/* <a href='https://atmiyauni.ac.in/facultylist/computer-science-information-technology/97' target="_balnk">Profile Link</a> */}
                  <a href='https://atmiyauni.ac.in/facultylist/information-technology-pg/263' target="_balnk">Profile Link</a>
                  </p>
                  </div>
                 </div>
    

                 <div className='row'>
                 <h5 className='text-left'><span style={{ backgroundColor: "#94c045",
                    color:"#fff",
                    lineHeight: "43px",
                    padding: "5px 10px",
                    whiteSpace:"pre-wrap"}}>Editorial Board Members</span></h5>
                  <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}>Dr. Mohammad Ashikur Rahman Khan, </div>	
                  <p style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted">
                  Department of Information and Communication Engineering,<br/> Noakhali Science and Technology University,<br/> Noakhali, Bangladesh.
                  <br/>ashik@nstu.edu.bd<br/>
                  <a href='https://www.nstu.edu.bd/faculty-member.php?faculty=dr-md-ashikur-rahman-khan-mlr436' target="_balnk">Profile Link</a></p>
                  </div>

                  <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}>Dr. Mohamed Abdelfatah Elshebrawi Moustafa, </div>	
                  <p style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted">
                  Department of Computer Sciences,<br/> College of Computer Engineering and Sciences, <br/>
                  Prince Sattam Bin Abdulaziz University, <br/>Kingdom Saudi Arabia.
                  <br/>m.ashabrawy@psau.edu.sa<br/>
                  <a href='https://faculty.psau.edu.sa/en/psau/facultymember/m.ashabrawy' target="_balnk">Profile Link</a></p>
                  </div>

                  <div className='col-lg-12'>
                  <div className='text-left' style={{fontWeight:"bold"}}>Dr. Nur Haliza Binti Abdul Wahab, </div>	
                  <p style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted">
                  Department of Computer Science<br/>School of Computing, Faculty of Engineering,<br/> Universiti Teknologi Malaysia,<br/> Malaysia.
                  <br/>nur.haliza@utm.my<br/>
                  <a href='https://people.utm.my/nur-haliza/' target="_balnk">Profile Link</a></p>
                  </div>
           
              
                 </div>



                      
                </div>

            </div>
          </div>

        </section>


      </main>
    </>
  )
}

export default Dsdscyeditorialboard;