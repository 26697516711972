import React,{useState,useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Link,NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dscse/Accordion';
import Posts from '../../components/Dscse/Currentissue/Posts/Posts';
import { getjournalPosts } from '../../actions/journal/posts';
import Dsjstarticletemplate from '../../downloads/DS-DST-Article_Template.doc';
import Dsjstcopyrightform from '../../downloads/DS-DST-Copyright_Form.docx';
import { getcissuePost } from '../../actions/currentissue/posts';

const Currentissue = () => {
  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();
  const [refresh,setReferesh] = useState(false);

  const jouname = "DST";
  const [postData, setPostData] = useState({ journal_title: '', volume: '', issue: '' });
  const cpost = useSelector((state) => (jouname ? state.cissueposts.posts.find((jname)=>  jname.journal_title === jouname) : null));

  useEffect(()=>{setReferesh(true)},[refresh]);

  useEffect(() => {
    dispatch(getjournalPosts());    
  }, [currentId, dispatch]);

  // useEffect(() => {
  //   dispatch(getcissuePost(jouname))
  // }, [jouname])

  useEffect(() => {
    if (cpost) setPostData(cpost);
    console.log("currentissue",cpost);
  }, [cpost]);


  


  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  const accordionData = [{
    content: <div>
        <HashLink smooth to="/dst" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Aim and Scope</HashLink>
        <NavLink to="/dst/editorial-board"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
        <NavLink to="/dst/paper-submission"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
        <NavLink to="/dst/current-issue"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/dst/archives"           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/dst/topics"                           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target= "_blank"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target= "_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/dst/apc"               className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
        {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
        <a href={Dsjstarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
        <a href={Dsjstcopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
</div> 
},
  ];
  return (
    <>
    <Helmet>
    <title>Current Issue - DST Journal | DS Journals</title>
    <link rel="canonical" href="https://dsjournals.com/dst/current-issue"/>
    <meta name="viewport" content="width=device-width, initial-scale=1"/>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="title" content="Current Issue - DST Journal | DS Journals"/>
    <meta name="description" content="Explore the latest issue of DST Journal - a digital science technology journal covering areas of science, technology, and engineering. Open access."/>
    <meta name="keywords" content="Current Issue in digital science journals, recent journals in digital science, ds journal, digital Trends journal, open access science journals, current issue in digital science, recent research topic in digital science."/>
    <meta property="og:type" content="Website"/>
    <meta property="og:site_name" content="Dream Science"/>
    <meta property="og:url" content="https://dsjournals.com/dst/current-issue"/>
    <meta property="og:title" content="Current Issue - DST Journal | DS Journals"/>
    <meta property="og:description" content="Explore the latest issue of DST Journal - a digital science technology journal covering areas of science, technology, and engineering. Open access."/>
    <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Digital-Science-and-Technology.webp"/>
    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:title" content="Current Issue - DST Journal | DS Journals"/>
    <meta name="twitter:description" content="Explore the latest issue of DST Journal - a digital science technology journal covering areas of science, technology, and engineering. Open access."/>
    <meta name="twitter:site" content="@DreamScience4"/>
    <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
    <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Digital-Science-and-Technology.webp"/>
    <meta name="robots" content="index, follow"/>
    </Helmet>
     <>
     <section id="hero-no-slide-dst" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
   <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
     <div className="row justify-content-center">
       <div className="col-xl-8">
         <div className="row justify-content-center">
           {/* <div className="col-xl-8">
             <h2>Current Issue</h2>
           
           </div> */}
            <div className="col-lg-12">
                            <h1 style={{fontSize:"25px"}}>DS Journal of Digital Science and Technology ( DS-DST )</h1>
                            {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                        </div>
       </div>
     </div>
   </div>
   </div>
 </section>
 
 <section className="breadcrumbs">
   <div className="container">
     <div className="d-flex justify-content-between align-items-center">
       {/* <h2>Current Issues</h2> */}
       <ol>
         <li><Link to="/home">Home</Link></li>
         <li><Link to="/journals">Journals</Link></li>
        <li><Link to="/dst">DS-DST</Link></li>
         <li>Current Issue</li>
       </ol>
     </div>
   </div>
 </section>

      <main id="main">
<section class="blog" id="blog">
 <div class="container">
     <div class="row">
         <div class="col-lg-3">
         <div className="accordion">
                        {accordionData.map(({content }) => (
                          <Accordion content={content} />
                      ))}
        </div> 
         </div>
         <div class="col-lg-9">
               <div className="row mt-3">
                 <div className="col-lg-12 text-center">
                 {/* <h5 style={{alignItems:'center'}}>Volume 2 Issue 1,  January - March 2023 </h5> */}
                 <h5 style={{alignItems:'center'}}>Volume {postData.volume} Issue {postData.issue}  </h5>
                 </div>
             
             </div>
             <div className="row">
              <div className='col-lg-12 mt-3'>
              <Posts setCurrentId={setCurrentId} />  
              </div>

             </div>
         </div>
       
     </div>
 </div>

</section>


</main>     
 </>
</>
  )
}

export default Currentissue;