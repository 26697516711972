import React,{useState,useRef,useEffect} from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector} from 'react-redux';
import { useParams } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Grid from '@mui/material/Grid';
import {
    Typography,
    TextField,
    Button,
    Stepper,
    Step,
    StepLabel,
    Paper
  } from "@material-ui/core";
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { updatejournalPost, getjournalPost } from '../../../actions/journal/dslll/posts';

const useStyles = makeStyles((theme) => ({
    root:{
      "& > *": {
        margin: theme.spacing(2),
        width: theme.spacing(120),
        height: theme.spacing(130)
      }
    },
    form:{
      width:theme.spacing(110),
      marginTop:theme.spacing(2),
      marginLeft:theme.spacing(5),
    },
    paper:{
      height:theme.spacing(15),
      
    },
    contpaper:{
      marginTop:theme.spacing(10),
      height:theme.spacing(110),
    },
    button: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
  }));

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  

const Multistepupdateform = () => {

    const ref = useRef();
    const { id } = useParams(); 
    console.log(id);
    // const { post } = useSelector((state) => state.journalposts);
    // const  post  = useSelector((state) => state.journalposts.posts.find(post=> post._id === id));
    const  post  = useSelector((state) => state.dsllljposts.posts.find(post=> post._id === id));
  
    console.log("reference",post.references.map((item)=> <div>{item}</div>));
    useEffect(() => {
      if(post) setPostData(post);
    }, [post])
    const yourDate = new Date();
    // const [postData, setPostData] = useState({ id:'',title: '', authors: '', article_id: '', volume: '', issue: '', 
    //                                          year:moment(yourDate).format("YYYY"), doi: '', abstract: '', key_words: '', references:'', 
    //                                          mla_style: '',apa_style: '',article_file:''});     

                                             const [postData, setPostData] = useState({ id:post._id,title:post.title, authors: post.authors, article_id: post.article_id, volume: post.volume, issue: post.volume, 
                                             year:moment(post.year).format("YYYY"), doi: post.doi, abstract:post.abstract, key_words:post.key_words, references:post.references, 
                                             mla_style: post.apa_style,apa_style: post.apa_style,article_file:post.article_file});     


console.log("PostData",postData.references);
    const dispatch = useDispatch();
    const clear = () => {
    // ref.current.value = 'No file Choosen';
   

    setPostData({ id:'',title: '', authors:'', article_id:'', volume:'', issue:'', year:'', doi:'', abstract:'', 
                key_words:'', references:'', mla_style:'',apa_style:'',article_file: ''});
            };  

            useEffect(() => {
              dispatch(getjournalPost(id));
           }, [id]);
            
            const classes = useStyles();
            const [activeStep, setActiveStep]= useState(0);
            const steps = getSteps();
            
            const handleNext = () =>{
              setActiveStep (activeStep + 1);
            }
            const handleBack = () =>{
              setActiveStep (activeStep - 1);
            }

            const handleSubmit = async (e) => {
                e.preventDefault();
                const formdata = new FormData();
                // formdata.set('title',postData.title);
                // formdata.set('authors',postData.authors);
                // formdata.set('article_id',postData.article_id);
                // formdata.set('volume',postData.volume);
                // formdata.set('issue',postData.issue);
                // formdata.set('year',postData.year);
                // formdata.set('doi',postData.doi);
                // formdata.set('abstract',postData.abstract);
                // formdata.set('key_words',postData.key_words);
                // formdata.set('references',postData.references);
                // formdata.set('mla_style',postData.mla_style);
                // formdata.set('apa_style',postData.apa_style);
                // formdata.set('file',postData.article_file);
                formdata.set('title',     postData.title);
                formdata.set('authors',   postData.authors);
                formdata.set('article_id',postData.article_id);
                formdata.set('volume',    postData.volume);
                formdata.set('issue',     postData.issue);
                formdata.set('year',      postData.year);
                formdata.set('doi',       postData.doi);
                formdata.set('abstract',  postData.abstract);
                formdata.set('key_words', postData.key_words);
                formdata.set('references',postData.references);
                formdata.set('mla_style', postData.mla_style);
                formdata.set('apa_style', postData.apa_style);
                formdata.set('file',      postData.article_file);
            
            
            if(!id === 0){
              dispatch(updatejournalPost(id,formdata));
              }
                clear();
              };

              function getSteps(){
                return[
                "Basic Information",
                "Abstract & Keywords",
                "References"
                ];}


                function getStepContent(step){
                    switch(step){
                        case 0:
                            return(
                              <div className='col-lg-12'>
                                <Grid container  direction="row" alignItems="center" spacing={2}>
                                  <Grid item xs={3}>
                                    <Item><TextField  className={classes.gridMargin} name="Id" variant="outlined"  label="Id" value={post._id} /></Item>
                                  </Grid>
                                  <Grid item xs={9}>
                                    <Item><TextField  className={classes.gridMargin} name="Title" variant="outlined"  fullWidth label="Title" value={postData.title} onChange={(e) => setPostData({ ...postData, title: e.target.value })} /></Item>
                              </Grid>
                              <Grid item xs={12}>
                                <Item><TextField className={classes.gridMargin} name="Authors" variant="outlined" fullWidth label="Authors" value={postData.authors} onChange={(e) => setPostData({...postData, authors: e.target.value.split(',') })}/></Item>
                              </Grid>
              
                              <Grid item xs={3}>
                                <Item><TextField className={classes.gridMargin} name="Article Id" variant="outlined" label="Article Id" value={postData.article_id} onChange={(e) => setPostData({...postData, article_id: e.target.value })}/></Item>
                                
                              </Grid>
                              <Grid item xs={3}>
                              {/* <Item><TextField className={classes.gridMargin} name="Volume" variant="outlined" label="Volume" value={post.volume} onChange={(e) => setPostData({...postData, volume: e.target.value })}/></Item> */}
              
                              <Item>
                              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                              <InputLabel id="volume-select-small">Volume</InputLabel>
                                <Select
                                labelId="volume-select-small"
                                id="volume-select-small"
                                value={postData.volume}
                                label="Volume"
                                onChange={(e) => setPostData({...postData, volume: e.target.value })}
                              >
                                <MenuItem value="">
                                  <em>Select</em>
                                </MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                              </Select>
                            </FormControl>
                              </Item>
                              </Grid>
              
                              <Grid item xs={3}>
                              {/* <Item><TextField className={classes.gridMargin} name="Issue" variant="outlined" label="Issue" value={post.issue} onChange={(e) => setPostData({...postData, issue: e.target.value })}/> </Item> */}
                              <Item>
                              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                              <InputLabel id="issue-select-small">Issue</InputLabel>
                      <Select
                      labelId="issue-select-small"
                      id="issue-select-small"
                      value={postData.issue}
                      label="Issue"
                      onChange={(e) => setPostData({...postData, issue: e.target.value })}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                    </Select>
                  </FormControl>
                              </Item>
                              </Grid>
              
                              <Grid item xs={3}>
                              {/* <Item><TextField className={classes.gridMargin} name="Year" variant="outlined" label="Year" value={postData.year} onChange={(e) => setPostData({...postData, year: e.target.value })}/></Item> */}
                              <Item>
                              
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                  label="Year"
                                  views={["year"]}
                                  inputFormat="yyyy"
                                  value={postData.year}
                                  onChange={(e) => setPostData({...postData, year: e})}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                                </LocalizationProvider>
                                
                               
                              </Item>
                              </Grid>
                             
                              <Grid item xs={3}>
                              <Item><TextField className={classes.gridMargin} name="Page Start" variant="outlined" label="Page Start" value={postData.page_start} onChange={(e) => setPostData({...postData, page_start: e.target.value})}/> 
                              </Item>
                              </Grid>
                              <Grid item xs={3}>
                              <Item><TextField className={classes.gridMargin} name="Page End" variant="outlined" label="Page End" value={postData.page_end} onChange={(e) => setPostData({...postData, page_end: e.target.value})}/></Item>
                              </Grid>
                            
                              <Grid item xs={3}>
                                {/* <Item><TextField className={classes.gridMargin} name="Received Date" variant="outlined" label="Received Date"  value={postData.received_date} onChange={(e) => setPostData({...postData, received_date: e.target.value})}/></Item> */}
                                <Item>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                  label="Received Date"
                                  views={["day","month","year"]}
                                  inputFormat="dd/MMM/yyyy"
                                  value={post.received_date}
                                  onChange={(e) => setPostData({...postData, received_date: moment(e).format('DD MMM YYYY')})}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                                </LocalizationProvider>
                    
                                </Item>
                              </Grid>
                              <Grid item xs={3}>
                              {/* <Item><TextField className={classes.gridMargin} name="Revised Date" variant="outlined" label="Revised Date"  value={postData.revised_date} onChange={(e) => setPostData({...postData, revised_date: e.target.value})}/></Item> */}
                              <Item>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                  label="Revised Date"
                                  views={["day","month","year"]}
                                  inputFormat="dd/MMM/yyyy"
                                  value={post.revised_date}
                                  onChange={(e) => setPostData({...postData, revised_date: moment(e).format('DD MMM YYYY')})}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                                </LocalizationProvider>
                                </Item>
                              </Grid>
              
                              <Grid item xs={3}>
                              {/* <Item><TextField className={classes.gridMargin} name="Accepted Date" variant="outlined" label="Accepted Date"  value={postData.accepted_date} onChange={(e) => setPostData({...postData, accepted_date: e.target.value})}/></Item> */}
                              <Item>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                  label="Accepted Date"
                                  views={["day","month","year"]}
                                  inputFormat="dd/MMM/yyyy"
                                  value={post.accepted_date}
                                  onChange={(e) => setPostData({...postData, accepted_date: moment(e).format('DD MMM YYYY')})}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                                </LocalizationProvider>
                                </Item>
                              </Grid>
                              <Grid item xs={3}>
                              {/* <Item><TextField className={classes.gridMargin} name="Published Date" variant="outlined" label="Published Date"  value={postData.published_date} onChange={(e) => setPostData({...postData, published_date: e.target.value})}/></Item> */}
                              <Item>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Published Date"
                                    views={["day","month","year"]}
                                    inputFormat="dd/MMM/yyyy"
                                    value={post.published_date}
                                    onChange={(e) => setPostData({...postData, published_date: moment(e).format('DD MMM YYYY')})}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </LocalizationProvider>
                                </Item>
                              </Grid>
                              <Grid item xs={12}>
                              <Item><TextField className={classes.gridMargin} name="mla_style" variant="outlined" label="MLA Style" fullWidth multiline maxRows={4} value={postData.mla_style} onChange={(e) => setPostData({...postData, mla_style: e.target.value })}/></Item>
                              </Grid>
                              <Grid item xs={12}>
                              <Item><TextField className={classes.gridMargin} name="apa_style" variant="outlined" label="APA Style" fullWidth multiline maxRows={4} value={postData.apa_style} onChange={(e) => setPostData({...postData, apa_style: e.target.value })}/></Item>
                              </Grid>
                              <Grid item xs={12}>
                              <Item><div className={classes.fileInput}><input type='file' ref={ref} onChange={(e) => setPostData({...postData, article_file: e.target.files[0] })}/></div></Item>
                              <Item>File Name:{postData.article_file}</Item>
                              </Grid>
                              </Grid>     
                              </div>
              
                            );
                        case 1:
                            return( <div>
                              <Grid container  direction="row" alignItems="center" spacing={2}>
                                <Grid item xs={12}>
                                  <Item><TextField name="Abstract" variant="outlined" label="Abstract" fullWidth multiline maxRows={4} value={postData.abstract} onChange={(e) => setPostData({...postData, abstract: e.target.value })}/></Item>
                                </Grid>
                                <Grid item xs={12}>
                                  <Item></Item>
                                </Grid>
                                <Grid item xs={12}>
                                  <Item><TextField className={classes.gridMargin} name="Kewords" variant="outlined" fullWidth label="Keywords" value={postData.key_words} onChange={(e) => setPostData({...postData, key_words: e.target.value.split(',') })}/></Item>
                                </Grid>
                                <Grid item xs={8}>
                                  <Item><TextField className={classes.gridMargin} name="doi" variant="outlined" fullWidth label="doi" value={postData.doi} onChange={(e) => setPostData({...postData, doi: e.target.value })}/></Item>
                                </Grid>
                              </Grid>        
                            </div>);
                        case 2:
                          return(<div>
                                  <ReactQuill className={classes.quill} theme="snow" value={postData.references.toString() } onChange={(e) => setPostData({...postData, references: e})} />
                                 
                                </div>);
                        default:
                          //do nothing
                    }
                }



  return (
    <div className={classes.root}>
    <Paper className={classes.paper} elevation={6}>
    <Grid container spacing={2}>
    <Grid item xs={12}>
                 <Item><Typography variant="h6">Add Record</Typography></Item>
     </Grid>
     <Grid item xs={12}>
       <Item>
       <Stepper activeStep={activeStep} orientation='horizontal'>
     {steps.map((step,index) => {
       const labelProps = {};
       const stepProps = {};
       return (
         <Step {...stepProps} key={index}>
           <StepLabel {...labelProps}>{step}</StepLabel>
         </Step>
       );
     })}
   </Stepper>
       </Item>
     </Grid>    
    </Grid>
    </Paper>
   <Paper className={classes.contpaper} elevation={6}>
   <form  noValidate className={`${classes.form}`} onSubmit={handleSubmit} encType="multipart/form-data">
     {getStepContent(activeStep)}
     </form>
       <Button
         className={classes.button}
         disabled={activeStep === 0}
         onClick={handleBack}
       >
         back
       </Button>
       
       <Button
         className={classes.button}
         variant="contained"
         color="primary"
         // onClick={handleNext}
         onClick={ (e) =>{ if(activeStep === steps.length - 1){
           handleSubmit(e);
         }else{
           handleNext();
         }} }
       >
         {activeStep === steps.length - 1 ? "Submit" : "Next"}
       </Button>
   </Paper>
 </div>
  )
}

export default Multistepupdateform;