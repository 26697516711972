import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Listaccordion from '../../components/Listaccordion';
import Accordion from '../../components/Dsce/Accordion';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import Ddscearticletemplate from '../../downloads/DS-CE-Article_Template.doc';
import Ddscecopyrightform from '../../downloads/DS-CE-Copyright_Form.docx';
import Journaltitle from '../../pages/dsce/data.json';



const Archives = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const accordionData = [{
    content:
      <div>
        <HashLink smooth to="/ce" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
        <NavLink to="/ce/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>   Editorial Board</NavLink>
        <NavLink to="/ce/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
        <NavLink to="/ce/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
        <NavLink to="/ce/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
        <NavLink to="/ce/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
        <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
        <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
        <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
        <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
        <NavLink to="/ce/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
        {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
        <a href={Ddscearticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
        <a href={Ddscecopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
      </div>
  },
  ];
  const listaccordionData = [{
    heading: "2024", content: <Link to="/ce/archives/volume1/issue1"> Volume1 Issue1 , October - December</Link>,
  },
  ];
  return (
    <>
      <Helmet>
            <title>Archives - DS Journal of Reviews of Commerce and Economics</title>
            <link rel="canonical" href="https://dsjournals.com/ce/archives"/>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="title" content="Archives - DS Reviews of Commerce and Economics"/>
            <meta name="description" content="Explore the archives of DS-CE Journal and gain new insights into historical scientific developments. Access valuable research from your field today."/>
            <meta name="keywords" content="recent research paper in Reviews of Commerce and Economics, archives of Reviews of Commerce and Economics journal, a journal article, a journal of Reviews of Commerce and Economics research, a Reviews of Commerce and Economics peer reviewed journal, article for journal, journal Reviews of Commerce and Economics, Reviews of Commerce and Economics research, a Reviews of Commerce and Economics journal, journal of Reviews of Commerce and Economics research, research in Reviews of Commerce and Economics."/>
            <meta property="og:type" content="Website"/>
            <meta property="og:site_name" content="Dream Science"/>
            <meta property="og:url" content="https://dsjournals.com/ce/archives"/>
            <meta property="og:title" content="Archives - DS Reviews of Commerce and Economics"/>
            <meta property="og:description" content="Explore the archives of DS-CE Journal and gain new insights into historical scientific developments. Access valuable research from your field today."/>
            <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Commerce-and-Economics.webp"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content="Archives - DS Reviews of Commerce and Economics"/>
            <meta name="twitter:description" content="Explore the archives of DS-CE Journal and gain new insights into historical scientific developments. Access valuable research from your field today."/>
            <meta name="twitter:site" content="@DreamScience4"/>
            <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
            <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Commerce-and-Economics.webp"/>
            <meta name="robots" content="index, follow"/>

      </Helmet>
      <section id="hero-no-slide-dsce" className="d-flex justify-center align-items-center" style={{ height: "300px" }}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  {/* <h2 style={{color:"black"}}>Archive</h2> */}
                  {
                                Journaltitle.map(heading => {
                                  return(
                                    <h2 style={{fontSize:"25px"}}>{heading.title}</h2>     
                                  )
                                      
                                })
                            }
                  {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            {/* <h2>Archives</h2> */}
            <ol>
              <li><Link to="/home">Home</Link></li>
              <li><Link to="/journals">Journals</Link></li>
              <li><Link to="/ce">DS-CE</Link></li>
              <li>Archive</li>
            </ol>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="blog" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 mb-3">
                <div className="list-group">
                  <div className="accordion">
                    {accordionData.map(({ content }) => (
                      <Accordion content={content} />
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h5 style={{ alignItems: 'center' }}>Archive</h5>
                    <ul className="accordion">
                      {listaccordionData.map(({ heading, content }) => (
                        <Listaccordion heading={heading} content={content} />
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="row">
                  {/*<h5>2021</h5>
        <hr/>
         <div className="col-md-2">
        <Link to="/archiveslist" className="btn btn-outline-dark float-center">Issue 1</Link>
        </div> */}



                </div>
              </div>



            </div>

          </div>

        </section>


      </main>
    </>
  )
}

export default Archives;
