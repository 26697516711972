import React,{useState} from 'react';
import { convertToRaw } from 'draft-js';
// import Richtextbox from '../components/Richtextbox';

import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIRichTextEditor from "mui-rte";
import {EditorState} from 'draft-js';


const Test = () => {

    const [postdata, setPostdata] = useState(()=>EditorState.createEmpty());
    
    const handleChange = e =>{
       
        // const rteContent = convertToRaw(e.getCurrentContent()) // for rte content with text formating
        // rteContent && setPostdata(JSON.stringify(rteContent))
    }
    console.log(handleChange());

    // const save = (data) => {
    //     console.log(data);
    //   };
      
      const myTheme = createTheme({
        // Set up your custom MUI theme here
      });
      
  return (
    <>
    <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center" style= {{height:"300px"}}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="row justify-content-center">
                <div className="col-xl-8">
                  <h2>Test</h2> 
                </div>
            </div>
          </div>
        </div>
        </div>
      </section>
      <main id="main">
        <section className='blog'>
        {/* <Richtextbox/> */}
        <ThemeProvider theme={myTheme}>
    <MUIRichTextEditor
      label="Type something here..."
      inlineToolbar={true}
     
      onChange={handleChange}
    />
  </ThemeProvider>,
        </section>
      </main>
    
    </>
  )
}

export default Test;