import { FETCH_ALL_ARCH,START_LOADING,END_LOADING } from "../../constants/actionTypes";

export default (state = {isLoading:true,posts:[]},action) =>{
    switch(action.type) {
        case START_LOADING:
            return { ...state, isLoading: true };
        case END_LOADING:
            return { ...state, isLoading: false };
        case FETCH_ALL_ARCH:
            return { ...state, posts: action.payload};
        default:
            return state;
    }

}