//import React from 'react';
import { Link } from 'react-router-dom';
import './Homecont.css';
import { Helmet } from 'react-helmet';
import React,{ useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';


const Homecont = () => {
  const [currentId, setCurrentId] = useState(0);
    const dispatch = useDispatch();
   
  
    useEffect(() => {
      window.scrollTo(0,0)
    //   dispatch(getPosts());
    // }, [currentId, dispatch]);
    },[]);
  return (
    <>
      <Helmet>
      <title>Dream Science | Engineering and Technology Journals</title>
      <link rel="canonical" href="https://dsjournals.com/home"/>
      <meta name="title" content="Dream Science | Engineering and Technology Journals"/>
      <meta name="description" content="DS Journals publishes high-quality academic research papers in various fields. Submit your manuscript for peer review and get published early!"/>
      <meta name="keywords" content="research journals, journal publication, scopus indexed journal, science journals, indexed jounals, science magazine, scopus journal, scopus journal list, scientific journal, peer reviewed journals, ugc approved journals, academic journal, google scholarly articles."/>
      <meta property="og:type" content="Website"/>
      <meta property="og:site_name" content="Dream Science"/>
      <meta property="og:url" content="https://dsjournals.com/home"/>
      <meta property="og:title" content="Dream Science | Engineering and Technology Journals"/>
      <meta property="og:description" content="DS Journals publishes high-quality academic research papers in various fields. Submit your manuscript for peer review and get published early!"/>
      <meta property="og:image" content="https://dsjournals.com/assets/img/meta/Dream-science-home.webp"/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content="Dream Science | Engineering and Technology Journals"/>
      <meta name="twitter:description" content="DS Journals publishes high-quality academic research papers in various fields. Submit your manuscript for peer review and get published early!"/>
      <meta name="twitter:site" content="@DreamScience4"/>
      <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
      <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/Dream-science-home.webp"/>
      <meta name="robots" content="index, follow"/>
      </Helmet>    
        <section id="hero-no-slider" className="d-flex justify-content-center align-items-center">
          <div className="container-fluid position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                    <h2>Invent to Broadcast Scientific Communal</h2>
                    <p>Augment the Wham of Exploring Transparency through Scientific Modernity</p>
                    <Link to="/journals" className="btn-get-started">Our Journals</Link>
              </div>
            </div>
          </div>
      </section>
    <main id="main">    
    {/* <section id="about" className="about section-bg mt-5"> */}
    <section id="about" className="about">
      <div className="container-fluid">
      <div className="section-title">
         {/* <h2>About Us</h2> */}
         <h1>About Us</h1>
      </div>
      {/* <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                <div className="count-box py-5">
                  <i className="bi bi-journal-richtext"></i>
                  <span data-purecounter-start="0" data-purecounter-end="10" className="purecounter">0</span>
                  <p>Journals</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                <div className="count-box py-5">
                  <i className="bi bi-globe"></i>
                  <span data-purecounter-start="0" data-purecounter-end="15" className="purecounter">0</span>
                  <p>Country Author</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                <div className="count-box pb-5 pt-0 pt-lg-5">
                  <i className="bi bi-calendar3"></i>
                  <span data-purecounter-start="0" data-purecounter-end="4" className="purecounter">0</span>
                  <p>Issues for yearly</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                <div className="count-box pb-5 pt-0 pt-lg-5">
                  <i className="bi bi-award"></i>
                  <span data-purecounter-start="0" data-purecounter-end="7" className="purecounter">0</span>
                  <p>Globally Accepected</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="row">

          <div className="col-lg-6 video-box align-self-baseline position-relative">
            <img src="assets/img/about.jpg" className="img-fluid" alt="about"/>
            {/* <NavLink to="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox play-btn mb-4"></NavLink> */}
          </div>

          <div className="col-lg-6 pt-3 pt-lg-0 content">
            {/* <h3>Who We Are...</h3> */}
            <p className="justify-content-start d-flex" style={{textAlign:"justify"}}>
            Dream Science is a global community of scientists who adhere to the same fundamental research methodology standards and general conceptions of Science.The processes can either help or hinder the achievement of the goal of assisting humanity. Dream Science will also take into account the 
            processes through which scientific knowledge and technology are directed, disseminated, and applied, as well as the effects of such processes on the selection of what research is done and on the outcomes achieved.
            Dream Science is a service based nonprofit research organization founded in India, furnishing 
            supports and services to education professionals and researchers around the globe without any 
            cost or financial expectation. Our mission has always focused on helping our researchers succeed, 
            wherever they are in their education and professional careers.</p>
            {/* <p className="justify-content-start d-flex" style={{textAlign:"justify"}}>
            We are bridging the higher education gap by delivering content solutions in new and innovative ways to 
            enrich the learning experience.We encourage learning to be a continuous, lifelong experience – an integral, essential part of every stage of building a career.
            </p> */}

              <p className="justify-content-start d-flex" style={{textAlign:"justify"}}>
              We are strengthening the research community by partnering with learned societies and supporting researchers to communicate discoveries that make a difference. 
              We collaborate with authors, societies, libraries, and other members of the research community to generate, communicate, and enable access to the scientific and 
              scholarly insights that are helping to solve some of the world’s biggest challenges.</p>
              <p className="justify-content-start d-flex" style={{textAlign:"justify"}}>
              Our commitment to partnership helps us advance innovation and connect researchers, learners, and professionals with the content, 
              platforms, and tools they need to be successful.</p>

              {/* <p className="justify-content-start d-flex" style={{textAlign:"justify"}}>
              Dream Science is a global community of scientists who adhere to the same fundamental research methodology standards and general conceptions of Science.The processes can either help or hinder the achievement of the goal of assisting humanity. Dream Science will also take into account the 
processes through which scientific knowledge and technology are directed, disseminated, and applied, as well as the effects of such processes on the selection of what research is done and on the outcomes achieved.
We are also focusing on the connections between science and human rights in order to safeguard and defend the freedom and obligations where scientists should have carrying out their profession.The main goal of the  
Dream Science guidelines is, to utilize the untapped potential of the scientific community to strengthen the connections between science and society by bridging the science-society, science-science, and society-science gaps by supporting the community to recognize 
their issues and making appropriate scientific and technical solutions.Science and society have a mutually beneficial relationship, and we will discuss both the social implications for science and the impact of science on society. In order to foster an environment 
of knowledge and resource exchange, Dream Science will further connect top scientific institutions with all relevant parties. It will make it possible for the scientific community to change its perspective, improve the organisations' 
social standing, and motivate students to pursue careers in science.
              </p>
              <p className="justify-content-start d-flex" style={{textAlign:"justify"}}>
              Dream Science is a service-based nonprofit research organization founded for furnishing supports and services to educate professionals and 
researchers around the globe without any cost or financial expectation. Our mission has always focused on helping our researchers succeed, wherever they are in their education and professional careers.
              </p>
              <p className="justify-content-start d-flex" style={{textAlign:"justify"}}>
              We are bridging the higher education gap by delivering content solutions in new and innovative ways to enrich the learning experience.
We encourage learning to be a continuous, lifelong experience – an integral, essential part of every stage of building a career.
              </p>
              <p className="justify-content-start d-flex" style={{textAlign:"justify"}}>
              We are strengthening the research community by partnering with learned societies and supporting researchers to communicate discoveries that makes a difference. 
We collaborate with authors, societies, libraries, and other members of the research community to generate, communicate, and enable access to the 
scientific and scholarly insights that are helping to solve some of the world’s biggest challenges.
              </p>
              <p className="justify-content-start d-flex" style={{textAlign:"justify"}}>
              Our commitment to partnership helps us advance innovation and connect researchers, learners, and professionals with the content, platforms, and tools they need to be successful.
              </p> */}
            
           {/* <h3>Why We Are...</h3>
            <p style={{textAlign:"justify"}}>
            Dream Science is a service based nonprofit research organization founded in India, furnishing 
            supports and services to education professionals and researchers around the globe without any 
            cost or financial expectation. Dream Science play a vital role in the scientific community. 
            Every day, you make judgment calls that have a direct impact on published research, and on 
            individual researchers' careers.
            </p> */}
          </div>
        </div>
      </div>
    </section>

    <section className="services">
    <div className="section-title">
         <h2>Our Features</h2>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-4 d-flex align-items-stretch" data-aos="fade-up">
            <Link to="/abstract-and-indexing" className="icon-box icon-box-pink">
              <div className="icon"><i className="bx bxs-book-content"></i></div>
              <h4 className="title">Abstracting and indexing</h4>
              <p className="description">World Wide indexed international journals in various disciplines from Dream Science for research community...</p>
            </Link>
          </div>

          <div className="col-md-6 col-lg-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
            <Link to="/open-access" className="icon-box icon-box-cyan">
              <div className="icon"><i className="bx bxs-lock-open-alt"></i></div>
              <h4 className="title">Open Access</h4>
               <p className="description">In accordance with major definitions of open access in scientific literature (namely the Budapest, Berlin, and Bethesda declarations)...</p>
            </Link>
          </div>

          <div className="col-md-6 col-lg-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <Link to="/for-authors/licensing-policy" className="icon-box icon-box-green">
              <div className="icon"><i className="bi bi-journal-medical"></i></div>
              <h4 className="title">License Policy</h4>
              <p className="description">Dream Science International Journals publishes open access articles under a Attribution-NonCommercial-No Derivatives 4.0....</p>
            </Link>
          </div>

          {/* <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <Link to="/conferences" className="icon-box icon-box-blue">
              <div className="icon"><i className="bx bx-world"></i></div>
              <h4 className="title">Conferences</h4>
              <p className="description">Dream Science sponsors international conferences which playing a major role in knowledge sharing among the researchers.</p>
            </Link>
          </div> */}

        </div>

      </div>
    </section>

{/* Test section 
<section className="why-us section-bg" data-aos="fade-up" date-aos-delay="200">
      <div className="section-title">
         <h2>Latest Updates</h2>
      </div>

     <div className="container">
       <div className="row">
            <div className="col-md-6 col-lg-6 mt-3 mb-3 d-md-flex align-items-md-stretch">

            <Posts setCurrentId={setCurrentId} />
            </div>
            <div className="col-md-6 col-lg-3 mt-3 mb-3 d-md-flex align-items-md-stretch">
                <Card className="card h-100">
                <CardMedia className="proposal-issue-image" image='assets/img/card3.jpg' />
                    <div className="card-body">
                    <Typography variant="h6">Special Issues Proposal</Typography>
                    <Typography variant="body2" color="textSecondary" component="p">This is a wider card with supporting text below as a natural lead-in 
                    to additional content. This content is a little bit longer.</Typography>
                    </div>
                    <div className="card-footer">
                    <Typography variant="body2" className="text-muted">Last updated 3 mins ago</Typography>
                    </div>
                </Card>
            </div>
            <div className="col-md-6 col-lg-3 mt-3 mb-3 d-md-flex align-items-md-stretch">
              <Card className="card h-100">
              <CardMedia className="Event-proposal-image" image='assets/img/card2.jpg' />
                 
                  <div className="card-body">
                  <Typography variant="h6">Event Proposal</Typography>
                  <Typography variant="body2" color="textSecondary" component="p">This is a wider card with supporting text below as a natural lead-in 
                    to additional content. This content is a little bit longer.</Typography>
                  </div>
                  <div className="card-footer">
                  <Typography variant="body2" className="text-muted">Last updated 3 mins ago</Typography>
                  </div>
              </Card>
            </div>
        </div>
     </div>
   </section>
    Test Section End */}

   {/* <section className="why-us section-bg" data-aos="fade-up" date-aos-delay="200">
      <div className="section-title">
         <h2>Latest Updates</h2>
      </div>

     <div className="container">
       <div className="row">
            <div className="col-md-6 col-lg-6 mt-3 mb-3 d-md-flex align-items-md-stretch">
            
            <Card className="card">
                <CardMedia className="special-issue-image" image='assets/img/card1.png' />
               
                <div className="card-body">
                <Link to='/specialissues'><Typography variant="h6">Special Issues</Typography>
                    <Typography variant="body2" color="textSecondary" component="p">Special Issues are a key component of our journal contents, focusing on emerging areas in the discipline, 
                    or providing detailed analysis of a key research topic.</Typography></Link>
                </div>
                <div className="card-footer">
                <Typography variant="body2" className="text-muted">updated by admin</Typography>
                </div>
                </Card>
            </div>
            <div className="col-md-6 col-lg-3 mt-3 mb-3 d-md-flex align-items-md-stretch">
                <Card className="card h-100">
                <CardMedia className="proposal-issue-image" image='assets/img/card3.jpg' />
                    <div className="card-body">
                    <Link to="/specialissueproposal"><Typography variant="h6">Special Issues Proposal</Typography>
                    <Typography variant="body2" color="textSecondary" component="p">Dream Science International Journals encourages academicians to organize National / International conferences in 
                    association with SSRG in the name of special issues on common topics of interest.</Typography></Link>
                    </div>
                    <div className="card-footer">
                    <Typography variant="body2" className="text-muted">updated by admin</Typography>
                    </div>
                </Card>
            </div>
            <div className="col-md-6 col-lg-3 mt-3 mb-3 d-md-flex align-items-md-stretch">
              <Card className="card h-100">
              <CardMedia className="Event-proposal-image" image='assets/img/card2.jpg' />
                 
                  <div className="card-body">
                  <Link to="/eventproposal"><Typography variant="h6">Event Proposal</Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                  Our Dresam Science Event Management is the leading peer-reviewed international journal for the study and analysis of events and festivals, meeting the research 
                  and educational needs of this rapidly growing industries.</Typography></Link>
                  </div>
                  <div className="card-footer">
                  <Typography variant="body2" className="text-muted">updated by admin</Typography>
                  </div>
              </Card>
            </div>
        </div>
     </div>
   </section> */}



   <section className="features">
      <div className="container-fluid">
          {/* <div className="section-title">
            <h2>Features</h2>
          </div> */}
          <div className="row" data-aos="fade-up">
              <div className="col-md-5">
                <img src="assets/img/service-details-1.jpg" className="img-fluid" alt=""/>
              </div>
              <div className="col-md-7 pt-1">
                <h3>We Focus On....</h3>
                {/* <p className="fst-italic" style={{textAlign:"justify"}}>
                  The research journey does not end when a paper is published. It is then that the work of helping 
                  others discover and understand the research begins. To help you be successful in this stage of your 
                  journey is why we, in collaboration with leading science communicators, created this science 
                  communication guide.
                </p> */}
                <p style={{textAlign:"justify"}}>
                    The main goal of Dream Science is, to utilize the untapped potential of the scientific community to strengthen the connections between science and society by bridging the science-society, science-science, and society-science gaps by supporting the community to recognize 
                their issues and making appropriate scientific and technical solutions.Science and society have a mutually beneficial relationship, and we will discuss both the social implications for science and the impact of science on society. In order to foster an environment 
                of knowledge and resource exchange, Dream Science will further connect top scientific institutions with all relevant parties. It will make it possible for the scientific community to change its perspective, improve the organisations' 
                social standing, and motivate students to pursue careers in science.
                </p>
                <p style={{textAlign:"justify"}}> 
                    The processes can either help or hinder the achievement of the goal of assisting humanity. Dream Science will also take into account the 
                processes through which scientific knowledge and technology are directed, disseminated, and applied, as well as the effects of such processes on the selection 
                of what research is done and on the outcomes achieved.
                </p>
                
              </div>
          </div>
      </div>
    </section>      
  </main> 
    </>
  )
}

export default Homecont;