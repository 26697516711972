import React from 'react';
import {Link} from 'react-router-dom';
import './Footer.css';
import ScrollToTop from 'react-scroll-to-top';




const Footer = ()=> {
  // const [showButton, setShowButton] = useState(false);
  
  // const checkScrollTop = () => {
  //   if(!showButton && window.pageYOffset > 400) {
  //     setShowButton(true)
  //   }else if(showButton && window.pageYOffset <= 400) {
  //     setShowButton(false)
  //   }
  // };
  // const scrollTop = () =>{
  //   window.scrollTo({top: 0, behavior: 'smooth'});
  // };
  // useEffect(()=>{
  //   window.addEventListener("scroll",checkScrollTop)
  // });
    

  return (
    <>
    <section className='footer' id="footer" >
    <footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
<div className="footer-top">
<div className="container">

<div className="row">
  <div className="col-lg-2 col-md-6 footer-links">
{/* <h4 style={{fontSize:'14px'}}>Useful Links</h4> */}
<ul>
<li><i className="bx bx-chevron-right"></i> <Link to="/home">Home</Link></li>
  <li><i className="bx bx-chevron-right"></i> <Link to="/journals">Journals</Link></li>
  <li><i className="bx bx-chevron-right"></i> <Link to="/about-us">About Us</Link></li>
  {/* 
  <li><i className="bx bx-chevron-right"></i> <Link to="/forreviewers">For Reviewers</Link></li> */}
 
</ul>
</div>

<div className="col-lg-3 col-md-6 footer-links">
{/* <h4>Our Features</h4> */}
<ul>
<li><i className="bx bx-chevron-right"></i> <Link to="/for-authors">Authors Guidelines</Link></li>
<li><i className="bx bx-chevron-right"></i> <Link to="/for-editors">Editor Guidelines</Link></li>
<li><i className="bx bx-chevron-right"></i> <Link to="/for-reviewers">Reviewer Guidelines</Link></li>
</ul>
</div>

<div className="col-lg-3 col-md-6 footer-links">
{/* <h4>Our Features</h4> */}
<ul>
<li><i className="bx bx-chevron-right"></i> <Link to="/open-access">Open Access</Link></li>
<li><i className="bx bx-chevron-right"></i> <Link to="/for-authors/publication-ethics">Publication Ethics</Link></li>
<li><i className="bx bx-chevron-right"></i> <Link to="/for-authors/copyright-infringement">Copyright Infringement</Link></li>
</ul>
</div>

<div className="col-lg-2 col-md-6 footer-links">
{/* <h4>Useful Links</h4>*/}
<ul>
{/* <li><i className="bx bx-chevron-right"></i> <Link to="/faq">FAQ</Link></li> */}
<li><i className="bx bx-chevron-right"></i> <Link to="/for-authors/licensing-policy">Licensing Policy</Link></li>
<li><i className="bx bx-chevron-right"></i> <Link to="/faq">FAQ</Link></li>
<li><i className="bx bx-chevron-right"></i> <Link to="/contact-us">Contact Us</Link></li>  
</ul>

</div>


{/* <div className="col-lg-2 col-md-6 footer-info">
 {/* <h4 style={{fontSize:'14px'}}>Address</h4>  
<p>
  140, General Bazzar Street,<br/>
  Thennur, Trichy 620017. <br/>
  TamilNadu, India.<br/><br/>
 
</p>

</div> */}


<div className="col-lg-2 col-md-6 footer-contact">
 <h4 style={{fontSize:'14px'}}>Follow Us</h4> 
{/* <i class="bi bi-phone-vibrate-fill"></i><span style={{fontSize:"11.5px"}}> +91-9578957897</span><br/>
<i class="bi bi-envelope"></i><span style={{fontSize:"11.5px"}}> queriesfords@gmail.com</span><br/> */}
<div className="social-links mt-3">
  <a href='https://twitter.com/DreamScience4' target="blank"><i className="bx bxl-twitter"></i></a>
  <a href='https://www.facebook.com/Dream-Science-Journals-102737959201943' className="facebook" target="blank"><i className="bx bxl-facebook"></i></a>
  <a href='https://www.instagram.com/dreamsciencejournals/' className="instagram" target="blank"><i className="bx bxl-instagram"></i></a>
  <a href='https://www.linkedin.com/in/dream-science-journals-a7a688246/' className="linkedin" target="blank"><i className="bx bxl-linkedin"></i></a>
  {/* <Link to="/" className="twitter"><i className="bx bxl-twitter"></i></Link>
  <Link to="/" className="facebook"><i className="bx bxl-facebook"></i></Link>
  <Link to="/" className="instagram"><i className="bx bxl-instagram"></i></Link>
  <Link to="/" className="linkedin"><i className="bx bxl-linkedin"></i></Link> */}
</div>
</div>

</div>
</div>
</div>

<div className="container">
<div className="copyright">
&copy; Copyright <strong><span>Dream Science</span></strong>. All Rights Reserved
</div>

</div>
</footer>

{/* <Link to="#header" className="back-to-top d-flex align-items-center justify-content-center">
<i className="bi bi-arrow-up-short"></i></Link> */}
{/* <button onClick={scrollTop} className="back-to-top d-flex align-items-center justify-content-center">
  <i className="bi bi-arrow-up-short"></i>
</button> 
*/}

    <ScrollToTop smooth color='#94c045' style={{background:'#94c146'}} component={<div style={{fontSize:'25px',color:'#fff'}}>
    <i className="bi bi-arrow-up-short"></i></div>} viewBox='0 0 256 256' />
    </section>
</>
  )
}

export default Footer;
