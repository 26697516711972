import React,{useState,useEffect} from 'react';
import './Accordion.css';


const Accordion = ({ title, content }) => {
    const [isActive, setIsActive] = useState(true);
    const [screenSize, getDimension] = useState({dynamicWidth: window.innerWidth});

   
    const setDimension = () => {
        getDimension({
          dynamicWidth: window.innerWidth
        })
      }

   

    function screenhide(screenset){
        if(screenset < 992){
            setIsActive(false)
        }else{
            setIsActive(true)
        }
        
    }
    
    useEffect(() => {
        var screenset = screenSize.dynamicWidth;
        window.addEventListener('resize', setDimension);
        window.addEventListener('resize', screenhide(screenset))
        // console.log("Screen Set: ", screenset);
        return(() => {
            window.removeEventListener('resize', setDimension);

        })
      }, [screenSize])
          
    // useEffect(() => {
    //     const accordcheck = document.getElementById("accordion-text");
    //     console.log("getElementById :",accordcheck);
    // },[]);
    
    return (
        
            <div id="sidebar-accordion" className="accordion-item">
                    {/* <div className="accordion-title" onClick={() => setIsActive(!isActive)}> */}
                    <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                        <div className="accordion-text" style={{padding:"10px"}}>DS-MS Menu
                        {/* <span className="accordion-icon">{isActive ? '-' : '+'}</span></div> */}
                        <span className="accordion-icon">{isActive ? <i class="bi bi-menu-down"></i> : <i class="bi bi-menu-up"></i>}</span></div>
                    </div>
                {isActive && <div className="accordion-content">{content}</div>}
            </div>
    );
  };

export default Accordion;
