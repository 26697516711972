import { START_LOADING, END_LOADING, FETCH_ALL,FETCH_ALL_ARCH, FETCH_POST, CREATE, UPDATE, DELETE,FETVOLISS } from "../../../constants/actionTypes";
import * as api from '../../../api/index.js';


export const getvolissuePost = (vol,iss) => async (dispatch) =>{
  try{
    dispatch({ type: START_LOADING });
    const {data} = api.fetchdsmsvolissjournalPost(vol,iss)
    dispatch({type:FETVOLISS, payload: {post:data}});
    dispatch({type:END_LOADING});
  }
  catch(error){
    console.log(error);
  }
}



export const getarchivePosts = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchdsmsarchivePosts();
    dispatch({ type: FETCH_ALL_ARCH, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};




export const createjournalPost = (post) => async (dispatch) => {
  try{
    dispatch({ type: START_LOADING });
    const data = await api.createdsmsjournalPost(post);
    dispatch({type: CREATE, payload: data}); 
    alert("Data Created successfully");
    }catch(error){  
    console.log(error.message);
    alert("Data Already Exist");
   }
};


export const getjournalPost = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    
    const { data } = await api.fetchdsmsjournalPost(id);

    dispatch({ type: FETCH_POST, payload: {post : data} });
   
  } catch (error) {
    console.log(error);
  }
};


export const getjournalPosts = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchdsmsjournalPosts();

    dispatch({ type: FETCH_ALL, payload: data });
    dispatch({ type: END_LOADING });
   
  } catch (error) {
    console.log(error);
  }
};

export const updatejournalPost = (id, post) => async (dispatch) =>{
  try {
    const data = await api.updatedsmsjournalPost(id,post);
    dispatch({type: UPDATE, payload: data});
    alert("Data updated successfully");
  } catch (error) {
    console.log(error.message);
  }
};

export const deletejournalPost = (id) => async (dispatch) => {
  try {
    await await api.deletedsmsjournalPost(id);

    dispatch({ type: DELETE, payload: id });
    alert("Are your sure  deleted " + id);
  } catch (error) {
    console.log(error);
  }
};