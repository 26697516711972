import React, { useState,useEffect }  from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Grid, LinearProgress} from '@material-ui/core';
import { Box,Typography,CircularProgress} from '@mui/material';
import { PropTypes } from 'prop-types';
import Post from './Post/Post';
import { getjournalPosts } from '../../../../actions/journal/dsms/posts';

// const Posts = ({setCurrentId}) => {
//   // const dispatch = useDispatch();
//     const posts = useSelector((state) =>  state.dsmsjposts.posts)
    
//     // useEffect(() => {
//     //   dispatch(getjournalPosts());    
//     // }, [dispatch]);

//   return (
//     !posts.length ? <CircularProgress /> : (
//         <Grid container alignItems="stretch" spacing={3}>
//             {posts.sort((a, b) => a.article_id > b.article_id ? 1 : -1).map((post) => (
//                <Grid key={post._id} item xs={12} sm={12} md={12}>
//                <Post post={post} setCurrentId={setCurrentId} />
//              </Grid>
//             ))}
//         </Grid>
//     )
  
//   )
// }

// export default Posts

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};



const Posts = ({ setCurrentId, volume, issue }) => {
  const [progress, setProgress] = useState(10); 
  const posts = useSelector((state) => state.dsmsjposts.posts)
  const dispatch = useDispatch();




useEffect(() => {
  const timer = setInterval(() => {
    setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
  }, 800);
  return () => {
    clearInterval(timer);
  };
}, []);


useEffect(() => {
  dispatch(getjournalPosts());    
}, [volume,issue,dispatch]);


return (
  // !posts.length ? <CircularProgress /> : (
    posts && posts.length <= 0 ?  
      (<div style={{display:'flex',alignItems:'center',justifyContent:'center',padding:'50px'}}>
        <CircularProgressWithLabel value={progress}/> 
          &nbsp;&nbsp;&nbsp;&nbsp;<Typography variant="h5">Loading...</Typography>
      </div>)
       : 
       (<Grid container alignItems="stretch" spacing={3}>
    {posts.filter(key => key.volume == volume & key.issue == issue).sort((a, b) => a.article_id > b.article_id ? 1 : -1).map((post) => (
      <Grid key={post._id} item xs={12} sm={12} md={12}>
        <Post post={post} setCurrentId={setCurrentId} />
      </Grid>
    ))}
  </Grid>
  )

)
}

export default Posts