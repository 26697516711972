import React from 'react';
import {Link} from 'react-router-dom';
import Accordion from '../components/Accordion';

function Specialissues() {
  const accordionData = [{
    content: <div>
        <Link to="#" class="list-group-item list-group-item-action"><i class="bi bi-chevron-double-right"></i>Special Issues offer</Link>
                    <Link to="#" class="list-group-item list-group-item-action"><i class="bi bi-chevron-double-right"></i>They Should</Link>
                    <Link to="#" class="list-group-item list-group-item-action"><i class="bi bi-chevron-double-right"></i>Proposal Should</Link>
</div> 
},
  ];
    return (
        <>
        <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center">
          <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    <h2>Special Issues</h2>
                    <Link to="/" className="btn-get-started ">Read More</Link>
                  </div>
              </div>
            </div>
          </div>
          </div>
        </section>
        
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Special Issues</h2>
              <ol>
              <li><Link to="/home">Home</Link></li>
                <li><Link to="/journals">Journals</Link></li>
                <li><Link to="/journalsview">Journal Discipline</Link></li>
                <li>Special Issues</li>
              </ol>
            </div>
          </div>
        </section>
             <main id="main">
    <section class="blog" id="blog">
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                  <div class="list-group">
                  <div className="accordion">
                      {accordionData.map(({content }) => (
                        <Accordion content={content} />
                    ))}
                  </div>
                    {/* <Link to="#" class="list-group-item list-group-item-action btn-submit active">Contents</Link>
                    <Link to="#" class="list-group-item list-group-item-action"><i class="bi bi-chevron-double-right"></i>Special Issues offer</Link>
                    <Link to="#" class="list-group-item list-group-item-action"><i class="bi bi-chevron-double-right"></i>They Should</Link>
                    <Link to="#" class="list-group-item list-group-item-action"><i class="bi bi-chevron-double-right"></i>Proposal Should</Link> */}
                  </div>
                </div>
                <div class="col-lg-9">
                    <h5>Special Issues</h5>
                <p style={{textAlign:"justify"}}>Special Issues are an important component of Dream Science Journal of Engineering 
                    (Dream Science). They deal with more focused topics with high current interest falling 
                    within the scope of the journal in which they are published. They should be organized by 
                    recognized experts in the area and attract articles of the highest quality.</p>
      <p style={{textAlign:"justify"}}>Special Issue proposals are welcome at any time during the year, and Proposals should be submitted to 
          editor@Dream Scienceonline.org, and they are subject to approval by the journal's Editorial Board. 
          A proposal should not exceed 1-2 pages.</p>   
<p style={{textAlign:"justify"}}>Proposals for Special Issues should include the following:</p>
<p style={{textAlign:"justify"}}>Proposals for Special Issues should be submitted by the Lead Guest Editor of the Special Issue and 
    should include the following:</p>
    <ul>
<li>The journal for which the Special Issue is intended</li>

<li>A suggested title for the Special Issue (should not exceed 12 words)</li>

<li>Proposed Aims and Scope, giving an overview of the Special Issues intended focus and a list of the topics to be covered</li>

<li style={{textAlign:"justify"}}> A list of Guest Editors who will join the Lead Guest Editor in managing the Special Issue, including their names, emails, affiliations, and a short biography (one paragraph) of each of the Guest Editors</li>

<li>A proposed timeline and schedule which includes:</li></ul>
<p style={{textAlign:"justify"}}>(a) Deadline for submission (6 months from the initial Call-for-Papers)</p>
<p style={{textAlign:"justify"}}>(b) First round of review (3 months after submission deadline)</p>
<p style={{textAlign:"justify"}}>(c) Tentative publication date (3 months after the first round of review)</p>
      <p style={{textAlign:"justify"}}>All proposals are subject to approval by the journal following a discussion of the proposed 
          Special Issue among the journal’s Editorial Board. If approved, a Call-for-Papers for the Special 
          Issue will be issued and posted online.</p>
      <p style={{textAlign:"justify"}}>Special Issues are made freely available online to all interested readers leading to the maximum 
          possible dissemination and recognition within the scientific community. Special Issues can be 
          viewed and downloaded on the individual article level, but are also available in an edited volume 
          format as a single PDF published with a custom cover. They are also made available in print for all
           readers interested in a print edition.</p>
<p style={{fontWeight:"bold",textAlign:"justify"}}>Special issues of Dream Science should offer</p>
<ul>
<li>either an authoritative review of current thinking and debates in a particular area of Engineering (Software, Mechanical, Civil, Electronics & Electrical, etc,.)</li>

<li>And/or a presentation of state-of-the-art analysis within an area of current approach and academic interest, that thus informs approach debate in this area.</li></ul>

<p style={{fontWeight:"bold",textAlign:"justify"}}>They should</p><ul>
<li>dvance understanding of topics in the engineering and its applications</li>

<li>Provide pointers to future trends and challenges.</li></ul>

<p style={{fontWeight:"bold",textAlign:"justify"}}>Proposals should</p>
<ul>
<li>Set out the importance of the area and topics that the special issue will focus on;</li>

<li>Explain the anticipated contribution of the special issue in advancing understanding in this area;</li>

<li>Indicate the time-scale in which the special issue could be produced (paper writing, reviewing, and submission of final copies to Dream Science) assuming the proposal is accepted.</li></ul>
<p style={{fontWeight:"bold",textAlign:"justify"}}>Editing a Successful Special Issue</p>
      <p style={{textAlign:"justify"}}>Once a proposal has been approved in principle by the Editorial Board, it is the responsibility of the Guest Editor(s) to deliver the soft copy of the entire issue to the Dream Science within the agreed time-scale. Guest Editors must organize a double-blind reviewing process for all papers submitted for inclusion in the special issue, so as to:
Identify those papers to be finally included in the special issue</p>

<p style={{textAlign:"justify"}}>Strengthen these papers, so as to ensure the highest possible standards for the issue.

Verify the accepted papers for any possible infringements of any copyrights or intellectual properties. Dream Science authorities shall cross-verify the concepts and inventions proposed the submitted research papers against the India Patent directory.

Coordinating with the reviewers while developing extensive reviews of the submitted research papers.
      The editors of Dream Science Journal of Engineering require seeing copies of all correspondence between reviewers and Guest Editors at the time that the completed draft of the special issue is submitted to Dream Science Journal of Engineering.
      Guest Editors should keep the editor of Dream Science informed by e-mail of the progress in preparing the special issue and in particular alert the Dream Science authorities in advance if agreed deadlines are likely to slip.
      Once the final selection of papers has been made and final versions (as per Dream Science format and author guidelines of all these have been received, the Guest Editors should send electronic copies to the Editor. The guest editors will be provided with special FTP privileges to upload the papers onto Dream Science server system.
      The editorial board of Dream Science Journal of Engineering will check the quality (like: originality, novelty, clarity, research contributions, completeness, relevance, significance, methodology, presentation style) of the issue before forwarding it for publication in the Digital Library and print version of the same. The Editorial Board reserves the right to request a third review of papers and, if necessary, to request modifications to an issue before approving it for publication. However, the procedures set out above are designed to minimize the need for such measures.
Proposals for special issues can be submitted to special.issues@dreamscience.com.</p>
                </div>
              
            </div>
        </div>

    </section>


  </main>
        </>
    )
}
export default Specialissues;