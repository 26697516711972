import React,{useEffect} from 'react'
import {Link,NavLink} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dsms/Accordion';
import { HashLink } from 'react-router-hash-link';
import Ddsmsarticletemplate from '../../downloads/DS-MS-Article_Template.doc';
import Ddsmscopyrightform from '../../downloads/DS-MS-Copyright_Form.docx';
import Dsmsdata from './dsmsdata';

const Dsdsmstopics = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);
    const accordionData = [{
        content: <div>
             <HashLink smooth to="/ms" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
            <NavLink to="/ms/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>   Editorial Board</NavLink>
            <NavLink to="/ms/paper-submission"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/ms/current-issue"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/ms/archives"           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/ms/topics"                           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target= "_blank"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target= "_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/ms/apc"               className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
        {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
            <a href={Ddsmsarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Ddsmscopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
    </div> 
    },
      ];

  return (
    <>
     <Helmet>
        <title>Topics Covered - DS-MS Journal</title>
        <link rel="canonical" href="https://dsjournals.com/ms/topics"/>
        <meta name="title" content="Topics Covered - DS-MS Journal"/>
        <meta name="description" content="Discover the wide range of topics covered by DS-MS Journal. From simulation and modeling, explore the latest research and insights in your field."/>
        <meta name="keywords" content="simulation model, modeling and simulation, molecular simulation, journal of simulation, modeling and simulation in engineering, system modeling and simulation, simulation modeling and analysis, molecular simulation journal, dynamic simulation modeling, physics based simulation, mathematical modeling and simulation."/>
        <meta property="og:type" content="Website"/>
        <meta property="og:site_name" content="Dream Science"/>
        <meta property="og:url" content="https://dsjournals.com/ms/topics"/>
        <meta property="og:title" content="Topics Covered - DS-MS Journal"/>
        <meta property="og:description" content="Discover the wide range of topics covered by DS-MS Journal. simulation and modeling, explore the latest research and insights in your field."/>
        <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of -Modeling-and-Simulation.webp"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="Topics Covered - DS-MS Journal"/>
        <meta name="twitter:description" content="Discover the wide range of topics covered by DS-MS Journal. From simulation and modeling, explore the latest research and insights in your field."/>
        <meta name="twitter:site" content="@DreamScience4"/>
        <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
        <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of -Modeling-and-Simulation.webp"/>
        <meta name="robots" content="index, follow"/>

        </Helmet>
     <section id="hero-no-slide-dsms" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
                <div className="col-xl-8">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            {/* <h2 style={{color:"#21494f"}}>Topics</h2> */}
                            <h2 style={{fontSize:"25px",color:"#21494f"}}>{Dsmsdata[0].banner_title}</h2>
                            {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="breadcrumbs">
        <div className="container">
            <div className="d-flex justify-content-between align-items-center">
                
                <ol>
                <li><NavLink to="/home">Home</NavLink></li>
                <li><NavLink to="/journals">Journals</NavLink></li>
                <li> <NavLink to="/ms">DS-MS</NavLink></li>
                <li>Topics</li>
              </ol>
            </div>
        </div>
    </section>

    <main id="main">
<section className="blog" id="blog">
<div className="container">
<div className="row">
    <div className="col-lg-3 mb-3">
      <div className="list-group">
        <div className="accordion">
                        {accordionData.map(({content }) => (
                          <Accordion content={content} />
                      ))}
        </div>
      </div>
   </div>
    <div className="col-lg-9">
      
<p style={{textAlign:'justify',fontWeight:"bold"}}>Topics</p>
<p style={{textAlign:'justify',textIndent:"50px"}}>
The DS Journal of Modeling and Simulation provides an advanced forum for studies related to the development and applications of modeling and simulation techniques. It welcomes technical papers 
on new developments and their implications, papers on applications of existing techniques in science and engineering, survey papers on state-of-the-art as well as short communications on current 
events. The journal aims at being a reference and a powerful tool for all those professionally active and/or interested in the methods and applications of simulation. It also aims to encourage 
scientists to publish their model developments and simulation results in as much detail as possible. There is no restriction on the length of the papers. The full details on modeling, calibration,
validation, and application must be provided so that the results can be reproduced. Electronic files and software regarding the full details of the algorithmic formulations, implementations, 
validation examples, and computational applications, if unable to be published in a normal way, can be deposited as supplementary electronic material.
</p>
<p style={{textAlign:'justify',fontWeight:"bold"}}>The papers are demanded, but not limited to, the following topics:</p>
<p style={{textAlign:'justify'}}>
    <div className='row'>
        <div className='col-lg-6 col-md-6 col-sm-6'>
            <ul>
                <li>Modeling and Simulation Theory and Methodology</li>
                <li>Modeling and Simulation Languages</li>
                <li>Optimization in Modeling and Simulation</li>
                <li>Agent-based Modeling and Simulation</li>
                <li>Data-driven Modeling and Simulation</li>
                <li>Applications of Modeling and Simulation </li>
                <li>Modeling and Simulation of Continuous Systems/Discrete Systems/Hybrid Systems/Intelligent Systems</li>
                <li>Modeling and Simulation Technology of Complex Systems/System of Systems/Cyber-Physical Systems </li>
                <li>Model Engineering / Model-based System Engineering </li>
                <li>Mathematical Modeling (Stochastic And Probabilistic Modeling, Continuum and Discrete Modeling, Graph Theory, Algebraic Coding, Behavioural Language) </li>
                <li>Conceptual Modeling </li>
                <li>Complex System Modeling  </li>
                <li>Stochastic and Polymorphic Uncertainty Models </li>
                <li>Model Verification and Validation  </li>
                <li>Neural and Fuzzy Modeling </li>
            </ul>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-6'>
        <ul>
            <li>Numerical Modeling of Materials, Structures, Processes, and Systems</li>
            <li>Model-Free (Experimental) Modeling</li>
            <li>Formal Methods of Simulation (Monte Carlo Method, Planning and Scheduling, Ontologies, Real-Time Systems, Stochastic Method, Dynamic Method, Nonlinear Method, Optimization, Distributed and Parallel Computing, Mathematical Method, Decision Support, Risk Analysis, Verification and Validation, Multi-Scale Analysis)</li>
            <li>Business Systems Simulation</li>
            <li>Computational Simulation Methodologies</li>
            <li>High-Performance Simulation</li>
            <li>Intelligent Simulation</li>
            <li>Knowledge-Based Simulation Environments</li>
            <li>Methodology for Analysing Simulation Output</li>
            <li>Simulation Application Techniques</li>
            <li>Simulation Applications in Different Disciplines</li>
            <li>Simulation-Based Acquisition / Virtual Prototyping / Digital Twin</li>
            <li>Simulation Software, Simulators</li>
            <li>Simulation Supporting Tools and Platforms</li>
       </ul>

        </div>
    </div>

</p>

    </div>
</div>
</div>
</section>
</main>
    </>
  )
}

export default Dsdsmstopics ;