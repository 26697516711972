import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Accordion from '../../components/Dsms/Accordion';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import Ddsmsarticletemplate from '../../downloads/DS-MS-Article_Template.doc';
import Ddsmscopyrightform from '../../downloads/DS-MS-Copyright_Form.docx';
import './Dsdsmseditorialboard.css';
import Dsmsdata from './dsmsdata';

const Dsdsmseditorialboard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const accordionData = [{
    content: <div>
      <HashLink smooth to="/ms" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
      <NavLink to="/ms/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>   Editorial Board</NavLink>
      <NavLink to="/ms/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
      <NavLink to="/ms/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
      <NavLink to="/ms/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
      <NavLink to="/ms/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
      <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
      <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
      <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
      <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
      <NavLink to="/ms/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
      {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
      <a href={Ddsmsarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
      <a href={Ddsmscopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>

    </div>
  },
  ];
  return (
    <>
      <Helmet>
        <title>Editorial Board | DS Journal of Modeling and Simulation (DS-MS)</title>
        <link rel="canonical" href="https://dsjournals.com/ms/editorial-board" />
        <meta name="title" content="Editorial Board | DS Journal of Modeling and Simulation (DS-MS)" />
        <meta name="description" content="DS-MS Journals editorial board, consisting of experts from different fields, ensures the quality and integrity of published research. Learn more here." />
        <meta name="keywords" content="editorial board, editorial board members, editorial department, journal editorial board, become editorial board member, editorial member, editorial editor, board editorial, editorial team members, journal editorial board positions, editorial committee, editorial board positions." />
        <meta property="og:type" content="Website" />
        <meta property="og:site_name" content="Dream Science" />
        <meta property="og:url" content="https://dsjournals.com/ms/editorial-board" />
        <meta property="og:title" content="Editorial Board | DS Journal of Modeling and Simulation (DS-MS)" />
        <meta property="og:description" content="DS-MS Journals editorial board, consisting of experts from different fields, ensures the quality and integrity of published research. Learn more here." />
        <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of -Modeling-and-Simulation.webp" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="DS-MS Journals editorial board, consisting of experts from different fields, ensures the quality and integrity of published research. Learn more here." />
        <meta name="twitter:site" content="@DreamScience4" />
        <meta name="twitter:title" content="Editorial Board | DS Journal of Modeling and Simulation (DS-MS)" />
        <meta name="twitter:url" content="https://twitter.com/DreamScience4" />
        <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of -Modeling-and-Simulation.webp" />
        <meta name="robots" content="index, follow" />
      </Helmet>



      <section id="hero-no-slide-dsms" className="d-flex justify-cntent-center align-items-center" style={{ height: "300px" }}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="row justify-content-center">
                <div className="col-xl-8">
                  {/* <h2 style={{ color: "#21494f" }}>Editorial Board</h2> */}
                  <h2 style={{fontSize:"25px",color:"#21494f"}}>{Dsmsdata[0].banner_title}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            {/* <h2>Editor Spotlight</h2> */}
            <ol>
              <li><Link to="/home">Home</Link></li>
              <li><Link to="/journals">Journals</Link></li>
              <li><Link to="/ms">DS-MS</Link></li>
              <li>Editorial Board</li>
            </ol>
          </div>
        </div>
      </section>
      <main id="main">
        <section className="blog" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 mb-3">
                <div className="list-group">
                  <div className="accordion">
                    {accordionData.map(({ content }) => (
                      <Accordion content={content} />
                    ))}
                  </div>
                </div>

                <div className="abtlistgrp">
                </div>
              </div>
              {/* <div className='col-lg-9 video-box align-self-baseline position-relative'>
                 <div className="d-flex justify-content-center">
                           <p><h1>Coming Soon...</h1></p>
                           
                           </div>    
                           <div className="copyright d-flex justify-content-center">
                              &copy; Powered By &nbsp; <strong><span>Dream Science</span></strong>. 
                          </div>
                </div> */}
              <div id='head1' className="col-lg-9">
                <h3>Editorial Board</h3>
                <div className='row'>
                  <h5 className='text-left'>
                    <span style={{
                      backgroundColor: "#94c045",
                      color: "#fff",
                      lineHeight: "43px",
                      padding: "5px 10px",
                      whiteSpace: "pre-wrap"
                    }}>Editor in Chief</span></h5>
                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}>Prof. Dr. G. Vijay Kumar,</div> <p style={{ textAlign: "left", fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-justify text-muted">
                      Department of Mechanical Engineering,<br />
                      NS Raju Institute of Technology (NSRIT),<br />
                      Visakhapatnam, Andhra Pradesh, India.<br />
                      drgvkumar.me@nsrit.edu.in<br />
                      {/* <a href='https://www.sriniet.edu.in/MechanicalFacultyProfile' target="_balnk">Profile Link</a> */}
                      <a href='http://nsrit.edu.in/admin/img/cms/2Dr%20Vijay%20Kumar%20(2).pdf' target="_balnk">Profile Link</a> 
                      </p> 
                  </div>


                </div>

                <div className='row'>
                  <h5 style={{ textAlign: "left" }}><span style={{
                    backgroundColor: "#94c045",
                    color: "#fff",
                    lineHeight: "43px",
                    padding: "5px 10px",
                    whiteSpace: "pre-wrap"
                  }}>Associate Editor</span></h5>
                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}> Prof. Dr. Muhammad Sukri bin Saud, </div>
                    <p style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-left text-muted">
                      Department of Technical and Engineering Education,<br /> 
                      University of Technology Malaysia (UTM),<br />
                      Malaysia,<br/>
                      p-sukri@utm.my<br />
                      <a href='https://people.utm.my/sukri/' target="_balnk">Profile Link</a>
                      {/* <a href='https://humanities.utm.my/education/staff-expertise/department-of-technical-and-engineering-education/' target="_balnk">Profile Link</a> */}
                    </p>
                  </div>
                </div>


                <div className='row'>
                  <h5 className='text-left'><span style={{
                    backgroundColor: "#94c045",
                    color: "#fff",
                    lineHeight: "43px",
                    padding: "5px 10px",
                    whiteSpace: "pre-wrap"
                  }}>Editorial Board Members</span></h5>
                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}>Dr. Sabyasachi Pramanik, </div>
                    <p style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-left text-muted">
                      Department of Computer Science Engineering,<br /> Haldia Institute of Technology,<br /> West Bengal, India.
                      <br />sabyasachi.pramanik@hithaldia.in<br />
                      <a href='https://hithaldia.irins.org/profile/332388' target="_balnk">Profile Link</a></p>
                  </div>
                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}>Dr. G. Suganthi, </div>
                    <p style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-left text-muted">
                      Department of Mathematics,<br />Sona College of Technology, <br /> Salem, Tamilnadu, India.
                      <br />suganthig@sonatech.ac.in<br />
                      <a href='https://www.sonatech.ac.in/dhm/suganthi.g.html' target="_balnk">Profile Link</a></p>
                  </div>

                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}>Dr. Yusri Bin Yusof, </div>
                    <p style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-left text-muted">
                        Faculty of Mechanical and Manufacturing  Engineering,<br />University Tun Hussein Onn Malaysia (UTHM), <br /> Malaysia.
                      <br />yusri@uthm.edu.my<br />
                      <a href='https://community.uthm.edu.my/yusri#CTC' target="_balnk">Profile Link</a></p>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </section>


      </main>
    </>
  )
}

export default Dsdsmseditorialboard;