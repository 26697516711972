import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import './Multistepform.css'
import Grid from '@mui/material/Grid';
import {Typography,
        TextField,
        Button,
        Stepper,
        Step,
        StepLabel,
        Paper } from "@material-ui/core";
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {createjournalPost,updatejournalPost} from '../../../actions/journal/dsair/posts'
// import { createjournalPost, updatejournalPost } from '../../../actions/journal/dsair/posts';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useForm} from 'react-hook-form';


const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
      width: theme.spacing(120),
      height: theme.spacing(130)
    }
  },
  form: {
    width: theme.spacing(110),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
  },
  paper: {
    height: theme.spacing(100),

  },
  contpaper: {
    marginTop: theme.spacing(10),
    height: theme.spacing(110),
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(5)
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



const Multistepform = ({ currentId, setCurrentId }) => {

  
  const history = useHistory();
  const ref = useRef();
  const yourDate = new Date();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [postData, setPostData] = useState({
                                            _id: '', 
                                            title: '', 
                                            authors: [], 
                                            article_id: '', 
                                            volume: '', 
                                            issue: '',
                                            page_start:'',
                                            page_end:'',
                                            year: moment(yourDate).format("YYYY"), 
                                            month_of_issue:'',
                                            journal_status:'',
                                            received_date: moment().format("DD MMM YYYY"), 
                                            revised_date: moment().format("DD MMM YYYY"),
                                            accepted_date: moment().format("DD MMM YYYY"), 
                                            published_date: moment().format("DD MMM YYYY"),
                                            doi: '', 
                                            abstract: '', 
                                            key_words: [], 
                                            references: '',
                                            article_file: []
                                          });

  const post = useSelector((state) => (currentId ? state.dsairjposts.posts.find((key) => key._id === currentId) : null));

  console.log("post",{post});
  
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  
  useEffect(() => {
    if (post) setPostData(post);
  }, [post])


  
  const clear = () => {
    // ref.current.value = 'No file Choosen';
    setCurrentId(0);
    setPostData({
                _id: '', 
                title: '', 
                authors: [], 
                article_id: '', 
                volume: '', 
                issue: '', 
                year: '', 
                page_start:'',
                page_end:'',
                month_of_issue:'',
                journal_status:'',
                doi: '', 
                abstract: '',
                received_date: '', 
                revised_date: '', 
                accepted_date: '', 
                published_date: '', 
                key_words: [], 
                references: '', 
                article_file: ''
    });
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  }
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append('_id', postData._id);
    formdata.append('title', postData.title);
    formdata.append('authors', postData.authors);
    formdata.append('article_id', postData.article_id);
    formdata.append('volume', postData.volume);
    formdata.append('issue', postData.issue);
    formdata.append('year', postData.year);
    formdata.append('month_of_issue', postData.month_of_issue);
    formdata.append('journal_status', postData.journal_status);
    formdata.append('doi', postData.doi);
    formdata.append('abstract', postData.abstract);
    formdata.append('key_words', postData.key_words);
    formdata.append('references', postData.references);
    formdata.append('page_start', postData.page_start);
    formdata.append('page_end', postData.page_end);
    formdata.append('received_date', postData.received_date);
    formdata.append('revised_date', postData.revised_date);
    formdata.append('accepted_date', postData.accepted_date);
    formdata.append('published_date', postData.published_date);
    formdata.append('file', postData.article_file);


    const updateformdata = new FormData();
    updateformdata.append('title', postData.title);
    updateformdata.append('authors', postData.authors);
    updateformdata.append('volume', postData.volume);
    updateformdata.append('issue', postData.issue);
    updateformdata.append('year', postData.year);
    updateformdata.append('month_of_issue', postData.month_of_issue);
    updateformdata.append('journal_status', postData.journal_status);
    updateformdata.append('doi', postData.doi);
    updateformdata.append('abstract', postData.abstract);
    updateformdata.append('key_words', postData.key_words);
    updateformdata.append('references', postData.references);
    updateformdata.append('page_start', postData.page_start);
    updateformdata.append('page_end', postData.page_end);
    updateformdata.append('received_date', postData.received_date);
    updateformdata.append('revised_date', postData.revised_date);
    updateformdata.append('accepted_date', postData.accepted_date);
    updateformdata.append('published_date', postData.published_date);
    updateformdata.append('file', postData.article_file);

    if (currentId === 0) {
      dispatch(createjournalPost(formdata));
      clear();
    } else {
      dispatch(updatejournalPost(currentId, updateformdata));
      history.push('/dashboard/dsair/dsairviewdata');
      clear();
    }
  }

  function getSteps() {
    return [
      "Basic Information",
      "Abstract & Keywords",
      "References"
    ];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div className='col-lg-12'>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <FormControl sx={{ m: 1, minWidth: 100 }}>
                  <TextField className={classes.gridMargin} 
                  name="Id" 
                  variant="outlined" 
                  label="Id" 
                  value={postData._id} 
             
                  onChange={(e) => setPostData({ ...postData, _id: e.target.value })} />
                </FormControl>
          
              </Grid>
              <Grid item xs={9}>
                <FormControl sx={{ m: 1, minWidth: 650 }}>
                  <TextField className={classes.gridMargin} name="Title" variant="outlined" fullWidth label="Title" value={postData.title} 
               
                  onChange={(e) => setPostData({ ...postData, title: e.target.value })} />
                </FormControl>
            
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ m: 1, minWidth: 875 }}>
                  <TextField className={classes.gridMargin} name="Authors" variant="outlined" fullWidth label="Authors" value={postData.authors} onChange={(e) => setPostData({ ...postData, authors: e.target.value.split(',') })} />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl sx={{ m: 1, minWidth: 100 }}>
                  {/* <Item> */}
                  <TextField className={classes.gridMargin} name="ArticleId" variant="outlined" label="ArticleId" value={postData.article_id} onChange={(e) => setPostData({ ...postData, article_id: e.target.value })} />
                  {/* </Item> */}
                </FormControl>

                <FormControl sx={{ m: 1, maxWidth: 120 }}>
                  <InputLabel id="volume-select-small">Volume</InputLabel>
                  <Select
                    labelId="volume-select-small"
                    id="volume-select-small"
                    defaultValue=''
                    value={postData.volume}
                    label="Volume"
                    onChange={(e) => setPostData({ ...postData, volume: e.target.value })}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="issue-select-small">Issue</InputLabel>
                  <Select
                    labelId="issue-select-small"
                    id="issue-select-small"
                    defaultValue=''
                    value={postData.issue}
                    label="Issue"
                    onChange={(e) => setPostData({ ...postData, issue: e.target.value })}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 200 }}>
                  <InputLabel id="month-select-small">Mon. of the Issue</InputLabel>
                  <Select
                    labelId="month-select-small"
                    id="month-select-small"
                    defaultValue=''
                    value={postData.month_of_issue}
                    label="Mon. of issue"
                    onChange={(e) => setPostData({ ...postData, month_of_issue: e.target.value })}
                  >
                    <MenuItem value="January-March">January-March</MenuItem>
                    <MenuItem value="April-June">April-June</MenuItem>
                    <MenuItem value={"July-September"}>July-September</MenuItem>
                    <MenuItem value="October-December">October-December</MenuItem>
                    <MenuItem value="January">January</MenuItem>
                    <MenuItem value="February">February</MenuItem>
                    <MenuItem value="March">March</MenuItem>
                    <MenuItem value="April">April</MenuItem>
                    <MenuItem value="May">May</MenuItem>
                    <MenuItem value="June">June</MenuItem>
                    <MenuItem value="July">July</MenuItem>
                    <MenuItem value="August">August</MenuItem>
                    <MenuItem value="September">September</MenuItem>
                    <MenuItem value="October">October</MenuItem>
                    <MenuItem value="November">November</MenuItem>
                    <MenuItem value="December">December</MenuItem>
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 150 }}>
                  <InputLabel id="jstatus-select-small">Status</InputLabel>
                  <Select
                    labelId="jstatus-select-small"
                    id="jstatus-select-small"
                    defaultValue=''
                    value={postData.journal_status}
                    label="Issue"
                    onChange={(e) => setPostData({ ...postData, journal_status: e.target.value })}>
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value="publish">Publish</MenuItem>
                    <MenuItem value="unpublish">Unpublish</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl sx={{ m: 1, maxWidth: 110 }}>
                  <TextField className={classes.gridMargin} name="Page Start" variant="outlined" label="Page Start" value={postData.page_start} onChange={(e) => setPostData({ ...postData, page_start: e.target.value })} />
                </FormControl>

                <FormControl sx={{ m: 1, maxWidth: 110 }}>
                  <TextField className={classes.gridMargin} name="Page End" variant="outlined" label="Page End" value={postData.page_end} onChange={(e) => setPostData({ ...postData, page_end: e.target.value })} />
                </FormControl>

                <FormControl sx={{ m: 1, maxWidth: 100 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Year"
                      views={["year"]}
                      inputFormat="yyyy"
                      value={moment(postData.year).format("YYYY")}
                      onChange={(e) => setPostData({ ...postData, year: moment(e).format("YYYY") })}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>


                <FormControl sx={{ m: 1, maxWidth: 150 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Received Date"
                      views={["day", "month", "year"]}
                      inputFormat="dd/MMM/yyyy"
                      value={moment(postData.received_date).format("DD MMM YYYY")}
                      onChange={(e) => setPostData({ ...postData, received_date: moment(e).format('DD MMM YYYY') })}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ m: 1, maxWidth: 150 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Revised Date"
                      views={["day", "month", "year"]}
                      inputFormat="dd/MMM/yyyy"
                      value={moment(postData.revised_date).format("DD MMM YYYY")}
                      onChange={(e) => setPostData({ ...postData, revised_date: moment(e).format('DD MMM YYYY') })}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ m: 1, maxWidth: 150 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Accepted Date"
                      views={["day", "month", "year"]}
                      inputFormat="dd/MMM/yyyy"
                      value={moment(postData.accepted_date).format("DD MMM YYYY")}
                      onChange={(e) => setPostData({ ...postData, accepted_date: moment(e).format('DD MMM YYYY') })}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ m: 1, maxWidth: 150 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Published Date"
                      views={["day", "month", "year"]}
                      inputFormat="dd/MMM/yyyy"
                      value={moment(postData.published_date).format("DD MMM YYYY")}
                      onChange={(e) => setPostData({ ...postData, published_date: moment(e).format('DD MMM YYYY') })}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        );
      case 1:
        return (<div>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <FormControl sx={{ m: 1, minWidth: 875 }}>
                <TextField
                  className={classes.gridMargin}
                  name="Abstract"
                  variant="outlined"
                  label="Abstract"
                  fullWidth
                  multiline
                  maxRows={10}
                  value={postData.abstract}
                  onChange={(e) => setPostData({ ...postData, abstract: e.target.value })} />
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 875 }}>
                <TextField className={classes.gridMargin} name="Kewords" variant="outlined" fullWidth multiline maxRows={4} label="Keywords" value={postData.key_words} onChange={(e) => setPostData({ ...postData, key_words: e.target.value.split(',') })} />
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 875 }}>
                <TextField className={classes.gridMargin} name="doi" variant="outlined" fullWidth label="doi" value={postData.doi} onChange={(e) => setPostData({ ...postData, doi: e.target.value })} />
              </FormControl>
            </Grid>
          </Grid>
        </div>);
      case 2:
        return (<div>

          <Grid item xs={12}>
            <div className={classes.fileInput}>
              <input id="pdf_file" type='file' ref={ref} onChange={(e) => setPostData({ ...postData, article_file: e.target.files[0]})} />
            </div>
            <label for="pdf_file">Click me to upload file</label>
          </Grid>

          <Grid item xs={12}>
            <ReactQuill className={classes.quill} theme="snow" value={postData.references} onChange={(e) => setPostData({ ...postData, references: e })} />
          </Grid>
        </div>);
      default:
      //do nothing
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item><Typography variant="h6">{currentId ? `Update "${postData._id}" Record` : 'AIR Add Record'}</Typography></Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <Stepper activeStep={activeStep} orientation='horizontal'>
                {steps.map((step, index) => {
                  const labelProps = {};
                  const stepProps = {};
                  return (
                    <Step {...stepProps} key={index}>
                      <StepLabel {...labelProps}>{step}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Item>
          </Grid>
        </Grid>

        <form autoComplete="off" noValidate className={`${classes.form}`} onSubmit={handleSubmit} encType="multipart/form-data">
          {getStepContent(activeStep)}
        </form>

        <Button
          className={classes.button}
          disabled={activeStep === 0}
          onClick={handleBack}
        >
          back
        </Button>

        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          // onClick={handleNext}
          onClick={(e) => {
            if (activeStep === steps.length - 1) {
              handleSubmit(e);
            } else {
              handleNext();
            }
          }}
        >
          {activeStep === steps.length - 1 ? "Submit" : "Next"}
        </Button>
      </Paper>
    </div>
  )
}

export default Multistepform;