import React,{useEffect} from 'react';
import { Link,NavLink } from 'react-router-dom';
import Accordion from '../components/Accordion';
import { HashLink } from 'react-router-hash-link';
import Llistaccordion from '../components/Llistaccordion';
import { Helmet } from 'react-helmet';

const Conflicts_of_interest = () => {
    useEffect(() => {
        window.scrollTo(0,0);
      }, [])
    const accordionData = [{
        content: <div>
            <NavLink  to="/for-authors/publication-ethics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics </NavLink>
            <NavLink  to="/for-authors/author-guideline" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Step by Step Guidelines for Authors</NavLink>
            <NavLink  to="/for-authors/submitting-a-manuscript" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Submitting a Manuscript</NavLink>
            <NavLink  to="/for-authors/open-access-author" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is Open Access ?</NavLink>
            <NavLink  to="/for-authors/review-process" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Review Process</NavLink>
            <NavLink  to="/for-authors/conflicts-of-interest" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Conflicts of Interest</NavLink>
            <NavLink  to="/for-authors/licensing-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Licensing Policy</NavLink>
            <NavLink  to="/for-authors/copyright-infringement" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Copyright Infringement</NavLink>
            <NavLink  to="/for-authors/correction-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Correction Policy</NavLink>
        <NavLink  to="/for-authors/what-is-apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is APC ?</NavLink>
            {/* <HashLink smooth to="#" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>How to subscribe the hard copy of journal ?</HashLink> */}
 
    </div> 
    },
      ];

      const Llistaccordiondata = [
        {
          heading: <p  style={{fontWeight:"bold"}}>Common Disputes Include the Following: </p>, 
          content: <div><p style={{textAlign:"justify"}}>
            <ul style={{listStyle:"none"}}>
                <li># Personal - An individual may act wrongly due to an existing connection.</li>
                <li># Financial - A person receives remuneration from related or affiliated organisations or from the topic of the research.</li>
                <li># Intellectual Property - A person places excessive attention on any patents or trademarks that they personally own or that belong to their company.</li>
                <li># Affiliations - A person who works for or belongs to a group that is interested in the research's results.</li>
                <li># Ideology — A person is influenced by associations or views related to the study's topic.</li>
                </ul>
                You should carefully think about how these and other related issues could influence you and other people managing the text.
                </p></div>,
        },
        {
            heading: <p  style={{fontWeight:"bold"}}>Authors</p>, 
            content: <div><p style={{textAlign:"justify"}}>Conflicts of interest for writers are frequently linked to the possibility of bias in a publication. As an author, you should make sure that any affiliations or interests that can be perceived as having impacted your decision-making are disclosed at the time of submission. Your statement can lead to requests for revisions to be made to your text. These demands do not constitute a charge of improper behaviour. Your work is being protected against potential criticism by the editor or reviewer. If you are unsure whether to disclose a potential conflict, keep in mind that doing so at the time of submission will prevent more issues than if it is discovered later, particularly after publication.</p></div>,
          },
          {
            heading: <p  style={{fontWeight:"bold"}}>Editors</p>, 
            content: <div>
                <p style={{textAlign:"justify"}}>When handling a manuscript as a member of the editorial board of a journal, you must be extremely mindful of the potential for conflicts.</p>
                <p style={{textAlign:"justify"}}>You should first evaluate any potential conflicts that you may have. If you recently coauthored the manuscript with the author, this might give the impression that you are biassed. Similarly, it can be viewed as improper for you to handle the author's work if you just disclosed an association or job history with them. While Dream Science tries to steer clear of sending articles to editors who could be at odds, we also ask our editors to disclose any conflicts they may have. Refrain from handling the text if you think there is a conflict of interest.</p>
                <p style={{textAlign:"justify"}}>The journal depends on your expertise in the field to evaluate any stated conflicts by submitting authors. You are also in a unique position to spot any unreported conflicts of interest an author may have. When recommending changes to the manuscript, keep these things in mind.</p>
                <p style={{textAlign:"justify"}}>When choosing reviewers for the article, you should also take any conflicts into account. Prior to sending out manuscripts for review, Dream Science does conflict of interest checks on each reviewer. However, you should also use your industry expertise to guide your assignment decisions. </p>
                <p style={{textAlign:"justify"}}>Typically, you should not select a referee who:</p>
                <p style={{textAlign:"justify"}}>co-authored a recent article with the author or writers; currently collaborates with the author; or currently works at the same institution as the author or authors.
                <ul style={{listStyle:"none"}}>
                    <li># Affiliations - A person who works for or belongs to a group that is interested in the research's results.</li>
                    <li># Ideology — A person is influenced by associations or views related to the study's topic.</li>
                </ul></p>
                <p style={{textAlign:"justify"}}>When a consortium of authors creates a publication, discretion may be used.</p>
                <p style={{textAlign:"justify"}}>If you have reservations about a prospective reviewer, you might want to choose another candidate. You may advise the authors not to respond to a reviewer's advice if you think they did so in order to further their own objectives.</p>
                <p style={{textAlign:"justify"}}>We are aware that there may be a higher possibility of association and overlap between researchers in some specialised fields. In some cases, despite having a relationship with the author or writers, you could be the most qualified person to serve as Editor. You should let your Dream Science editorial contact know if this is the case. The case can then be forwarded to our Research Integrity team for consideration.</p>
            </div>,
          },
          {
            heading: <p  style={{fontWeight:"bold"}}>Reviewers</p>, 
            content: <div><p style={{textAlign:"justify"}}>By consenting to peer review a work, you are offering valuable, objective feedback. As a result, you should make sure that you don't have any conflicts of interest that can be viewed as making it difficult for you to behave impartially.</p>
            <p style={{textAlign:"justify"}}>Make sure you have no recent connections to the author and haven't written anything with them before. Additionally, you shouldn't discuss a recent job history.</p>
            <p style={{textAlign:"justify"}}>The Dream Science uses a "double blind" peer review process. The authors won't have access to your name. This enables you to offer frank, useful criticism.</p>
            </div>,
          },
      ];
    
  return (
    <>
    <Helmet>
      <title>Conflicts of Interest: DS Journals Policy</title>
      <link rel="canonical" href="https://dsjournals.com/for-authors/conflicts-of-interest"/>
      <meta name="title" content="Conflicts of Interest: DS Journals Policy"/>
      <meta name="description" content="DS Journals is committed to ensuring transparency and ethical practices in research. Learn about our policy on conflicts of interest for authors."/>
      <meta name="keywords" content="conflicts of interest, conflict of interest in research, author guidelines, research integrity, define conflict of interest, conflicts of interest policy, actual conflict of interest, guide author, writer guidelines, submission of articles, submission articles."/>
      <meta property="og:type" content="Website"/>
      <meta property="og:site_name" content="Dream Science"/>
      <meta property="og:url" content="https://dsjournals.com/for-authors/conflicts-of-interest"/>
      <meta property="og:title" content="Conflicts of Interest: DS Journals Policy"/>
      <meta property="og:description" content="DS Journals is committed to ensuring transparency and ethical practices in research. Learn about our policy on conflicts of interest for authors."/>
      <meta property="og:image" content="image url"/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:description" content="DS Journals is committed to ensuring transparency and ethical practices in research. Learn about our policy on conflicts of interest for authors."/>
      <meta name="twitter:site" content="@DreamScience4"/>
      <meta name="twitter:title" content="Conflicts of Interest: DS Journals Policy"/>
      <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
      <meta name="twitter:image" content="image url"/>
      <meta name="robots" content="index, follow"/>
    </Helmet>
        <section id="hero-no-slider" style={{height:"300px"}} className="d-flex justify-cntent-center align-items-center">
         <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
           <div className="row justify-content-center">
             <div className="col-xl-8">
               <div className="row justify-content-center">
                 <div className="col-xl-8">
                   <h2>Conflicts of Interest</h2>
                   {/* <Link to="#" className="btn-get-started ">Read More</Link> */}
                 </div>
             </div>
           </div>
         </div>
         </div>
       </section>
    
    <section className="breadcrumbs">
    <div className="container">
    <div className="d-flex justify-content-between align-items-center">
    {/* <h2>Publication Ethics</h2> */}
    <ol>
     <li><Link to="/home">Home</Link></li>
     <li><Link to="/for-authors">Authors</Link></li>
     <li>Conflicts Of Interest</li>
    </ol>
    </div>
    </div>
    </section>
    
    <main id="main">
    <section className="blog" id="blog">
    <div className="container">
       <div className="row">
         <div className="col-lg-3">
         <div className="list-group">
          <div className="accordion">
                          {accordionData.map(({content }) => (
                            <Accordion content={content} />
                        ))}
                      </div>
          </div>
         </div>
           <div className="col-lg-9">
          
           <h1 style={{fontSize:"35px"}}>Conflicts of Interest</h1>
             <p style={{textAlign:"justify"}}>Conflicts of interest (also known as "competing interests") arise when external factors obstruct or appear to obstruct the impartiality or objectivity of research. This can take place at any point in the research cycle, including when conducting experiments, writing a manuscript, or turning a manuscript into a published article.</p>
             <p style={{textAlign:"justify"}}>Conflicts of interest do not usually preclude publication of a work or participation in the review process. They must be disclosed, though. Others are better able to judge the work and the review process by having a clear disclosure of any potential conflicts, regardless of whether they had an impact or not.</p>
             {Llistaccordiondata.map(({heading,content}) => (
              <Llistaccordion heading={heading} content={content} />
             ))}
             
   
           </div>
    
       </div>
    </div>
    </section>
    </main>
    
       </>
  )
}

export default Conflicts_of_interest;