import React from 'react';
import {Link} from 'react-router-dom';
import Listaccordion from '../components/Listaccordion';
import './Conferencelist.css';


const Conferencelist = () => {
    const listaccordionData = [{
        heading: 
         "Upcoming Conferences",
        content: 
        <table style={{width:"100%"}}>
        <tr>
                    <th style={{padding:"10px",textAlign:"center"}}></th>
                    <th style={{padding:"10px",textAlign:"center"}}>Conferences</th>
                    <th style={{padding:"10px",textAlign:"center"}}>Submission</th>
                    <th style={{padding:"10px",textAlign:"center"}}>Date</th>
        </tr>     
        <tr>
                    <td style={{padding:"0 0 20px 0",textAlign:"center"}}><i className="bi bi-pencil-square"></i></td>
                    <td style={{padding:"10px",textAlign:"justify"}}>
                        <Link to="/" style={{fontWeight:"bold"}}>International Conference on Engineering and Innovative Research(ICEIR), Kathir College of Engineering, Coimbatore</Link><br/>
                    
                    </td>
                    <td style={{padding:"10px",textAlign:"justify"}}>20/05/2022</td>
                    <td style={{padding:"10px",textAlign:"justify"}}>25/05/2022</td>
                </tr>        
                <tr>
                    <td style={{padding:"0 0 20px 0",textAlign:"center"}}><i className="bi bi-pencil-square"></i></td>
                    <td style={{padding:"10px",textAlign:"justify"}}>
                        <Link to="/" style={{fontWeight:"bold"}}>International Conference on Science, Humanities, Engineering, Medicine & Technology Innovations (SHEMTI), Annai College of Arts & Science, Kumbakonam</Link><br/>
                    
                    </td>
                    <td style={{padding:"10px",textAlign:"justify"}}>15/05/2022</td>
                    <td style={{padding:"10px",textAlign:"justify"}}>20/05/2022</td>
                </tr>        
</table>
    
    },
      ];
  return (
    <>
     <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center">
          <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    <h2>Archives</h2>
                    <Link to="/" className="btn-get-started ">Read More</Link>
                  </div>
              </div>
            </div>
          </div>
          </div>
        </section>
        
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Conferences List</h2>
              <ol>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/conferences">Conferences</Link></li>
                <li>Conferences List</li>
              </ol>
            </div>
          </div>
        </section>     

        <main id="main">
    <section className="blog" id="blog">
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                <div className="abtlistgrp">
                 <div className="card">
                  <ul className="list-group list-group-flush">
                    <Link to="#"  className="list-group-item btn-submit"><i className="bi bi-caret-right-square"></i>IJCSE</Link>
                    <Link to="/archives"  className="list-group-item"><i className="bi bi-book"></i>Journal Profile</Link>
                      <Link to="#"  className="list-group-item"><i className="bi bi-person"></i>Editor Spotlight</Link>
                        <Link to="#" className="list-group-item"><i className="bi bi-award"></i>Special Issues</Link>
                        <Link to="#" className="list-group-item"><i className="bi bi-calendar-check"></i>Current Issues</Link>
                        <li className="list-group-item"><button className="btn btn-outline-dark float-center">SignIn</button></li>
                  </ul>
                </div>
              </div> 
                </div>
                <div className="col-lg-9">
                    <h5>List of Archives</h5>
                    <div className="row mt-3">
                        <div className="col-lg-12 text-center">
                        <h5 style={{alignItems:'center'}}>Conferences</h5>
                        </div>
                    
                    </div>


                   
     
                      <ul className="accordion">
                      {listaccordionData.map(({heading,content }) => (
                        <Listaccordion heading={heading} content={content} />
                    ))}
                  </ul>
                </div>
            </div>
        </div>

    </section>


  </main>     
    </>
  )
}

export default Conferencelist;