import React , {useState,useEffect} from 'react';
import { useDispatch } from 'react-redux';
import Cmultistepform from '../../../components/Forms/currentissue/Cmultistepform';
import { getcissuePosts } from '../../../actions/currentissue/posts';
import Posts from '../../../components/cissuepost/Posts/Posts';


const Currentissue = () => {
  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();

   useEffect(() => {
      dispatch(getcissuePosts())
   },[currentId,dispatch]);


return (
  <>

                   <section className="home-section">
                  <div className="home-content">
                  <div className='row'>
                            {/* New code start here */}
                          <div className='col-lg-6 mb-5'> 
                          <Cmultistepform currentId={currentId} setCurrentId={setCurrentId} />        
                          </div>
                  
                          <div className='col-lg-6 mb-5'>
                            <Posts setCurrentId={setCurrentId} />
                          </div>


</div>

                      


                  </div>
               
          </section>
        
          </>
)
}

export default Currentissue