import {Link}  from 'react-router-dom';
import './Dviewjournal.css';

const Dviewjournal = () => {
  return (
    <>
    <section className="home-section">
   <div className="home-content">
   <h2>DS Journal of Cyber Securtiy</h2>

   <div className='row'>
       <div className='col-3'>
           <div className="overview-boxes">
           <div className="box">
               <div className="right-side">
                   <span className="text"> Create/Update Journals</span>
                   <Link to="/dashboard/dscys/dscyscreatejournal">
                   {/* <Link to={history.push(`/dashboard/dsair/dsaircreatejournal`)}> */}
                       <div className="indicator">   
                       <i className='bx bx-right-arrow-alt'></i>
                       <span className="text">View More</span>
                   </div></Link>
               </div>  
               <i className='bx bxs-message-square-add award'></i>
           </div>
           </div>

       </div>

       <div className='col-3'>
           <div className="overview-boxes">
                                           
           <div class="box">
               <div class="left-side">
               <span className="text"> View Journals</span>
               <Link to ="/dashboard/dscys/dscysviewdata">
                   <div class="indicator">
                   <i className='bx bx-right-arrow-alt'></i>
                       <span className="text">View More</span>
                   </div></Link>
               </div>
               <i class='bx bxs-message-square-detail award' ></i>
           </div>
           </div>
       </div>
   </div>



       {/* <div className="overview-boxes">

           <div className="box">
               <div className="right-side">
                   <span className="text"> Create/Update Journals</span>
                   <Link to="/dlll/dlllcreatejournal">
                       <div className="indicator">   
                       <i className='bx bx-right-arrow-alt'></i>
                       <span className="text">View More</span>
                   </div></Link>
               </div>  
               <i className='bx bxs-message-square-add award'></i>
           </div>

                                      
           <div class="box">
               <div class="left-side">
               <span className="text"> View Journals</span>
               <Link to ="/dlll/dlllviewdata">
                   <div class="indicator">
                   <i className='bx bx-right-arrow-alt'></i>
                       <span className="text">View More</span>
                   </div></Link>
               </div>
               <i class='bx bxs-message-square-detail award' ></i>
           </div>

           
       </div> */}
   </div>
</section>
</>
  )
}

export default Dviewjournal