import { START_LOADING, END_LOADING, FETCH_ALL,FETCH_ALL_ARCH, FETCH_POST, CREATE, UPDATE, DELETE } from "../../../constants/actionTypes";
import * as api from '../../../api/index.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





export const getarchivePosts = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchdsrlsarchivePosts();
    dispatch({ type: FETCH_ALL_ARCH, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};


export const createjournalPost = (post) => async (dispatch) => {
  try{
    dispatch({ type: START_LOADING });
    const data = await api.createdsrlsjournalPost(post);
    dispatch({type: CREATE, payload: data}); 
    // alert("Data added successfully");
    toast.success("Data Added Successfully")
    }catch(error){  
    console.log(error.message);
   }
};


export const getjournalPost = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchdsrlsjournalPost(id);
    dispatch({ type: FETCH_POST, payload: {post : data} });
  } catch (error) {
    console.log(error);
  }
};



export const getjournalPosts = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchdsrlsjournalPosts();
    dispatch({ type: FETCH_ALL, payload: data });
    console.log(data);
    dispatch({ type: END_LOADING });
   
  } catch (error) {
    console.log(error);
  }
};

export const updatejournalPost = (id, post) => async (dispatch) =>{
  try {
    const data = await api.updatedsrlsjournalPost(id,post);
    dispatch({type: UPDATE, payload: data});
    alert("Data updated successfully");
  } catch (error) {
    console.log(error.message);
  }
};

export const deletejournalPost = (id) => async (dispatch) => {
  try {
    await await api.deletedsrlsjournalPost(id);

    dispatch({ type: DELETE, payload: id });
    alert("Are your sure  deleted " + id);
  } catch (error) {
    console.log(error);
  }
};