import React,{useEffect} from 'react'
import {Link,NavLink} from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { HashLink } from 'react-router-hash-link';
import Accordion from '../../components/Dsrls/Accordion';
import Ddsrlsarticletemplate from '../../downloads/DS-RLS-Article_Template.doc';
import Ddsrlscopyrightform from '../../downloads/DS-RLS-Copyright_Form.docx';
import Journaltitle from '../../pages/dsrls/data.json';

const Dsdsrlstopics = () => {
  useEffect(() => {
    window.scrollTo(0,0);
}, []);
const accordionData = [{
    content: <div>
            <NavLink smooth to="/rls" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</NavLink>
            <NavLink to="/rls/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
           <NavLink to="/rls/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
           <NavLink to="/rls/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
           <NavLink to="/rls/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
           <NavLink to="/rls/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
           <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
           <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
           <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
           <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
           <NavLink to="/rls/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
           <a href={Ddsrlsarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
           <a href={Ddsrlscopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
       
</div> 
},
  ];

return (
<>
 <Helmet>
 <title>Topics Covered - DS-RLS Journal</title>
<link rel="canonical" href="https://dsjournals.com/rls/topics"/>
<meta name="title" content="Topics Covered - DS-RLS Journal"/>
<meta name="description" content="Dive into a wide range of topics in the field of Cyber Security with DS-RLS Journal. Explore the latest research and thought-provoking insights."/>
<meta name="keywords" content="science journal, peer reviewed journals, scientific research, science articles, research articles, research journal, journal of scientific research, journal open access, biology journal, research review paper, research article review, journal about life."/>
<meta property="og:type" content="Website"/>
<meta property="og:site_name" content="Dream Science"/>
<meta property="og:url" content="https://dsjournals.com/rls/topics"/>
<meta property="og:title" content="Topics Covered - DS-RLS Journal"/>
<meta property="og:description" content="Dive into a wide range of topics in the field of Cyber Security with DS-RLS Journal. Explore the latest research and thought-provoking insights."/>
<meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Research-in-Life-Sciences.webp"/>
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="Topics Covered - DS-RLS Journal"/>
<meta name="twitter:description" content="Dive into a wide range of topics in the field of Cyber Security with DS-RLS Journal. Explore the latest research and thought-provoking insights."/>
<meta name="twitter:site" content="@DreamScience4"/>
<meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
<meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Research-in-Life-Sciences.webp"/>
<meta name="robots" content="index, follow"/>

    </Helmet>
 <section id="hero-no-slide-dsrls" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
    <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
        <div className="row justify-content-center">
            <div className="col-xl-8">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        {/* <h2>Topics</h2> */}
                        {
                                Journaltitle.map(heading => {
                                  return(
                                    <h2 style={{fontSize:"25px"}}>{heading.title}</h2>     
                                  )
                                      
                                })
                            }
                        {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="breadcrumbs">
    <div className="container">
        <div className="d-flex justify-content-between align-items-center">
            
            <ol>
            <li><NavLink to="/home">Home</NavLink></li>
            <li><NavLink to="/journals">Journals</NavLink></li>
            <li> <NavLink to="/rls">DS-RLS</NavLink></li>
            <li>Topics</li>
          </ol>
        </div>
    </div>
</section>

<main id="main">
<section className="blog" id="blog">
<div className="container">
<div className="row">
<div className="col-lg-3 mb-3">
  <div className="list-group">
    <div className="accordion">
                    {accordionData.map(({content }) => (
                      <Accordion content={content} />
                  ))}
    </div>
  </div>
</div>
<div className="col-lg-9">
  
<p style={{textAlign:'justify',fontWeight:"bold"}}>Topics</p>
<p style={{textAlign:'justify',textIndent:"50px"}}>
                                DS Reviews of Life Sciences (DS-RLS) is a broad-scope journal covering all areas of biology. Our content includes reviews, objective descriptions, research reports, 
                                practical programmes, short notes, news items, book reviews, reports of meetings, professional announcements, Q&A, short communications, data notes, opinion 
                                pieces/debates, commentaries, hypotheses and descriptions of new methods across the Life Sciences, with a publication policy that combines selection for broad interest 
                                and importance with a commitment to serving authors well. DSRLS welcomes the submission of negative results and replication review studies. Proposals of themed 
                                collections are encouraged. Letters to the Editor are also accepted and should discuss an analysis of an article previously published in DSRLS. Constructive criticisms 
                                and discussions of published papers and letters of relevance and interest to the readership will be published at the discretion of the Managing Editor.
</p>
<p style={{textAlign:'justify',fontWeight:"bold"}}>This journal covers all aspects of life sciences. The broad research fields of interest include but are not limited to:</p>
<p style={{textAlign:'justify'}}>
<div className='row'>
    <div className='col-lg-6 col-md-6 col-sm-6'>
        <ul>
        <li>Abiogenesis</li>
        <li>Agriculture</li>
        <li>Anatomy</li>
        <li>Artificial Life</li>
        <li>Astrobiology</li>
        <li>Bacteriology</li>
        <li>Biochemical Genetics</li>
        <li>Biochemistry</li>
        <li>Bioclimatology</li>
        <li>Biodiversity</li>
        <li>Bioengineering</li>
        <li>Biogeography</li>
        <li>Bioinformatics</li>
        <li>Biological Anthropology</li>
        <li>Biology</li>
        <li>Biomaterials</li>
        <li>Biomathematics</li>
        <li>Biomedicine</li>
        <li>Biometry</li>
        <li>Bionics</li>
        <li>Biophysics</li>
        <li>Biotechnology</li>
        <li>Botany</li>
        <li>Cell Biology</li>
        <li>Chronobiology</li>
        <li>Conservation Biology</li>
        <li>Cryobiology</li>
        <li>Developmental Biology</li>
        <li>Ecology</li>
        <li>Entomology </li>
        <li>Ethnobiology</li>
        <li>Ethology </li>
        <li>Evolution and Population Genetics</li>
        <li>Evolutionary Biology</li>
        <li>Extremophiles</li>
        <li>Freshwater Biology</li>
        <li>Genetic Engineering</li>
        <li>Genetic Epidemiology</li>
        <li>Genetics</li>
        <li>Genomics</li>
        <li>Geobiology</li>
        <li>Habitability</li>
        </ul>
    </div>

    <div className='col-lg-6 col-md-6 col-sm-6'>
        <ul>   
        <li>Herpetology </li>
        <li>Hydrobiology</li>
        <li>Ichthyology</li>
        <li>Immunogenetics</li>
        <li>Immunology</li>
        <li>Immunotechnology</li>
        <li>life detection technology</li>
        <li>Mammalogy </li>
        <li>Marine Biology</li>
        <li>Medical Biology</li>
        <li>Metabolomics</li>
        <li>Microbiology</li>
        <li>Molecular Biology</li>
        <li>Molecular Genetics</li>
        <li>Molecular phylogeny</li>
        <li>Mycology</li>
        <li>Nanobiotechnology</li>
        <li>Neurobiology</li>
        <li>Origins of life</li>
        <li>Ornithology </li>
        <li>Paleobiology</li>
        <li>Palaeontology</li>
        <li>Parasitology</li>
        <li>phycology</li>
        <li>Physiology</li>
        <li>Prebiotic chemistry</li>
        <li>Primatology </li>
        <li>Proteomics</li>
        <li>Protistology </li>
        <li>Protozoology</li>
        <li>Psychobiology</li>
        <li>Space life sciences/ Space biology</li>
        <li>Structural biology</li>
        <li>Synthetic biology</li>
        <li>Systems biology</li>
        <li>Theoretical biology</li>
        <li>Tissue culture</li>
        <li>Toxicology </li>
        <li>Veterinary Sciences</li>
        <li>Virology</li>
        <li>Wildlife Biology</li>
        <li>Zoology</li>
        </ul>
    </div>
</div>

</p>

</div>
</div>
</div>
</section>
</main>
</>
)
}

export default Dsdsrlstopics