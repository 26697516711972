import React,{useEffect} from 'react';
import { Link,NavLink } from 'react-router-dom';
import Accordion from '../components/Accordion';
import { Helmet } from 'react-helmet';
import { HashLink } from 'react-router-hash-link';
import './Pubethics.css';
import Llistaccordion from './Llistaccordion';


function Pubethics() {
  const accordionData = [{
    content: <div>
        <NavLink  to="/for-authors/publication-ethics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics </NavLink>
        <NavLink  to="/for-authors/author-guideline" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Step by Step Guidelines for Authors</NavLink>
        <NavLink  to="/for-authors/submitting-a-manuscript" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Submitting a Manuscript</NavLink>
        <NavLink  to="/for-authors/open-access-author" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is Open Access ?</NavLink>
        <NavLink  to="/for-authors/review-process" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Review Process</NavLink>
        <NavLink  to="/for-authors/conflicts-of-interest" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Conflicts of Interest</NavLink>
        <NavLink  to="/for-authors/licensing-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Licensing Policy</NavLink>
        <NavLink  to="/for-authors/copyright-infringement" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Copyright Infringement</NavLink>
        <NavLink  to="/for-authors/correction-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Correction Policy</NavLink>
        <NavLink  to="/for-authors/what-is-apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is APC ?</NavLink>

       
        
        {/* <HashLink smooth to="#" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>How to subscribe the hard copy of journal ?</HashLink> */}
        
        
</div> 
},
  ];

const Llistaccordiondata = [
  {
    heading: <p  style={{fontWeight:"bold"}}>Article Evaluation</p>, 
    content: <p style={{textAlign:"justify"}}>Peer review is required for all manuscripts, and they must also 
    adhere to high academic standards. Submissions will be evaluated by peer reviewers, whose identities will 
    not be disclosed to the authors, if the editor approves them. On submissions having significant ethical, 
    security, biosecurity, or social ramifications, for example, our Research Integrity team may on occasion 
    seek assistance beyond the realm of traditional peer review. Before deciding on the best course of action, 
    we may consult experts and the academic editor. This may include, but is not limited to, selecting 
    reviewers with particular expertise, having additional editors evaluate the contribution, and declining to 
    take the submission further.</p>,
  },
  {
    heading: <p  style={{fontWeight:"bold"}}>Plagiarism</p>, 
    content: <p style={{textAlign:"justify"}}>All of the publications that Dream Science publishes are 
    dedicated to solely publishing original content, which is content that hasn't been published or isn't 
    currently being reviewed anywhere else. The software is used by Dream Science to identify instances of 
    duplicate and overlapping text in submitted publications. Sanctions for plagiarism will be applied to any 
    manuscripts that are discovered to have been lifted verbatim from another author's work, whether it was 
    published or not.</p>,
  },
  {
    heading: <p  style={{fontWeight:"bold"}}>Repetition of Submission</p>, 
    content: <p style={{textAlign:"justify"}}>Sanctions for duplicate submissions and publications will be 
    applied to manuscripts that are discovered to have been published or are currently being reviewed 
    elsewhere. Authors who have used their own previously published work or work that is presently being 
    reviewed as the foundation for a submitted manuscript must cite the earlier work and explain how their 
    new work goes above and beyond what was previously done.</p>,
  },
  {
    heading: <p  style={{fontWeight:"bold"}}>Manipulation of Citations</p>, 
    content: <p style={{textAlign:"justify"}}>Citation manipulation sanctions will be applied to submitted 
    manuscripts that are discovered to contain citations that are primarily intended to boost the number of 
    citations to a certain author's work or to publications published in a specific journal.</p>,
  },
  {
    heading: <p  style={{fontWeight:"bold"}}>Falsification and Fabrication of Data</p>, 
    content: <p style={{textAlign:"justify"}}>Sanctions for data fabrication and falsification will be applied 
    to submitted articles that contain either manufactured or falsified experimental results, including 
    the modification of pictures.</p>,
  },
  {
    heading: <p  style={{fontWeight:"bold"}}>Incorrect Author Attribution or Contribution</p>, 
    content: <p style={{textAlign:"justify"}}>All mentioned authors must have contributed significantly to the research in the manuscript and given their consent to all of its assertions. It's crucial to acknowledge everyone who contributed significantly to science, including students, researchers, project assistants, and lab technicians.</p>,
  },
  {
    heading: <p  style={{fontWeight:"bold"}}>Duplicate Publications</p>, 
    content: <p style={{textAlign:"justify"}}>In redundant publications, research findings are improperly split up into many articles.</p>,
  },
  {
    heading: <p  style={{fontWeight:"bold"}}>Competing Interests</p>, 
    content: <div><p style={{textAlign:"justify"}}>Conflicts of interest (COIs, commonly referred to as "competing interests") arise when circumstances unrelated to the research could be logically interpreted as influencing the objectivity or impartiality of the work or its evaluation. Whether or not they really had an impact, any conflicts of interest must be disclosed in order to make informed decisions. This declaration won't typically prohibit work from being published or always bar someone from participating in a review process.</p>
    <p style={{textAlign:"justify"}}>Declare a prospective interest if unsure, or speak with the editorial office. Sanctions may result from undeclared interests. Submissions with conflicts that are not stated but are later discovered may be rejected. Published articles could need to be revised, corrected, or in extreme circumstances, withdrawn.</p></div>,
  },
  {
    heading: <p  style={{fontWeight:"bold"}}>Conflicts Comprise</p>, 
    content: <div>
      <p style={{textAlign:"justify"}}>Financial: money, other payments, goods, and services received or anticipated by the writers in connection with the work's subject matter or from a company with a stake in its success.</p>
      <p style={{textAlign:"justify"}}>Being hired by, serving on the advisory board for, or belonging to an entity with a stake in the project's result are all examples of affiliations.</p>
      <p style={{textAlign:"justify"}}>Intellectual property includes patents and trademarks that are owned by an individual or business.</p>
      <p style={{textAlign:"justify"}}>Personal - relationships with friends, family, and other intimate friends and family members</p>
      <p style={{textAlign:"justify"}}>Ideology is a set of views or political or religious activism that is pertinent to the work.</p>
      <p style={{textAlign:"justify"}}>Academic rivals or those whose work is criticised.</p>
    </div>,
  },
  {
    heading: <p  style={{fontWeight:"bold"}}>Authors</p>, 
    content: <div><p style={{textAlign:"justify"}}>All potential interests must be disclosed by authors in a section titled "Conflicts of Interest," along with a justification for why the interest might be a conflict. The authors should declare that there are no conflicts of interest with regard to the publication of this paper if there are none. Co-authors' declarations of interests must be made by the authors who are submitting the work.</p>
    <p style={{textAlign:"justify"}}>Authors are required to disclose any current or recent financing, as well as any other payments, products, or services that may have influenced the work (including those for article processing fees). No matter if there is a conflict of interest, all funding must be disclosed in the "Acknowledgments."</p>
    <p style={{textAlign:"justify"}}>Any involvement in the conception, planning, design, conduct, or analysis of the work, the preparation or editing of the manuscript, or the decision to publish of anyone other than the authors who: 1) has an interest in the outcome of the work; 2) is affiliated to an organization with such an interest; or 3) was employed or paid by a funder, must be disclosed.</p>
    <p style={{textAlign:"justify"}}>Editors and reviewers will take into account declared conflicts of interest and include them in the final paper.</p></div>,
  },
  {
    heading: <p  style={{fontWeight:"bold"}}>Reviewers and Editors</p>, 
    content: <div>
      <p style={{textAlign:"justify"}}>Editors and reviewers ought to decline to work on a submission if: possess a current submission or recent publication with any author share your affiliation with any author, new or old. collaborate with any author, or have you just lately done so?</p>
      <p style={{textAlign:"justify"}}>Possess a close relationship with any author.</p>
      <p style={{textAlign:"justify"}}>Possess a monetary stake in the work's subject.</p>
      <p style={{textAlign:"justify"}}>Unable to be impartial.</p>
      <p style={{textAlign:"justify"}}>Reviewers must disclose any potential conflicts of interest in the "Confidential" area of the review form, where the editor will take them into account.</p>
      <p style={{textAlign:"justify"}}>If editors or reviewers have spoken with the authors about the article in the past, they must disclose this.</p>
      </div>,
  },
  {
    heading: <p  style={{fontWeight:"bold"}}>Sanctions</p>, 
    content: <div>
      <p style={{textAlign:"justify"}}>Regardless of whether the infractions took place in a journal published by DREAM SCIENCE, the following sanctions will be implemented if any of the aforementioned policies are violated and are documented in a journal:</p>
      <p style={{textAlign:"justify"}}>Rejection of the infringement-related manuscript right away.</p>
      <p style={{textAlign:"justify"}}>Immediate rejection of any other manuscripts that any of the authors of the plagiarised paper have sent to any journal that is published by Dream Science.</p>
      <p style={{textAlign:"justify"}}>Prohibition against all authors, individually or together with other authors of the infringing work, or jointly with any other authors, for any further submissions to any journal published by Dream Science. This ban will be in place for at least 36 months.</p>
      <p style={{textAlign:"justify"}}>Prohibition on the participation of all authors in the any journal's editorial board that Dream Science publishes.</p>
      <p style={{textAlign:"justify"}}>The publisher maintains the right to impose further consequences beyond those mentioned above in situations where the violations of the aforementioned rules are thought to be extremely flagrant.</p>
      </div>,
  },
  {
    heading: <p  style={{fontWeight:"bold"}}>Research Recording</p>, 
    content: <p style={{textAlign:"justify"}}>It is crucial that the authors document the findings of their research in a way that allows for analysis and evaluation both prior to publication and for a reasonable amount of time following publication by other researchers. Fabrication is viewed as a form of scientific misconduct, is very unethical, and in some jurisdictions may even be illegal. Examples include reporting results from studies that were never undertaken, deceiving people, or intentionally misleading them.</p>,
  },
  {
    heading: <p  style={{fontWeight:"bold"}}>Publication Techniques</p>, 
    content: <p style={{textAlign:"justify"}}>For each publication, the writers must submit their research papers in the exact format required by the journal. The writers' information should be succinct, accurate, and provide specifics on the research studies they conducted. To support their research, authors should use comparative analyses and contemporary research publications. To support their original piece of study, authors should, however, rework the data in their own words and offer it in a different way. This information should be referenced in the study publications as it was taken from the work of rivals, collaborators, and other academics. In addition, they ought to list works that helped define the parameters of the reported effort.</p>,
  },
  {
    heading: <p  style={{fontWeight:"bold"}}>Authorship Techniques</p>, 
    content: <div>
    <p style={{textAlign:"justify"}}>Authorship credit should be given based on significant contributions to the idea and design, the collection, analysis, and interpretation of data, the writing of the article or its critical revision for significant intellectual content, and the final approval of the published version. A cited author must satisfy each of these requirements. Other contributors to the research, such as those who secured funding for the study, gathered crucial information and materials, or coordinated with the publication, are significant but do not meet the criteria for authorship. The research articles can include references to these people.</p>
    <p style={{textAlign:"justify"}}>It is important to identify the funding source for any research projects or publications. The submitted work's main ideas should not have been published before, and the author should explicitly state that they are not being considered for publication elsewhere. The author should explicitly state whether or not a primary research report has been published as well as any new analyses or data synthesis that have been included in the secondary research report. A 10% overlap between such journals is thought to be acceptable.</p>
    <p style={{textAlign:"justify"}}>Plagiarism is a wholly inappropriate practise in the world of research and is unethical. Before submitting the study paper, authors must certify that they are the copyright owners or that they have obtained the copyright owners' consent. It is unacceptable to violate copyright in any way. In addition to publications, Dream Science also freely publishes conference proceedings. These are meant to be tools for the community to share the most recent work-in-progress in their various fields of study. The conference planners must state that the proceedings won't be distributed to or published in any other journal.</p>
    </div>,
  },
  {
    heading: <p  style={{fontWeight:"bold"}}>Responsibilities of Editorial</p>, 
    content: <p style={{textAlign:"justify"}}>The decision to approve or reject a work submitted to a journal rests entirely with the editor, who is unaffected by management or owners in any way. While making a judgement, the editor may consult with the associate editor, co-editors, and peer reviewers. All submissions should be evaluated by the editors based solely on their scientific quality, with minimal consideration of outside circumstances. Regardless of the author's caste, culture, origin, gender, or citizenship, the decision must be prompt and equitable. Editors, writers, and peer reviewers are all required to disclose any conflicts of interest that would make it difficult for them to present or evaluate data in an unbiased manner. Relevant financial, individual, political, intellectual, or religious interests are some examples. Editors and board members should disclose their ties and interests if these are pertinent to the material being considered or published. The editorial team should only give reviewers access to information about a submitted manuscript that is being considered. Conflicts of interest should never arise in situations.</p>,
  },
];



  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return (
    <>
  <Helmet>
    <title>Publication Ethics: DS Journals Guidelines</title>
    <link rel="canonical" href="https://dsjournals.com/for-authors/publication-ethics"/>
    <meta name="title" content="Publication Ethics: DS Journals Guidelines"/>
    <meta name="description" content="DS Journals is committed to upholding publication ethics. Learn about our guidelines for authors regarding plagiarism, authorship, and more."/>
    <meta name="keywords" content="journal author guidelines, author guidelines, submission of articles, submission articles, science advances author guidelines, journal guidelines"/>
    <meta property="og:type" content="Website"/>
    <meta property="og:site_name" content="Dream Science"/>
    <meta property="og:url" content="https://dsjournals.com/for-authors/publication-ethics"/>
    <meta property="og:title" content="Publication Ethics: DSJournals Guidelines"/>
    <meta property="og:description" content="DSJournals is committed to upholding publication ethics. Learn about our guidelines for authors regarding plagiarism, authorship, and more."/>
    <meta property="og:image" content="image url"/>
    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:title" content="Publication Ethics: DSJournals Guidelines"/>
    <meta name="twitter:description" content="DSJournals is committed to upholding publication ethics. Learn about our guidelines for authors regarding plagiarism, authorship, and more."/>
    <meta name="twitter:site" content="@DreamScience4"/>
    <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
    <meta name="twitter:image" content="image url"/>
    <meta name="robots" content="index, follow"/>
  </Helmet>


    <section id="hero-no-slider" style={{height:"300px"}} className="d-flex justify-cntent-center align-items-center">
     <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
       <div className="row justify-content-center">
         <div className="col-xl-8">
           <div className="row justify-content-center">
             <div className="col-xl-8">
               <h2>Publication Ethics</h2>
               {/* <Link to="#" className="btn-get-started ">Read More</Link> */}
             </div>
         </div>
       </div>
     </div>
     </div>
   </section>

<section className="breadcrumbs">
<div className="container">
<div className="d-flex justify-content-between align-items-center">
{/* <h2>Publication Ethics</h2> */}
<ol>
 <li><Link to="/home">Home</Link></li>
 <li><Link to="/for-authors">Authors</Link></li>
 <li>Publication Ethics</li>
</ol>
</div>
</div>
</section>

<main id="main">
<section className="blog" id="blog">
<div className="container">
   <div className="row">
     <div className="col-lg-3">
     <div className="list-group">
      <div className="accordion">
                      {accordionData.map(({content }) => (
                        <Accordion content={content} />
                    ))}
                  </div>
      </div>
     </div>
       <div className="col-lg-9">
      
       <h1 style={{fontSize:"35px"}}>Publication Ethics</h1>
         <p style={{textAlign:"justify"}}>In order to ensure high-quality scientific publications, public 
         confidence in scientific findings, and that people have been given credit for their contributions, 
         there are ethical standards for publication. Dream Science aspires to follow the COPE's Code of 
         Conduct and Best Practice Guidelines for Publication Ethics.</p>
         {Llistaccordiondata.map(({heading,content}) => (
          <Llistaccordion heading={heading} content={content} />
         ))}
         
{/* <h6>Article Assessment</h6>
<p id='article_assessment' style={{textAlign:"justify"}}>All manuscripts are subject to peer review and are expected to meet standards of academic excellence. 
If approved by the editor, submissions will be considered by peer-reviewers, whose identities will remain 
anonymous to the authors.</p>
<p style={{textAlign:"justify"}}>Our Research Integrity team will occasionally seek advice outside standard peer review, for example, on submissions with serious ethical, security, biosecurity, or societal implications. We may consult experts and the academic editor before deciding on appropriate actions, including but not limited to: recruiting reviewers with specific expertise, assessment by additional editors, and declining to further consider a submission.
Plagiarism</p>
<p style={{textAlign:"justify"}}>All journals published by DREAM SCIENCE are committed to publishing only original material, i.e., material that has neither been published elsewhere, nor is under review elsewhere. DREAM SCIENCE uses the software to detect instances of overlapping and similar text in submitted manuscripts. Manuscripts that are found to have been plagiarized from a manuscript by other authors, whether published or unpublished, will incur plagiarism sanctions.</p>
<h6>Duplicate Submission</h6>
<p id='duplicate_submission' style={{textAlign:"justify"}}>Manuscripts that are found to have been published elsewhere, or to be under review elsewhere, will incur duplicate submission/publication sanctions. If authors have used their own previously published work, or work that is currently under review, as the basis for a submitted manuscript, they are required to cite the previous work and indicate how their submitted manuscript offers novel contributions beyond those of the previous work.</p>
<h6>Citation Manipulation</h6>
<p id='citation_manipulation' style={{textAlign:"justify"}}>Submitted manuscripts that are found to include citations whose primary purpose is to increase the number of citations to a given author’s work, or to articles published in a particular journal, will incur citation manipulation sanctions.</p>
<h6>Data Fabrication and Falsification</h6>
<p id='data_fabrication_and_falsification' style={{textAlign:"justify"}}>Submitted manuscripts that are found to have either fabricated or falsified experimental results, including the manipulation of images, will incur data fabrication and falsification sanctions.</p>
<h6>Improper Author Contribution or Attribution</h6>
<p id='improper_author_contribution_or_attribution' style={{textAlign:"justify"}}>All listed authors must have made a significant scientific contribution to the research in the manuscript and approved all its claims. It is important to list everyone who made a significant scientific contribution, including students, research scholar, project assistant, and laboratory technicians.</p>
<h6>Redundant Publications</h6>
<p id='redundant_publications' style={{textAlign:"justify"}}>Redundant publications involve the inappropriate division of study outcomes into several articles.</p>
<h6>Conflicts of Interest</h6>
<p id='conflicts_of_interest' style={{textAlign:"justify"}}>Conflicts of interest (COIs, also known as ‘competing interests’) occur when issues outside research could be reasonably perceived to affect the neutrality or objectivity of the work or its assessment. Potential conflicts of interest must be declared – whether or not they actually had an influence – to allow informed decisions. In most cases, this declaration will not stop work from being published nor will it always prevent someone from being involved in a review process.</p>
<p style={{textAlign:"justify"}}>If unsure, declare a potential interest or discuss with the editorial office. Undeclared interests may incur sanctions. Submissions with undeclared conflicts that are later revealed may be rejected. Published articles may need to be re-assessed, have a corrigendum published, or in serious cases be retracted.</p>
<h6>Conflicts include:</h6>
<p id='conflicts_include' style={{textAlign:"justify"}}>Financial – funding and other payments, goods and services received or expected by the authors relating to the subject of the work or from an organization with an interest in the outcome of the work
Affiliations – being employed by, on the advisory board for, or a member of an organization with an interest in the outcome of the work
Intellectual property – patents or trademarks owned by someone or their organization
Personal – friends, family, relationships, and other close personal connections
Ideology – beliefs or activism, e.g. political or religious, relevant to the work
Academic – competitors or someone whose work is critiqued</p>
<h6>Authors</h6>
<p id='authors' style={{textAlign:"justify"}}>Authors must declare all potential interests in a ‘Conflicts of interest’ section, which should explain why the interest may be a conflict. If there are none, the authors should state “The author(s) declare(s) that there is no conflict of interest regarding the publication of this paper.” Submitting authors are responsible for co-authors declaring their interests.</p>
<p style={{textAlign:"justify"}}>Authors must declare current or recent funding (including for article processing charges) and other payments, goods or services that might influence the work. All funding, whether a conflict or not, must be declared in the ‘Acknowledgments’.</p>
<p style={{textAlign:"justify"}}>The involvement of anyone other than the authors who 1) has an interest in the outcome of the work; 2) is affiliated to an organization with such an interest; or 3) was employed or paid by a funder, in the commissioning, conception, planning, design, conduct, or analysis of the work, the preparation or editing of the manuscript, or the decision to publish must be declared.</p>
<p style={{textAlign:"justify"}}>Declared conflicts of interest will be considered by the editor and reviewers and included in the published article.</p>
<h6>Editors and Reviewers</h6>
<p id='editors_and_reviewers' style={{textAlign:"justify"}}>Editors and reviewers should decline to be involved with a submission when they:
<ul>
<li>
Have a recent publication or current submission with any author
</li>
<li>
Share or recently shared an affiliation with any author
</li>
<li>
Collaborate or recently collaborated with any author
</li>
<li>
Have a close personal connection to any author
</li>
<li>
Have a financial interest in the subject of the work
</li>
<li>
Feel unable to be objective
</li>
</ul>

<p style={{textAlign:"justify"}}>Reviewers must declare any remaining interests in the ‘Confidential’ section of the review form, which will
be considered by the editor.</p>
<p style={{textAlign:"justify"}}>Editors and reviewers must declare if they have previously discussed the manuscript with the authors.</p>
<h6>Sanctions</h6>
<p id='sanctions' style={{textAlign:"justify"}}>In the event that there are documented violations of any of the above mentioned policies in any journal, 
regardless of whether or not the violations occurred in a journal published by DREAM SCIENCE, the following 
sanctions will be applied:</p>
<ul>
<li>
Immediate rejection of the infringing manuscript
</li>
<li>
Immediate rejection of every other manuscript submitted to any journal published by DREAM SCIENCE by 
any of the authors of the infringing manuscript
</li>
<li>
Prohibition against all of the authors for any new submissions to any journal published by DREAM SCIENCE, 
either individually or in combination with other authors of the infringing manuscript, as well as in 
combination with any other authors. This prohibition will be imposed for a minimum of 36 months
</li>
<li>
Prohibition against all of the authors from serving on the Editorial Board of any journal published 
by DREAM SCIENCE
</li>
</ul>
In cases where the violations of the above policies are found to be particularly egregious, the publisher 
reserves the right to impose additional sanctions beyond those described above.</p> */}
       </div>

   </div>
</div>
</section>
</main>

   </>
  )
}

export default Pubethics;