import React ,{useEffect} from 'react';
import {Link, NavLink} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dsrls/Accordion';
import Ddsrlsarticletemplate from '../../downloads/DS-RLS-Article_Template.doc';
import Ddsrlscopyrightform from '../../downloads/DS-RLS-Copyright_Form.docx';
import Journaltitle from '../../pages/dsrls/data.json';

const Dsdsrlseditorialboard = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  const accordionData = [{
    content: <div>
       <HashLink smooth to="/rls" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
            <NavLink to="/rls/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
           <NavLink to="/rls/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
           <NavLink to="/rls/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
           <NavLink to="/rls/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
           <NavLink to="/rls/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
           <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
           <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
           <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
           <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
           <NavLink to="/rls/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
           <a href={Ddsrlsarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
           <a href={Ddsrlscopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
        
</div> 
},
  ];
return (
<>
<Helmet><title>Editorial Board | DS Reviews of Research in Life Sciences (DS-RLS)</title>
<link rel="canonical" href="https://dsjournals.com/rls/editorial-board"/>
<meta name="title" content="Editorial Board | DS Reviews of Research in Life Sciences (DS-RLS)"/>
<meta name="description" content="Meet the distinguished editorial board of DS-RLS Journal, who ensure the quality and integrity of research published in our peer-reviewed journal."/>
<meta name="keywords" content="editorial board, editorial board members, life sciences journal editorial department, journal editorial board, become editorial board member, editorial member, editorial editor, board editorial, editorial team members, journal editorial board positions, editorial committee, editorial board positions."/>
<meta property="og:type" content="Website"/>
<meta property="og:site_name" content="Dream Science"/>
<meta property="og:url" content="https://dsjournals.com/rls/editorial-board"/>
<meta property="og:title" content="Editorial Board | DS Reviews of Research in Life Sciences (DS-RLS)"/>
<meta property="og:description" content="Meet the distinguished editorial board of DS-RLS Journal, who ensure the quality and integrity of research published in our peer-reviewed journal."/>
<meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Research-in-Life-Sciences.webp"/>
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="Editorial Board | DS Reviews of Research in Life Sciences (DS-RLS)"/>
<meta name="twitter:description" content="Meet the distinguished editorial board of DS-RLS Journal, who ensure the quality and integrity of research published in our peer-reviewed journal."/>
<meta name="twitter:site" content="@DreamScience4"/>
<meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
<meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Research-in-Life-Sciences.webp"/>
<meta name="robots" content="index, follow"/>

        </Helmet>



 <section id="hero-no-slide-dsrls" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
      <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                {/* <h2>Editorial Board</h2> */}
                {
                                Journaltitle.map(heading => {
                                  return(
                                    <h2 style={{fontSize:"25px"}}>{heading.title}</h2>     
                                  )
                                      
                                })
                            }
              </div>
          </div>
        </div>
      </div>
      </div>
    </section>
    
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          {/* <h2>Editor Spotlight</h2> */}
          <ol>
          <li><Link to="/home">Home</Link></li>
            <li><Link to="/journals">Journals</Link></li>
            <li><Link to="/rls">DS-RLS</Link></li>
            <li>Editorial Board</li>
          </ol>
        </div>
      </div>
    </section>
         <main id="main">
<section className="blog" id="blog">
    <div className="container">
        <div className="row">
            <div className="col-lg-3 mb-3">
            <div className="list-group">
              <div className="accordion">
                    {accordionData.map(({content }) => (
                      <Accordion content={content} />
                  ))}
              </div>
            </div>

        <div className="abtlistgrp">
          </div>
            </div>
            <div className='col-lg-9 video-box align-self-baseline position-relative'>
             <div className="d-flex justify-content-center">
                       <p><h1>Coming Soon...</h1></p>
                       
                       </div>    
                       <div className="copyright d-flex justify-content-center">
                          &copy; Powered By &nbsp; <strong><span>Dream Science</span></strong>. 
                      </div>
            </div>
            {/* <div id='head1' className="col-lg-9"> 
             <h3>Editorial Board</h3>
             <div className='row'>
              <h5 className='text-left'>
                <span style={{ backgroundColor: "#94c045",
color:"#fff",
lineHeight: "43px",
padding: "5px 10px",
whiteSpace:"pre-wrap"}}>Editor in Chief</span></h5>

                     



              <div className='col-lg-12'>
              <div className='text-left' style={{fontWeight:"bold"}}>Dr. Pooja Sharma ,</div> <p style={{textAlign:"left",fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-justify text-muted">
                                                                                                Associate Professor, <br />
                                                                                                Department of MBA,<br/>
                                                                                                Sagar Institute of Research and Technology Excellence, <br/>
                                                                                                Bhopal. <br/>
                                                                                                pooja.s@sirte.bhopal.ac.in<br/>
                <a href='https://www.sirtebhopal.ac.in/department-faculty-members-MBA.php' target="_balnk">Profile Link</a> </p>
              </div>
             
            
             </div>

             <div className='row'>
                <h5 style={{textAlign:"left"}}><span style={{ backgroundColor: "#94c045",
color:"#fff",
lineHeight: "43px",
padding: "5px 10px",
whiteSpace:"pre-wrap"}}>Associate Editor</span></h5>
             <div className='col-lg-12'>
              <div className='text-left' style={{fontWeight:"bold"}}> Dr. Ciurea Maria, </div>	
              <p  style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted"> Associate Professor,

              <br/>
              Departamentul de Științe Economice<br/>
              University of Petrosani,<br/> 
              Petrosani,<br/> Romania.
              <br/>mariaciurea@upet.ro<br/>
              <a href='https://www.upet.ro/profile/?n=Ciurea&p=Maria' target="_balnk">Profile Link</a></p>
              </div>
             </div>

 
             <div className='row'>
             <h5 className='text-left'><span style={{ backgroundColor: "#94c045",
color:"#fff",
lineHeight: "43px",
padding: "5px 10px",
whiteSpace:"pre-wrap"}}>Editorial Board Members</span></h5>
              <div className='col-lg-12'>
              <div className='text-left' style={{fontWeight:"bold"}}>Prof.Dr. S. Ganesan, </div>	
              <p style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted">Associate Professor,
              <br/>Department of English,<br/> National College (Autonomous),<br/> Tamilnadu, India.
              <br/>drganesan@hhrc.ac.in<br/>
              <a href='https://www.hhrc.ac.in/English_Staff_Profile/Dr_S_Ganesan.pdf' target="_balnk">Profile Link</a></p>
              </div>
              <div className='col-lg-12'>
              <div className='text-left' style={{fontWeight:"bold"}}>Dr. G. Kannaiyan, </div>	
              <p style={{fontSize:"12px",fontFamily:"sans-serif",fontWeight:"bold"}} className="text-left text-muted"> Assistant Professor,
              <br/>Department of English,<br/> St.Jospeh's College (Autonomous),<br/> Tamilnadu, India.
              <br/>kannaiyan_en2@mail.sjctni.edu<br/>
              <a href='https://www.sjctni.edu/Department/StaffProfile/F12EN89.pdf' target="_balnk">Profile Link</a></p>
              </div>
          
             </div> 



                  
            </div> */}
          
        </div>
    </div>

</section>


</main>
</>
)
}

export default Dsdsrlseditorialboard