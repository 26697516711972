import React,{useEffect} from 'react';
import { useParams, useHistory, Link,NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from '../../components/Dscse/Accordion';
// import Accordion from '../components/Accordion';
// import useStyles from '../styles';
import { getjournalPost } from '../../actions/journal/posts';
import { HashLink } from 'react-router-hash-link';
import Dsjstarticletemplate from '../../downloads/DS-DST-Article_Template.doc';
import Dsjstcopyrightform from '../../downloads/DS-DST-Copyright_Form.docx';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';



const Articledetails = () => {
    const { post} = useSelector((state) => state.journalposts);
    const dispatch = useDispatch();
    
    
    const { id } = useParams();
    const styleObj = {
      lineHeight: '1rem'
    };
    
    useEffect(() => {
       dispatch(getjournalPost(id));
    }, [id]);

    useEffect(()=>{
      window.scrollTo(0,0);
    },[]);
    const accordionData = [{
      content: <div>
          <HashLink smooth to="/dst" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Aim and Scope</HashLink>
          <NavLink to="/dst/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
          <NavLink to="/dst/paper-submission"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
          <NavLink to="/dst/current-issue"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/dst/archives"           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/dst/topics"                           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target= "_blank"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target= "_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/dst/apc"               className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
          {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
          <a href={Dsjstarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
          <a href={Dsjstcopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
  </div> 
  },
    ];

    if(!post) return null;

    // if(isLoading){
    //     return(
    //         <Paper elevation={6}>
    //             <CircularProgress size="7em"/>
    //         </Paper>
    //     );
    // }

  
    
    // const accordionData = [{
    //     content: <div>
    //         <Link smooth to="#abstract" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>Abstract</Link>
    //         <Link to="#keywords" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>Keywords</Link>
    //         <Link to="#references" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>References</Link>
    // </div> 
    // },
    //   ];
      
      // if(!post) return null;

      // if(isLoading) {
      //   return <Paper elevation={6} className={classes.loadingPaper}>
      //     <CircularProgress/>
      //   </Paper>
      // }

     
  return (
    <>
   
   <Helmet>
   
   <title>DS - {`${post.title}`} </title>
   {/* <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"/> */}

   <title>{`${post.title}`} </title>
          <meta name="robots" content="index"/>
          <meta name="Author" content={post.authors}/>
          <meta name="title" content={post.title}/>
          <meta name="description" content={post.abstract}/>
          <meta name="keywords" content={post.key_words}/>
          <meta name="rights" content="Copyright Dream science"/>
          <meta name="citation_title" content={post.title}/>
          <meta name="citation_journal_title" content="Dream Science Journal of Digital Science and Technology"/>
          <meta name="citation_publisher" content="Dream Science"/>
          <meta name="citation_author" content={post.authors}/>
          <meta name="citation_volume" content={`volume-${post.volume}`}/>
          <meta name="citation_year" content={moment(post.year).format("yyyy")}/>
          <meta name="citation_date" content={moment(post.published_date).format("D MMM yyyy")}/>
          <meta name="citation_online_date" content={moment(post.published_date).format("D MMM yyyy")}/>
          <meta name="citation_doi" content={post.doi}/>
          <meta name="citation_issn" content="2583-5416"/>
          <meta name="citation_abstract" content={post.abstract}/>
          <meta name="citation_pdf_url" content={`https://dsjournals.com/upload/LLL/volume-${post.volume}/issue-${post.issue}/${post.article_file}`}/>
          <meta name="citation_language" content="English" />
          <meta property="og:url" content={`https://dsjournals.com/dst/${post.article_id}`}/>
          <meta property="og:site_name" content="dsjournals"/>
          <meta property="og:title" content={post.title}/>
          <meta property="og:type" content="website"/>
          <meta property="og:description" content={post.abstract}/>
          <meta property="og:image:type" content="image/webp"/>
          <meta name="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Digital-Science-and-Technology.webp"/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:site" content="website"/>
          <meta name="twitter:description" content={post.title}/>
          <meta name="twitter:title" content={post.abstract}/>
          <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Digital-Science-and-Technology.webp"/>
        </Helmet>
   
    <section id="hero-no-slide-dst" style={{height:"300px"}} className="d-flex justify-cntent-center align-items-center">
      <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
      <div className="row justify-content-center">
        <div className="col-xl-8">
          <div className="row justify-content-center">
            {/* <div className="col-xl-8">
              <h2>Article Details</h2>
            </div> */}
             <div className="col-lg-12">
                <h1 style={{fontSize:"25px"}}>DS Journal of Digital Science and Technology ( DS-DST )</h1>
              </div>
        </div>
      </div>
    </div>
    </div>
    </section>
      
     
        
   
<main id="main">
<section className="blog" id="blog">
    <div className="container">
        <div className="row">
            <div className="col-lg-3"> 
            <div className="list-group">
                    <div className="accordion">
                                    {accordionData.map(({content }) => (
                                        <Accordion content={content} />
                                    ))}
                                </div>
                    </div>
            </div>
            
            <div className="col-lg-9 mt-3">
                {/* <h6 style={{fontWeight:"bold"}}>Research Article | Open Access | <span style={{fontSize:"16px",fontStyle:"normal"}}><Link to={`/upload/volume-${post.volume}/issue-${post.issue}/${post.article_file}`} target="_blank" download><i class="bi bi-download"></i> Download</Link></span></h6> */}
                
                {!post ? <CircularProgress/> : <div>
                
                <h6 style={{fontWeight:"bold"}}>Research Article | Open Access | <span style={{fontSize:"16px",fontStyle:"normal"}}><a href={`/upload/DST/volume-${post.volume}/issue-${post.issue}/${post.article_file}`} target="_blank"><i class="bi bi-download"></i> Download Full Text</a></span></h6>
              
                <p style={{fontSize:"small"}}>Volume {post.volume} | Issue {post.issue} | Year {moment(post.year).format("yyyy")} | Article Id. {post.article_id} | DOI : <a href={post.doi} target="_blank">{post.doi}</a>
                </p>
                <div className="collapse" id="collapseExample">
                    
                  </div>
                  <h3 style={{fontSize:"25px"}}>{post.title}</h3>
                  {/* <h3 style={{fontSize:"25px"}}>Common Fixed Point Theorems for a Pair of Self-Mappings in 
                  Fuzzy Cone Metric Spaces</h3> */}
                  <hr/>
                  <p style={{fontSize:"12px"}}>
                  <span style={{fontWeight:"bold"}}> {post.authors} </span>
                   {/* <span style={{fontWeight:"bold"}}> Saif Ur Rehman, Yongjin Li ,Shamoona Jabeen, Tayyab Mahmood </span> */}
                  </p>
                  
                                                      
                  <table style={{width:"100%"}}>
                      <tr>
                          <td className="text-wrap text-center border-top border-end border-bottom m-2">
                              Received<br/>
                              {moment(post.received_date).format("D MMM YYYY")}
                          </td>
                          <td className="text-wrap text-center border-top border-end border-bottom">
                              Revised<br/>
                              {moment(post.revised_date).format("D MMM yyyy")}
                          </td>
                          <td className="text-wrap text-center border-top border-end border-bottom">
                              Accepted<br/>
                              {moment(post.accepted_date).format("D MMM yyyy")}
                          </td>
                          <td className="text-wrap text-center border-top border-bottom">
                              Published<br/>
                             {moment(post. published_date).format("D MMM yyyy")}
                              {/* {post.createdAt} */}
                          </td>
                      </tr>
                  </table>
                  
                  
                  {/* {console.log(`public/upload/volume-${post.volume}/issue-${post.issue}/${post.article_file}`)}  */}
                  
                  <h5 style={{marginTop:'10px'}}>Citations:</h5>
                  <p id='keywords' style={{textAlign:"justify"}}>
                    {/* Computer Science, Self-mapping, Fuzzy */}
                    {post.authors}, " {post.title} " <span style={{fontStyle:"italic"}}>DS Journal of Digital Science and Technology,</span> vol. {post.volume}, no. {post.issue}, pp. {post.page_start}-{post.page_end}, {moment(post.year).format("yyyy")}.
                   
                  </p>
                <h5>Abstract</h5>
                      <p id='abstract' style={{textAlign:"justify"}}> {post.abstract}</p>
                  <h5>Keywords</h5>
                  <p id='keywords' style={{textAlign:"justify"}}>
                    {/* Computer Science, Self-mapping, Fuzzy */}
                    {post.key_words}
                     {/* {post.key_words.toString().split(',').map((item)=> <>{item}<br/></>)}  */}
                  </p>

                  <h5>References</h5>
                  <p style={{ textAlign: "justify",overflowWrap:"break-word" }}><div className="refertag" dangerouslySetInnerHTML={{ __html: post.references }} style={styleObj} /></p>
                  {/* <p style={{textAlign:"justify",overflowWrap:"break-word"}}>{post.references.toString().split('[EOL]').map((item)=> <>{item}<br/></>)}</p> */}
                  
                  </div>}
                  
            </div>

           
        </div>
    </div>
</section>
</main>
</>
  )
}

export default Articledetails