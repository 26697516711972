import axios from 'axios';


const API = axios.create({baseURL:'https://dev.dsjournals.com'});
// const API = axios.create({ baseURL: 'https://92.204.129.212:5000' });
// const API = axios.create({ baseURL: 'http://dsjournals.com:80'});
// const API = axios.create({ baseURL: 'http://localhost:5000' });
// const API = axios.create({ baseURL: 'http://localhost:8000' });
// const API = axios.create({ baseURL: 'https://ds-journals.herokuapp.com' });

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  }
  return req;
});
 
export const fetchPosts = () => API.get('/posts');
export const createPost = (newPost) => API.post('/posts', newPost);
export const likePost   = (id) => API.patch(`/posts/${id}/likePost`);
export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);

//----------------------------------------------Admin Panel Current Issue----------------------------------------------//
export const fetchcissuePosts    = () => API.get('/currentissuemgt');
export const fetchcissueSearch    = (searchQuery) => API.get(`/currentissuemgt/search?searchQuery=${searchQuery.search}`);
export const fetchcissuePost     = (journal_title) => API.get(`/currentissuemgt/${journal_title}`);
export const createcissuePost    = (newPost) => API.post('/currentissuemgt', newPost);
export const updatedcissuePost    = (id, updatedcissuePost) => API.patch(`/currentissuemgt/${id}`, updatedcissuePost);
export const deletecissuePost    = (id) => API.delete(`/currentissuemgt/${id}`);

//----------------------------------------------Global Test----------------------------------------------//
export const fetchjouPosts    = () => API.get('/journalmgt');
export const fetcharcPosts    = () => API.get('/journalmgt/archivepost');
export const fetchSearch    = (searchQuery) => API.get(`/journalmgt/search?searchQuery=${searchQuery.search}`);
export const fetchjouPost     = (id) => API.get(`/journalmgt/${id}`);
export const createjouPost    = (newPost) => API.post('/journalmgt', newPost);
export const updatejouPost    = (id, updatedjournalPost) => API.patch(`/journalmgt/${id}`, updatedjournalPost);
export const deletejouPost    = (id) => API.delete(`/journalmgt/${id}`);

//----------------------------------------------Search----------------------------------------------------------------------//
export const fetchPostBySearch    = (searchQuery) => API.get(`/search?searchQuery=${searchQuery.search}`);
//----------------------------------------------Digital Science and Technology----------------------------------------------//
export const fetchjournalPosts    = () => API.get('/journalmgt');
export const fetcharchivePosts    = () => API.get('/journalmgt/archivepost');
// export const fetchPostBySearch    = (searchQuery) => API.get(`/journalmgt/search?searchQuery=${searchQuery.search}`);
export const fetchPostByauthor    = (searchAuthor) => API.get(`/journalmgt/search?searchAuthor=${searchAuthor.search}`);
export const fetchjournalPost     = (id) => API.get(`/journalmgt/${id}`);
export const createjournalPost    = (newPost) => API.post('/journalmgt', newPost);
export const updatejournalPost    = (id, updatedjournalPost) => API.patch(`/journalmgt/${id}`, updatedjournalPost);
export const deletejournalPost    = (id) => API.delete(`/journalmgt/${id}`);

//----------------------------------------------Language, Lingustics and Literature----------------------------------------------//

export const fetchdsllljournalPosts    = () => API.get('/dsllljournalmgt');
export const fetchdslllarchivePosts    = () => API.get('/dsllljournalmgt/archivepost');
export const fetchdslllPostBySearch    = (searchQuery) => API.get(`/dsllljournalmgt/search?searchQuery=${searchQuery.search}`);
export const fetchdslllPostByauthor    = (searchAuthor) => API.get(`/dsllljournalmgt/search?searchAuthor=${searchAuthor.search}`);
export const fetchdsllljournalPost     = (id) => API.get(`/dsllljournalmgt/${id}`);
export const createdsllljournalPost    = (newPost) => API.post('/dsllljournalmgt', newPost);
export const updatedsllljournalPost    = (id, updatedjournalPost) => API.patch(`/dsllljournalmgt/${id}`, updatedjournalPost);
export const deletedsllljournalPost    = (id) => API.delete(`/dsllljournalmgt/${id}`);

//----------------------------------------------Artificial Intelligence and Robotics----------------------------------------------//
export const fetchdsairjournalPosts    = () => API.get('/dsairjournalmgt');
export const fetchairvolissjournalPost = (vol,iss) => API.get(`/dsairjournalmgt/volume${vol}/issue${iss}`);
export const fetchdsairarchivePosts    = () => API.get('/dsairjournalmgt/archivepost');
export const fetchdsairjournalPost     = (id) => API.get(`/dsairjournalmgt/${id}`);
export const createdsairjournalPost    = (newPost) => API.post('/dsairjournalmgt', newPost);
export const updatedsairjournalPost    = (id, updatedjournalPost) => API.patch(`/dsairjournalmgt/${id}`, updatedjournalPost);
export const deletedsairjournalPost    = (id) => API.delete(`/dsairjournalmgt/${id}`);

//----------------------------------------------Cyber Security----------------------------------------------//
export const fetchdscysjournalPosts    = () => API.get('/dscysjournalmgt');
export const fetchdscysarchivePosts    = () => API.get('/dscysjournalmgt/archivepost');
export const fetchdscysPostBySearch    = (searchQuery) => API.get(`/dscysjournalmgt/search?searchQuery=${searchQuery.search}`);
export const fetchdscysPostByauthor    = (searchAuthor) => API.get(`/dscysjournalmgt/search?searchAuthor=${searchAuthor.search}`);
export const fetchdscysjournalPost     = (id) => API.get(`/dscysjournalmgt/${id}`);
export const createdscysjournalPost    = (newPost) => API.post('/dscysjournalmgt', newPost);
export const updatedscysjournalPost    = (id, updatedjournalPost) => API.patch(`/dscysjournalmgt/${id}`, updatedjournalPost);
export const deletedscysjournalPost    = (id) => API.delete(`/dscysjournalmgt/${id}`);

//----------------------------------------------Modelling and Simulation----------------------------------------------//
export const fetchdsmsjournalPosts    = () => API.get('/dsmsjournalmgt');
export const fetchdsmsvolissjournalPost    = (vol,iss) => API.get(`/dsmsjournalmgt/volume${vol}/issue${iss}`);
export const fetchdsmsarchivePosts    = () => API.get('/dsmsjournalmgt/archivepost');
export const fetchdsmsPostBySearch    = (searchQuery) => API.get(`/dsmsjournalmgt/search?searchQuery=${searchQuery.search}`);
export const fetchdsmsPostByauthor    = (searchAuthor) => API.get(`/dsmsjournalmgt/search?searchAuthor=${searchAuthor.search}`);
export const fetchdsmsjournalPost     = (id) => API.get(`/dsmsjournalmgt/${id}`);
export const createdsmsjournalPost    = (newPost) => API.post('/dsmsjournalmgt', newPost);
export const updatedsmsjournalPost    = (id, updatedjournalPost) => API.patch(`/dsmsjournalmgt/${id}`, updatedjournalPost);
export const deletedsmsjournalPost    = (id) => API.delete(`/dsmsjournalmgt/${id}`);

//----------------------------------------------Multidisciplinary----------------------------------------------//
export const fetchdsmjournalPosts    = () => API.get('/dsmjournalmgt');
export const fetchdsmarchivePosts    = () => API.get('/dsmjournalmgt/archivepost');
export const fetchdsmPostBySearch    = (searchQuery) => API.get(`/dsmjournalmgt/search?searchQuery=${searchQuery.search}`);
export const fetchdsmPostByauthor    = (searchAuthor) => API.get(`/dsmjournalmgt/search?searchAuthor=${searchAuthor.search}`);
export const fetchdsmjournalPost     = (id) => API.get(`/dsmjournalmgt/${id}`);
export const createdsmjournalPost    = (newPost) => API.post('/dsmjournalmgt', newPost);
export const updatedsmjournalPost    = (id, updatedjournalPost) => API.patch(`/dsmjournalmgt/${id}`, updatedjournalPost);
export const deletedsmjournalPost    = (id) => API.delete(`/dsmjournalmgt/${id}`);

//----------------------------------------------Commerce and Economics----------------------------------------------//
export const fetchdscejournalPosts    = () => API.get('/dsrcejournalmgt');
export const fetchdscearchivePosts    = () => API.get('/dsrcejournalmgt/archivepost');
export const fetchdscePostBySearch    = (searchQuery) => API.get(`/dsrcejournalmgt/search?searchQuery=${searchQuery.search}`);
export const fetchdscePostByauthor    = (searchAuthor) => API.get(`/dsrcejournalmgt/search?searchAuthor=${searchAuthor.search}`);
export const fetchdscejournalPost     = (id) => API.get(`/dsrcejournalmgt/${id}`);
export const createdscejournalPost    = (newPost) => API.post('/dsrcejournalmgt', newPost);
export const updatedscejournalPost    = (id, updatedjournalPost) => API.patch(`/dsrcejournalmgt/${id}`, updatedjournalPost);
export const deletedscejournalPost    = (id) => API.delete(`/dsrcejournalmgt/${id}`);

//----------------------------------------------Life Sciences----------------------------------------------//
export const fetchdsrlsjournalPosts    = () => API.get('/dsrlsjournalmgt');
export const fetchdsrlsarchivePosts    = () => API.get('/dsrlsjournalmgt/archivepost');
export const fetchdsrlsPostBySearch    = (searchQuery) => API.get(`/dsrlsjournalmgt/search?searchQuery=${searchQuery.search}`);
export const fetchdsrlsPostByauthor    = (searchAuthor) => API.get(`/dsrlsjournalmgt/search?searchAuthor=${searchAuthor.search}`);
export const fetchdsrlsjournalPost     = (id) => API.get(`/dsrlsjournalmgt/${id}`);
export const createdsrlsjournalPost    = (newPost) => API.post('/dsrlsjournalmgt', newPost);
export const updatedsrlsjournalPost    = (id, updatedjournalPost) => API.patch(`/dsrlsjournalmgt/${id}`, updatedjournalPost);
export const deletedsrlsjournalPost    = (id) => API.delete(`/dsrlsjournalmgt/${id}`);

// ---------------------------------------------------------------------------------------------//
export const fetchhmcardPosts   = ()                => API.get('/midcard');
export const createhmcardPost   = (newPost)         => API.post('/midcard', newPost);
export const likehmcardPost     = (id)              => API.patch(`/midcard/${id}/likePost`);
export const updatehmcardPost   = (id, updatedPost) => API.patch(`/midcard/${id}`, updatedPost);
export const deletehmcardPost   = (id)              => API.delete(`/midcard/${id}`);

// --------------------------------------------------------------------------------------------//
export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
