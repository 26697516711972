import React,{useEffect} from 'react';
import { Link,NavLink } from 'react-router-dom';
import Accordion from '../components/Accordion';
import { Helmet } from 'react-helmet';

const Reviewprocess = () => {
    useEffect(() => {
        window.scrollTo(0,0);
      }, []);
    const accordionData = [{
        content: <div>
            <NavLink  to="/for-authors/publication-ethics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics </NavLink>
            <NavLink  to="/for-authors/author-guideline" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Step by Step Guidelines for Authors</NavLink>
            <NavLink  to="/for-authors/submitting-a-manuscript" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Submitting a Manuscript</NavLink>
            <NavLink  to="/for-authors/open-access-author" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is Open Access ?</NavLink>
            <NavLink  to="/for-authors/review-process" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Review Process</NavLink>
            <NavLink  to="/for-authors/conflicts-of-interest" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Conflicts of Interest</NavLink>
            <NavLink  to="/for-authors/licensing-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Licensing Policy</NavLink>
            <NavLink  to="/for-authors/copyright-infringement" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Copyright Infringement</NavLink>
            <NavLink  to="/for-authors/correction-policy" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Correction Policy</NavLink>
        <NavLink  to="/for-authors/what-is-apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>What is APC ?</NavLink>
            {/* <HashLink smooth to="#" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>How to subscribe the hard copy of journal ?</HashLink> */}    
    </div> 
    },
      ];
  return (
    <>
    <Helmet>
    <title>DS Journals Review Process: For Authors</title>
    <link rel="canonical" href="https://dsjournals.com/for-authors/review-process"/>
    <meta name="title" content="DS Journals Review Process: For Authors"/>
    <meta name="description" content="DS Journal review process ensures high-quality publications. Learn about our editorial policies, peer-review process, and timelines for authors."/>
    <meta name="keywords" content="journal of cyber security, journal of multidisciplinary, journal of modeling and simulation, simulation modelling practice and theory, international journal of modelling and simulation, asia pacific journal of multidisciplinary research, asian journal of multidisciplinary studies, acm transactions on modeling and computer simulation, multidisciplinary science journal, wall street journal cybersecurity, ieee paper on cyber security, coj robotics & artificial intelligence."/>
    <meta property="og:type" content="Website"/>
    <meta property="og:site_name" content="Dream Science"/>
    <meta property="og:url" content="https://dsjournals.com/for-authors/review-process"/>
    <meta property="og:title" content="DS Journals Review Process: For Authors"/>
    <meta property="og:description" content="DS Journal review process ensures high-quality publications. Learn about our editorial policies, peer-review process, and timelines for authors."/>
    <meta property="og:image" content="image url"/>
    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:description" content="DS Journal review process ensures high-quality publications. Learn about our editorial policies, peer-review process, and timelines for authors."/>
    <meta name="twitter:site" content="@DreamScience4"/>
    <meta name="twitter:title" content="DS Journals Review Process: For Authors"/>
    <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
    <meta name="twitter:image" content="image url"/>
    <meta name="robots" content="index, follow"/>
    </Helmet>
    <section id="hero-no-slider" style={{height:"300px"}} className="d-flex justify-cntent-center align-items-center">
     <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
       <div className="row justify-content-center">
         <div className="col-xl-8">
           <div className="row justify-content-center">
             <div className="col-xl-8">
               <h2>Review Process</h2>
               {/* <Link to="#" className="btn-get-started ">Read More</Link> */}
             </div>
         </div>
       </div>
     </div>
     </div>
   </section>

<section className="breadcrumbs">
<div className="container">
<div className="d-flex justify-content-between align-items-center">
{/* <h2>Publication Ethics</h2> */}
<ol>
 <li><Link to="/home">Home</Link></li>
 <li><Link to="/for-authors">Authors</Link></li>
 <li>Review Process</li>
</ol>
</div>
</div>
</section>

<main id="main">
<section className="blog" id="blog">
<div className="container">
   <div className="row">
     <div className="col-lg-3">
     <div className="list-group">
      <div className="accordion">
                      {accordionData.map(({content }) => (
                        <Accordion content={content} />
                    ))}
                  </div>
      </div>
     </div>
       <div className="col-lg-9">
      
       <h1 style={{fontSize:"35px"}}>Review Process</h1>
         <p style={{textAlign:"justify"}}>With the help of our knowledgeable Editors, Dream Science International 
         Journals uses a double-blind peer review procedure for an efficient evaluation technique. Senior employees 
         who have a thorough understanding of the subject matter are in charge of the journal's content. With the 
         assistance of internal journal editors, several of the portfolio's journals have external Editors-in-Chief.
         <ul>
            <li>Every article is initially evaluated by our editorial staff based on its timeliness, the interest and significance of the subject, the application of the scientific method, the clarity of the presentation (including the level of English), and the relevance to readers.</li>
            <li>If the manuscript is accepted for peer review, members of the worldwide Editorial Board of the journal and/or other experts in the field will evaluate it.</li>
            <li>The editorial staff chooses these candidates based on their qualifications and reputation.</li>
            <li>Reviewers are expected to declare any possible conflicts of interest that would prevent them from giving an objective assessment of an article.</li>
            <li>The peer-review procedure may be single-blinded or double-blinded depending on the journal.</li>
            <li>Peer reviewers fill out a referee report form and give both general and detailed feedback to the author as well as general remarks to the journal's editor-in-chief(s).</li>
            <li>Anonymized constructive criticism that might aid authors in improving their work is passed (even if the paper is not ultimately accepted).</li>
            <li>If necessary, revised papers can be submitted to additional peer review.</li>
            <li>The Editor-in-Chief of the journal has the final say on whether an article should be accepted for publication.</li>
            <li>Our cascade procedure may be used to suggest an alternate journal to evaluate the manuscript if an article is not judged appropriate for publishing in the journal to which it is submitted.</li>
            <li>This review procedure encourages the production of objective, factually correct, and topical material.</li>
         </ul>
         </p>
         
       </div>

   </div>
</div>
</section>
</main>

   </>
  )
}

export default Reviewprocess