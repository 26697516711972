import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Typography, Paper } from '@material-ui/core';
import useStyles from './styles';
import { createcissuePost,updatecissuePost } from '../../../actions/currentissue/posts';
import './Cmultistepform.css';

const Cmultistepform = ({currentId,setCurrentId}) => {
  const [refresh,setReferesh] = useState(true);
    const [postData, setPostData] = useState({ journal_title: '', volume: '', issue: '' });
    const post = useSelector((state) => (currentId ? state.cissueposts.posts.find((jname) => jname._id === currentId) : null));
    console.log(post)
    const dispatch = useDispatch();
    const classes = useStyles();

    

    useEffect(() => {
        if (post) setPostData(post);
      }, [post]);

    const clear = () => {
        // setCurrentId (0);
        setPostData({ journal_title: '', volume: '', issue: '' });
      };

      // const reset = () =>{
      //   postData('')
        
      // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (currentId === 0) {
            console.log(postData);
            dispatch(createcissuePost(postData));
            clear();
          } else {
            dispatch(updatecissuePost(currentId, postData));
            clear();
          }
      };

  return (
    <div className='col-lg-6'>
    <Paper className={classes.paper}>
    <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit}>
      <Typography variant="h6">{currentId ? `Editing "${post.journal_title}"` : 'Current Issue'}</Typography>
      <TextField name="journal_title" variant="outlined" label="Journal Name" fullWidth value={postData.journal_title} onChange={(e) => setPostData({ ...postData, journal_title: e.target.value })} />
      <TextField name="volume" variant="outlined" label="Volume" fullWidth value={postData.volume} onChange={(e) => setPostData({ ...postData, volume: e.target.value })} />
      <TextField name="issue" variant="outlined" label="Issue" fullWidth value={postData.issue} onChange={(e) => setPostData({ ...postData, issue: e.target.value })} />
      <Button className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="submit">Submit</Button>
      <Button className={classes.buttonCancel} variant="contained" color="secondary" size="small" onClick={clear} >Clear</Button>
      {/* <Button className={classes.buttonCancel} variant="contained" color="secondary" size="small" onClick={reset} >Reset</Button> */}
    </form>
  </Paper>
    </div>
  )
}

export default Cmultistepform;