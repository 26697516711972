import React from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Typography,Divider } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import { useDispatch } from 'react-redux';
// import { deletehmcardPost } from '../../../../../actions/Hmcard/Middle/posts';
import { deletehmcardPost } from '../../../../../actions/card/middle/posts';
// import './styles.css';
import useStyles from './styles';

const Post = ({ post , setCurrentId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Card className="card">
      <CardMedia className="special-issue-image" image={post.selectedFile || 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'} title={post.title} />
      <Typography  gutterBottom variant="h5" component="h2" style={{fontSize:"22px",fontWeight:"800",marginLeft:"20px"}}>{post.title}</Typography>
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">{post.message}</Typography>
      </CardContent>
      <Divider className="divider-style"style={{border:"0.1px solid",backgroundColor:"#f7f7f7"}}/>
      <CardActions>
      <Typography variant="body2">Posted by{post.creator}</Typography>
        <Typography variant="body2">{moment(post.createdAt).fromNow()}</Typography>
        <Button style={{ color: 'blue' }} size="small" onClick={() => setCurrentId(post._id)}>Edit</Button>
        {/* <Button size="small" color="primary" onClick={() => dispatch(likePost(post._id))}><ThumbUpAltIcon fontSize="small" /> Like {post.likeCount} </Button> */}
        <Button size="small" color="primary" onClick={() => dispatch(deletehmcardPost(post._id))}><DeleteIcon fontSize="small" /> Delete</Button>
      </CardActions>
    </Card>
  );
};

export default Post;
