import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import Listaccordion from '../../components/Listaccordion';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dsrls/Accordion';
import Archivegroupaccordion from '../../components/Dsrls/Archivegroup/Accordion';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import { getarchivePosts } from '../../actions/journal/dsrls/posts';
import Ddsrlsarticletemplate from '../../downloads/DS-RLS-Article_Template.doc';
import Ddsrlscopyrightform from '../../downloads/DS-RLS-Copyright_Form.docx';
import Journaltitle from '../../pages/dsrls/data.json';
import moment from 'moment';

const Archives = () => {
  const [postyear, setPostyear] = useState({ _id: "", noofvolume: "" });
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  
  const cpost = useSelector((state) => state.dsrlsarchivesgroup.posts)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // archivesort(cpost);
    if (!refresh) setRefresh(true)
  }, [refresh])

  const accordionData = [{
    content:
      <div>
        <HashLink smooth to="/rls" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
        <NavLink to="/rls/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
        <NavLink to="/rls/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
        <NavLink to="/rls/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
        <NavLink to="/rls/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
        <NavLink to="/rls/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
        <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
        <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
        <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
        <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
        <NavLink to="/rls/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
        <a href={Ddsrlsarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
        <a href={Ddsrlscopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
      </div>
  },
  ];

  cpost && cpost.forEach(yearData => {
    yearData.noofvolume.sort((a, b) => {
      // Sort by volume
      if (a.volume !== b.volume) {
        return a.volume - b.volume;
      }

      // If volumes are the same, sort by issue
      if (a.Issue !== b.Issue) {
        return b.Issue - a.Issue;
      }
    });
  });


  
//Archives Group
const Archivegroups = () => {
  return (
    <ul className="accordion">
      {/* {cpost.map((key) => ( */}

      {!cpost.length ? <LinearProgress/> : cpost.map((key) => (
        <Archivegroupaccordion title={moment(key._id).format("YYYY")} content={key.noofvolume.map((voliss) =>
          <div>
            <Link
              to={{
                // pathname:`/archives-list/volume-${voliss.volume}/issue-${voliss.Issue}`,
                pathname: `/rls/archives/volume${voliss.volume}/issue${voliss.Issue}`,
                // pathname:"/archives-list",
                state: {
                  volume: voliss.volume,
                  issue: voliss.Issue,
                  month_of_issue: voliss.month_of_issue
                }
              }}
            >
              {/* volume {voliss.volume} Issue{voliss.Issue}, {voliss.month_of_issue} */}
              Volume {voliss.volume} Issue {voliss.Issue}, {voliss.month_of_issue}
            </Link>
          </div>)} />
      )
      )}
    </ul>
  )
}
 
useEffect(() => {
  dispatch(getarchivePosts());
}, [dispatch])


useEffect(() => {
  if (cpost) setPostyear(cpost);

}, [cpost])

  
  const listaccordionData = [{
    heading: "2023", content: <Link to="/rls/archives/volume1/issue1"> Volume1 Issue1 , July – September</Link>,

  },
  ];
  return (
    <>
      <Helmet>
        <title>Archives - DS Reviews of Research in Life Sciences (DS-RLS)</title>
        <link rel="canonical" href="https://dsjournals.com/rls/archives" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="title" content="Archives - DS Reviews of Research in Life Sciences (DS-RLS)" />
        <meta name="description" content="Discover past publications and research articles in the archives of DS-RLS. Find information on innovative studies and scientific advancements." />
        <meta name="keywords" content="recent research paper in life sciences, archives of life sciences journal, a journal article, a journal of life sciences research, a life sciences peer reviewed journal, article for journal, journal life sciences, life sciences research, a life sciences journal, journal of life sciences research, research in life sciences." />
        <meta property="og:type" content="Website" />
        <meta property="og:site_name" content="Dream Science" />
        <meta property="og:url" content="https://dsjournals.com/rls/archives" />
        <meta property="og:title" content="Archives - DS Reviews of Research in Life Sciences (DS-RLS)" />
        <meta property="og:description" content="Discover past publications and research articles in the archives of DS-RLS. Find information on innovative studies and scientific advancements." />
        <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Research-in-Life-Sciences.webp" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Archives - DS Reviews of Research in Life Sciences (DS-RLS)" />
        <meta name="twitter:description" content="Discover past publications and research articles in the archives of DS-RLS. Find information on innovative studies and scientific advancements." />
        <meta name="twitter:site" content="@DreamScience4" />
        <meta name="twitter:url" content="https://twitter.com/DreamScience4" />
        <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Research-in-Life-Sciences.webp" />
        <meta name="robots" content="index, follow" />

      </Helmet>
      <section id="hero-no-slide-dsrls" className="d-flex justify-center align-items-center" style={{ height: "300px" }}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="row justify-content-center">
                <div className="col-xl-8">
                  {/* <h2>Archive</h2> */}
                  {
                    Journaltitle.map(heading => {
                      return (
                        <h2 style={{ fontSize: "25px" }}>{heading.title}</h2>
                      )

                    })
                  }
                  {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            {/* <h2>Archives</h2> */}
            <ol>
              <li><Link to="/home">Home</Link></li>
              <li><Link to="/journals">Journals</Link></li>
              <li><Link to="/rls">DS-RLS</Link></li>
              <li>Archive</li>
            </ol>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="blog" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 mb-3">
                <div className="list-group">
                  <div className="accordion">
                    {accordionData.map(({ content }) => (
                      <Accordion content={content} />
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h5 style={{ alignItems: 'center' }}>Archives</h5>
                    <Archivegroups/>
                    {/* <ul className="accordion">
                      {listaccordionData.map(({ heading, content }) => (
                        <Listaccordion heading={heading} content={content} />
                      ))}
                    </ul> */}
                  </div>
                </div>
                <div className="row">
                  {/*<h5>2021</h5>
        <hr/>
         <div className="col-md-2">
        <Link to="/archiveslist" className="btn btn-outline-dark float-center">Issue 1</Link>
        </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Archives