import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dsair/Accordion';
import { HashLink } from 'react-router-hash-link';
import Ddsairarticletemplate from '../../downloads/DS-AIR-Article_Template.doc';
import Ddsaircopyrightform from '../../downloads/DS-AIR-Copyright_Form.docx';

const Dsdsairtopics = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const accordionData = [{
        content: <div>
            <HashLink smooth to="/air" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
            <NavLink to="/air/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
            <NavLink to="/air/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/air/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/air/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/air/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/air/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
            <a href={Ddsairarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Ddsaircopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>

        </div>
    },
    ];

    return (
        <>
            <Helmet>
                <title>Topics Covered - DS-AIR Journal</title>
                <link rel="canonical" href="https://dsjournals.com/air/topics" />
                <meta name="title" content="Topics Covered - DS-AIR Journal" />
                <meta name="description" content="Discover the wide range of topics covered by DS-AIR Journal. From Artificial Intelligence and Robotics, explore the latest research and insights in your field." />
                <meta name="keywords" content="Wireless sensor networks for robot navigation, Expert systems, Embodied intelligence, Computer vision, Robotics and Automation, Artificial general intelligence, AI Technique, ai theory, ai games." />
                <meta property="og:type" content="Website" />
                <meta property="og:site_name" content="Dream Science" />
                <meta property="og:url" content="https://dsjournals.com/air/topics" />
                <meta property="og:title" content="Topics Covered - DS-AIR Journal" />
                <meta property="og:description" content="Discover the wide range of topics covered by DS-AIR Journal. Artificial Intelligence and Robotics, explore the latest research and insights in your field." />
                <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Artificial-Intelligence-and-Robotics.webp" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Topics Covered - DS-AIR Journal" />
                <meta name="twitter:description" content="Discover the wide range of topics covered by DS-AIR Journal. From Artificial Intelligence and Robotics, explore the latest research and insights in your field." />
                <meta name="twitter:site" content="@DreamScience4" />
                <meta name="twitter:url" content="https://twitter.com/DreamScience4" />
                <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Artificial-Intelligence-and-Robotics.webp" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <section id="hero-no-slide-dsair" className="d-flex justify-cntent-center align-items-center" style={{ height: "300px" }}>
                <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="row justify-content-center">
                                {/* <div className="col-lg-12">
                                    <h2>Topics</h2>
                                </div> */}
                                <div className="col-lg-12">
                                    <h2 style={{ fontSize: "25px" }}>DS Journal of Artificial Intelligence and Robotics ( DS-AIR )</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <ol>
                            <li><NavLink to="/home">Home</NavLink></li>
                            <li><NavLink to="/journals">Journals</NavLink></li>
                            <li> <NavLink to="/air">DS-AIR</NavLink></li>
                            <li>Topics</li>
                        </ol>
                    </div>
                </div>
            </section>

            <main id="main">
                <section className="blog" id="blog">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 mb-3">
                                <div className="list-group">
                                    <div className="accordion">
                                        {accordionData.map(({ content }) => (
                                            <Accordion content={content} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">

                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>Topics</p>
                                <p style={{ textAlign: 'justify', textIndent: "50px" }}>
                                    DS-AIR lays the foundation for a common framework for publication of ideas, derivation and testing of techniques that combine theory, science, engineering, and mathematics, leading to future
                                    innovations and novel technologies at the forefront of disseminating and communicating scientific knowledge and impactful discoveries defining the frontiers of a cutting-edge domain such as
                                    Robotics and AI to researchers and the public, worldwide. The journal encourages scientists and engineers to publish their experimental and theoretical research in as much detail as possible.
                                    Therefore, the journal has no restrictions regarding the length of papers. Full experimental details should be provided so that the results can be reproduced.  DS-AIR publishes original research
                                    articles, review articles, short papers, book reviews and communications, that offer substantial new insight into any field of study that involves artificial intelligence (AI) and robotics.
                                    Special emphasis is given to technological innovations and real-world applications. Special issues related to the topics of DS-AIR are also welcome.
                                </p>
                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>The scope of the journal encompasses subjects within the wide domain of AI and Robotics, including but not limited to the following topics:</p>
                                <p style={{ textAlign: 'justify' }}>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-sm-6'>
                                            <ul>
                                                <li>Wireless Sensor Networks for Robot Navigation</li>
                                                <li>Theoretical Interpretation of AI</li>
                                                <li>The Modeling, Identification, and Control of Robotic Systems</li>
                                                <li>The History of Robotics</li>
                                                <li>System Modelling</li>
                                                <li>Swarm Robotics, Mobile, and Flying Robotic Systems</li>
                                                <li>Swarm Intelligence, Artificial Life and Multi-Agent Systems</li>
                                                <li>Space, Planetary and Undersea Exploration</li>
                                                <li>Soft Robotics</li>
                                                <li>Social and Lifestyle Robots</li>
                                                <li>Social and Ethical Issues Related to AI-R</li>
                                                <li>Smart Actuators</li>
                                                <li>Service Robotics, Industrial Robotizations, Space Robotics</li>
                                                <li>Sensorization, Robot Navigation, Communication Robotics</li>
                                                <li>Science of Robotics For Human</li>
                                                <li>Science of Intelligent Physical Systems</li>
                                                <li>Robotics and Health/Medicine</li>
                                                <li>Robotics and Automation</li>
                                                <li>Robot and Swarm Intelligence</li>
                                                <li>Remote Operation and Exploration</li>
                                                <li>Psychology-based Social Robotics</li>
                                                <li>Probabilistic Computing</li>
                                                <li>Novel and Biologically Inspired Robotics</li>
                                                <li>Natural Language Processing, Recognition and Applications</li>
                                                <li>Nano and Micro-Robotics</li>
                                                <li>Multi-Sensor Data Fusion and SLAM</li>
                                                <li>Multi-Robot Control</li>
                                                <li>Multimodal Human–Machine Interaction</li>
                                                <li>Motion Planning and Control</li>
                                                <li>Morphological Computation in Modular Robots</li>
                                                <li>Machine Perception and Pattern Recognition</li>
                                                <li>Machine and Deep Learning</li>
                                                <li>Knowledge Representation</li>
                                                <li>Kinematics Knowledge Reasoning and Discovery</li>
                                                <li>Intelligent Robotics, Mechatronics, and Biomimetics</li>
                                                <li>Intelligent Machine/Agent</li>
                                                <li>Intelligent and Information Systems</li>
                                                <li>Intelligence System and Application</li>
                                                <li>Information Theory of Cognitive Systems</li>
                                                
                                            </ul>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-6'>
                                            <ul>
                                                <li>Industrial Robotics</li>
                                                <li>Humanoids</li>
                                                <li>Human–Machine Interaction</li>
                                                <li>Human Augmentation</li>
                                                <li>Guided Self-Organization</li>
                                                <li>Genetic Algorithms</li>
                                                <li>Fuzzy Logic</li>
                                                <li>Field Robotics</li>
                                                <li>Expert Systems</li>
                                                <li>Exoskeletons, Prosthetics, and Artificial Organs</li>
                                                <li>Evolutionary Game Theory in Robotics</li>
                                                <li>Embodied Intelligence</li>
                                                <li>Data Mining for Robotics</li>
                                                <li>Conversational AI</li>
                                                <li>Control Theory</li>
                                                <li>Control Software</li>
                                                <li>Control Applications</li>
                                                <li>Computer Vision</li>
                                                <li>Computational Robotics</li>
                                                <li>Computational Intelligence</li>
                                                <li>Complex Networks and Distributed Computation</li>
                                                <li>Complex Adaptive Systems</li>
                                                <li>Brain Science-Inspired AI</li>
                                                <li>Brain and Cognitive Science</li>
                                                <li>Biomedical, Rehabilitation, and Surgical Robotics</li>
                                                <li>Bio-Inspired Robotics</li>
                                                <li>Bio-Inspired Design</li>
                                                <li>Bio-Inspired Cyber-Physical Security</li>
                                                <li>Big Data and Knowledge Engineering</li>
                                                <li>Autonomous Vehicles</li>
                                                <li>Autonomous Behaviors</li>
                                                <li>Automated Planning and Scheduling</li>
                                                <li>Artificial Neural Networks</li>
                                                <li>Artificial General Intelligence</li>
                                                <li>AI-Generated Art</li>
                                                <li>AI-Based Molecular and Material Design</li>
                                                <li>AI-Based Medical Image Analysis</li>
                                                <li>AI-Assisted Protein Structure Predictions</li>
                                                <li>AI-Assisted Mathematics</li>
                                                <li>AI, Neural Networks, and Fuzzy Logic in Robotics</li>
                                                <li>AI Theory</li>
                                                <li>AI Technique</li>
                                                <li>AI Games</li>
                                                <li>AI Application</li>
                                            </ul>
                                        </div>
                                    </div>

                                </p>

                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Dsdsairtopics;