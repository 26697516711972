import React,{ useState, useEffect } from 'react';
import Posts from '../../../components/Homepagepost/Posts/Posts';
import {getPosts} from '../../../actions/posts';
import { useDispatch } from 'react-redux';

const Lcardhome = () => {
    const [currentId,setCurrentId] = useState(0);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getPosts());
    },[currentId,dispatch]);
    return (
        <div>
             <Posts setCurrentId={setCurrentId} />
        </div>
    )
}

export default Lcardhome
