import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Accordion from './Accordion';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import './Foreditors.css';

function Foreditors() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const accordionData = [{
    content: <div>

      <HashLink to="#advantages" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Advantages</HashLink>
      <HashLink to="#duties-and-responsibilities" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Duties and Responsibilities</HashLink>
      <HashLink to="#consensus" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Consensus</HashLink>


    </div>
  },
  ];
  return (
    <>
      <Helmet>
          <title>Editorial Guidelines and Resources - DS Journals</title>
          <link rel="canonical" href="https://dsjournals.com/for-editors"/>
          <meta name="title" content="Editorial Guidelines and Resources - DS Journals"/>
          <meta name="description" content="DS Journals' editors play a critical role in ensuring the quality and integrity of our publications. Explore our resources and guidelines for editors."/>
          <meta name="keywords" content="submission guidelines, editorial policies, manuscript evaluation, peer review, publication, scopus indexed journals, publishers, peer reviewed journals, open access journals."/>
          <meta property="og:type" content="Website"/>
          <meta property="og:site_name" content="Dream Science"/>
          <meta property="og:url" content="https://dsjournals.com/for-editors"/>
          <meta property="og:title" content="Editorial Guidelines and Resources - DS Journals"/>
          <meta property="og:description" content=" DS Journals' editors play a critical role in ensuring the quality and integrity of our publications. Explore our resources and guidelines for editors."/>
          <meta property="og:image" content="image url"/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:description" content=" DS Journals' editors play a critical role in ensuring the quality and integrity of our publications. Explore our resources and guidelines for editors."/>
          <meta name="twitter:site" content="@DreamScience4"/>
          <meta name="twitter:title" content="Editorial Guidelines and Resources - DS Journals"/>
          <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
          <meta name="twitter:image" content="image url"/>
          <meta name="robots" content="index, follow"/>
      </Helmet>



      <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center" style={{ height: "300px" }}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="row justify-content-center">
                <div className="col-xl-8">
                  <h2>Editors</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            {/* <h2>For Editors</h2> */}
            <ol>
              <li><Link to="/home">Home</Link></li>
              <li>Editors</li>
            </ol>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="blog" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="list-group">
                  <div className="accordion">
                    {accordionData.map(({ content }) => (
                      <Accordion content={content} />
                    ))}
                  </div>
                </div>

              </div>
              <div className="col-lg-9">
                <h1 style={{fontSize:"35px"}}>Editors</h1>
                <p style={{ textAlign: 'justify' }}>We appreciate your interest in serving on the editorial board or as a reviewer for DS
                  Journals, and we ask that you read the terms and conditions carefully.</p>
                <h6>Advantages</h6>

                <p id="advantages" style={{ textAlign: 'justify' }}>Being a member of a journal's editorial board or
                  a reviewer is a rewarding, pleasurable, and distinguished experience that contributes to
                  the scientific community through the methods and guidelines provided by professionals
                  in the field. Despite the fact that it is time-consuming and sometimes unnoticed,
                  editorial board members/reviewers reap significant benefits. While serving on the
                  journal's editorial board or as a reviewer, you will be entitled to the following
                  perks.</p>
                <p style={{ textAlign: 'justify' }}>You've been compelled to read a variety of papers in your field of importance and
                  interest. This is how you're pushed to stay current while checking and suggesting
                  modifications to the manuscript on a regular basis. Helps to establish your
                  reputation as a well-known expert in your field, which may result in more
                  invitations to speak at conferences or requests for invited study in your
                  specialized area.
                </p>
                <p style={{ textAlign: 'justify' }}>As society needs change, shape and decide on the most critical approaches.</p>
                <p style={{ textAlign: 'justify' }}>Ideas and subject inputs may assist in the organization of special issues based on your interests and preferences.</p>
                <p style={{ textAlign: 'justify' }}>Gives you a leading role in your research community.</p>

                <h6>Duties and Responsibilities</h6>
                <p id="duties-and-responsibilities" style={{ textAlign: 'justify' }}>Our editorial board members and reviewers must be leaders in their
                  fields and have previous experience publishing publications. Because a reviewer's opinion determines
                  whether an article is accepted or rejected, they play a vital part in the peer-review process. All the
                  members are requested to test out the articles submitted to them without any bias to increase our
                  journals' quality. There are no hard and fast rules for analyzing an article; it all depends on its worth,
                  quality, and uniqueness. While verifying the article, keep the following aspects in mind.</p>

                <h6>Consensus</h6>
                <p id='consensus' style={{ textAlign: 'justify' }}>Acceptance of the accompanying terms and conditions
                  confirms your appointment as a member of the Dream Science editorial
                  board or reviewer.</p>
                <p style={{ textAlign: 'justify' }}>Your contract is for One year at first.</p>
                <p style={{ textAlign: 'justify' }}>You must adhere to the Dream Science Journals general standards, code of ethics, and procedures, which may change
                  from time to time based on expansion plans to improve the quality of the journal
                  system.</p>
                <p style={{ textAlign: 'justify' }}>You consent to your name being published on the DS Journals website.</p>
              </div>

            </div>
          </div>

        </section>


      </main>
    </>
  )
}

export default Foreditors;
