import React from 'react';
import { Link } from 'react-router-dom';
import Accordion from '../components/Accordion';

const Specialissueproposal = () => {
    const accordionData = [{
        content: <div>
            <Link to="head1" spy={true} smooth={true} offset={-80} className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i> Special Issue Proposal</Link>
    </div> 
    },
      ];
  return (
    <div>
         <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center">
<div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
<div className="row justify-content-center">
<div className="col-xl-8">
<div className="row justify-content-center">
<div className="col-xl-8">
  <h2>Special Issue Proposal</h2>
  <Link to="/" className="btn-get-started ">Read More</Link>
</div>
</div>
</div>
</div>
</div>
</section>

<section className="breadcrumbs">
<div className="container">
<div className="d-flex justify-content-between align-items-center">
<h2>Special Issue Proposal</h2>
<ol>
<li><Link to="/home">Home</Link></li>
<li>Special Issue Proposal</li>
</ol>
</div>
</div>
</section>
<main id="main">
<section className="blog" id="blog">
<div className="container">
<div className="row">
    <div className="col-lg-3">
      <div className="list-group">
      <div className="accordion">
                      {accordionData.map(({content }) => (
                        <Accordion content={content} />
                    ))}
                  </div>
      </div>
   </div>
    <div className="col-lg-9">
     <h3>Special Issue Proposal</h3>
   
    <p style={{textAlign:"justify"}}><strong>Dream Science </strong> International Journals encourages academicians to organize National / International conferences in association 
    with SSRG in the name of special issues on common topics of interest.</p>
<h6>General Information of the Special Issues:</h6>

<p style={{textAlign:"justify"}}>
    <ul>
        <li>Proposals from academicians significantly involved and recognized in their field will be considered.</li>
        <li>Proposals from honorable members of the Review and associated Boards of SSRG are acceptable.</li>
        <li>Guest editors can eligible to co-publish the special issue with SSRG.</li>
        <li>Guest editors are eligible to apply for SSRG Research activities &amp; programs.</li>
    </ul>
</p>
<h6>Proposals should contain:</h6>
<p style={{textAlign:"justify"}}>
    <ul>
        <li>Name of the organizer/ Institute/University/etc. and details.</li>
        <li>Title of the special issue</li>
        <li>Set out the importance of the area and topics that the special issue will focus on;</li>
        <li>Explain the anticipated contribution of the special issue in advancing understanding in this area;</li>
        <li>Indicate the time scale in which the special issue could be produced (paper writing, reviewing, and submitting final copies to SSRG), assuming the proposal is accepted.</li>
    </ul>
</p>

<p style={{textAlign:"justify"}}> Submit your proposal to: queries@dsjournals.org </p>

    </div>

</div>
</div>

</section>


</main>
    </div>
  )
}

export default Specialissueproposal