import React,{useEffect} from 'react'
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dsm/Accordion';
import { HashLink } from 'react-router-hash-link';
import Ddsmarticletemplate from '../../downloads/DSM-Article_Template.docx';
import Ddsmcopyrightform from '../../downloads/DSM-Copyright_Form.docx';
import { NavLink } from 'react-router-dom';
import Journaltitle from '../../pages/dsdsm/data.json';

const Dsllltopics = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);
    const accordionData = [{
        content: <div>
            <HashLink smooth to="/dsm" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
            <NavLink to="/dsm/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>   Editorial Board</NavLink>
            <NavLink to="/dsm/paper-submission"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
            <NavLink to="/dsm/current-issue"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
            <NavLink to="/dsm/archives"           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
            <NavLink to="/dsm/topics"                           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
            <NavLink to="/for-authors/publication-ethics" target= "_blank"  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
            <NavLink to="/for-authors/author-guideline" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors" target= "_blank"   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers" target= "_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/dsm/apc"               className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
        {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
            <a href={Ddsmarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Ddsmcopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
           
    </div> 
    },
      ];

  return (
    <>
     <Helmet>
         <title>Topics - Multidisciplinary - DS Journals</title>
        <link rel="canonical" href="https://dsjournals.com/dsm/topics"/>
        <meta name="title" content="Topics - Multidisciplinary - DS Journals"/>
        <meta name="description" content="Multidisciplinary Topics: Browse and explore diverse areas of research in Multidisciplinary covered by DS Journals. Find the latest trends and innovations."/>
        <meta name="keywords" content="Psychology and Literature, Feminism, Ecofeminism, Eco Criticism, World Classics in Translation, Modern European Fiction Literature, Gender Studies, Cultural Studies, Dialect, Idiolect, Sociolect, Pidgin, Creole."/>
        <meta property="og:type" content="Website"/>
        <meta property="og:site_name" content="Dream Science"/>
        <meta property="og:url" content="https://dsjournals.com/dsm/topics"/>
        <meta property="og:title" content="Topics - Multidisciplinary - DS Journals"/>
        <meta property="og:description" content="Multidisciplinary Topics: Browse and explore diverse areas of research in Multidisciplinary covered by DS Journals. Find the latest trends and innovations."/>
        <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Multidisciplinary.webp"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="Topics - Multidisciplinary - DS Journals"/>
        <meta name="twitter:description" content="Multidisciplinary Topics: Browse and explore diverse areas of research in Multidisciplinary covered by DS Journals. Find the latest trends and innovations."/>
        <meta name="twitter:site" content="@DreamScience4"/>
        <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
        <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Multidisciplinary.webp"/>
        <meta name="robots" content="index, follow"/>

        </Helmet>
     <section id="hero-no-slide-dsdsm" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
                <div className="col-xl-8">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            {/* <h2>Topics</h2> */}
                            {
                                Journaltitle.map(heading => {
                                  return(
                                    <h2 style={{fontSize:"25px"}}>{heading.title}</h2>     
                                  )
                                      
                                })
                            }
                            {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="breadcrumbs">
        <div className="container">
            <div className="d-flex justify-content-between align-items-center">
                
                <ol>
                <li><NavLink to="/home">Home</NavLink></li>
                <li><NavLink to="/journals">Journals</NavLink></li>
                <li> <NavLink to="/dsm">DSM</NavLink></li>
                <li>Topics</li>
              </ol>
            </div>
        </div>
    </section>

    <main id="main">
<section className="blog" id="blog">
<div className="container">
<div className="row">
    <div className="col-lg-3 mb-3">
      <div className="list-group">
        <div className="accordion">
                        {accordionData.map(({content }) => (
                          <Accordion content={content} />
                      ))}
        </div>
      </div>
   </div>
    <div className="col-lg-9">
      
<p style={{textAlign:'justify',fontWeight:"bold"}}>Topics</p>
<p style={{textAlign:'justify',textIndent:"50px"}}>
The journal publishes peer-reviewed research articles, surveys, reviews and analyses, book reviews, short communications, case studies, recent research and more. The journal is an innovative, 
multidisciplinary, and universal publication that underscores empirical, methodological, and theoretical articles in various fields of study. The journal's role entails mixed methods approach, 
spotlighting design and methodology problems, recognizing the logistics of undertaking mixed methodologies research, and classifying various research methods used in various fields.
</p>
<p style={{textAlign:'justify',fontWeight:"bold"}}>The papers are demanded, but not limited to, the following topics:</p>
<p style={{textAlign:'justify'}}>
    <div className='row'>
        <div className='col-lg-6 col-md-6 col-sm-6'>
            <ul>
            <li>Computational Biomedicine</li>
<li>Hematology and Medical Oncology</li>
<li>Biomedical Genetics</li>
<li>Neurotechnology</li>
<li>Digital Therapeutics</li>
<li>Technology in Mental Health</li>
<li>Internet of Medical Things</li>
<li>Remote Patient Monitoring</li>
<li>NanoMedicine</li>
<li>Tricorders in Diagnoising Patients</li>
<li>Robotic Surgery</li>
<li>Quantum Computing</li>
<li>Edge Computing</li>
<li>Hyperautomation</li>
<li>Cybersecurity</li>
<li>Telemedicine</li>
<li>Health Care Digital Assistants</li>
<li>3D Printing  Human Tissues</li>
<li>Cancer Immunotherapy</li>
<li>Computational Creativity in Music</li>



            </ul>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-6'>
            <ul>
       
           
<li>Cosmological Simulations in Machine Learning</li>
<li>Human Rights in the Age of AI</li>
<li>3D Fabrication Technology</li>
<li>Intelligent Automation and Robotic Process Automation (RPA)</li>
<li>Autonomous Robotics</li>
<li>AI in Healthcare </li>
<li>Virtual Reality and Augmented Reality </li>
<li>Bioprinting</li>
<li>Immersive Technologies</li>
<li>AI in Medical Imaging</li>
<li>Reinforcement Learning Between AI System and Environment</li>
<li>Judicial Ethics</li>
<li>Mass Torts</li>
<li>Wage & Hour Litigation  </li>
<li>Genomics</li>
<li>Pharmaceutical Innovation</li>
<li>AI-Powered Personalization, and AI-Content Creation</li>
<li>Commerece Technology</li>



       </ul>

        </div>
    </div>

</p>

    </div>
</div>
</div>
</section>
</main>
    </>
  )
}

export default Dsllltopics ;