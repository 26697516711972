import React from 'react';
import {Link} from 'react-router-dom';
import Accordion from '../components/Accordion';

function Aboutjournal() {
  const accordionData = [{
    content: <div>
        
                    <Link to="#" className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>Aims and Scope</Link>
                    <Link to="#" className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>Bibiliography Information</Link>
                    <Link to="#" className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>Open Access</Link>
                    <Link to="#" className="list-group-item list-group-item-action"><i className="bi bi-chevron-double-right"></i>Contact</Link> 
</div> 
},
  ];
    return (
        <>
        <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center" style={{height:"300px"}}>
          <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    <h2>About Journals</h2>
                    <Link to="/" className="btn-get-started ">Read More</Link>
                  </div>
              </div>
            </div>
          </div>
          </div>
        </section>
        
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>For Editors</h2>
              <ol>
              <li><Link to="/home">Home</Link></li>
                <li><Link to="/journals">Journals</Link></li>
                <li><Link to="/journalsview">Journal Discipline</Link></li>
                <li>Journal Profile</li>
              </ol>
            </div>
          </div>
        </section>
             <main id="main">
    <section className="blog" id="blog">
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                  <div className="list-group">
                  <div className="accordion">
                      {accordionData.map(({content }) => (
                        <Accordion content={content} />
                    ))}
                  </div>
                   
                  </div>
                  <div className="abtlistgrp">
                 <div className="card">
                  <ul className="list-group list-group-flush">
                    <Link to="#"  className="list-group-item btn-submit"><i className="bi bi-file-arrow-up"></i>Submit</Link>
                      <Link to="#"  className="list-group-item"><i className="bi bi-person m-2"></i>Editor Spotlight</Link>
                        <Link to="#" className="list-group-item"><i className="bi bi-award m-2"></i>Special Issues</Link>

                  </ul>
                </div>
              </div>
                </div>
                <div className="col-lg-9">
                 <h3> About this Journal</h3>
                    <h5>Aims and scope</h5>
                      <p style={{textAlign:"justify"}} className="text-justify">Abstract and Applied Analysis is a mathematical journal devoted exclusively
                       to the publication of high-quality research papers in the fields of abstract 
                       and applied analysis. Emphasis is placed on important developments in classNameical 
                       analysis, linear and nonlinear functional analysis, ordinary and partial 
                       differential equations, optimization theory, and control theory. 
                       Abstract and Applied Analysis supports the publication of original material
                        involving the complete solution of significant problems in the above disciplines. 
                        Abstract and Applied Analysis also encourages the publication of timely and 
                        thorough survey articles on current trends in the theory and applications of 
                        analysis.</p>

                     <h5> Bibliographic information</h5>
                      <p style={{textAlign:"justify"}} className="text-justify">ISSN: 1085-3375 (Print)
                      ISSN: 1687-0409 (Online)
                      DOI: 10.1155/4058</p>

                      <h5>Open Access</h5>
                      <p style={{textAlign:"justify"}} className="text-justify">Abstract and Applied Analysis is an open access journal. 
                        All articles are immediately available to read and reuse upon publication. 
                        More information about our Open Access policy can be found on our copyright page.</p>

                      <h5>Contact</h5>
                      Editorial enquiries should be directed to aaa@hindawi.com.
                      General enquiries should be directed to help@hindawi.com.
                </div>
               
            </div>
        </div>

    </section>


  </main>
        </>
    )
}
export default Aboutjournal;