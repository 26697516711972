import React,{ useState, useEffect } from 'react';
import Form from '../../components/Forms/Mcardform';
import Posts from '../../components/Hmcardpost/Middle/Posts/Posts';
import {gethmcardPosts} from '../../actions/card/middle/posts';
import { useDispatch } from 'react-redux';



const Middlecard = () => {
 
    
    const [currentId, setCurrentId] = useState(0);
    const dispatch = useDispatch();
    //const classes = useStyles();
  
    useEffect(() => {
      dispatch(gethmcardPosts());
    }, [currentId, dispatch]);
 
    return (
        <>
             <section className="home-section">
                    <div className="home-content">
                        <div className="overview-boxes">
                            <div className="row">
                                <div className="col-lg-6">
                                    <Form currentId={currentId} setCurrentId={setCurrentId} />
                                </div>
                                <div className="col-lg-6">
                                    <Posts setCurrentId={setCurrentId} />
                                </div>
                            </div>
                        
                        </div>
                        
                    </div>
            </section>
        </>
    )
}

export default Middlecard
