import React,{useState,useEffect} from 'react';
import { useDispatch } from 'react-redux';
import {useParams,useHistory} from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Box, Button} from '@material-ui/core';
import useStyles from './styles';
import { getjournalPosts } from '../../../actions/journal/dscys/posts';
import Multistepform from '../../../components/Forms/dscy/Multistepform';

const Dcreatejournal = () => {
  const { id } = useParams();
  const history = useHistory();
  const initialValue = 0;
  const [currentId, setCurrentId] = useState(id === undefined ? initialValue : id);
  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <>
     <section className="home-section">
           <div className="home-content">
           <h2>DS Journal of Cyber Securtiy</h2>
           <Multistepform currentId = {currentId} setCurrentId={setCurrentId}/>
           </div>
    </section>
    </>
  )
}

export default Dcreatejournal