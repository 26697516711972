import { FETCH_BY_SEARCH } from "../../constants/actionTypes";

export default (state = {isLoading: true, posts:[] },action) => {
    switch(action.type){
        case 'START_LOADING':
            return { ...state, isLoading: true };
        case 'END_LOADING':
            return { ...state, isLoading: false };
        case FETCH_BY_SEARCH:
            return { ...state, posts: action.payload};
            default:
                return state;
    }

};