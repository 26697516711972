import React,  { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Link,NavLink} from 'react-router-dom';
import Listaccordion from '../../components/Listaccordion';
import Accordion from '../../components/Dsms/Accordion';
import Archivegroupaccordion from '../../components/Dsms/Archivegroup/Accordion';
import { getarchivePosts } from '../../actions/journal/dsms/posts';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import Ddsmsarticletemplate from '../../downloads/DS-MS-Article_Template.doc';
import Ddsmscopyrightform from '../../downloads/DS-MS-Copyright_Form.docx';
import Dsmsdata from './dsmsdata';
import { LinearProgress } from '@material-ui/core';
import moment from 'moment';


const Archives = () => {

  const [postyear, setPostyear] = useState({ _id: "", noofvolume: "" });
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);

  const cpost = useSelector((state) => state.dsmsarchivesgroup.posts);

  const Archivegroups = () =>{
    return(
      <ul className="accordion">
    {!cpost.length ? <LinearProgress/> : cpost.map((key) => (
      <Archivegroupaccordion title={moment(key._id).format("YYYY")} content={key.noofvolume.map((voliss) => 
      <div>
        <Link
        to={{
          pathname: `/ms/archives/volume${voliss.volume}/issue${voliss.Issue}`,
          state: {
            volume: voliss.volume,
            issue: voliss.Issue,
            month_of_issue: voliss.month_of_issue
          }
        }}
      >
            volume {voliss.volume} Issue{voliss.Issue}, {voliss.month_of_issue}
      </Link>
      </div>)} />
    ))}
    </ul>
    )}

  const accordionData = [{
    content: 
    <div>
            <HashLink smooth to="/ms"                           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Aim and Scope    </HashLink>
            <NavLink to="/ms/editorial-board"                   className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Editorial Board  </NavLink>
            <NavLink to="/ms/paper-submission"                  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Paper Submission </NavLink>
            <NavLink to="/ms/current-issue"                     className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Current Issue    </NavLink>
            <NavLink to="/ms/archives"                          className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Archives         </NavLink>
            <NavLink to="/ms/topics"                            className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics           </NavLink>
            <NavLink to="/for-authors/publication-ethics" target= "_blank"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics </NavLink>
            <NavLink to="/for-authors/author-guideline"   target= "_blank"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
            <NavLink to="/for-editors"                    target= "_blank"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
            <NavLink to="/for-reviewers"                  target= "_blank"    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
            <NavLink to="/ms/apc"                                           className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC </NavLink>
              {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
            <a href={Ddsmsarticletemplate}  className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
            <a href={Ddsmscopyrightform}    className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
</div> 
},
  ];


  const listaccordionData = [{
    heading: "2023", content: <Link to="/ms/archives/volume1/issue1"> Volume1 Issue1 , October – December</Link>,

},
  ];

  useEffect(() => {
    dispatch(getarchivePosts());
  }, [dispatch])

  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  useEffect(() => {
    if (cpost) setPostyear(cpost);
  }, [cpost])

  return (
    <>
   <Helmet>
      <title>Archives - DS Journal of Modeling and Simulation</title>
      <link rel="canonical" href="https://dsjournals.com/ms/archives"/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="title" content="Archives - DS Journal of Modeling and Simulation"/>
      <meta name="description" content="Explore the archives of DS-MS Journal and gain new insights into historical scientific developments. Access valuable research from your field today."/>
      <meta name="keywords" content="recent research paper in Modeling and Simulation, archives of Modeling and Simulation journal, a journal article, a journal of Modeling and Simulation research, a Modeling and Simulation peer reviewed journal, article for journal, journal Modeling and Simulation, Modeling and Simulation research, a Modeling and Simulation journal, journal of Modeling and Simulation research, research in Modeling and Simulation."/>
      <meta property="og:type" content="Website"/>
      <meta property="og:site_name" content="Dream Science"/>
      <meta property="og:url" content="https://dsjournals.com/ms/archives"/>
      <meta property="og:title" content="Archives - DS Journal of Modeling and Simulation"/>
      <meta property="og:description" content="Explore the archives of DS-MS Journal and gain new insights into historical scientific developments. Access valuable research from your field today."/>
      <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of -Modeling-and-Simulation.webp"/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content="Archives - DS Journal of Modeling and Simulation"/>
      <meta name="twitter:description" content="Explore the archives of DS-MS Journal and gain new insights into historical scientific developments. Access valuable research from your field today."/>
      <meta name="twitter:site" content="@DreamScience4"/>
      <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
      <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of -Modeling-and-Simulation.webp"/>
      <meta name="robots" content="index, follow"/>
   </Helmet>
    <section id="hero-no-slide-dsms" className="d-flex justify-center align-items-center" style={{height:"300px"}}>
<div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
<div className="row justify-content-center">
  <div className="col-xl-8">
    <div className="row justify-content-center">
      <div className="col-xl-8">
        {/* <h2 style={{color:"#21494f"}}>Archive</h2> */}
        <h2 style={{fontSize:"25px",color:"#21494f"}}>{Dsmsdata[0].banner_title}</h2>
        {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
      </div>
  </div>
</div>
</div>
</div>
</section>

<section className="breadcrumbs">
<div className="container">
<div className="d-flex justify-content-between align-items-center">
  {/* <h2>Archives</h2> */}
  <ol>
    <li><Link to="/home">Home</Link></li>
    <li><Link to="/journals">Journals</Link></li>
    <li><Link to="/ms">DS-MS</Link></li>
    <li>Archive</li>
  </ol>
</div>
</div>
</section>

 <main id="main">
<section className="blog" id="blog">
<div className="container">
<div className="row">
<div className="col-lg-3 mb-3">
      <div className="list-group">
        <div className="accordion">
                        {accordionData.map(({content }) => (
                          <Accordion content={content} />
                      ))}
        </div>
      </div>
   </div>
    <div className="col-lg-9">
        <div className="row">
            <div className="col-lg-12 text-center">
            <h5 style={{alignItems:'center'}}>Archive</h5>
            
             <ul className="accordion">
             <Archivegroups />
              {/* {listaccordionData.map(({heading,content }) => (
                <Listaccordion heading={heading} content={content} />
        ))} */}
      </ul>
            </div>
        </div>
        <div className="row">
        {/*<h5>2021</h5>
        <hr/>
         <div className="col-md-2">
        <Link to="/archiveslist" className="btn btn-outline-dark float-center">Issue 1</Link>
        </div> */}


        
        </div>
    </div>

  
  
</div>

</div>

</section>


</main>  
</>
  )
}

export default Archives;
