import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Accordion from '../../components/Dslll/Accordion';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import Dlllarticletemplate from '../../downloads/DS-LLL-Article_Template.doc';
import Dlllcopyrightform from '../../downloads/DS-LLL-Copyright_Form.docx';
import './Dsllleditorialboard.css';

const Dsllleditorialboard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const accordionData = [{
    content: <div>
      <HashLink smooth to="/lll" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Aim and Scope</HashLink>
      <NavLink to="/lll/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
      <NavLink to="/lll/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
      <NavLink to="/lll/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
      <NavLink to="/lll/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
      <NavLink to="/lll/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
      <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
      <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
      <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
      <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
      <NavLink to="/lll/article-processing-charge" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
      {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
      <a href={Dlllarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
      <a href={Dlllcopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>

    </div>
  },
  ];
  return (
    <>

      <Helmet>
      <title>Editorial Board | DS Journal of Language, Linguistics and Literature</title>
      <link rel="canonical" href="https://dsjournals.com/lll/editorial-board"/>
      <meta name="title" content="Editorial Board | DS Journal of Language, Linguistics and Literature"/>
      <meta name="description" content="Meet the distinguished editorial board of Language, Linguistics and Literature, composed of experts in Language, Linguistics and Literature and related fields from around the world."/>
      <meta name="keywords" content="editorial board, editorial board members, editorial department, journal editorial board, become editorial board member, editorial member, editorial editor, board editorial, editorial team members, journal editorial board positions, editorial committee, editorial board positions."/>
      <meta property="og:type" content="Website"/>
      <meta property="og:site_name" content="Dream Science"/>
      <meta property="og:url" content="https://dsjournals.com/lll/editorial-board"/>
      <meta property="og:title" content="Editorial Board | DS Journal of Language, Linguistics and Literature"/>
      <meta property="og:description" content="Meet the distinguished editorial board of Language, Linguistics and Literature, composed of experts in Language, Linguistics and Literature and related fields from around the world."/>
      <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Language-Linguistics-and-Literature.webp"/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:description" content="Meet the distinguished editorial board of Language, Linguistics and Literature, composed of experts in Language, Linguistics and Literature and related fields from around the world."/>
      <meta name="twitter:site" content="@DreamScience4"/>
      <meta name="twitter:title" content="Editorial Board | DS Journal of Language, Linguistics and Literature"/>
      <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
      <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Language-Linguistics-and-Literature.webp"/>
      <meta name="robots" content="index, follow"/>
      </Helmet>
      <section id="hero-no-slide-dlll" className="d-flex justify-cntent-center align-items-center" style={{ height: "300px" }}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="row justify-content-center">
                {/* <div className="col-xl-8">
                  <h2>Editorial Board</h2>
                </div> */}
                <div className="col-lg-12">
                                    <h2 style={{ fontSize: "25px" }}>DS Journal of Language, Linguistics and Literature ( DS-LLL )</h2>
                                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            {/* <h2>Editor Spotlight</h2> */}
            <ol>
              <li><Link to="/home">Home</Link></li>
              <li><Link to="/journals">Journals</Link></li>
              <li><Link to="/lll">DS-LLL</Link></li>
              <li>Editorial Board</li>
            </ol>
          </div>
        </div>
      </section>
      <main id="main">
        <section className="blog" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 mb-3">
                <div className="list-group">
                  <div className="accordion">
                    {accordionData.map(({ content }) => (
                      <Accordion content={content} />
                    ))}
                  </div>
                </div>

                <div className="abtlistgrp">
                  {/* <div className="card">
                  <ul className="list-group list-group-flush">
                    <Link to="/submission"  className="list-group-item btn-submit"><i className="bi bi-file-arrow-up"></i>Submit</Link>
                      <Link to="/journalprofile"  className="list-group-item"><i className="bi bi-book"></i>Journal Profile</Link>
                        <Link to="/specialissues" className="list-group-item"><i className="bi bi-award"></i>Special Issues</Link>

                  </ul>
                </div> */}
                </div>
              </div>
              <div id='head1' className="col-lg-9">
                <h3>Editorial Board</h3>
                <div className='row'>
                  <h5 className='text-left'>
                    <span style={{
                      backgroundColor: "#94c045",
                      color: "#fff",
                      lineHeight: "43px",
                      padding: "5px 10px",
                      whiteSpace: "pre-wrap"
                    }}>Editor in Chief</span></h5>
                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}>Prof. Dr. Reza Kafipour,</div> <p style={{ textAlign: "left", fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-justify text-muted">
                      Department of English <br />
                      Shiraz University of Medical Sciences, <br />
                      Shiraz, Iran.
                      <br />Kafipour@sums.ac.ir<br />
                      <a href='https://paramed.sums.ac.ir/Dorsapax/userfiles/Sub82/%D8%B2%D8%A8%D8%A7%D9%86%20%D8%A7%D9%86%DA%AF%D9%84%DB%8C%D8%B3%DB%8C/%D9%81%D8%A7%DB%8C%D9%84/cv/kafipour.pdf' target="_balnk">Profile Link</a></p>
                  </div>


                </div>

                <div className='row'>
                  <h5 style={{ textAlign: "left" }}><span style={{
                    backgroundColor: "#94c045",
                    color: "#fff",
                    lineHeight: "43px",
                    padding: "5px 10px",
                    whiteSpace: "pre-wrap"
                  }}>Associate Editor</span></h5>
                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}> Prof. Dr. Felix Julca Guerrero, </div>
                    <p style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-left text-muted">
                      Department of Linguistics,<br /> Universidad Nacional Santiago Antúnez de Mayolo,<br />  Perú.
                      <br />fjulca@unasam.edu.pe<br />
                      {/* <a href='https://ctivitae.concytec.gob.pe/appDirectorioCTI/VerDatosInvestigador.do?id_investigador=32459' target="_balnk">Profile Link 1</a> | <a href='https://orcid.org/0000-0001-5637-5440' target="_balnk">Profile Link 2</a></p> */}
                      <a href='https://ctivitae.concytec.gob.pe/appDirectorioCTI/VerDatosInvestigador.do?id_investigador=32459' target="_balnk">Profile Link</a></p>
                  </div>
                </div>


                <div className='row'>
                  <h5 className='text-left'><span style={{
                    backgroundColor: "#94c045",
                    color: "#fff",
                    lineHeight: "43px",
                    padding: "5px 10px",
                    whiteSpace: "pre-wrap"
                  }}>Editorial Board Members</span></h5>
                  

                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}>Dr. S. Ganesan, </div>
                    <p style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-left text-muted">
                      Department of English,<br /> H.H. The Rajah's College (Autonomous) ,<br /> Tamilnadu, India.
                      <br />drganesan@hhrc.ac.in<br />
                      <a href='https://www.hhrc.ac.in/English_Staff_Profile/Dr_S_Ganesan.pdf' target="_balnk">Profile Link</a></p>
                  </div>
                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}> Dr. Ambreen Safder Kharbe, </div>
                    <p style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-left text-muted">
                      Department of English<br />College of Languages and Translation,<br /> Najran University, Najran,<br /> KSA.
                      <br />askharbe@nu.edu.sa<br />
                      <a href='https://www.nu.edu.sa/en/web/askharbe' target="_balnk">Profile Link</a></p>
                  </div>
                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}>Dr. G. Kannaiyan, </div>
                    <p style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-left text-muted">
                      Department of English,<br /> St.Jospeh's College (Autonomous),<br /> Tamilnadu, India.
                      <br />kannaiyan_en2@mail.sjctni.edu<br />
                      <a href='https://www.sjctni.edu/Department/StaffProfile/F12EN89.pdf' target="_balnk">Profile Link</a></p>
                  </div>
                  <div className='col-lg-12'>
                    <div className='text-left' style={{ fontWeight: "bold" }}>Dr. M. Leena Chandrika, </div>
                    <p style={{ fontSize: "12px", fontFamily: "sans-serif", fontWeight: "bold" }} className="text-left text-muted">
                      Department of English,<br /> Sri Ramakrishna Engineering College,<br /> Tamilnadu, India.
                      <br />leena.mahalingam@srec.ac.in<br />
                      <a href='https://srec.ac.in/subdepartment/english/faculty/details/356' target="_balnk">Profile Link</a></p>
                  </div>
                </div>



              </div>

            </div>
          </div>

        </section>


      </main>
    </>
  )
}

export default Dsllleditorialboard