import React, { Component } from 'react';
import { Link } from 'react-router-dom';



class Dcontent extends Component {

    render() {

        return (
            <>
                <section className="home-section">

                    <div className='row'>
                        <div className='col-lg-3 col-md-3 col-sm-3'>
                            <div className="home-content">
                                <div className="overview-boxes">

                                    <div className="box">
                                        <div className="right-side">
                                            <div className="box-topic">Current Issues</div>
                                            <div className="number">J</div>
                                            <span className="text">ournals</span>
                                            <Link to="/dashboard/currentissue">
                                                <div className="indicator">
                                                    <i className='bx bx-right-arrow-alt'></i>
                                                    <span className="text">View More</span>
                                                </div></Link>
                                        </div>
                                        <i className='bx bx-award award'></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-3'>
                            <div className="home-content">
                                <div className="overview-boxes">

                                    <div className="box">
                                        <div className="right-side">
                                            <div className="box-topic">Issues</div>
                                            <div className="number">L</div>
                                            <span className="text">ist</span>
                                            <Link to="/issuelist">
                                                <div className="indicator">
                                                    <i className='bx bx-right-arrow-alt'></i>
                                                    <span className="text">View More</span>
                                                </div></Link>
                                        </div>
                                        <i className='bx bx-award award'></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>

                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className="home-content">
                                {/* <div className="overview-boxes"> */}
                                <div className='row'>
                                    <div className='col-sm-3 col-md-3'>
                                        <div className="overview-boxes">
                                            <div className="box">
                                                <div className="right-side">
                                                    <div className="box-topic">DS DST</div>
                                                    <div className="number">J</div>
                                                    <span className="text">ournals</span>
                                                    <Link to="/dviewjournal">
                                                        <div className="indicator">
                                                            <i className='bx bx-right-arrow-alt'></i>
                                                            <span className="text">View More</span>
                                                        </div></Link>
                                                </div>
                                                <i className='bx bx-award award'></i>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='col-sm-3 col-md-3'>
                                        <div className="overview-boxes">
                                            <div className="box">
                                                <div className="right-side">
                                                    <div className="box-topic">DS LLL</div>
                                                    <div className="number">J</div>
                                                    <span className="text">ournals</span>
                                                    <Link to="/dlll/dslllviewjournal">
                                                        <div className="indicator">
                                                            <i className='bx bx-right-arrow-alt'></i>
                                                            <span className="text">View More</span>
                                                        </div></Link>
                                                </div>
                                                <i className='bx bx-award award'></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-sm-3 col-md-3'>
                                        <div className="overview-boxes">


                                            <div className="box">
                                                <div className="right-side">
                                                    <div className="box-topic">DS AIR</div>
                                                    <div className="number">J</div>
                                                    <span className="text">ournals</span>
                                                    <Link to="/dashboard/dsair/dsairviewjournal">
                                                        <div className="indicator">
                                                            <i className='bx bx-right-arrow-alt'></i>
                                                            <span className="text">View More</span>
                                                        </div></Link>
                                                </div>
                                                <i className='bx bx-award award'></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-sm-3 col-md-3'>
                                        <div className="overview-boxes">
                                            <div className="box">
                                                <div className="right-side">
                                                    <div className="box-topic">DS CYS</div>
                                                    <div className="number">J</div>
                                                    <span className="text">ournals</span>
                                                    <Link to="/dashboard/dscys/dscysviewjournal">
                                                        <div className="indicator">
                                                            <i className='bx bx-right-arrow-alt'></i>
                                                            <span className="text">View More</span>
                                                        </div></Link>
                                                </div>
                                                <i className='bx bx-award award'></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3 col-md-3'>
                                        <div className="overview-boxes">


                                            <div className="box">
                                                <div className="right-side">
                                                    <div className="box-topic">DS MS</div>
                                                    <div className="number">J</div>
                                                    <span className="text">ournals</span>
                                                    <Link to="/dashboard/dsms/dsmsviewjournal">
                                                        <div className="indicator">
                                                            <i className='bx bx-right-arrow-alt'></i>
                                                            <span className="text">View More</span>
                                                        </div></Link>
                                                </div>
                                                <i className='bx bx-award award'></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-sm-3 col-md-3'>
                                        <div className="overview-boxes">
                                            <div className="box">
                                                <div className="right-side">
                                                    <div className="box-topic">DSM</div>
                                                    <div className="number">J</div>
                                                    <span className="text">ournals</span>
                                                    <Link to="/dashboard/dsm/dsmviewjournal">
                                                        <div className="indicator">
                                                            <i className='bx bx-right-arrow-alt'></i>
                                                            <span className="text">View More</span>
                                                        </div></Link>
                                                </div>
                                                <i className='bx bx-award award'></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-sm-3 col-md-3'>
                                        <div className="overview-boxes">
                                            <div className="box">
                                                <div className="right-side">
                                                    <div className="box-topic">DS CE</div>
                                                    <div className="number">J</div>
                                                    <span className="text">ournals</span>
                                                    <Link to="/dashboard/dsce/dsceviewjournal">
                                                        <div className="indicator">
                                                            <i className='bx bx-right-arrow-alt'></i>
                                                            <span className="text">View More</span>
                                                        </div></Link>
                                                </div>
                                                <i className='bx bx-award award'></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-sm-3 col-md-3'>
                                        <div className="overview-boxes">
                                            <div className="box">
                                                <div className="right-side">
                                                    <div className="box-topic">DS RLS</div>
                                                    <div className="number">J</div>
                                                    <span className="text">ournals</span>
                                                    <Link to="/dashboard/dsrls/dsrlsviewjournal">
                                                        <div className="indicator">
                                                            <i className='bx bx-right-arrow-alt'></i>
                                                            <span className="text">View More</span>
                                                        </div></Link>
                                                </div>
                                                <i className='bx bx-award award'></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-sm-3'>
                                    <div className="overview-boxes">
                                    </div>
                                </div>






                                {/* </div> */}
                            </div>
                        </div>
                    </div>




                </section>
            </>
        )
    }
}
export default Dcontent;