import React,{useState} from 'react';
import './Llistaccordion.css';

const Llistaccordion = ({ heading, content }) => {
    const [isActive, setIsActive] = useState(false);
  return (
    <li className="accordion-item">
    <div id="laccordion-toggle" className="accordion-toggle" onClick={() => setIsActive(!isActive)}>     
        <span className="accordion-icon" style={{marginRight:"10px"}}>{isActive ? '-' : '+'}</span><h6>{heading}</h6>
    </div>
{isActive && <div className="accordion-content">{content}</div>}
</li>
  );
};

export default Llistaccordion;